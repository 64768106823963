import {gql} from '@apollo/client';
import {NOTIFICATION_WORKFLOWS_COMMON_FIELDS} from '../fragments';

export const NOTIFICATION_WORKFLOWS_GET_BY_NOTIF_ID = gql`
${NOTIFICATION_WORKFLOWS_COMMON_FIELDS}
query GetNotificationWorkflowsByNotifId($notifId: Int!) {
  notificationWorkflows(where: {notifId: {equals: $notifId}}, orderBy: [{date: {sort: desc}}, {id: desc}]) {
    ...NotificationWorkflowCommonFields
  }
}
`;

export const NOTIFICATION_WORKFLOWS_CREATE = gql`
${NOTIFICATION_WORKFLOWS_COMMON_FIELDS}
mutation CreateNotificationWorkflow($data: NotificationWorkflowUncheckedCreateInput!) {
  createOneNotificationWorkflow(data: $data) {
    ...NotificationWorkflowCommonFields
  }
}
`;

export const NOTIFICATION_WORKFLOWS_GET_COUNTER = gql`
query GetNotificationWorkflowsCounter {
  aggregateNotificationWorkflow {
    _count {
      id
    }
  }
}
`;
