import {gql} from '@apollo/client';
import {WORKORDER_REF_EVENT_TYPES_COMMON_FIELDS} from '../fragments';

export const WORKORDER_REF_EVENT_TYPES_GET_MANY = gql`
${WORKORDER_REF_EVENT_TYPES_COMMON_FIELDS}
query GetAllRefEventTypes($orderBy: [RefEventTypeOrderByWithRelationInput!], $where: RefEventTypeWhereInput) {
    refEventTypes(orderBy: $orderBy, where: $where) {
      ...RefEventTypeCommonFields
    }
}`;

export const WORKORDER_REF_EVENT_TYPES_GET_COUNTER = gql`
query GetRefEventTypesCounter {
  aggregateRefEventType {
    _count {
      id
    }
  }
}
`;

