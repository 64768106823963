import {gql} from '@apollo/client';

export const DOCUMENTATION_CATALOGS_COMMON_FIELDS = gql`
    fragment RefDocsCatalogCommonFields on RefDocsCatalog {
        id
        description
        code
        category
        isActive
    }
`;
