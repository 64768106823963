import {gql} from '@apollo/client';

export const WORKORDER_EVENT_INSPECTION_DRAWINGS_COMMON_FIELDS = gql`
    fragment EventInspectionDrawingCommonFields on EventInspectionDrawing {
      id
      idwgId
      sort
      status
      wrkoId
    }
`;
