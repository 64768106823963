import {useTranslation} from 'react-i18next';
import PanelFilters from '@app/components/Common/Panel/PanelFilters';
import {TDbSchema, TLabelValue} from '@app/types/app';
import {TDbTablesGroup} from '..';
import {useMemo} from 'react';
import {useAdminImportExportStore} from '@app/stores/admin/importExport';

type TFilters = Readonly<{
    onChange?: (item: TLabelValue<string>[]) => void
    value?: TLabelValue<string>|string
    dbGroups?: TDbTablesGroup[]
    allDbGroups?: TDbSchema[]
}>

export default function Filters({onChange, value, dbGroups, allDbGroups}: TFilters) {
  const {searchInput} = useAdminImportExportStore();
  const {t} = useTranslation();
  const filters: TLabelValue<string>[] = useMemo(() => [
    {
      label: 'label.all',
      value: 'all',
    },
    ...(allDbGroups?.map((item: TDbSchema) => ({
      label: item.title ?? `label.dbSchemas.${item.name}.title`,
      value: item.name,
    })) ?? []),
  ].filter((item: TLabelValue<string>) => item.value === 'all' || searchInput === '' || !!dbGroups?.find((dbGroup: TDbTablesGroup) => dbGroup.name === item.value)), [dbGroups]);

  return (
    <PanelFilters
      items={filters.map(item => ({...item, label: t(item.label)}))}
      value={value}
      onChange={onChange}
    />
  );
}
