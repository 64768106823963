/* eslint-disable max-depth */
import React from 'react';
import {Sector} from '@app/graphql/__types__/graphql';
import {TLabelValueField, TMaybeCodeDescriptionDatas} from '@app/types/app';
import {EFieldType, EInputFieldType} from '../enums';
import moment from 'moment';
import {FORMAT_DATE_DEFAULT} from '../constants';

export const renderSectorLabel = (item?: Sector|null, defaultValue = '') => item ? `[${item.sector}] - ${item.description}` : defaultValue;
export const renderCodeAndDescription = (field?: TMaybeCodeDescriptionDatas | null, defaultValue = '') => field && (field.code || field.description) ? `[${field.code ?? ''}] - ${field.description ?? ''}` : defaultValue;

export const handleFormInputBlur = (e: React.FocusEvent<HTMLInputElement|HTMLTextAreaElement>, fieldRow: TLabelValueField, callback?: (fieldRow: TLabelValueField, value: unknown) => void) => {
  if (!e.currentTarget?.contains(e.relatedTarget)) {
    if (EInputFieldType.includes(fieldRow.fieldType ?? EFieldType.text)) {
      const newValue = e.target.value ?? '';
      if (fieldRow.fieldType === EFieldType.date) {
        if (fieldRow.value && fieldRow.value !== '') {
          if (newValue !== '') {
            const currentDate = moment(fieldRow.value);
            const newDate = moment(newValue);
            if (currentDate.isValid() && newDate.isValid() && currentDate.format(FORMAT_DATE_DEFAULT) === newDate.format(FORMAT_DATE_DEFAULT)) {
              e.preventDefault();
              e.stopPropagation();
              return;
            }
          }
        } else if (newValue === '') {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
      }

      callback?.(fieldRow, newValue);
    }
  }
};

export const handleFormInputKeydown = (e: React.KeyboardEvent<HTMLInputElement|HTMLTextAreaElement>, fieldRow: TLabelValueField, setInputValue?: (value: string|undefined)=>void, handleFieldChange?: (field: string, value: unknown) => void) => {
  if (e.key === 'Escape' || (e.key === 'z' && e.ctrlKey) || (e.key === 'z' && e.metaKey)) {
    let field = null;
    if (fieldRow.fieldType === EFieldType.autocomplete) {
      setInputValue?.(fieldRow.initialValue ? String(fieldRow.initialValue) : '');
    } else if (EInputFieldType.includes(fieldRow.fieldType ?? EFieldType.text)) {
      field = fieldRow.field;
    }

    if (field !== null) {
      handleFieldChange?.(field!, fieldRow.initialValue);
    }

    e.preventDefault();
    e.stopPropagation();
  } else if (e.key === 'Enter') {
    if (fieldRow.fieldType === EFieldType.autocomplete) {
      setInputValue?.(fieldRow.initialValue ? String(fieldRow.initialValue) : '');
    } else if (fieldRow.inputComponent === 'textarea') {
      if (e.shiftKey) {
        return;
      }
    }

    e.currentTarget.blur();
  }
};
