import {Component, PropsWithChildren} from 'react';
import React from 'react';

interface ILoaderProps extends PropsWithChildren {
    readonly isLoading: boolean,
    // readonly progress?: number,
    readonly display?: string
}
interface ILoaderState { }

export default class LoaderMinimalist extends Component<ILoaderProps, ILoaderState> {
  static defaultProps = {
    // progress: 0,
    display: undefined,
  };

  render() {
    return (
      <div className='h-full w-full overflow-hidden'>
        {this.props.isLoading
                    && <div className={`bg-white opacity-80 ${this.props.display ? this.props.display : 'fixed'} inset-0 h-full w-full z-90 flex justify-center items-center align-middle`}>
                      <div>
                        <ul className='loaderr'>
                          <li className='loader__item'/>
                          <li className='loader__item'/>
                          <li className='loader__item'/>
                        </ul>
                      </div>
                    </div>}
        {this.props.children}

        <style>{`
                    .loaderr {
                        display: flex;
                    }
                    .loader__item {
                        width: 16px;
                        height: 16px;
                        margin: 4px;
                        background: #282444;
                        border-radius: 50%;
                        list-style: none;
                        -webkit-animation: animation-loading 1.2s infinite;
                        animation: animation-loading 1.2s infinite;
                    }
                    .loader__item:nth-child(1) {
                        -webkit-animation-delay: -0.3s;
                        animation-delay: -0.3s;
                    }
                    .loader__item:nth-child(2) {
                        -webkit-animation-delay: -0.15s;
                        animation-delay: -0.15s;
                    }
                    @-webkit-keyframes animation-loading {
                        0% {
                            transform: scale(0);
                        }
                        40% {
                            transform: scale(1);
                        }
                        80% {
                            transform: scale(0);
                        }
                        100% {
                            transform: scale(0);
                        }
                    }
                    @keyframes animation-loading {
                        0% {
                            transform: scale(0);
                        }
                        40% {
                            transform: scale(1);
                        }
                        80% {
                            transform: scale(0);
                        }
                        100% {
                            transform: scale(0);
                        }
                    }
                `}
        </style>
      </div>
    );
  }
}

