import {gql} from '@apollo/client';
import {NOTIFICATION_REF_TYPES_COMMON_FIELDS} from '../fragments';

export const NOTIFICATION_REF_TYPES_GET_MANY = gql`
${NOTIFICATION_REF_TYPES_COMMON_FIELDS}
query GetAllRefNotifTypes($orderBy: [RefNotifTypeOrderByWithRelationInput!], $where: RefNotifTypeWhereInput) {
    refNotifTypes(orderBy: $orderBy, where: $where) {
      ...RefNotifTypeCommonFields
    }
}`;

export const NOTIFICATION_REF_TYPES_GET_COUNTER = gql`
query GetRefNotifTypesCounter {
  aggregateRefNotifType {
    _count {
      id
    }
  }
}
`;
