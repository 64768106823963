import {useMutation} from '@apollo/client';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import {Event, UpdateEventByIdMutation} from '@app/graphql/__types__/graphql';
import {WORKORDER_EVENTS_UPDATE_BY_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useEventStore} from '@app/stores/event';
import {useLayoutStore} from '@app/stores/layout';
import {useHolisAuth} from '@holis/auth-client-react';
import {t} from 'i18next';
import {useState} from 'react';
import {LuClipboardList} from 'react-icons/lu';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export type ReportSummaryProps = {
	className?: string;
  readonly?: boolean;
}
export default function ReportSummary({className, readonly}: Readonly<ReportSummaryProps>) {
  const {updateEvent, editEvent, activeEvent, createEventWorkflowFunc} = useEventStore();
  const {startLoading, stopLoading} = useLayoutStore();
  const [updateEventById] = useMutation<UpdateEventByIdMutation>(WORKORDER_EVENTS_UPDATE_BY_ID);
  const [summary, setSummary] = useState<string>(activeEvent?.reportSummary ?? '');
  const {user} = useHolisAuth();

  const handleUpdateEventReportSummary = () => {
    const actionDate = new Date();
    if (summary !== activeEvent?.reportSummary) {
      startLoading();
      updateEventById({variables: {id: activeEvent?.id!, data: {reportSummary: {set: summary}}}}).then(newData => {
        const newEvent = {
          ...newData?.data?.updateOneEvent,
        } as Partial<Event>;
        if (newEvent.status !== editEvent?.status) {
          createEventWorkflowFunc?.({
            variables: {
              data: {
                userLogin: user?.username,
                actionDate,
                status: newEvent.status,
                wrkoId: activeEvent?.id!,
              }}});
        }

        updateEvent(newEvent);

        AppNotifications.success(t('message.success.eventUpdated'));
        return true;
      }).catch((_err: Error) => {
        const errorMessage: string = t('message.error.default.title');

        AppNotifications.error(errorMessage);
        return false;
      }).finally(() => {
        stopLoading();
      });
    }
  };

  return (
    <div className={className}>
      <FormGroupHeader className='mb-3'><LuClipboardList className='mr-2' size={20}/> {t('label.inspectionSummary')}</FormGroupHeader>

      <div className='h-[330px]'>
        <ReactQuill
          key={editEvent?.reportSummary}
          readOnly={readonly}
          className={`mr-2 h-[290px]${readonly ? ' opacity-50' : ''}`} theme='snow' defaultValue={summary}
          onChange={setSummary} onBlur={handleUpdateEventReportSummary}/>
      </div>
    </div>
  );
}
