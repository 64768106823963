import {DndContext, DragEndEvent, DragOverlay, DragStartEvent, UniqueIdentifier} from '@dnd-kit/core';
import {Props as DndContextProps} from '@dnd-kit/core/dist/components/DndContext/DndContext';
import {SortableContext} from '@dnd-kit/sortable';
import {Props as SortableContextProps} from '@dnd-kit/sortable/dist/components/SortableContext';
import React, {useState} from 'react';

export type SortableItem = {
  id: UniqueIdentifier
}

type TSortableList<TItem> = Readonly<{
  items: TItem[];
  onSortStart?: (activeItem: TItem) => void;
  onSortChange?: (activeItem: TItem, dropItem: TItem, dropItemIdex: number) => void;
  renderItem: (item: TItem, idx?: number) => React.ReactNode;
  renderActiveItem?: (item: TItem) => React.ReactNode;
  dndContext?: Partial<DndContextProps>;
  sortableContext?: Partial<SortableContextProps>;
  noDataMessage?: React.ReactNode;
}>;

export default function SortableList<TItem extends SortableItem = {id: UniqueIdentifier}>({items, onSortStart, renderActiveItem, onSortChange, renderItem, dndContext, sortableContext, noDataMessage}: TSortableList<TItem>) {
  const [activeItem, setActiveItem] = useState<TItem | undefined>();
  const handleChangeSortStart = (e: DragStartEvent) => {
    const item = items.find((item: TItem) => item.id === e.active.id);
    setActiveItem(item);
    if (item) {
      onSortStart?.(item);
    }
  };

  const handleChangeSortEnd = ({active, over}: DragEndEvent) => {
    const dragItemId = active.id;
    const dropItemId = over?.id;
    if (dragItemId !== dropItemId) {
      const dragItem = items?.find((item: TItem) => item.id === dragItemId);
      const dropItemIdx = items?.findIndex((item: TItem) => item.id === dropItemId) ?? -1;
      if (dragItem && dropItemIdx >= 0) {
        onSortChange?.(dragItem, items[dropItemIdx], dropItemIdx);
      }
    }

    setActiveItem(undefined);
  };

  return (
    items?.length ? <DndContext {...dndContext} cancelDrop={() => false} onDragStart={handleChangeSortStart} onDragEnd={handleChangeSortEnd}>
      <SortableContext {...sortableContext} disabled={!items?.length || items.length <= 1} items={(items ?? []).map((item: TItem) => item.id!)}>
        {items?.map((item: TItem, index: number) => (
          renderItem(item, index)
        ))}
      </SortableContext>
      {activeItem && renderActiveItem && <DragOverlay>
        <div className='cursor-move'>{renderActiveItem(activeItem)}</div>
      </DragOverlay>}
    </DndContext>
      : <div className='flex justify-center flex-col items-center text-gray-500 w-full'>{noDataMessage}</div>);
}
