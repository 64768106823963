import {gql} from '@apollo/client';
import {DAMAGE_REF_CLASSES_COMMON_FIELDS} from '../fragments';

export const DAMAGE_REF_CLASSES_GET_MANY = gql`
${DAMAGE_REF_CLASSES_COMMON_FIELDS}
query GetAllRefDamageClasses($orderBy: [RefDamageClassOrderByWithRelationInput!], $where: RefDamageClassWhereInput) {
    refDamageClasses(orderBy: $orderBy, where: $where) {
      ...RefDamageClassCommonFields
    }
}`;

export const DAMAGE_REF_CLASSES_GET_COUNTER = gql`
query GetRefDamageClassesCounter {
  aggregateRefDamageClass {
    _count {
      id
    }
  }
}
`;
