import {MENU_GROUP_COLORS} from '@app/utils/constants';
import {useTranslation} from 'react-i18next';
import FirstLetterBadge from '@app/components/Common/FirstLetterBadge';
import {MENU_ITEMS} from '@app/components/Menu';
import {ILinkGroup} from '@holis/react-ui';

export function DocumentationBadge() {
  const {t} = useTranslation();
  const index = MENU_ITEMS.findIndex((item: ILinkGroup) => item.groupName === t('label.documentation'));
  return (
    <FirstLetterBadge
      iconColor={MENU_GROUP_COLORS[index > -1 ? index : 0]}
      title={t('label.documentation')}
    />
  );
}

export default function DocumentationPage() {
  return (
    <>
      Documentation
    </>
  );
}
