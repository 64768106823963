import React from 'react';
import {useTranslation} from 'react-i18next';
import {IConfirmModal} from '..';
import WarningConfirmModal from '../Warning';

type TExitWithoutSavingModal = IConfirmModal;

export default function ExitWithoutSavingModal({...restProps}: TExitWithoutSavingModal) {
  const {t} = useTranslation();
  return (
    <WarningConfirmModal
      description={t('message.questions.exitWithoutSaving')}
      {...restProps}
    />
  );
}
