import {useTranslation} from 'react-i18next';
import {handleFormInputKeydown, renderCodeAndDescription, setObjValueByPath} from '@app/utils/functions';
import React, {InputHTMLAttributes, TextareaHTMLAttributes, useEffect, useRef, useState} from 'react';
import {TFieldsBlock, TMaybeCodeDescriptionDatas, TRenderAutocompleteProps} from '@app/types/app';
import FormFieldsBlock from '@app/components/Common/Form/FormFieldsBlock';
import {MAX_LENGTH_VALIDATORS, useEventStore, ZOD_EVENT_DATAS} from '@app/stores/event';
import {Event, EventUncheckedUpdateInput, GetAllRefFlocCatalogsQuery, RefFlocCatalogs, UpdateEventByIdMutation} from '@app/graphql/__types__/graphql';
import {EFieldType, EFLOC_CATALOGS_CATEGORY, EWORKORDER_EVENT_PRIORITY, EWORKORDER_EVENT_STAGE} from '@app/utils/enums';
import {FetchResult, useMutation, useQuery} from '@apollo/client';
import {WORKORDER_EVENTS_UPDATE_BY_ID} from '@app/graphql/requests';
import AppAutocomplete from '@app/components/Common/Form/Autocomplete';
import _ from 'lodash';
import {FLOC_CATALOGS_GET_MANY} from '@app/graphql/requests/refFlocCatalogs';
import {RadBadge, RadForm} from '@holis/react-ui/rad';
import {Link} from 'react-router-dom';
import {ROUTE_SCHEDULING_INSPECTION_PLAN_DETAIL} from '@app/utils/constants';
import {z} from 'zod';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import AppNotifications from '@app/services/notification';
import {useHolisAuth} from '@holis/auth-client-react';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import {LuClipboardList} from 'react-icons/lu';

type TEventInfo = Readonly<{
  readonly event: Partial<Event>
  readonly readonly?: boolean;
}>;

export default function EventInfo({event, readonly}: TEventInfo) {
  const {t} = useTranslation();
  const {user} = useHolisAuth();
  const {setUpdateData, createEventWorkflowFunc, updateEvent, updateDataField, updateData, updateSchedulingTagData, updateSchedulingTagDataField, editEvent, setEditEvent, hasFieldError} = useEventStore();
  const flocCatalogResult = useQuery<GetAllRefFlocCatalogsQuery>(FLOC_CATALOGS_GET_MANY);
  const [updateEventByIdApi] = useMutation<UpdateEventByIdMutation>(WORKORDER_EVENTS_UPDATE_BY_ID);
  const [submitRequested, setSubmitRequested] = useState<boolean>(false);

  const zodFormObject = z.object(_.omit(ZOD_EVENT_DATAS(t)));
  const form = useForm<z.infer<typeof zodFormObject>>(
    {resolver: zodResolver(zodFormObject), mode: 'onBlur'});

  const htmlForm = useRef<HTMLFormElement | null>(null);

  const handleFieldChange = (field: string, value: unknown, update?: boolean) => {
    const editedEvent = {...editEvent};
    setObjValueByPath(editedEvent, field, value);
    setEditEvent(editedEvent);
    if (update) {
      updateDataField(field, value);
    }
  };

  const renderAutocomplete = (props: TRenderAutocompleteProps) : React.ReactNode => {
    const {fieldRow, setInputValue, renderMenuItemLabel, dbValue, field, foreignField, inputProps} = props ?? {};
    return (
      <AppAutocomplete
        onSelect={(item: Record<string, unknown> | null) => {
          if (
            (updateData && Object.keys(updateData).includes(field!))
            || (updateSchedulingTagData && Object.keys(updateSchedulingTagData).includes(field!))
            || (!((updateData && Object.keys(updateData).includes(field!))
            || (updateSchedulingTagData && Object.keys(updateSchedulingTagData).includes(field!)))
            && (item?.id ?? null) !== dbValue)) {
            if (!!item && typeof renderMenuItemLabel!(item) === 'string') {
              setInputValue?.(renderMenuItemLabel!(item) as string);
            }

            const editedEvent = _.cloneDeep(editEvent);
            if (foreignField) {
              setObjValueByPath(editedEvent!, foreignField!, item);
            }

            if (field) {
              setObjValueByPath(editedEvent!, field!, item?.id ?? null);
            }

            setEditEvent(editedEvent);
            if (field === 'schedulingTag.gridId') {
              updateSchedulingTagDataField('gridId', item?.id ?? null);
            } else {
              updateDataField(field!, item?.id ?? null);
            }

            setSubmitRequested(true);
          }
        }}
        {...props}
        inputProps={{
          ...inputProps,
          onKeyDown: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => handleFormInputKeydown(e, fieldRow!, val => setInputValue?.(val ?? ''), handleFieldChange),
        }}
      />
    );
  };

  const handleUpdateEvent = () => {
    const actionDate = new Date();
    return updateEventByIdApi({variables: {id: event!.id, data: updateData}}).then(queryResult => {
      AppNotifications.success(t('message.success.eventUpdated'));
      const newEvent = {...(queryResult.data?.updateOneEvent ?? {})} as Partial<Event>;
      if (newEvent.status && newEvent.status !== editEvent?.status) {
        createEventWorkflowFunc?.({
          variables: {
            data: {
              userLogin: user?.username,
              actionDate,
              status: newEvent.status,
              wrkoId: event.id!,
            }}});
      }

      updateEvent(newEvent);
      setUpdateData({});
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'));
    });
  };

  const handleFormSubmitSucess = () => {
    if (!updateData) {
      return;
    }

    // Check if values are different between event and updateData
    let hasChange = false;
    for (const key of Object.keys(updateData || {})) {
      if (event[key as keyof Event] !== updateData?.[key as keyof EventUncheckedUpdateInput]?.set) {
        hasChange = true;
        break;
      }
    }

    if (!hasChange) {
      return;
    }

    // Submit changes
    const eventNumberChanged = updateData.event && updateData.event !== event.event;
    handleUpdateEvent().then(() => {
      // If event number changed, modify last url segment to set the new event number
      if (eventNumberChanged) {
        const url = window.location.href;
        const urlParts = url.split('/');
        urlParts[urlParts.length - 1] = editEvent!.event!;
        window.history.replaceState(null, '', urlParts.join('/'));
      }
    });
  };

  useEffect(() => {
    setEditEvent(event);
    form.reset(event as Partial<typeof zodFormObject>);
  }, [event]);

  useEffect(() => {
    if (submitRequested) {
      htmlForm.current?.requestSubmit();

      setSubmitRequested(false);
    }
  }, [submitRequested]);

  if (!editEvent) {
    return null;
  }

  const priorities = Object.entries(EWORKORDER_EVENT_PRIORITY).filter(p => p[1] !== EWORKORDER_EVENT_PRIORITY.UNKNOWN).map(([label, value]) => ({label, value, id: value}));
  const selectedPriority = priorities.find(p => p.value === editEvent.priority);
  const fieldBlocks: TFieldsBlock[] = [
    {
      title: 'label.identification',
      fields: [
        {
          label: 'label.eventNumber',
          field: 'event',
          fieldType: EFieldType.text,
          className: 'w-[320px]',
          isRequired: true,
          isDisabled: true,
          renderInput: () => (<span className='px-2 font-semibold'>{event!.event}</span>),
        },
        {
          label: 'label.type',
          field: 'type.type',
          fieldType: EFieldType.text,
          className: 'flex-1',
          isRequired: true,
          isDisabled: true,
          renderInput: () => (<div className='ml-2'><RadBadge className=''>{event!.type?.type ?? ''}</RadBadge></div>),
        },
        {
          label: 'label.planNumber',
          field: 'plan',
          fieldType: EFieldType.text,
          isDisabled: true,
          className: 'w-full',
          renderInput: () => (
            <div className='ml-2'>
              {event!.plan
                ? <Link
                  to={ROUTE_SCHEDULING_INSPECTION_PLAN_DETAIL.replace(':number', event.plan.plan)}
                  target='_blank'
                  className='border-b'>{event.plan.plan}</Link>
                : '-'}
            </div>
          ),
        },
        {
          label: 'label.description',
          field: 'description',
          fieldType: EFieldType.text,
          hasError: hasFieldError('description'),
          isRequired: true,
          className: 'flex w-full',
          initialValue: event!.description,
          value: editEvent.description,
          inputProps: {
            maxLength: MAX_LENGTH_VALIDATORS.DESCRIPTION,
          } as InputHTMLAttributes<HTMLInputElement>,
        },
      ],
      fieldsClassName: 'w-full flex flex-row flex-wrap',
      fieldClassName: 'inline-flex w-auto',
    },
    {
      title: 'label.notes',
      fields: [
        {
          label: '',
          labelClassName: 'hidden',
          field: 'notes',
          fieldType: EFieldType.text,
          hasError: hasFieldError('notes'),
          initialValue: event!.notes,
          value: editEvent.notes,
          inputComponent: 'textarea',
          inputProps: {
            className: 'flex border-0 w-full rounded-md bg-transparent px-3 py-1 text-sm outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 transition-all hover:bg-muted focus-visible:shadow focus-visible:ring-1 focus-visible:ring-muted disabled:hover:bg-transparent cursor-pointer focus:cursor-auto',
            maxLength: MAX_LENGTH_VALIDATORS.NOTES,
            rows: 4,
          } as TextareaHTMLAttributes<HTMLTextAreaElement>,
        },
      ],
    },
    {
      title: <div className='flex items-center'><span className='w-[150px]'>{t('label.siteLocation')}</span></div>,
      fields: [
        {
          label: 'label.sector',
          field: 'siteSector',
          fieldType: EFieldType.text,
          isDisabled: true,
          renderInput: () => (event!.schedulingTag?.sector
            ? <div className='gap-2 flex items-center ml-2 opacity-50'>
              <span>[{event!.schedulingTag?.sector?.sector}] - {event!.schedulingTag?.sector?.description}</span>
              <RadBadge className='bg-muted text-muted-foreground hover:bg-muted'>{event!.schedulingTag?.sector?.site?.site}</RadBadge>
            </div> : ''),
        },
        {
          label: 'label.grid',
          field: 'schedulingTag.gridId',
          fieldType: EFieldType.text,
          value: editEvent.schedulingTag?.grid?.description,
          isDisabled: true,
          inputProps: {
            className: 'disabled:cursor-text',
          },
        },
      ],
    },
    {
      title: 'label.scheduling',
      fields: [
        {
          label: 'label.inspectionDate',
          field: 'inspectionDate',
          fieldType: EFieldType.date,
          initialValue: event.inspectionDate,
          value: editEvent.inspectionDate,
          className: 'w-full',
        },
        {
          label: 'label.plannedDate',
          field: 'plannedDate',
          fieldType: EFieldType.date,
          initialValue: event.plannedDate,
          value: editEvent.plannedDate,
          className: 'w-full',
        },
        {
          label: 'label.batchNumber',
          field: 'batchNumber',
          fieldType: EFieldType.text,
          hasError: hasFieldError('batchNumber'),
          initialValue: event!.batchNumber,
          value: editEvent.batchNumber,
          className: 'w-full',
          inputProps: {
            maxLength: MAX_LENGTH_VALIDATORS.BATCH_NUMBER,
          } as InputHTMLAttributes<HTMLInputElement>,
        },
        {
          label: 'label.priority',
          field: 'priority',
          fieldType: EFieldType.autocomplete,
          className: 'w-full',
          dbValue: event.priority,
          foreignField: 'priority',
          foreignObject: selectedPriority,
          items: priorities,
          listProps: {
            title: '',
            value: editEvent.priority,
          },
          renderInput: renderAutocomplete,
          renderMenuItemLabel: item => (item as {label: string})?.label,
        },
      ],
      fieldsClassName: 'w-full flex flex-row flex-wrap',
      fieldClassName: 'inline-flex w-auto',
    },
    {
      title: 'label.responsabilities',
      fields: [
        {
          label: 'label.plannerGroup',
          field: 'plannerGroupId',
          initialValue: renderCodeAndDescription(event!.plannerGroup as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription(editEvent!.plannerGroup as TMaybeCodeDescriptionDatas),
          foreignObject: editEvent.plannerGroup,
          foreignField: 'plannerGroup',
          dbValue: event!.plannerGroupId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: flocCatalogResult,
          getItemsFromResult: (result: FetchResult) => (result as FetchResult<GetAllRefFlocCatalogsQuery>)?.data?.findManyRefFlocCatalogs?.filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.PLAN_GROUP) ?? [],
          renderMenuItemLabel: field => renderCodeAndDescription(field as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
        {
          label: 'label.workCenter',
          field: 'mainWorkCenterId',
          initialValue: renderCodeAndDescription(event!.mainWorkCenter as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription(editEvent!.mainWorkCenter as TMaybeCodeDescriptionDatas),
          foreignObject: editEvent.mainWorkCenter,
          foreignField: 'mainWorkCenter',
          dbValue: event!.mainWorkCenterId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: flocCatalogResult,
          getItemsFromResult: (result: FetchResult) => (result as FetchResult<GetAllRefFlocCatalogsQuery>)?.data?.findManyRefFlocCatalogs?.filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.MAIN_WORK_CENTER) ?? [],
          renderMenuItemLabel: field => renderCodeAndDescription(field as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
      ],
    },
  ];

  return (
    <RadForm {...form}>
      <form
        ref={htmlForm} onSubmit={form.handleSubmit(handleFormSubmitSucess, data => console.log('form is Invalid', data, form.getValues()))}
      >
        <FormGroupHeader>
          <div className='flex items-center'>
            <LuClipboardList size={20} className='mr-2'/> {t('label.eventheader')}
          </div>
        </FormGroupHeader>

        <FormFieldsBlock
          isFormContext
          isDisabled={EWORKORDER_EVENT_STAGE.COMPLETED === event.statusWorkOrder?.stage || readonly}
          className='text-gray-700'
          fieldsBlocks={fieldBlocks}
          objectType='event'
          objectStatus={event.status}
          onFieldChange={handleFieldChange}
          onFieldBlur={() => setSubmitRequested(true)}
        />

      </form>
    </RadForm>
  );
}
