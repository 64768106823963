import {useMutation} from '@apollo/client';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import {FunctionalLocation, UpdateFlocByIdMutation} from '@app/graphql/__types__/graphql';
import {FLOCS_UPDATE_BY_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useLayoutStore} from '@app/stores/layout';
import {useFlocStore} from '@app/stores/methodEngineering/floc';
import {t} from 'i18next';
import {useState} from 'react';
import {LuClipboardList} from 'react-icons/lu';
import ReactQuill from 'react-quill';

export default function BackgroundBlock() {
  const {activeFloc, editFloc, updateFloc} = useFlocStore();
  const {startLoading, stopLoading} = useLayoutStore();
  const [updateFlocById] = useMutation<UpdateFlocByIdMutation>(FLOCS_UPDATE_BY_ID);
  const [summary, setSummary] = useState<string>(activeFloc?.longDescription ?? '');

  const handleUpdateLongDescription = () => {
    if (summary !== activeFloc?.longDescription) {
      startLoading();
      updateFlocById({variables: {id: activeFloc?.id!, data: {longDescription: {set: summary}}}}).then(newData => {
        const newFloc = {
          ...newData?.data?.updateOneFunctionalLocation,
        } as Partial<FunctionalLocation>;
        updateFloc(newFloc);

        AppNotifications.success(t('message.success.flocUpdated'));
        return true;
      }).catch((_err: Error) => {
        const errorMessage: string = t('message.error.default.title');

        AppNotifications.error(errorMessage);
        return false;
      }).finally(() => {
        stopLoading();
      });
    }
  };

  return (
    <div className='flex flex-col gap-2 px-4 mt-6'>
      <FormGroupHeader className='mb-3'><LuClipboardList className='mr-2' size={20}/> {t('label.background')}</FormGroupHeader>

      <div className='h-[330px]'>
        <ReactQuill
          key={editFloc!.longDescription}
          className='mr-2 h-[290px]' theme='snow' defaultValue={activeFloc!.longDescription ?? ''}
          onChange={setSummary} onBlur={handleUpdateLongDescription}/>
      </div>
    </div>
  );
}
