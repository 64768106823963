import {gql} from '@apollo/client';
import {APP_PLAN_STATUSES_COMMON_FIELDS} from '../fragments';

export const APP_PLAN_STATUSES_GET_MANY = gql`
${APP_PLAN_STATUSES_COMMON_FIELDS}
query GetAllPlanStatuses($orderBy: [StatusPlanOrderByWithRelationInput!], $where: StatusPlanWhereInput) {
    statusPlans(orderBy: $orderBy, where: $where) {
      ...PlanStatusCommonFields
    }
}`;
