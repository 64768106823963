export default class PermissionNotifications {
  public static resourceName = 'notifications';

  public display = false;
  public create = false;
  public import = false;
  public update = false;
  public approve = false;
  public delete = false;
}
