import {RadTooltipProvider, RadTooltip, RadTooltipTrigger, RadTooltipContent} from '@holis/react-ui/rad';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {LuFileText} from 'react-icons/lu';

type TEventMeasurementNotes = Readonly<{
  notes?: string
}>;

export default function EventMeasurementNotes({notes}: TEventMeasurementNotes) {
  const {t} = useTranslation();
  return (
    <RadTooltipProvider>
      {typeof notes === 'string' && notes.trim() !== '' && <RadTooltip>
        <RadTooltipTrigger>
          <LuFileText className='h-4 w-4'/>
          <span className='sr-only'>
            {t('label.notes')}
          </span>
        </RadTooltipTrigger>
        <RadTooltipContent side='top' className='whitespace-pre'>
          {notes}
        </RadTooltipContent>
      </RadTooltip>}
    </RadTooltipProvider>
  );
}
