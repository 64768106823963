import {gql} from '@apollo/client';
import {FLOC_REF_CLAS_TOS_COMMON_FIELDS} from '../fragments';

export const FLOC_REF_CLAS_TOS_GET_MANY = gql`
${FLOC_REF_CLAS_TOS_COMMON_FIELDS}
query GetAllRefFlocClasTos {
  refFlocClasTos {
    ...RefFlocClasToCommonFields
  }
}`;

export const FLOC_REF_CLAS_TOS_GET_COUNTER = gql`
query GetRefFlocClasTosCounter {
  aggregateRefFlocClasTo {
    _count {
      id
    }
  }
}
`;
