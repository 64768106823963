import ElevatedButton from '@app/components/Common/Button/ElevatedButton';
import {TDbTable} from '@app/types/app';
import {Badge, Card} from '@holis/react-ui';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FaDownload, FaUpload} from 'react-icons/fa';
import {useDropzone} from 'react-dropzone';
import AppNotifications from '@app/services/notification';
import {useDataStore} from '@app/stores/data';
import {EDB_TABLE_REF, EImportService} from '@app/utils/enums';
import {AxiosError, AxiosResponse} from 'axios';
import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent';
import {useAdminImportExportStore} from '@app/stores/admin/importExport';
import AppTooltip from '@app/components/Common/Tooltip';
import {downloadFileFromBlobData} from '@app/utils/functions';
import SpinnerLoader from '@app/components/Loaders/SpinnerLoader';
type TDbTableCard = Readonly<{
  item: TDbTable,
}>;

export default function DbTableCard({item}: TDbTableCard) {
  const {name, schema, counter, reference, counterQueryResult, downloadBtnHidden, uploadBtnHidden, downloadFormat, uploadFormats, importType, importCheckType} = item;
  const supportedFormats: string[] = reference && [EDB_TABLE_REF.TABLE_FILE_DOC_FILE_NAME, EDB_TABLE_REF.TABLE_FILE_DRAWING_FILE_NAME].includes(reference!) ? ['.pdf', '.zip'] : ['.xls', '.csv', '.xlsx'];
  const {acceptedFiles, fileRejections, getRootProps, getInputProps, inputRef} = useDropzone({
    accept: uploadFormats ?? supportedFormats,
    noClick: true,
    maxFiles: 300,
  });
  const [downloadBtnElem, setDownloadBtnElem] = useState<HTMLDivElement>();
  const [uploadBtnElem, setUploadBtnElem] = useState<HTMLDivElement>();
  const downloadBtnRef = React.createRef<HTMLDivElement>();
  const uploadBtnRef = React.createRef<HTMLDivElement>();
  const [unavailableUploadTooltip, setUnavailableUploadTooltip] = useState<string>();
  const [unavailableDownloadTooltip, setUnavailableDownloadTooltip] = useState<string>();
  const {uploadDownloadService} = useDataStore();
  const {changeTableDataImportErrorsModalDisplay, changeTableDataImportConfirmModalDisplay, dataUploading, changeDataUploading, dataDownloading, changeDataDownloading} = useAdminImportExportStore();
  const {t} = useTranslation();
  const handleClickUpload = () => {
    inputRef.current?.click();
  };

  const uploadFiles = (files: File[], nbAttempts = 1) => {
    changeDataUploading(reference!, true);
    uploadDownloadService!.uploadMultipleFiles(files, [EDB_TABLE_REF.TABLE_FILE_DOC_FILE_NAME, EDB_TABLE_REF.TABLE_FILE_DRAWING_FILE_NAME].includes(reference!) ? {updateIfExists: true} : {
      'fTypes[]': files.map(() => reference as string),
    }, importType ?? (reference === EDB_TABLE_REF.TABLE_FILE_DOC_FILE_NAME ? EImportService.UPLOAD_FILE : (reference === EDB_TABLE_REF.TABLE_FILE_DRAWING_FILE_NAME ? EImportService.UPLOAD_DRAWING : EImportService.IMPORT_DATA))).then((res: AxiosResponse) => {
      console.log(res);
      if (res.status === 200 || res.status === 201) {
        AppNotifications.success(t('message.success.importService.dataImported'));
        changeTableDataImportConfirmModalDisplay(false);
        counterQueryResult?.refetch();
      } else if (nbAttempts < 5 && res.status === 401) {
        nbAttempts++;
        uploadFiles(files);
      } else {
        AppNotifications.error(t('message.error.importService.tableDataImportFailed'));
        const jsonData = JSON.parse(res.data);
        if (jsonData?.errors && typeof jsonData?.errors === 'object' && jsonData?.errors.files) {
          changeTableDataImportErrorsModalDisplay(true, jsonData?.errors.files);
        }
      }
    }).catch(err => {
      console.log('err');
      if (nbAttempts < 5 && err instanceof AxiosError && err.status === 401) {
        nbAttempts++;
        uploadFiles(files);
        return;
      }

      AppNotifications.error(t('message.error.importService.tableDataImportFailed'));
    }).finally(() => {
      changeDataUploading(reference!, false);
    });
  };

  const checkUploadFiles = (files: File[], nbAttempts = 1) => {
    changeDataUploading(reference!, true);
    uploadDownloadService!.uploadMultipleFiles(files, [EDB_TABLE_REF.TABLE_FILE_DOC_FILE_NAME, EDB_TABLE_REF.TABLE_FILE_DRAWING_FILE_NAME].includes(reference!) ? {updateIfExists: true} : {
      'fTypes[]': files.map(() => reference as string),
    }, importCheckType ?? ([EDB_TABLE_REF.TABLE_FILE_DOC_FILE_NAME, EDB_TABLE_REF.TABLE_FILE_DRAWING_FILE_NAME].includes(reference!) ? EImportService.UPLOAD_CHECK_FILE : EImportService.IMPORT_CHECK_DATA)).then((res: AxiosResponse) => {
      if (res.status === 200) {
        AppNotifications.success(t('message.success.importService.importDataChecked'));
        changeTableDataImportConfirmModalDisplay(true, () => uploadFiles(files));
      } else if (nbAttempts < 5 && res.status === 401) {
        nbAttempts++;
        checkUploadFiles(files);
      } else {
        AppNotifications.error(t('message.error.importService.tableDataCheckFailed'));
        const jsonData = JSON.parse(res.data);
        if (jsonData?.errors && typeof jsonData?.errors === 'object' && jsonData?.errors.files) {
          changeTableDataImportErrorsModalDisplay(true, jsonData?.errors.files);
        }
      }
    }).catch(err => {
      if (nbAttempts < 5 && err instanceof AxiosError && err.status === 401) {
        nbAttempts++;
        checkUploadFiles(files);
        return;
      }

      AppNotifications.error(t('message.error.importService.tableDataCheckFailed'));
    }).finally(() => {
      changeDataUploading(reference!, false);
    });
  };

  const handleClickDownload = (extension = 'xls') => {
    changeDataDownloading(reference!, true);
    uploadDownloadService!.exportTableData(reference!, extension).then((res: AxiosResponse) => {
      if (res.status === 200) {
        downloadFileFromBlobData(res.data, `${name}.${extension}`);
        AppNotifications.success(t('message.success.importService.dataExported'));
      } else {
        AppNotifications.error(t('message.error.importService.tableDataExportFailed'));
      }
    }).catch((err: Error) => {
      console.log(err);
      AppNotifications.error(t('message.error.importService.tableDataExportFailed'));
    }).finally(() => {
      changeDataDownloading(reference!, false);
    });
  };

  useEffect(() => {
    setUploadBtnElem(uploadBtnRef.current!);
    setDownloadBtnElem(downloadBtnRef.current!);
  }, []);

  useEffect(() => {
    if (acceptedFiles.length || fileRejections.length) {
      if (dataUploading?.[reference!]) {
        AppNotifications.error(t('message.error.importService.tableDataImportInProgress'));
      } else if (!reference) {
        AppNotifications.error(t('message.error.importService.tableDataImportUnavailable'));
      } else if (fileRejections.length) {
        const {message} = fileRejections[0].errors[0];
        AppNotifications.error(message ?? t('message.error.default.title'));
      } else if (acceptedFiles.length) {
        checkUploadFiles(acceptedFiles);
      }
    }
  }, [acceptedFiles, fileRejections]);

  useEffect(() => {
    if (!reference) {
      setUnavailableDownloadTooltip(t('message.info.tableDataExportUnavailable'));
      setUnavailableUploadTooltip(t('message.info.tableDataImportUnavailable'));
    }
  }, [reference]);

  return (
    <Card className='!w-[390px] !h-[130px] border-[.25px] border-gray-400 bg-white' sizePreset={Card.SizePresets.Medium} shapePreset={Card.ShapePresets.Rectangle}>
      <SpinnerLoaderComponent isLoading={dataUploading?.[reference!] || dataDownloading?.[reference!]}>
        <div className='flex flex-row justify-between h-full w-full p-2 cursor-default' {...getRootProps()}>
          <div className='flex flex-col justify-between'>
            <div className='flex flex-col'>
              <div className='font-poppins font-semibold text-sm text-gray-500'>{item?.title ?? t(`label.dbSchemas.${schema}.tables.${name}.title`)}</div>
              <div className='font-poppins font-normal text-xs text-gray-500'>{item?.description ?? t(`label.dbSchemas.${schema}.tables.${name}.description`)}</div>
            </div>
            <Badge className='bg-blue-100 text-blue-800 font-semibold text-2xs relative min-h-[16px] min-w-[50px] gap-0'>{!!counterQueryResult?.loading && <SpinnerLoader isLoading spinnerClassName='text-gray-50' className='static w-3 h-3 bg-transparent mr-0.5'/>} {t('label.itemCounterWithVar', {counter: counter ?? ''})}</Badge>
          </div>
          <div className='flex flex-col items-stretch justify-between'>
            {unavailableDownloadTooltip && <AppTooltip target={downloadBtnElem}>{unavailableDownloadTooltip}</AppTooltip>}
            <div ref={downloadBtnRef}>
              {downloadBtnHidden !== true && <ElevatedButton disabled={!reference} className='bg-gray-50 relative flex flex-col ralative w-full shadow-none text-primary hover:text-white' onClick={() => handleClickDownload()}>
                <span className='absolute right-1 top-0.5 text-gray-300 font-semibold text-3xs/[0.5rem]'>{downloadFormat ?? '.xls'}</span>
                <FaDownload className='text-xl'/>
                <span className='font-semibold text-2xs/[0.5rem] font-poppins'>{t('label.download')}</span>
              </ElevatedButton>}
            </div>
            {unavailableUploadTooltip && <AppTooltip target={uploadBtnElem}>{unavailableUploadTooltip}</AppTooltip>}
            <div ref={uploadBtnRef}>
              {uploadBtnHidden !== true && <ElevatedButton className='bg-gray-50 relative flex flex-col ralative w-full shadow-none text-teal-500 hover:text-white' disabled={!reference} onClick={handleClickUpload}>
                <span className='absolute right-1 top-0.5 text-gray-300 text-3xs/[0.5rem] font-semibold flex-col flex items-end'>{(uploadFormats ?? supportedFormats).map((format: string) => <span key={format}>{format}</span>)}</span>
                <FaUpload className='text-xl'/>
                <span className='font-semibold text-2xs/[0.5rem] font-poppins'>{t('label.upload')}</span>
                <input className='hidden' type='file' {...getInputProps()}/>
              </ElevatedButton>}
            </div>
          </div>
        </div>
      </SpinnerLoaderComponent>
    </Card>
  );
}
