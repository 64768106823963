import {useLazyQuery, useMutation} from '@apollo/client';
import {Grid} from '@app/components/Common/Grid';
import AppPopover from '@app/components/Common/Popover';
import {ControlExtWorkordersMutation, EventControlDataExtResponse, ExtWorkorder, GetAllExtWorkordersQuery, ImportExtWorkordersMutation} from '@app/graphql/__types__/graphql';
import {EXT_WORKORDERS_CONTROL, EXT_WORKORDERS_GET_ALL, EXT_WORKORDERS_IMPORT} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useLayoutStore} from '@app/stores/layout';
import {FORMAT_DATE_EU} from '@app/utils/constants';
import {TDataRowTreeList, TTreeList} from '@holis/react-ui';
import {RadBadge, RadButton, RadCheckbox, RadTabs, RadTabsList, RadTabsTrigger} from '@holis/react-ui/rad';
import moment from 'moment';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LuBadgeCheck, LuBadgeX} from 'react-icons/lu';
import {MdOpenInNew} from 'react-icons/md';
import colors from 'tailwindcss/colors';
import SummaryModal from './components/SummaryModal';

export type TExtWorkorderListPage = Readonly<{
  stage?: string;
  title: string;
  badge: React.ReactNode;
  route: string;
}>;

export default function ExternalWorkorderListPage() {
  const [events, setExtWorkorders] = useState<Partial<ExtWorkorder>[]>();
  const {t} = useTranslation();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isListToControl, setIsListToControl] = useState<boolean>(true);
  const {startLoading, stopLoading} = useLayoutStore();
  const [importControlSummary, setImportCotrolSummary] = useState<EventControlDataExtResponse[]>();

  const [getExtWorkordersApi, {loading, error}] = useLazyQuery<GetAllExtWorkordersQuery>(EXT_WORKORDERS_GET_ALL, {
    variables: {
      orderBy: [
        {
          id: 'desc',
        },
      ],
    },
    fetchPolicy: 'no-cache',
  });

  const [controlExtWorkordersApi] = useMutation<ControlExtWorkordersMutation>(EXT_WORKORDERS_CONTROL);
  const [importExtWorkordersApi] = useMutation<ImportExtWorkordersMutation>(EXT_WORKORDERS_IMPORT);

  const getExtWorkorders = () => {
    getExtWorkordersApi().then(queryResults => setExtWorkorders(((queryResults.data?.extWorkorders ?? []) as Partial<ExtWorkorder>[]).filter(item => {
      const {controlStatus} = item as ExtWorkorder;

      let {controlStatus: status} = (controlStatus as Record<string, unknown>) ?? {};
      if (!status) {
        status = 'NEW';
      }

      return (isListToControl && status !== 'OK') || (!isListToControl && status === 'OK');
    })));
  };

  const onCheckboxChanged = (checked: boolean, id: number) => {
    const ids = [...selectedIds];
    const idIdx = ids.indexOf(id);
    console.log(checked, idIdx);
    if (checked) {
      if (idIdx === -1) {
        ids.push(id);
      }
    } else if (idIdx > -1) {
      ids.splice(idIdx, 1);
    }

    setSelectedIds(ids);
  };

  const controlRowsData = () => {
    const ids = [...selectedIds];
    if (ids.length) {
      startLoading();
      controlExtWorkordersApi({
        variables: {
          ids,
        },
      }).then(res => {
        console.log(res.data?.controlExtWorkpackData);
        setImportCotrolSummary(res.data?.controlExtWorkpackData as EventControlDataExtResponse[]);
        setSelectedIds([]);
        getExtWorkorders();
      }).catch(() => {
        AppNotifications.error(t('message.error.default.title'));
      }).finally(stopLoading);
    }
  };

  const importRowsData = () => {
    const ids = [...selectedIds];
    if (ids.length) {
      startLoading();
      importExtWorkordersApi({
        variables: {
          ids,
        },
      }).then(res => {
        console.log(res.data?.importExtWorkpackData);
        getExtWorkorders();
      }).catch(() => {
        AppNotifications.error(t('message.error.default.title'));
      }).finally(stopLoading);
    }
  };

  useEffect(() => {
    getExtWorkorders();
  }, [isListToControl]);

  const gridProps: Partial<TTreeList<TDataRowTreeList>> = useMemo(() => ({
    columns: [
      {
        field: 'action',
        title: t('label.action'),
        type: 'string',
        width: 77.5,
        cellRenderer: 'action',
        titleHidden: true,
        filter: false,
      },
      {
        field: 'status',
        title: t('label.status'),
        type: 'string',
        width: 117,
        cellRenderer: 'status',
        hidden: false,
        pinned: false,
      },
      // ...(isListToControl ? [
      //   {
      //     field: 'controlWorkorderStatus',
      //     title: t('label.controlWorkorderStatus'),
      //     type: 'string',
      //     width: 117,
      //     cellRenderer: 'controlWorkorderStatus',
      //     hidden: false,
      //     pinned: false,
      //   },
      //   {
      //     field: 'controlMeasPointStatus',
      //     title: t('label.controlMeasPointStatus'),
      //     type: 'string',
      //     width: 117,
      //     cellRenderer: 'controlMeasPointStatus',
      //     hidden: false,
      //     pinned: false,
      //   },
      // ] : []),
      {
        field: 'id',
        title: 'Id',
        type: 'number',
        width: 80,
        hidden: true,
      },
      {
        field: 'woNumber',
        title: t('label.event'),
        type: 'string',
        width: 129,
        cellRenderer: 'textBold',
        hidden: false,
      },
      {
        field: 'description',
        title: t('label.description'),
        type: 'string',
        width: 289,
        hidden: false,
      },
      {
        field: 'type',
        title: t('label.type'),
        type: 'string',
        width: 289,
        hidden: false,
      },
      {
        field: 'schedulingTag',
        title: t('label.schedulingTag'),
        type: 'string',
        width: 294,
        hidden: false,
      },
      {
        field: 'reportNumber',
        title: t('label.reportNumber'),
        type: 'string',
        width: 100,
        hidden: true,
      },
      {
        field: 'packId',
        title: t('label.packId'),
        type: 'number',
        width: 77.5,
        hidden: true,
      },
      {
        field: 'dueDate',
        title: t('label.dueDate'),
        type: 'string',
        width: 80,
        cellRenderer: 'date',
        hidden: false,
      },
    ],
    cellRenderers: {
      action: (_val: string, rowData: unknown) => (
        <RadCheckbox
          aria-label='Select row'
          className='w-4 h-4'
          checked={selectedIds.includes((rowData as ExtWorkorder).id)}
          onCheckedChange={checked => onCheckboxChanged(!!checked, (rowData as ExtWorkorder).id)}
        >
          <MdOpenInNew size={18}/>
        </RadCheckbox>
      ),
      status(_val: string, rowData: unknown) {
        const {controlStatus} = rowData as ExtWorkorder;

        let {controlStatus: status, controlMeasPoint, controlWorkorder} = (controlStatus as Record<string, unknown>) ?? {};
        const {schedulingTag, type: eventType, dueDate} = (controlWorkorder as Record<string, boolean | null>) ?? {};
        const {isOk, measuringPointStatuses} = (controlMeasPoint as Record<string, unknown>) ?? {};
        let bgColor = 'bg-gray-200';
        let textColor = 'text-gray-700';
        let tooltip;
        if (!status) {
          status = 'NEW';
        }

        switch (status) {
          case 'UPDATED':
            bgColor = 'bg-light-blue-200';
            textColor = 'text-light-blue-700';
            break;
          case 'ERROR':
            bgColor = 'bg-red-200';
            textColor = 'text-red-700';
            tooltip = (
              <AppPopover position='right' container='body' tooltipClassName='p-0 border-1' tooltipColor={colors.red[50]} target={`#control-status-ext-workorder-${(rowData as ExtWorkorder).id}`}>
                <div className='p-4 gap-2 flex flex-col items-start text-sm justify-start flex-wrap overflow-auto list-disc max-h-[300px]'>
                  <div className='flex items-center gap-4 justify-start flex-wrap'><span className='font-bold text-gray-700 w-[150px] text-left'>{t('label.schedulingTag')}:</span> {schedulingTag ? <LuBadgeCheck className='text-green-700'/> : <LuBadgeX className='text-red-700'/>}</div>
                  <div className='flex items-center gap-4 justify-start flex-wrap'><span className='font-bold text-gray-700 w-[150px] text-left'>{t('label.eventType')}:</span> {eventType ? <LuBadgeCheck className='text-green-700'/> : <LuBadgeX className='text-red-700'/>}</div>
                  <div className='flex items-center gap-4 justify-start flex-wrap'><span className='font-bold text-gray-700 w-[150px] text-left'>{t('label.dueDate')}:</span> {dueDate ? <LuBadgeCheck className='text-green-700'/> : <LuBadgeX className='text-red-700'/>}</div>
                  <div className='flex items-center gap-4 justify-start flex-wrap'><span className='font-bold text-gray-700 w-[150px] text-left'>{t('label.controlMeasPoints')}: </span>{isOk ? <LuBadgeCheck className='text-green-700'/> : <LuBadgeX className='text-red-700'/>}</div>
                  {Array.isArray(measuringPointStatuses) && measuringPointStatuses.length && (<div className='flex flex-col items-start w-full text-xs -mt-1'>
                    {
                      measuringPointStatuses.map(measuringPointStatus => {
                        const {location, ptrid, flocExists, integrityStatusExists} = measuringPointStatus ?? {};
                        return (
                          <div key={`${location}-${ptrid}`} className='flex w-full items-center justify-between text-gray-700 gap-2'>
                            <div className='flex-1 text-left'>- <span className={`whitespace-pre-wrap ${flocExists ? '' : 'line-through'}`}>{`${location}`}</span> | {`${ptrid}`}: </div>
                            {integrityStatusExists ? <LuBadgeCheck className='text-green-700'/> : <LuBadgeX className='text-red-700 w-100 text-right'/>}
                          </div>
                        );
                      })
                    }
                  </div>)}
                </div>
              </AppPopover>
            );
            break;
          case 'OK':
            bgColor = 'bg-green-200';
            textColor = 'text-green-700';
            break;
          default:
            bgColor = 'bg-gray-200';
            textColor = 'text-gray-700';
            break;
        }

        return (
          <>
            <RadBadge id={`control-status-ext-workorder-${(rowData as ExtWorkorder).id}`} variant='outline' className={`py-1 font-normal ${bgColor} ${textColor} ${status === 'ERROR' ? 'hover:cursor-pointer' : ''}`}>
              {t(`label.extWorkorderStatuses.${status}`)}
            </RadBadge>
            {tooltip}
          </>
        );
      },
      controlWorkorderStatus(_val: string, rowData: unknown) {
        const {controlStatus} = rowData as ExtWorkorder;

        const {controlWorkorder: status} = (controlStatus as Record<string, unknown>) ?? {};
        let bgColor = 'bg-gray-200';
        let textColor = 'text-gray-700';
        let textMsg = 'label.extWorkorderControlWorkorderStatuses.NEW';

        switch (status) {
          case true:
            bgColor = 'bg-green-200';
            textColor = 'text-green-700';
            textMsg = 'label.extWorkorderControlWorkorderStatuses.OK';
            break;
          case false:
            bgColor = 'bg-red-200';
            textColor = 'text-red-700';
            textMsg = 'label.extWorkorderControlWorkorderStatuses.ERROR';
            break;
          default:
            bgColor = 'bg-gray-200';
            textColor = 'text-gray-700';
            break;
        }

        return (
          <RadBadge variant='outline' className={`py-1 font-normal ${bgColor} ${textColor}`}>
            {t(textMsg)}
          </RadBadge>
        );
      },
      controlMeasPointStatus(_val: string, rowData: unknown) {
        const {controlStatus} = rowData as ExtWorkorder;

        const {controlMeasPoint: status} = (controlStatus as Record<string, unknown>) ?? {};
        let bgColor = 'bg-gray-200';
        let textColor = 'text-gray-700';
        let textMsg = 'label.extWorkorderControlMeasPointStatuses.NEW';

        switch (status) {
          case true:
            bgColor = 'bg-green-200';
            textColor = 'text-green-700';
            textMsg = 'label.extWorkorderControlMeasPointStatuses.OK';
            break;
          case false:
            bgColor = 'bg-red-200';
            textColor = 'text-red-700';
            textMsg = 'label.extWorkorderControlMeasPointStatuses.ERROR';
            break;
          default:
            bgColor = 'bg-gray-200';
            textColor = 'text-gray-700';
            break;
        }

        return (
          <RadBadge variant='outline' className={`py-1 font-normal ${bgColor} ${textColor}`}>
            {t(textMsg)}
          </RadBadge>
        );
      },
      date: (val: string) => val ? moment(val).format(FORMAT_DATE_EU) : '',
      textBold: (val: string) => val ? <span className='font-bold'>{val}</span> : '',
    },
    canSelect: true,
  }), [events, selectedIds]);

  const columnsAlwaysVisible: string[] = useMemo(() => ([
    'action',
    'woNumber',
    'description',
    'schedulingTag',
    'type',
    'dueDate',
    'status',
    'controlWorkorderStatus',
    'controlWorkorderStatus',
  ]), [events]);

  return (
    <>
      <div
        className='h-full'>
        <Grid
          gridName='importExternalWorkorder'
          queryData={{
            data: events ?? [],
            loading,
            error,
          }}
          gridProps={gridProps}
          title={<RadTabs className='pt-2 px-4' value={isListToControl ? 'control' : 'import'}>
            <RadTabsList>
              <RadTabsTrigger key='control-data-list' value='control' onClick={() => setIsListToControl(true)}>
                {t('label.control')}
              </RadTabsTrigger>
              <RadTabsTrigger key='import-data-list' value='import' onClick={() => setIsListToControl(false)}>
                {t('label.import')}
              </RadTabsTrigger>
            </RadTabsList>
          </RadTabs>}
          columnsAlwaysVisible={columnsAlwaysVisible}
        >
          <RadButton className='h-8 rounded-md px-3 gap-2 text-sm' disabled={!selectedIds.length} onClick={isListToControl ? controlRowsData : importRowsData}>{t(isListToControl ? 'label.controlData' : 'label.import')}</RadButton>
        </Grid>
      </div>
      {importControlSummary && <SummaryModal isOpen results={importControlSummary} onClose={() => setImportCotrolSummary(undefined)}/>}
    </>
  );
}
