import {gql} from '@apollo/client';

export const MEASPOINT_REF_CLASSES_COMMON_FIELDS = gql`
  fragment RefMeasPointClassCommonFields on RefMeasPointClass {
    id
    class
    description
    isActive
    classCodeGroups {
      codeGroupId
    }
}`;
