import {useTranslation} from 'react-i18next';

export type TFileErrors = Readonly<{
  name: string;
  errors: string[] | string;
}>

export default function FileErrors({name, errors}: TFileErrors) {
  const {t} = useTranslation();
  return (
    <div className='flex flex-col'>
      <div className='font-bold'>{t('label.fileWithNameVar', {name})}:</div>
      <ul className='text-gray-500 font-medium'>{Array.isArray(errors) ? errors.map((error: string) => (<li key={error}>- {error}</li>)) : (<li>{String(errors)}</li>)}</ul>
    </div>
  );
}
