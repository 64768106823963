import IanList from './IanList';
import IntegrityStatusBlock from './IntegrityStatus';

type TIntegrityPage = Readonly<{
  visible: boolean;
}>;
export default function IntegrityPage({visible}: TIntegrityPage) {
  return (
    <div className={`flex flex-col gap-2 ${visible ? '' : 'hidden'}`}>
      <IntegrityStatusBlock/>
      <IanList/>
    </div>
  );
}
