import {useLazyQuery} from '@apollo/client';
import {EventInspectionPoint, GetLatestEventInspectionPointByInspIdQuery, InspectionPoint} from '@app/graphql/__types__/graphql';
import {WORKORDER_EVENT_INSPECTION_POINTS_GET_LATEST_BY_INSP_ID} from '@app/graphql/requests';
import useInspectionPointStore from '@app/stores/insp';
import {QUALREADING_COLOR} from '@app/utils/constants';
import {isValidUnit} from '@app/utils/functions';
import {
  RadCard,
  RadCardContent,
  RadContextMenu,
  RadContextMenuContent,
  RadContextMenuItem,
  RadContextMenuTrigger,
} from '@holis/react-ui/rad';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LuCamera, LuExternalLink} from 'react-icons/lu';

type TInspectionPointItem = Readonly<{
  inspectionPoint: Partial<InspectionPoint>;
  eventInspectionPoint?: Partial<EventInspectionPoint>;
  readonly?: boolean;
  hasMenuContext?: boolean;
}>;

export default function InspectionPointItem({inspectionPoint, eventInspectionPoint, hasMenuContext}: TInspectionPointItem) {
  // const {setInspectionPointToDelete} = useEventStore();
  const [evtInsp, setEvtInsp] = useState<Partial<EventInspectionPoint | null>>();
  const {setActiveInspectionPoint} = useInspectionPointStore();
  const [getLatestEventCmlByCmlIdApi] = useLazyQuery<GetLatestEventInspectionPointByInspIdQuery>(WORKORDER_EVENT_INSPECTION_POINTS_GET_LATEST_BY_INSP_ID);

  const {t} = useTranslation();

  useEffect(() => {
    if (!eventInspectionPoint) {
      getLatestEventCmlByCmlIdApi({
        variables: {
          inspId: inspectionPoint.id,
        },
      }).then(queryResult => setEvtInsp(queryResult.data?.findFirstEventInspectionPoint as Partial<EventInspectionPoint>)).catch(setEvtInsp);
    } else {
      setEvtInsp(eventInspectionPoint);
    }
  }, [eventInspectionPoint]);

  return (
    <RadContextMenu key={`inspection-point-${inspectionPoint.id}`}>
      <RadContextMenuTrigger disabled={!hasMenuContext}>
        <RadCard className='rounded-md'>
          <RadCardContent className='flex p-2 gap-2 items-center'>
            <div className='self-stretch rounded-md bg-blue-50 p-2 min-w-14 text-center'>
              <div className='text-primary font-extrabold'>
                {inspectionPoint?.class?.class}
              </div>

              <div className='font-extrabold'>
                {inspectionPoint?.position}
              </div>
            </div>

            <div className='flex-1'>
              <div className='font-medium'>
                {inspectionPoint?.description}
              </div>

              <div className='font-medium text-2xs'>
                {inspectionPoint?.functionalLocation?.floc}
              </div>
            </div>

            <div className='flex gap-2 items-center'>
              {(!!inspectionPoint._count?.pictures || !!eventInspectionPoint?.inspectionPoint?._count?.pictures) && (
                <LuCamera className='text-primary' size={20}/>
              )}

              <div
                className='self-stretch rounded-md p-2 bg-gray-200 min-w-14 text-center flex flex-col justify-center'
                style={{
                  backgroundColor: (evtInsp?.qualReading?.integrityCondition.color ?? QUALREADING_COLOR) as string,
                }}
              >
                <div className='font-medium'>
                  {(isValidUnit(inspectionPoint.char?.unit) ? evtInsp?.quantReading : evtInsp?.qualReading?.valCode) ?? '-'}
                </div>
              </div>
            </div>
          </RadCardContent>
        </RadCard>
      </RadContextMenuTrigger>

      <RadContextMenuContent>
        <RadContextMenuItem onClick={() => setActiveInspectionPoint(inspectionPoint)}>
          <LuExternalLink className='mr-2'/>

          {t('label.open')}
        </RadContextMenuItem>

        {/* <RadContextMenuItem
          className='item-destructive'
          disabled={readonly}
          onClick={() => setInspectionPointToDelete(eventInspectionPoint!)}
        >
          <LuTrash2 className='mr-2'/>

          {t('label.removeFromEvent')}
        </RadContextMenuItem> */}
      </RadContextMenuContent>
    </RadContextMenu>
  );
}
