import {gql} from '@apollo/client';

export const WORKORDER_REF_EVENT_TASKS_COMMON_FIELDS = gql`
  fragment RefEventTaskCommonFields on RefEventTask {
    id
    category
    description
    hasReporting
    longDescription
    method
    techniqueId
    task
}`;
