import {FetchResult, useLazyQuery, useMutation} from '@apollo/client';
import AttachmentCard from '@app/components/Common/Block/Attachment/AttachmentBlock/AttachmentCard';
import DeleteAttachmentModal from '@app/components/Common/Block/Attachment/AttachmentBlock/DeleteAttachmentModal';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import SortableList, {SortableItem} from '@app/components/Common/List/SortableList';
import {Attachment, Notification, GetAttachmentsByNotifIdQuery, CreateAttachmentMutation, UpdateAttachmentByIdMutation, UpdateOneEventDocumentByIdMutation} from '@app/graphql/__types__/graphql';
import {FILE_ATTACHMENTS_CREATE, FILE_ATTACHMENTS_GET_BY_NOTIF_ID, FILE_ATTACHMENTS_UPDATE_BY_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import useAttachmentStore from '@app/stores/attachment';
import {useDataStore} from '@app/stores/data';
import {useLayoutStore} from '@app/stores/layout';
import useNotificationStore from '@app/stores/notification';
import {EImportService} from '@app/utils/enums';
import {RadButton} from '@holis/react-ui/rad';
import {AxiosResponse} from 'axios';
import {t} from 'i18next';
import {useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import {LuClipboardList, LuPlusSquare} from 'react-icons/lu';

type TNotificationAttachments = Readonly<{
  notification: Partial<Notification>
  readonly?: boolean
}>;

export default function NotificationAttachments({notification, readonly}: TNotificationAttachments) {
  const {setFetchAttachments, fetchAttachments, itemToDelete} = useAttachmentStore();
  const [getNotificationAttachmentsApi, {called, refetch, data, loading, error}] = useLazyQuery<GetAttachmentsByNotifIdQuery>(FILE_ATTACHMENTS_GET_BY_NOTIF_ID, {
    variables: {
      notifId: notification.id!,
    },
    fetchPolicy: 'no-cache',
  });
  const [createAttachmentApi] = useMutation<CreateAttachmentMutation>(FILE_ATTACHMENTS_CREATE);
  const [updateAttachmentById] = useMutation<UpdateAttachmentByIdMutation>(FILE_ATTACHMENTS_UPDATE_BY_ID);
  const {acceptedFiles, fileRejections, getRootProps, getInputProps, inputRef} = useDropzone({
    accept: ['.png', '.jpeg', '.jpg', '.svg', '.pdf'],
    noClick: true,
    multiple: false,
  });
  const {startLoading, stopLoading} = useLayoutStore();
  const {uploadDownloadService} = useDataStore();

  const {setAttachments} = useNotificationStore();

  const getNotificationAttachments = () => {
    (called ? refetch : getNotificationAttachmentsApi)().then(queryResult => {
      setAttachments((queryResult.data?.attachments ?? []) as Partial<Attachment>[]);
    });
  };

  useEffect(() => {
    setFetchAttachments(getNotificationAttachments);
  }, []);

  const handleNewAttachment = async () => {
    let result: FetchResult<CreateAttachmentMutation>;
    try {
      result = await createAttachmentApi({
        variables: {
          data: {
            notifId: notification?.id!,
          },
        },
      });
    } catch {
      AppNotifications.error(t('message.error.default.title'));
    }

    fetchAttachments?.();
    return result!;
  };

  const uploadImages = async (file: File) => {
    startLoading();

    // Create attachment
    const result = await handleNewAttachment();
    const newAttachment = result.data?.createOneAttachment as Partial<Attachment>;

    uploadDownloadService!.uploadFile(file, {id: newAttachment.id}, EImportService.UPLOAD_ATTACHMENT).then((res: AxiosResponse) => {
      if (res.status === 200) {
        fetchAttachments?.();
        AppNotifications.success(t('message.success.importService.attachmentUploaded'));
      } else {
        throw new Error(String(res.data));
      }
    }).catch((err: Error) => {
      console.log(err);
      AppNotifications.error(t('message.error.importService.attachmentUploadFailed'));
    }).finally(() => {
      stopLoading();
    });
  };

  const updateSortHandler = (item: Partial<Attachment>, newSortVal: number) => {
    startLoading();
    updateAttachmentById({
      variables: {
        id: item.id!,
        data: {
          sort: {
            set: newSortVal,
          },
        },
      },
    }).then((_result: FetchResult<UpdateOneEventDocumentByIdMutation>) => {
      AppNotifications.success(t('message.success.attachmentUpdated'));
      fetchAttachments?.();
    }).catch((error: Error) => {
      console.log(error);
      AppNotifications.error(t('message.error.default.title'));
    }).finally(() => {
      stopLoading();
    });
  };

  const handleSortChange = (dragItem: SortableItem, dropItem: SortableItem, dropIndex: number) => {
    updateSortHandler(dragItem as Partial<Attachment>, (dropItem as Partial<Attachment>).sort ?? dropIndex);
  };

  useEffect(() => {
    if (acceptedFiles.length || fileRejections.length) {
      if (fileRejections.length) {
        const {message} = fileRejections[0].errors[0];
        AppNotifications.error(message ?? t('message.error.default.title'));
      } else if (acceptedFiles.length) {
        uploadImages(acceptedFiles[0]);
      }
    }
  }, [acceptedFiles, fileRejections]);

  useEffect(() => {
    setFetchAttachments(getNotificationAttachments);
    getNotificationAttachments();
  }, []);

  return (
    <div className='mt-6 mb-3'>
      <div {...getRootProps()}>
        <FormGroupHeader>
          <div className='flex gap-4 items-center'>
            <div className='flex items-center'>
              <LuClipboardList className='mr-2' size={20}/> {t('label.attachments')} ({data?.attachments.length ?? 0})</div>
            <RadButton variant='outline' disabled={readonly} className='text-foreground' onClick={() => inputRef.current?.click()}><LuPlusSquare className='mr-2'/> {t('label.addAttachment')}</RadButton>
          </div>
        </FormGroupHeader>
        <input ref={inputRef} {...getInputProps()}/>
      </div>

      {loading && <div className='p-4 text-muted-foreground'>{t('label.loading')}</div>}

      {error && <div className='p-4 text-red-900'>Error: {error.message}</div>}

      {!loading && !error && (
        <div className='grid grid-cols-2 gap-2 mt-2'>
          <SortableList
            items={(data?.attachments ?? []) as SortableItem[]}
            renderItem={attachment => (
              <AttachmentCard key={`attachment-${attachment.id}`} hasActionButtons hasDragBtn attachment={attachment as Partial<Attachment>}/>
            )}
            onSortChange={handleSortChange}/>
        </div>
      )}
      {!!itemToDelete && <DeleteAttachmentModal open attachment={itemToDelete}/>}
    </div>
  );
}
