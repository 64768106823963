import React, {PropsWithChildren} from 'react';
import {twMerge} from 'tailwind-merge';
import SpinnerLoader from './SpinnerLoader';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

const StyledSpinnerLoader = styled(SpinnerLoader)`
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
`;

type TSpinnerLoaderComponent = Readonly<PropsWithChildren & {
    isLoading?: boolean;
    className?: string;
    contentClassName?: string;
    spinnerClassName?: string;
    ref?: React.RefObject<HTMLDivElement>;
    contentRef?: React.RefObject<HTMLDivElement>;
    error?: React.ReactNode;
    style?: React.CSSProperties;
}>;

/**
 * Show loader or error or component on ready
 */
export default function SpinnerLoaderComponent({isLoading: loading, ref, className, children, spinnerClassName, error, contentClassName, style, contentRef}: TSpinnerLoaderComponent) {
  const {t} = useTranslation();
  return (
    <div ref={ref} className={twMerge(`w-full h-full relative${loading ? ' bg-gray-300' : ''}`, className)} style={style}>
      <StyledSpinnerLoader className={twMerge('w-[50px] h-[50px] text-center absolute m-auto bg-transparent', spinnerClassName)} isLoading={loading}/>
      <div ref={contentRef} className={twMerge(`w-full h-full ${loading ? 'opacity-80 pointer-events-none' : ''}`, contentClassName)}>
        {!!error && <div className='text-red-700 flex text-center justify-center items-center text-sm font-medium h-full'>{error === true ? t('message.error.default.title') : error}</div>}
        {children}
      </div>
    </div>
  );
}
