import DrawingViewers from '@app/components/Common/Block/Event/EventModal/components/DrawingViewers';
import DrawingPageHeader from './components/DrawingPageHeader';
import {useEventStore} from '@app/stores/event';
import {useEffect, useState} from 'react';
import {EWorkpackRightSideTab} from '@app/utils/enums';
import {SortableItem} from '@app/components/Common/List/SortableList';
import {EventInspectionDrawing, UpdateOneEventInspectionDrawingByIdMutation} from '@app/graphql/__types__/graphql';
import {FetchResult, useMutation} from '@apollo/client';
import AppNotifications from '@app/services/notification';
import {t} from 'i18next';
import {useLayoutStore} from '@app/stores/layout';
import {WORKORDER_EVENT_INSPECTION_DRAWINGS_UPDATE_BY_ID} from '@app/graphql/requests';
import {RightPanelPage} from '../..';

export default function DrawingPage({visible, readonly}: RightPanelPage) {
  const {activeEvent, setRightSideTabSelectedValue, fetchEventInspectionDrawings, setInspectionDrawingSortChangeCallback} = useEventStore();
  const {startLoading, stopLoading} = useLayoutStore();
  const [updateEventInspectionDrawingApi] = useMutation<UpdateOneEventInspectionDrawingByIdMutation>(WORKORDER_EVENT_INSPECTION_DRAWINGS_UPDATE_BY_ID);
  const [firstVisibleRenderDone, setFirstVisibleRenderDone] = useState<boolean>(false);

  const updateEventIdwgSortHandler = (item: Partial<EventInspectionDrawing>, newSortVal: number) => {
    startLoading();
    updateEventInspectionDrawingApi({
      variables: {
        id: item.id!,
        data: {
          sort: {
            set: newSortVal,
          },
        },
      },
    }).then((_result: FetchResult<UpdateOneEventInspectionDrawingByIdMutation>) => {
      AppNotifications.success(t('message.success.eventInspectionDrawingSortUpdated'));
      fetchEventInspectionDrawings?.();
    }).catch((_error: Error) => {
      AppNotifications.error(t('message.error.default.title'));
    }).finally(() => {
      stopLoading();
    });
  };

  const handleEventIdwgSortChange = (dragItem: SortableItem, dropItem: SortableItem, dropIndex: number) => {
    updateEventIdwgSortHandler(dragItem as Partial<EventInspectionDrawing>, (dropItem as Partial<EventInspectionDrawing>).sort ?? dropIndex);
  };

  useEffect(() => {
    setRightSideTabSelectedValue(EWorkpackRightSideTab.DRAWINGS);
  }, [setRightSideTabSelectedValue]);

  useEffect(() => {
    setInspectionDrawingSortChangeCallback(handleEventIdwgSortChange);
  }, [setInspectionDrawingSortChangeCallback, fetchEventInspectionDrawings]);

  useEffect(() => {
    // Drawing Viewers must not be initialized in a hidden state.
    if (visible) {
      setFirstVisibleRenderDone(true);
    }
  }, [visible]);

  if (!activeEvent) {
    return null;
  }

  if (!firstVisibleRenderDone) {
    return null;
  }

  return (
    <div className={`h-full flex flex-col overflow-hidden ${visible ? '' : 'hidden'}`}>
      <DrawingPageHeader readonly={readonly}/>

      <DrawingViewers
        hideActionList
        event={activeEvent}
      />
    </div>
  );
}
