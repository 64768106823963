import {gql} from '@apollo/client';

export const SCHEDULING_REF_PLAN_TYPES_COMMON_FIELDS = gql`
    fragment RefPlanTypeCommonFields on RefPlanType {
        id
        type
        description
        isActive
    }
`;
