import {gql} from '@apollo/client';

export const FLOC_REF_SECES_COMMON_FIELDS = gql`
    fragment RefFlocSeceCommonFields on RefFlocSece {
        id
        description
        class
        barrier
    }
`;
