import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import AppNotifications from '@app/services/notification';
import React from 'react';
import colors from 'tailwindcss/colors';
import {useMutation} from '@apollo/client';
import {CreateCmlMutation, Cml, UpdateCmlByIdMutation} from '@app/graphql/__types__/graphql';
import {OBJ_NEW_ID} from '@app/utils/constants';
import ActionButtons from '@app/components/Common/Form/ActionButtons';
import {EAdditionalAction, EApiOperator} from '@app/utils/enums';
import {TAdditionalActions} from '@app/types/app';
import useCmlStore from '@app/stores/cml';
import {CMLS_CREATE, CMLS_UPDATE_BY_ID} from '@app/graphql/requests';
import {LuTrash2} from 'react-icons/lu';
import {RadDropdownMenuItem} from '@holis/react-ui/rad';

type TActions = Readonly<{
    cml: Partial<Cml>;
    onCreatedOrUpdated?: (cml: Partial<Cml>, operator?: EApiOperator) => void;
}>

const ActionsContainer = styled(ActionButtons)`
     & .action-dropdown .menu-item:last-child{
        border-top: 1px solid ${colors.gray['100']};
    }
`;

export default function Actions({cml, onCreatedOrUpdated}: TActions) {
  const {t} = useTranslation();
  const {isSaved, hasError, setUpdateCmlData: setUpdateData, changeDeleteCmlModalDisplay, updateCml, editCml, updateCmlDataField: updateData, cancelEditData, setActiveCml} = useCmlStore();
  const [updateCmlById] = useMutation<UpdateCmlByIdMutation>(CMLS_UPDATE_BY_ID);
  const [createCml] = useMutation<CreateCmlMutation>(CMLS_CREATE);

  const handleCancelEditDataClick = (_e?: React.MouseEvent) => {
    cancelEditData();
  };

  const submitChange = () => {
    const cmlId = cml.id;
    if (cmlId === OBJ_NEW_ID) {
      createCml({
        variables: {
          data: updateData,
        },
      }).then(newData => {
        // const newCml = {
        //   ...editCml,
        //   ...newData?.data?.createOneCml,
        // } as Cml;
        // updateCml(newCml, true);
        // setUpdateCmlData({});
        setActiveCml();
        onCreatedOrUpdated?.(newData.data?.createOneCml as Partial<Cml>, EApiOperator.CREATE);
        AppNotifications.success(t('message.success.cmlCreated'));
      }).catch((_err: Error) => {
        const errorMessage: string = t('message.error.default.title');

        AppNotifications.error(errorMessage);
      });
    } else {
      updateCmlById({variables: {id: cml.id, data: updateData}}).then(newData => {
        const newCml = {
          ...editCml,
          ...newData?.data?.updateOneCml,
        } as Cml;
        updateCml(newCml);
        setUpdateData({});
        onCreatedOrUpdated?.(newCml, EApiOperator.UPDATE);
        AppNotifications.success(t('message.success.cmlUpdated'));
      }).catch((_err: Error) => {
        const errorMessage: string = t('message.error.default.title');

        AppNotifications.error(errorMessage);
      });
    }
  };

  const additionalActions: TAdditionalActions = {
    [EAdditionalAction.DELETE]: <RadDropdownMenuItem className='item-destructive gap-1 cursor-pointer' onClick={() => changeDeleteCmlModalDisplay(true)}><LuTrash2/> {t('label.deleteCml')}</RadDropdownMenuItem>,
  };

  return (
    <ActionsContainer
      isSaved={isSaved}
      dropdownClassName='action-dropdown'
      hasError={hasError}
      handleCancelEditDataClick={handleCancelEditDataClick}
      additionalActions={cml.id !== OBJ_NEW_ID ? additionalActions : undefined}
      submitChange={submitChange}
    />
  );
}
