import {useMutation} from '@apollo/client';
import {IConfirmModal} from '@app/components/Modal/Confirm';
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning';
import {DeleteOneFlocDocMutation} from '@app/graphql/__types__/graphql';
import {DOCUMENTATION_DOCUMENTS_FLOCS_DELETE} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useDocumentStore} from '@app/stores/document';
import {useLayoutStore} from '@app/stores/layout';
import {useTranslation} from 'react-i18next';

type TDeleteDocFlocModal = IConfirmModal & Readonly<{
  onDeleted?: () => void;
}>;

export default function DeleteDocFlocModal({onDeleted, ...restProps}: TDeleteDocFlocModal) {
  const {setFlocToDelete, flocToDelete, activeDoc} = useDocumentStore();
  const {startLoading, stopLoading} = useLayoutStore();
  const [deleteDocFlocApi] = useMutation<DeleteOneFlocDocMutation>(DOCUMENTATION_DOCUMENTS_FLOCS_DELETE);
  const {t} = useTranslation();
  const onConfirm = () => {
    if (flocToDelete) {
      startLoading();
      deleteDocFlocApi({
        variables: {
          docId: activeDoc!.id!,
          flocId: flocToDelete!.id!,
        },
      }).then(() => {
        onDeleted?.();
        AppNotifications.success(t('message.success.deleteDocFloc'));
      }).catch((_err: Error) => {
        AppNotifications.error(t('message.error.deleteDocFloc'));
      }).finally(() => {
        stopLoading();
      });
    }
  };

  return (
    <WarningConfirmModal
      description={t('message.questions.deleteDocFloc')}
      title={t('message.questions.areYouAbsolutlySure')}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      onClose={setFlocToDelete}
      {...restProps}
    />
  );
}
