import {gql} from '@apollo/client';
import {CML_REF_VAL_CODES_COMMON_FIELDS} from './refCmlValCodes';

export const WORKORDER_EVENT_CMLS_COMMON_FIELDS = gql`
  ${CML_REF_VAL_CODES_COMMON_FIELDS}
  fragment EventCmlCommonFields on EventCml {
      id
      qualReadingId
      qualReading{
        ...RefCmlValCodeCommonFields
      }
      cmlId
      wrkoId
      quantReading
      notes
      reader
      reportingDate
      cancelled
  }
`;
