import {gql} from '@apollo/client';
import {FLOC_REF_PARTS_COMMON_FIELDS} from './refFlocParts';
import {WORKORDER_REF_EVENT_TECHNIQUES_COMMON_FIELDS} from './refEventTechniques';
import {GRIDS_AUTOCOMPLETE_COMMON_FIELDS} from './grids';
import {FLOC_CATALOGS_COMMON_FIELDS} from './refFlocCatalogs';
import {FLOCS_AUTOCOMPLETE_COMMON_FIELDS} from './flocs';
import {CML_REF_CLASSES_COMMON_FIELDS} from './refCmlClasses';
import {CML_REF_CODE_GROUPS_COMMON_FIELDS} from './refCmlCodeGroups';

export const CMLS_COMMON_FIELDS = gql`
    ${CML_REF_CLASSES_COMMON_FIELDS}
    ${CML_REF_CODE_GROUPS_COMMON_FIELDS}
    ${FLOC_REF_PARTS_COMMON_FIELDS}
    ${WORKORDER_REF_EVENT_TECHNIQUES_COMMON_FIELDS}
    ${GRIDS_AUTOCOMPLETE_COMMON_FIELDS}
    ${FLOC_CATALOGS_COMMON_FIELDS}
    ${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
    fragment CmlCommonFields on Cml {
        id
        position
        longDescription
        description
        coordinates2d
        markerCoordinates2d
        coordinates3d
        markerCoordinates3d
        diameter
        display2d
        display3d
        flagStatus
        nominal
        alarm1
        alarm2
        alarm3
        partId
        idwgId
        part {
            ...RefFlocPartCommonFields
        }
        gridId
        grid {
            ...GridAutocompleteCommonFields
        }
        accessibilityId
        accessibility {
            ...RefFlocCatalogsCommonFields
        }
        codeGroupId
        codeGroup {
            ...RefCmlCodeGroupCommonFields
        }
        techniqueId
        technique {
            ...RefEventTechniqueCommonFields
        }
        flocId
        functionalLocation {
            ...FlocAutocompleteCommonFields
        }
        maxEndDate
        corrRateST
        corrRateLT
        annotation
    }
`;
