import {Document} from '@app/graphql/__types__/graphql';
import DocInfo from './DocInfo';

type TLeftSide = Readonly<{
  doc: Partial<Document>;
}>;

export default function LeftSide({doc}: TLeftSide) {
  return (
    <div className='h-full w-full overflow-auto'>
      <DocInfo doc={doc}/>
    </div>
  );
}
