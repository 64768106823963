import DocumentationBlock from '@app/components/Common/Block/Event/EventModal/components/DocumentationBlock';
import EventInfo from '@app/components/Common/Block/Event/EventModal/components/General/EventInfo';
import FlocBlock from '@app/components/Common/Block/Event/EventModal/components/General/ObjectList/FlocBlock';
import TaskBlock from '@app/components/Common/Block/Event/EventModal/components/General/ObjectList/TaskBlock';
import CmlBlock from '@app/components/Common/Block/Event/EventModal/components/Inspection/CmlBlock';
import DamageBlock from '@app/components/Common/Block/Event/EventModal/components/Inspection/DamageBlock';
import InspectionPointBlock from '@app/components/Common/Block/Event/EventModal/components/Inspection/InspectionPointBlock';
import {useEventStore} from '@app/stores/event';
import {RadTabs, RadTabsList, RadTabsTrigger} from '@holis/react-ui/rad';
import React from 'react';
import {RefObject, UIEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';

const LEFT_PANEL_TABS = [
  'header',
  'floc',
  'task',
  'checklist',
  'cml',
  'damage',
  'documentation',
] as const;
type panelKey = typeof LEFT_PANEL_TABS[number];

export type LeftPanelProps = Readonly<{
  readonly?: boolean;
}>;

export default function LeftPanel({readonly}: LeftPanelProps) {
  const {t} = useTranslation();
  const [currentAnchor, setCurrentAnchor] = useState('header');
  const {activeEvent} = useEventStore();
  const scrollContainerRef = React.createRef<HTMLDivElement>();
  const anchRef: Partial<{ [K in panelKey]: RefObject<HTMLDivElement> }> = {};

  for (const k of LEFT_PANEL_TABS) {
    anchRef[k] = React.createRef<HTMLDivElement>();
  }

  const handleSetAnchor = (anchor: panelKey) => {
    if (anchRef[anchor]?.current) {
      const elPos = anchRef[anchor]!.current!.offsetTop - scrollContainerRef.current!.offsetTop;
      scrollContainerRef.current?.scrollTo({
        top: elPos,
        behavior: 'smooth',
      });
    }

    setCurrentAnchor(anchor);
  };

  const handleInnerPanelScroll = (e: UIEvent<HTMLDivElement>) => {
    const scrollPos = e.currentTarget.scrollTop;
    let newCurrTarget:panelKey = LEFT_PANEL_TABS[0];
    for (let i = LEFT_PANEL_TABS.length - 1; i >= 0; i--) {
      const k = LEFT_PANEL_TABS[i];
      if (anchRef[k]?.current) {
        const elPos = anchRef[k]!.current!.offsetTop - e.currentTarget.offsetTop - 75;
        if (elPos <= scrollPos) {
          newCurrTarget = k;
          break;
        }
      }
    }

    setCurrentAnchor(newCurrTarget);
  };

  if (!activeEvent) {
    return null;
  }

  return (
    <div className='flex flex-col h-full w-full overflow-hidden'>
      <RadTabs className='pt-2 px-4' value={currentAnchor}>
        <RadTabsList>
          { LEFT_PANEL_TABS.map(tab => (
            <RadTabsTrigger key={tab} value={tab} onClick={() => handleSetAnchor(tab)}>
              {t(`label.${tab}`)}
            </RadTabsTrigger>
          )) }
        </RadTabsList>
      </RadTabs>

      <div ref={scrollContainerRef} className='flex-1 mt-4 px-4 pb-6 flex flex-col overflow-auto' onScroll={handleInnerPanelScroll}>

        {/* Event Header */}
        <div ref={anchRef.header} className='mt-3'>
          <EventInfo event={activeEvent} readonly={readonly}/>
        </div>

        {/* Floc */}

        <div ref={anchRef.floc} className='mt-6'>
          <FlocBlock event={activeEvent} readonly={readonly}/>
        </div>

        {/* Task */}
        <div ref={anchRef.task} className='mt-6'>
          <TaskBlock event={activeEvent} readonly={readonly}/>
        </div>

        {/* Checklist */}
        <div ref={anchRef.checklist} className='mt-6'>
          <InspectionPointBlock event={activeEvent} readonly={readonly}/>
        </div>
        {/* CML */}
        <div ref={anchRef.cml} className='mt-6'>
          <CmlBlock event={activeEvent} readonly={readonly}/>
        </div>

        {/* Damage */}
        <div ref={anchRef.damage} className='mt-6'>
          <DamageBlock event={activeEvent} readonly={readonly}/>
        </div>

        {/* Documentation */}
        <div ref={anchRef.documentation} className='mt-6'>
          <DocumentationBlock event={activeEvent} readonly={readonly}/>
        </div>

      </div>

    </div>
  );
}
