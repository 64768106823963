import {TUploadParameters} from '@app/types/app';
import {OptimusClientConfig} from '@app/utils/clientConfig';
import {EImportService} from '@app/utils/enums';
import {Axios, AxiosResponse} from 'axios';

export default class UploadDownloadService {
  private client: Axios;
  private uploadUrl: string;
  private downloadUrl: string;
  static uploadService: UploadDownloadService;
  constructor(private getAccessToken: () => Promise<string | undefined>) {
    if (!OptimusClientConfig.current.fileUploadUrl) {
      throw new Error('Environmental variable VITE_FILE_UPLOAD_URL is not set !');
    }

    if (!OptimusClientConfig.current.fileDownloadUrl) {
      throw new Error('Environmental variable VITE_FILE_DOWNLOAD_URL is not set !');
    }

    this.uploadUrl = OptimusClientConfig.current.fileUploadUrl;
    this.downloadUrl = OptimusClientConfig.current.fileDownloadUrl;
    this.client = new Axios({
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  private async getClient() {
    const token = await this.getAccessToken();
    if (token) {
      this.client.defaults.headers.common = {
        ...this.client.defaults.headers.common,
        Authorization: `Bearer ${token}`,
      };
    }

    return this.client;
  }

  private addDataToForm(form: FormData, data?: TUploadParameters) {
    if (data) {
      Object.entries(data).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((val: string | number | boolean) => {
            form.append(`${key}${key.endsWith('[]') ? '' : '[]'}`, String(val));
          });
        } else {
          form.append(key, String(value));
        }
      });
    }
  }

  async exportTableData(tableRef: string, format: string): Promise<AxiosResponse> {
    const client: Axios = await this.getClient();
    return client.get(
      `${this.downloadUrl}/table-data?ref=${tableRef}&format=${format}`, {
        responseType: 'blob',
      },
    );
  }

  async uploadFile(file: File, data?: TUploadParameters, mode?: EImportService): Promise<AxiosResponse> {
    const client: Axios = await this.getClient();

    const form = new FormData();
    form.append('mode', mode ?? EImportService.UPLOAD_FILE);
    form.append('files[]', file);
    this.addDataToForm(form, data);

    return client.post(this.uploadUrl, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  }

  async uploadMultipleFiles(files: File[], data?: TUploadParameters, mode?: EImportService): Promise<AxiosResponse> {
    const client: Axios = await this.getClient();

    const form = new FormData();
    form.append('mode', mode ?? EImportService.UPLOAD_FILE);
    files.forEach((file: File) => {
      form.append('files[]', file);
    });
    this.addDataToForm(form, data);
    return client.post(this.uploadUrl, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  }
}
