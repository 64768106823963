import ListModal, {TListModalWithoutListProps} from '@app/components/Modal/List';
import {Cml} from '@app/graphql/__types__/graphql';
import {FORMAT_DATE_EU} from '@app/utils/constants';
import {EAppFlagStatus} from '@app/utils/enums';
import {TDataRowTreeList} from '@holis/react-ui';
import moment from 'moment';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {IconType} from 'react-icons/lib';
import {LuAlertTriangle, LuCheckCircle, LuCircleSlash} from 'react-icons/lu';
import colors from 'tailwindcss/colors';

export default function CmlListModal({items, ...restProps}: TListModalWithoutListProps<Partial<Cml>>) {
  const {t} = useTranslation();
  return (
    <ListModal<Partial<Cml>>
      title={t('label.inspectionCmlList')}
      listProps={{
        listProps: {
          data: (items ?? []) as unknown as TDataRowTreeList[],
          cellRenderers: {
            date(value) {
              const dateMoment = value ? moment.parseZone(value) : null;
              return moment.isMoment(dateMoment) && dateMoment.isValid() ? dateMoment.format(FORMAT_DATE_EU) : '';
            },
            flagStatus(value) {
              let color: string = colors.green[700];
              let bgColor: string = 'bg-green-100';
              let Icon: IconType = LuCheckCircle;
              if (value === EAppFlagStatus.DELETED) {
                color = colors.red[700];
                Icon = LuCircleSlash;
                bgColor = 'bg-red-100';
              } else if (value === EAppFlagStatus.CLOSED) {
                color = colors.orange[700];
                Icon = LuAlertTriangle;
                bgColor = 'bg-orange-100';
              }

              return (
                <div className={`aspect-square rounded w-[30px] flex flex-col justify-center text-center items-center ${bgColor}`}><Icon color={color} size={20}/></div>
              );
            },
          },
          columns: [
            {
              field: 'flagStatus',
              title: '-',
              type: 'string',
              width: 20,
              cellRenderer: 'flagStatus',
              class: 'status-col',
            },
            {
              field: 'functionalLocation.floc',
              title: t('label.functionalLocation'),
              type: 'string',
              width: 150,
            },
            {
              field: 'position',
              title: t('label.position'),
              type: 'string',
              width: 60,
            },
            {
              field: 'description',
              title: t('label.description'),
              type: 'string',
              width: 150,
            },
            {
              field: 'codeGroup.class.class',
              title: t('label.class'),
              type: 'string',
              width: 60,
            },
          ],
        },
      }}
      {...restProps}
    />
  );
}
