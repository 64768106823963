import {FetchResult, useLazyQuery, useMutation} from '@apollo/client';
import TaskList from './TaskList';
import {CreateItemTasksMutation, GetItemTasksByItemIdQuery, Item, ItemTask, RefEventTask} from '@app/graphql/__types__/graphql';
import {SCHEDULING_ITEM_TASKS_CREATE_MANY, SCHEDULING_ITEM_TASKS_GET_BY_ITEM_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useLayoutStore} from '@app/stores/layout';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {EWORKORDER_REF_EVENT_TASK_CATEGORY} from '@app/utils/enums';
import DeleteItemTaskModal from './DeleteItemTaskModal';
import useItemStore from '@app/stores/item';
import {Badge} from '@holis/react-ui';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import {RadButton} from '@holis/react-ui/rad';
import {LuPlusSquare} from 'react-icons/lu';
import TaskSelectionModal from '@app/components/Common/Block/Task/TaskBlock/TaskSelectionModal';

type TTaskBlock = Readonly<{
  item: Partial<Item>
}>;

export default function TaskBlock({item}: TTaskBlock) {
  const {t} = useTranslation();
  const {taskToDelete, setTaskToDelete, fetchItemTasks, setFetchItemTasks} = useItemStore();
  const [tasks, setTasks] = useState<Partial<ItemTask>[]>();
  const [inspectionTasks, setInspectionTasks] = useState<Partial<ItemTask>[]>();
  const [safetyTasks, setSafetyTasks] = useState<Partial<ItemTask>[]>();
  const {startLoading, stopLoading} = useLayoutStore();
  const [manHoursTotal, setManHoursTotal] = useState<number|undefined>();
  const [addItemTasksApi] = useMutation<CreateItemTasksMutation>(SCHEDULING_ITEM_TASKS_CREATE_MANY);
  const [getItemTasksByItemId, {refetch, called}] = useLazyQuery<GetItemTasksByItemIdQuery>(SCHEDULING_ITEM_TASKS_GET_BY_ITEM_ID, {
    variables: {
      itemId: item.id!,
    },
    fetchPolicy: 'no-cache',
  });
  const [hiddenIds, setHiddenIds] = useState<number[]>();
  const [taskSelectionModalDisplayed, changeTaskSelectionModalDisplay] = useState<boolean>(false);

  const getTasks = () => {
    (called ? refetch : getItemTasksByItemId)().then(queryResults => {
      setTasks(queryResults.data?.itemTasks as Partial<ItemTask>[]);
    }).catch(_err => {
      AppNotifications.error(t('message.error.default.title'));
    });
  };

  const addTasks = (itemTasks: Partial<ItemTask>[], changeTaskSelectionModalDisplay: (displayed: boolean) => void) => {
    startLoading();
    addItemTasksApi({
      variables: {
        data: itemTasks.map((itemTask: Partial<ItemTask>) => ({
          itemId: item.id,
          taskId: itemTask.taskId,
        })),
      },
    }).then((_res: FetchResult<CreateItemTasksMutation>) => {
      fetchItemTasks?.();
      changeTaskSelectionModalDisplay(false);

      AppNotifications.success(t('message.success.addItemTask'));
      stopLoading();
    }).catch(_err => {
      stopLoading();
      AppNotifications.error(t('message.error.default.title'));
    });
  };

  const handleAddItems = (tasks: Partial<RefEventTask>[]) => {
    addTasks?.(tasks.map((task: Partial<RefEventTask>) => ({
      taskId: task.id,
      itemId: item.id,
      item,
      task,
    })) as Partial<ItemTask>[], changeTaskSelectionModalDisplay);
  };

  useEffect(() => {
    getTasks();
    setFetchItemTasks(getTasks);
  }, []);

  useEffect(() => {
    setSafetyTasks(tasks?.filter((task: Partial<ItemTask>) => task.task?.category === EWORKORDER_REF_EVENT_TASK_CATEGORY.SAFETY));
    setInspectionTasks(tasks?.filter((task: Partial<ItemTask>) => task.task?.category === EWORKORDER_REF_EVENT_TASK_CATEGORY.INSPECTION));
    setHiddenIds(tasks?.map((itemTask: Partial<ItemTask>) => itemTask.task!.id!));
  }, [tasks]);

  useEffect(() => {
    const total = 0;
    setManHoursTotal(inspectionTasks?.map((task: Partial<ItemTask>) => Number((task.duration ?? 0) * (task.nbWorkers ?? 0)))?.reduce((allTaskManHours: number, taskManHours: number) => allTaskManHours + taskManHours, total) ?? undefined);
  }, [inspectionTasks]);

  return (
    <div className='h-full flex flex-col'>
      <div className='flex flex-col overflow-visible'>
        <FormGroupHeader
          actions={
            <div className='flex gap-2 items-stretch'>
              <Badge className='bg-blue-100 p-2 px-3 h-9 text-blue-500'>{t('label.workloadInHour')} {manHoursTotal}</Badge>
              <RadButton type='button' variant='outline' onClick={() => changeTaskSelectionModalDisplay(true)}><LuPlusSquare className='mr-2'/> {t('label.addTask')}</RadButton>
            </div>
          }
        >
          {t('label.taskList')}
        </FormGroupHeader>
        <div className='pt-2 relative'>
          <TaskList
            hasActionButtons
            item={item}
            tasks={[
              ...(safetyTasks ?? []),
              ...(inspectionTasks ?? []),
            ]}
            isDeleteButtonDisabled={!!taskToDelete}
            onDeleteClick={setTaskToDelete}
          />
        </div>
        <DeleteItemTaskModal open={!!taskToDelete}/>
      </div>
      {tasks?.length === 0 && <div className='text-center text-gray-300 text-base flex-1 flex flex-col justify-center'>{t('label.taskListEmpty')}</div>}
      {taskSelectionModalDisplayed && <TaskSelectionModal open hiddenIds={hiddenIds} closeOnConfirm={false} onClose={() => changeTaskSelectionModalDisplay(false)} onValidate={handleAddItems}/>}
    </div>
  );
}
