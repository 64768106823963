import {FunctionalLocation} from '@app/graphql/__types__/graphql';
import TextButton from '@app/components/Common/Button/TextButton';
import {FaPlus} from 'react-icons/fa';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import FlocSelectionModal from '../../Block/Floc/FlocBlock/FlocSelectionModal';
import FlocCard from '../../Block/Floc/FlocBlock/FlocCard';

type TFLocList = Readonly<{
    flocs?: Partial<FunctionalLocation>[];
    onClickAddFlocs?: (item: Partial<FunctionalLocation>[], changeFlocAutocompleteDisplay: (displayed: boolean) => void) => void;
    isDeleteButtonDisabled?: boolean;
    hasActionButtons?: boolean;
    onDeleteClick?: (floc: Partial<FunctionalLocation>) => void;
}>

export default function FlocList({flocs, onClickAddFlocs, isDeleteButtonDisabled, onDeleteClick}: TFLocList) {
  const [flocSelectionModalDisplayed, changeFlocSelectionModalDisplay] = useState<boolean>(false);
  const {t} = useTranslation();

  const handleDeleteItemClick = (floc: Partial<FunctionalLocation>) => {
    onDeleteClick?.(floc);
  };

  const handleAddItems = (flocs: Partial<FunctionalLocation>[]) => {
    onClickAddFlocs?.(flocs, changeFlocSelectionModalDisplay);
  };

  return (
    <div className='flex flex-col gap-2'>
      {flocs?.map((floc: Partial<FunctionalLocation>) => (
        <FlocCard
          key={`floc-item-${floc.id!}`}
          isDeleteButtonDisabled={isDeleteButtonDisabled}
          floc={floc} onDeleteClick={handleDeleteItemClick}/>
      ))}
      {flocSelectionModalDisplayed && <FlocSelectionModal open selectedIds={flocs?.map((item: Partial<FunctionalLocation>) => item.id!) ?? []} closeOnConfirm={false} onClose={() => changeFlocSelectionModalDisplay(false)} onValidate={handleAddItems}/>}
      {!!onClickAddFlocs && <TextButton className='-ml-4' onClick={() => changeFlocSelectionModalDisplay(true)}><FaPlus/> {t('label.addFloc')}</TextButton>}
    </div>
  );
}
