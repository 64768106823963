import {LuAlertTriangle} from 'react-icons/lu';

export type AppErrorProps = {
	readonly error: string;
};
export default function AppError({error}: AppErrorProps) {
  return (
    <div className='h-screen w-screen flex justify-center items-center'>
      <div className='text-red-500 text-center mb-[10%]'>
        <LuAlertTriangle className='w-12 h-12 m-auto mb-4'/>
        <div className='mb-2 font-bold'>An unexpected error occurred:</div>
        <div className='mb-2'>&laquo; {error} &raquo;</div>
        <div>See browser console for more details.</div>
      </div>
    </div>
  );
}
