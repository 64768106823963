import {gql} from '@apollo/client/core';
import {DOCUMENTATION_DOCUMENTS_COMMON_FIELDS, WORKORDER_EVENT_DOCUMENTS_COMMON_FIELDS} from '../fragments';

export const WORKORDER_EVENT_DOCUMENTS_CREATE = gql`
${WORKORDER_EVENT_DOCUMENTS_COMMON_FIELDS}
mutation createEventDocument($docId: Int!, $evtId: Int!) {
  createOneEventDocument (data: {docId: $docId, wrkoId: $evtId}) {
    ...EventDocumentCommonFields
    event {
      id
      event
    }
  }
}`;

export const WORKORDER_EVENT_DOCUMENTS_DELETE = gql`
mutation deleteEventDocument($docId: Int!, $evtId: Int!) {
  deleteOneEventDocument (where: {wrkoId_docId: {docId: $docId, wrkoId: $evtId}}) {
    id
  }
}`;

export const WORKORDER_EVENT_DOCUMENTS_GET_BY_EVT_ID = gql`
${WORKORDER_EVENT_DOCUMENTS_COMMON_FIELDS}
${DOCUMENTATION_DOCUMENTS_COMMON_FIELDS}
query GetEventDocumentsByEvtId($evtId: Int!) {
  eventDocuments (where: {wrkoId: {equals: $evtId}}, orderBy: [{sort: {sort: asc}}]) {
    ...EventDocumentCommonFields
    document {
      ...DocumentCommonFields
    }
  }
}`;

export const WORKORDER_EVENT_DOCUMENTS_CREATE_MANY = gql`
mutation CreateEventDocuments($data: [EventDocumentCreateManyInput!]!) {
  createManyEventDocument(data: $data) {
    count
  }
}`;

export const WORKORDER_EVENT_DOCUMENTS_DELETE_MANY = gql`
mutation DeleteEventDocuments($ids: [Int!]) {
  deleteManyEventDocument (where: {id: {in: $ids}}) {
    count
  }
}`;

export const WORKORDER_EVENT_DOCUMENTS_UPDATE_BY_ID = gql`
${WORKORDER_EVENT_DOCUMENTS_COMMON_FIELDS}
${DOCUMENTATION_DOCUMENTS_COMMON_FIELDS}
mutation UpdateOneEventDocumentById($id: Int!, $data: EventDocumentUncheckedUpdateInput!) {
  updateOneEventDocument(where: {id: $id}, data: $data) {
    ...EventDocumentCommonFields
    document {
      ...DocumentCommonFields
    }
  }
}
`;

export const WORKORDER_EVENT_DOCUMENTS_DELETE_ONE_BY_ID = gql`
mutation DeleteOneEventDocumentById($id: Int!) {
  deleteOneEventDocument(where: {id: $id}) {
    id
  }
}
`;

export const WORKORDER_EVENT_DOCUMENTS_GET_COUNTER = gql`
query GetWorkorderEventDocumentsCounter {
  aggregateEventDocument {
    _count {
      id
    }
  }
}
`;
