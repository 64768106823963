import {useHolisAuth} from '@holis/auth-client-react';
import LayoutViewer, {TLayoutViewer, TZoomProps} from '..';
import React, {HTMLAttributes, useEffect, useState} from 'react';
import {addTokenToUrl} from '@app/utils/functions';
import {useTranslation} from 'react-i18next';
import {twMerge} from 'tailwind-merge';

type TImgViewer = Readonly<{
    src: string | File;
    alt?: string;
    zoomProps?: TZoomProps;
    imgRef?: React.RefObject<HTMLImageElement>;
    layoutProps?: TLayoutViewer;
    imgProps?: HTMLAttributes<HTMLImageElement>;
    isExternalSrc?: boolean;
    className?: string;
    imgClassName?: string;
    contentClassName?: string;
}>

export default function ImgViewer({src, imgRef, zoomProps, layoutProps, imgProps, isExternalSrc, alt, className, imgClassName, contentClassName}: TImgViewer) {
  imgRef = imgRef ?? React.createRef<HTMLImageElement>();
  const {t} = useTranslation();
  const [url, setUrl] = useState<string>();
  const {getAccessToken} = useHolisAuth();
  const generateImageUrl = (fileUrl: string) => {
    if (isExternalSrc) {
      setUrl(fileUrl);
    } else {
      addTokenToUrl(fileUrl, getAccessToken).then(setUrl);
    }
  };

  useEffect(() => {
    if (src instanceof File) {
      const imgReader = new FileReader();
      imgReader.onload = () => {
        if (imgRef?.current) {
          imgRef!.current!.src! = imgReader.result as string;
        }
      };

      imgReader.onerror = () => {
        setUrl('');
      };

      imgReader.readAsDataURL(src);
    } else {
      generateImageUrl(src as string);
    }
  }, [src]);
  return (
    <LayoutViewer className={className} contentClassName={twMerge('flex flex-col', contentClassName)} zoomProps={zoomProps} {...layoutProps}>
      <img ref={imgRef} className={twMerge('w-full h-full object-contain', imgClassName)} src={url} alt={typeof url === 'undefined' ? t('label.loading') : alt} {...imgProps}/>
    </LayoutViewer>
  );
}
