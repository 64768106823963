import SimplePageSelector from '@app/components/Common/Tabs/SimplePageSelector';
import {useTranslation} from 'react-i18next';
import DrawingPage from './pages/Drawing';
import PicturePage from './pages/Picture';
import {useEventStore} from '@app/stores/event';
import ReportPage from './pages/Report';
import {EWorkpackRightSideTab} from '@app/utils/enums';
import FlocOverviewDrawing from '@app/components/Common/Block/Floc/FlocBlock/FlocOverviewDrawing';
import LongDescriptionPage from './pages/LongDescription';
import {RadTabs, RadTabsList, RadTabsTrigger} from '@holis/react-ui/rad';
import {LuInfo} from 'react-icons/lu';

export type RightPanelPage = Readonly<{
  visible?: boolean;
  readonly?: boolean;
}>;

export type RightPanelProps = Readonly<{
  readonly?: boolean;
}>;

export default function RightPanel({readonly}: RightPanelProps) {
  const {t} = useTranslation();
  const {activeEvent, setRightSideTabSelectedValue, rightSideTabSelectedValue} = useEventStore();
  return (
    <div className='h-full w-full'>
      <div className='h-full w-full flex flex-col'>

        <div className='shadow-sm h-11 flex flex-col justify-center'>
          <RadTabs className='px-4 absolute' value={rightSideTabSelectedValue}>
            <RadTabsList>
              <RadTabsTrigger
                key={EWorkpackRightSideTab.LONG_DESCRIPTION}
                className='py-2' value={EWorkpackRightSideTab.LONG_DESCRIPTION}
                onClick={() => {
                  setRightSideTabSelectedValue(EWorkpackRightSideTab.LONG_DESCRIPTION);
                }}>
                <LuInfo className=''/>
              </RadTabsTrigger>
            </RadTabsList>
          </RadTabs>

          <SimplePageSelector
            items={[
              {label: t('label.picture'), value: EWorkpackRightSideTab.PICTURES},
              {label: t('label.drawing'), value: EWorkpackRightSideTab.DRAWINGS},
              {label: t('label.overview'), value: EWorkpackRightSideTab.OVERVIEW},
              {label: t('label.report'), value: EWorkpackRightSideTab.REPORT},
            ]}
            selectedValue={rightSideTabSelectedValue}
            onSelectedValueChanged={setRightSideTabSelectedValue}
          />
        </div>
        <div className='p-4 flex-1 flex flex-col overflow-auto'>

          <LongDescriptionPage visible={rightSideTabSelectedValue === EWorkpackRightSideTab.LONG_DESCRIPTION} readonly={readonly}/>

          <PicturePage visible={rightSideTabSelectedValue === EWorkpackRightSideTab.PICTURES} readonly={readonly}/>

          <DrawingPage visible={rightSideTabSelectedValue === EWorkpackRightSideTab.DRAWINGS} readonly={readonly}/>

          {activeEvent?.schedulingTag && <FlocOverviewDrawing floc={activeEvent.schedulingTag} visible={rightSideTabSelectedValue === EWorkpackRightSideTab.OVERVIEW}/>}

          <ReportPage readonly={readonly} visible={rightSideTabSelectedValue === EWorkpackRightSideTab.REPORT}/>

        </div>
      </div>
    </div>
  );
}
