import {gql} from '@apollo/client';
import {SECTORS_COMMON_FIELDS} from './sectors';

export const GRIDS_AUTOCOMPLETE_COMMON_FIELDS = gql`
  ${SECTORS_COMMON_FIELDS}
  fragment GridAutocompleteCommonFields on Grid {
    id
    grid
    description
    sectorId
    sector {
      ...SectorCommonFields
    }
  }
`;

