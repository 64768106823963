import {gql} from '@apollo/client';

export const DOCUMENTATION_DOCUMENTS_COMMON_FIELDS = gql`
  fragment DocumentCommonFields on Document {
    id
    document
    description
    docName
    sector {
      id
      sector
      site {
        id
        site
      }
      description
    }
    discipline {
      id
      code
      description
    }
    type {
      id
      code
      description
    }
    phase {
      id
      code
      description
    }
    catalog {
      id
      code
      description
    }
    stage {
      id
      code
      description
    }
    revision
    originator {
      id
      code
      description
    }
    catalogId
    disciplineId
    originatorId
    phaseId
    sectorId
    stageId
    typeId
    flagStatus
  }

`;

export const DOCUMENTATION_DOCUMENTS_COUNTER_FIELDS = gql`
  fragment DocumentCounterFields on Document {
    _count {
      flocDocs
    }
  }
`;

export const DOCUMENTATION_DOCUMENT_VIEWS_COMMON_FIELDS = gql`
  fragment DocumentViewCommonFields on DocumentView {
    id
    document
    description
    docName
    sector_sector
    sector_description
    sector_site_site
    sector_site_description
    sector_site_longDescription
    discipline_code
    discipline_description
    type_code
    type_description
    phase_code
    phase_description
    catalog_code
    catalog_description
    stage_code
    stage_description
    revision
    originator_code
    originator_description
    catalogId
    disciplineId
    originatorId
    phaseId
    sectorId
    stageId
    typeId
    flagStatus
  }
`;
