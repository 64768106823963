import {gql} from '@apollo/client';

export const MEASPOINT_REF_CLASS_CODE_GROUPS_GET_COUNTER = gql`
query GetRefMeasPointClassCodeGroupsCounter {
  aggregateRefMeasPointClassCodeGroup {
    _count {
      id
    }
  }
}
`;
