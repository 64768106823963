import {gql} from '@apollo/client';
import {NOTIFICATION_CATALOGS_COMMON_FIELDS} from '../fragments';

export const NOTIFICATION_CATALOGS_GET_MANY = gql`
${NOTIFICATION_CATALOGS_COMMON_FIELDS}
query GetAllRefNotifCatalogs($orderBy: [RefNotifCatalogOrderByWithRelationInput!], $where: RefNotifCatalogWhereInput) {
    refNotifCatalogs(orderBy: $orderBy, where: $where) {
      ...RefNotifCatalogCommonFields
    }
}`;

export const NOTIFICATION_CATALOGS_GET_COUNTER = gql`
query GetRefNotifCatalogsCounter {
  aggregateRefNotifCatalog {
    _count {
      id
    }
  }
}
`;
