import {gql} from '@apollo/client';

export const APP_EVENT_STATUSES_COMMON_FIELDS = gql`
  fragment EventStatusCommonFields on StatusWorkOrder {
      status
      stage
      description
      displayColor
    }
`;
