import {gql} from '@apollo/client';

export const DWG_IDWG_GRID_DELETE_MANY_BY_IDWG_ID_AND_FLOC_IDS = gql`
  mutation DeleteIdwgGridsByIdwgIdAndGridIds($idwgId: Int!, $gridIds: [Int!]) {
    deleteManyIdwgGrid(where: {idwgId: {equals: $idwgId}, gridId: {in: $gridIds}}) {
      count
    }
  }
`;

export const DWG_IDWG_GRID_CREATE_MANY = gql`
  mutation CreateIdwgGrids($data: [IdwgGridCreateManyInput!]!) {
    createManyIdwgGrid(data: $data, skipDuplicates: true) {
      count
    }
  }
`;

export const DWG_IDWG_GRIDS_GET_COUNTER = gql`
query GetIdwgGridsCounter {
  aggregateIdwgGrid {
    _count {
      _all
    }
  }
}
`;
