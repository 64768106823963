import PageContent from '@app/components/Layout/PageContent';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {QueryResult, useLazyQuery} from '@apollo/client';
import {GetPlanDetailByIdQuery, GetPlanDetailByNumberQuery, Plan} from '@app/graphql/__types__/graphql';
import {SCHEDULING_PLANS_GET_DETAIL_BY_NUMBER} from '@app/graphql/requests';
import LeftSide from '@app/components/Modal/MultiplePanels/Scheduling/Plan/components/LeftSide';
import RightSide from '@app/components/Modal/MultiplePanels/Scheduling/Plan/components/RightSide';
import {useLayoutStore} from '@app/stores/layout';
import {useEffect} from 'react';
import usePlanStore from '@app/stores/plan';
import DetailsPageTitleBar from '@app/components/Layout/DetailsPageTitleBar';
import {RadDropdownMenuItem} from '@holis/react-ui/rad';
import Footer from '@app/components/Modal/MultiplePanels/Scheduling/Plan/components/Footer';
import useItemStore from '@app/stores/item';
import PlanWorkflowTableModal from '@app/components/Modal/MultiplePanels/Scheduling/Plan/components/Workflow';
import {EAdditionalAction, ESCHEDULING_PLAN_STATUS} from '@app/utils/enums';
import {TAdditionalActions} from '@app/types/app';
import DeletePlanModal from '@app/components/Modal/MultiplePanels/Scheduling/Plan/components/DeletePlanModal';
import {LuDownload, LuGitFork, LuRefreshCw, LuTrash2} from 'react-icons/lu';
import ItemModal from '@app/components/Modal/Scheduling/Item';
import ItemsSummary, {TSummaryItem} from '@app/components/Common/ItemSummary';
import MultipleResizablePanels from '@app/components/Common/Panel/MultipleResizablePanels';
import useReportGeneration, {ItemType} from '@app/utils/hooks/useReportGeneration';
import ReportGeneratingMesaggeWrapper from '@app/components/Common/Block/Reporting/ReportGeneratingMesaggeWrapper';

type routeParams = {
  number: string;
};

export default function SchedulingInspectionPlanDetailsPage() {
  const {number: planNumber} = useParams<routeParams>();
  const {t} = useTranslation();
  const {startLoading, stopLoading} = useLayoutStore();
  const {editPlan, setFetchPlan, updatePlan, items, events, flocs, setActivePlan, workflowModalDisplayed, hasError, changeDeletePlanModalDisplay, changeWorkflowModalDisplay, deletePlanModalOpen} = usePlanStore();
  const {activeItem} = useItemStore();
  const [getPlanDetail, {loading}] = useLazyQuery<GetPlanDetailByNumberQuery>(SCHEDULING_PLANS_GET_DETAIL_BY_NUMBER, {
    variables: {number: planNumber},
    fetchPolicy: 'no-cache',
  });
  const {downloadReport, hasReport, isReportGenerating, launchReportGeneration} = useReportGeneration(ItemType.plan, editPlan?.id, editPlan?.plan);

  const getAndSetPlanDetail = () => getPlanDetail().then((queryResult: QueryResult<GetPlanDetailByIdQuery>) => {
    const newPlan = {...(queryResult.data?.plan ?? {})} as Partial<Plan>;
    updatePlan(newPlan);

    return newPlan;
  });

  const showWorkflowModal = () => {
    changeWorkflowModalDisplay(true);
  };

  useEffect(() => {
    if (loading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [loading]);

  useEffect(() => {
    setFetchPlan(getAndSetPlanDetail);
    getAndSetPlanDetail().then(setActivePlan);
  }, []);

  const additionalActions: TAdditionalActions = {
    [EAdditionalAction.DISPLAY_WORKFLOW]: <RadDropdownMenuItem className='gap-2' onClick={showWorkflowModal}><LuGitFork/> {t('label.workflow')}</RadDropdownMenuItem>,
    [EAdditionalAction.GENERATE_REPORT]: <RadDropdownMenuItem className='gap-2' disabled={isReportGenerating} onClick={launchReportGeneration}><LuRefreshCw className={`text-sm${isReportGenerating ? ' animate-spin' : ''}`}/> {t('label.generateReport')}</RadDropdownMenuItem>,
    ...(hasReport ? {[EAdditionalAction.DOWNLOAD_REPORT]: <RadDropdownMenuItem className='gap-2' disabled={isReportGenerating} onClick={downloadReport}><LuDownload className='text-sm'/> {t('label.downloadReport')}</RadDropdownMenuItem>} : {}),
    ...(editPlan?.status !== ESCHEDULING_PLAN_STATUS.CANCEL ? {[EAdditionalAction.DELETE]: <RadDropdownMenuItem className='gap-2 item-destructive' onClick={() => changeDeletePlanModalDisplay(true)}><LuTrash2/> {t('label.deletePlan')}</RadDropdownMenuItem>} : {}),
  };

  const itemsSummary: TSummaryItem[] = [
    {label: 'object', value: flocs?.length ?? 0},
    {label: 'item', value: items?.length ?? 0},
    {label: 'event', value: events?.length ?? 0},
  ];

  return (
    <>
      <PageContent
        header={
          <DetailsPageTitleBar
            title={`${t('label.inspectionPlan')} ${editPlan?.plan}`}
            actionButtons={{hasError, additionalActions}}
          >
            <ReportGeneratingMesaggeWrapper isReportGenerating={isReportGenerating}>
              <ItemsSummary items={itemsSummary}/>
            </ReportGeneratingMesaggeWrapper>
          </DetailsPageTitleBar>
        }
        footer={editPlan ? <Footer plan={editPlan}/> : undefined}
      >
        {
          editPlan
          && <MultipleResizablePanels
            panelsDefaultSize={[50, 50]}
            panelsMinSize={[27, 44]}
            autoSaveId='plan-detail'
            resizerClassName='left-0 panelResizer'
          >
            <LeftSide plan={editPlan}/>
            <RightSide plan={editPlan}/>
          </MultipleResizablePanels>
          // <RadResizablePanelGroup className='flex' direction='horizontal' autoSaveId='resizable-panel-plan-details'>
          //   <RadResizablePanel defaultSize={50} minSize={27}>
          //     <LeftSide plan={editPlan}/>
          //   </RadResizablePanel>
          //   <RadResizableHandle/>
          //   <RadResizablePanel defaultSize={50} minSize={44}>
          //     <RightSide plan={editPlan}/>
          //   </RadResizablePanel>
          // </RadResizablePanelGroup>
        }

        <DeletePlanModal open={deletePlanModalOpen}/>
      </PageContent>

      {activeItem && <ItemModal open item={activeItem}/>}
      {workflowModalDisplayed && <PlanWorkflowTableModal open plan={editPlan!}/>}
    </>
  );
}
