import {RefCmlValCode, RefDamageValCode, RefMeasPointValCode} from '@app/graphql/__types__/graphql';
import {RadBadge} from '@holis/react-ui/rad';
import {QUALREADING_COLOR} from '../constants';

export function renderQualReadingItem(item?: Partial<RefMeasPointValCode> | Partial<RefCmlValCode> | Partial<RefDamageValCode> | null) {
  return item ? <div className='flex flex-row items-center'>
    <RadBadge className='min-w-[60px] justify-center text-center mr-2' style={{backgroundColor: item.integrityCondition?.color ?? QUALREADING_COLOR}}>{item.valCode}</RadBadge>
    {item.description}
  </div> : '';
}
