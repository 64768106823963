import {useLazyQuery} from '@apollo/client';
import NotificationList from '@app/components/Common/Block/Notification/NotificationBlock/NotificationList';
import {Event, FunctionalLocation, GetNotificationsByEvtIdQuery, Notification} from '@app/graphql/__types__/graphql';
import {NOTIFICATIONS_GET_BY_EVT_ID} from '@app/graphql/requests';
import {useEventStore} from '@app/stores/event';
import useNotificationStore from '@app/stores/notification';
import {OBJ_NEW_ID} from '@app/utils/constants';
import {ENOTIFICATION_NOTIF_STATUS} from '@app/utils/enums';
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig';
import {t} from 'i18next';
import {useEffect} from 'react';

export type IanListProps = {
  className?: string;
  readonly?: boolean;
}

export default function IanList({readonly}: Readonly<IanListProps>) {
  const config = useOptimusConfig();
  const {activeEvent, setEventNotifications, eventNotifications, setFetchEventNotifications} = useEventStore();
  const {setActiveNotification} = useNotificationStore();

  const [getEventNotificationsApi, {loading, error, called: eventNotifApiCalled, refetch: eventNotifRefetch}] = useLazyQuery<GetNotificationsByEvtIdQuery>(NOTIFICATIONS_GET_BY_EVT_ID);

  const getEventNotifications = () => {
    (eventNotifApiCalled ? eventNotifRefetch({
      evtId: activeEvent?.id,
    }) : getEventNotificationsApi({
      variables: {
        evtId: activeEvent?.id,
      },
      fetchPolicy: 'no-cache',
    })).then(queryResult => {
      setEventNotifications((queryResult.data?.notifications ?? []) as Partial<Notification>[]);
    });
  };

  useEffect(() => {
    getEventNotifications();
    setFetchEventNotifications(getEventNotifications);
  }, []);

  const createNotif = () => {
    setActiveNotification({
      id: OBJ_NEW_ID,
      flocId: activeEvent!.schedulingTag!.id,
      functionalLocation: activeEvent!.schedulingTag as FunctionalLocation,
      wrkoId: activeEvent!.id!,
      event: activeEvent as Event,
      status: ENOTIFICATION_NOTIF_STATUS.INIT,
      notif: t('label.autoSetValue'),
      creationDate: new Date(),
      __typename: 'Notification',
    });
  };

  return (
    <NotificationList createIan={createNotif} error={!!error} createIanBtnDisabled={readonly || config.getActionIsDisabled('event', 'notificationAdd', activeEvent?.status)} isLoading={loading} notifications={eventNotifications}/>
  );
}
