import {gql} from '@apollo/client';

export const FLOC_REF_CHARACTERISTICS_GET_COUNTER = gql`
query GetRefFlocCharacteristicsCounter {
  aggregateRefFlocCharacteristic {
    _count {
      id
    }
  }
}
`;
