import {useQuery} from '@apollo/client';
import BorderedTable from '@app/components/Common/TreeList/BorderedTable';
import ConfirmModal, {IConfirmModal} from '@app/components/Modal/Confirm';
import {GetAllPlanStatusesQuery, GetPlanWorkflowsByPlanIdQuery, Plan, PlanWorkflow, StatusPlan} from '@app/graphql/__types__/graphql';
import {APP_PLAN_STATUSES_GET_MANY, SCHEDULING_PLAN_WORKFLOWS_GET_BY_PLAN_ID} from '@app/graphql/requests';
import {FORMAT_DATETIME} from '@app/utils/constants';
import {IColumn, TDataRowTreeList} from '@holis/react-ui';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import usePlanStore from '@app/stores/plan';
import {useMemo} from 'react';

type TPlanWorkflowTable = Readonly<IConfirmModal & {
  plan: Partial<Plan>;
}>;

export default function PlanWorkflowTableModal({plan, ...restProps}: TPlanWorkflowTable) {
  const {t} = useTranslation();
  const {changeWorkflowModalDisplay} = usePlanStore();
  const {data, loading, error} = useQuery<GetPlanWorkflowsByPlanIdQuery>(SCHEDULING_PLAN_WORKFLOWS_GET_BY_PLAN_ID, {
    variables: {
      planId: plan.id,
    },
    fetchPolicy: 'no-cache',
  });

  const planStatusesResult = useQuery<GetAllPlanStatusesQuery>(APP_PLAN_STATUSES_GET_MANY);

  const cellRenderers = useMemo(() => ({
    date(_val: string, rowData: unknown) {
      const dataRow = (rowData as Partial<PlanWorkflow>);
      return <span>{dataRow.actionDate ? moment(dataRow.actionDate).format(FORMAT_DATETIME) : ''}</span>;
    },
    statusDescription(_val: string, rowData: unknown) {
      const statuses = planStatusesResult.data?.statusPlans ?? [];
      const planStatus = statuses.find((item: unknown) => (item as Partial<StatusPlan>).status === (rowData as Partial<PlanWorkflow>).status);
      return <span>{planStatus ? (planStatus as Partial<StatusPlan>).description : ''}</span>;
    },

  }), [planStatusesResult]);

  const columns: IColumn[] = [
    {
      field: 'actionDate',
      title: t('label.date'),
      type: 'string',
      cellRenderer: 'date',
      width: 100,
      filter: false,
    },
    {
      field: 'status',
      title: t('label.status'),
      type: 'string',
      width: 60,
      filter: false,
    },
    {
      field: 'status1',
      title: t('label.description'),
      type: 'string',
      width: 120,
      filter: false,
      cellRenderer: 'statusDescription',
    },
    {
      field: 'userLogin',
      title: t('label.user'),
      type: 'string',
      width: 140,
      filter: false,
    },
  ];

  const handleCloseModal = () => {
    changeWorkflowModalDisplay(false);
  };

  return (
    <ConfirmModal open={restProps.open} cancelButtonLabel={false} title={plan.plan} confirmButtonLabel={false} onClose={handleCloseModal}>
      <BorderedTable
        hasSearchBar listProps={{
          columns,
          isLoading: loading,
          error: !!error,
          data: (data?.planWorkflows ?? []) as unknown as TDataRowTreeList[],
          cellRenderers,
        }}/>
    </ConfirmModal>
  );
}
