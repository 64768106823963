import ActionThreeDotsMenu from '@app/components/Common/Form/ActionThreeDotsMenu';
import {RadDialog, RadDialogContent, RadDialogFooter, RadDialogHeader, RadDialogTitle, RadDropdownMenuItem} from '@holis/react-ui/rad';
import {ComponentProps, ReactElement, ReactNode} from 'react';
import {twMerge} from 'tailwind-merge';

export type TSingleContentModal = Readonly<{
  header?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  itemDesctiption?: ReactNode;
	/**
	 * An array of RadDropdownMenuItem for the optional treedots menu
	 */
  menuItems?: ReactElement<ComponentProps<typeof RadDropdownMenuItem>>[];
  footer?: ReactNode;
  children?: ReactNode;
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  containerClassName?: string;
  contentClassName?: string;
  isHidden?: boolean;
  isTransparent?: boolean;
}>;

export default function SingleContentModal(props: TSingleContentModal) {
  const onOpenChange = (isOpen: boolean) => {
    props.onOpenChange?.(isOpen);
  };

  return (
    <RadDialog open={props.isOpen} onOpenChange={onOpenChange}>
      <RadDialogContent
        className={twMerge('flex flex-col min-w-[66%] max-h-[90%] border-border', props.containerClassName, props.isHidden ? 'hidden' : '', props.isTransparent ? 'opacity-0' : '')}
        onPointerDownOutside={e => e.preventDefault()}
        onEscapeKeyDown={e => e.preventDefault()}>
        <RadDialogHeader>
          {
            props.header
            ?? <>
              <RadDialogTitle>
                <div className='flex items-center'>
                  {props.title}
                  { props.itemDesctiption && <div className='text-gray-500 ml-2 font-normal'>{props.itemDesctiption}</div> }
                  { props.menuItems && <div className='ml-8'><ActionThreeDotsMenu menuAlign='start'>{props.menuItems}</ActionThreeDotsMenu></div> }
                </div>
              </RadDialogTitle>
              <div className='text-gray-600 text-xs'>
                {props.description}
              </div>
            </>
          }

        </RadDialogHeader>

        <div className={twMerge('overflow-auto flex-1', props.contentClassName)}>
          {props.children}
        </div>

        <RadDialogFooter>
          {props.footer}
        </RadDialogFooter>
      </RadDialogContent>
    </RadDialog>
  );
}
