import {gql} from '@apollo/client';

export const FILE_ATTACHMENTS_COMMON_FIELDS = gql`
    fragment AttachmentCommonFields on Attachment {
        id
        attachmentName
        description
        sort
        wrkoId
        notifId
        extension
        contentType
        createdAt
        updatedAt
        fsAdapter
        path
    }
`;
