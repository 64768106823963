import {gql} from '@apollo/client';
import {CML_REF_VAL_CODES_COMMON_FIELDS} from '../fragments/refCmlValCodes';

export const CML_REF_VAL_CODES_GET_MANY = gql`
${CML_REF_VAL_CODES_COMMON_FIELDS}
query GetAllRefCmlValCodes($orderBy: [RefCmlValCodeOrderByWithRelationInput!], $where: RefCmlValCodeWhereInput) {
    refCmlValCodes(orderBy: $orderBy, where: $where) {
      ...RefCmlValCodeCommonFields
      codeGroupValCodes {
        id
        codeGroupId
      }
    }
}`;

export const CML_REF_VAL_CODES_GET_COUNTER = gql`
query GetRefCmlValCodesCounter {
  aggregateRefCmlValCode {
    _count {
      id
    }
  }
}
`;
