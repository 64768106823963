import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

type State = {
  menuOpened: boolean;
  loading: boolean;
}

const initialState: State = {
  menuOpened: true,
  loading: false,
};

type Actions = {
    onMenuOpenChange: (menuOpened: boolean) => void
    startLoading: () => void
    stopLoading: () => void
}

export const useLayoutStore = create<State & Actions>()(
  immer(set => ({
    ...initialState,
    onMenuOpenChange: menuOpened => set(state => {
      state.menuOpened = menuOpened;
    }),
    startLoading: () => set(state => {
      state.loading = true;
    }),
    stopLoading: () => set(state => {
      state.loading = false;
    }),
  })),
);
