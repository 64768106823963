import {Event, EventDamage, Damage, Picture} from '@app/graphql/__types__/graphql';
import MultiplePanelsModal, {TMultiplePanelsModal} from '@app/components/Modal/NewMultiplePanels';
import {TCarouselApi} from '@holis/react-ui';
import {useState} from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import PictureCarousel from './components/pictures/PictureCarousel';
import GeneralInfo from './components/GeneralInfo';
import Actions from './components/Actions';
import useDamageStore from '@app/stores/damage';
import {EApiOperator} from '@app/utils/enums';
import {OBJ_NEW_ID} from '@app/utils/constants';
import DeleteDamageModal from './components/DeleteDamageModal';
import EventDamageHistory from './components/events/EventDamagesHistory';
import MeasurementBlock from './components/measurement';
import NotificationBlock from './components/Notification';
import useUserPermissions from '@app/utils/hooks/useUserPermissions';
type TDamageModal = TMultiplePanelsModal<Partial<Damage>> & Readonly<{
    event?: Partial<Event>;
    eventDamage?: Partial<EventDamage>;
    onCreatedOrUpdated?: (damage?: Partial<Damage>, operator?: EApiOperator) => void;
    onPicturesChanged?: (images?: Partial<Picture>[], operator?: EApiOperator) => void;
}>

export default function DamageModal({item, event, onOpenChange, onCreatedOrUpdated, onPicturesChanged, eventDamage, ...restProps}: TDamageModal) {
  const isNew = !item.id || item.id === OBJ_NEW_ID;
  const prms = useUserPermissions();
  const {deleteDamageModalOpen, resetData, isSaved, changeDeleteDamageModalDisplay, editDamage} = useDamageStore();

  const [pictureCarouselApi, setPictureCarouselApi] = useState<TCarouselApi>();

  const handlePictureCarouselRender = (api: TCarouselApi) => {
    setPictureCarouselApi(api);
  };

  const handleRightPanelResize = () => {
    pictureCarouselApi?.calculatePagination();
  };

  const handleDamageDeleted = () => {
    handleCloseModal();
    onCreatedOrUpdated?.(item, EApiOperator.DELETE);
  };

  const handleCloseModal = () => {
    resetData();
    onOpenChange?.(false);
  };

  return (
    <MultiplePanelsModal
      autoSaveId='modal-damage'
      header={<Header actions={isNew ? null : <Actions damage={item} onCreatedOrUpdated={onCreatedOrUpdated}/>} damage={item}/>}
      footer={<Footer damage={item}/>}
      panelsOnResize={[undefined, handleRightPanelResize]}
      panelsDefaultSize={[45, 55]}
      panelsMinSize={[30, 55]}
      item={item}
      isSaved={isSaved}
      onOpenChange={handleCloseModal}
      {...restProps}
    >
      <div className='flex flex-col w-full overflow-auto'>
        <GeneralInfo readonly={!prms.damages.update} damage={item} onCreatedOrUpdated={onCreatedOrUpdated}/>
        <NotificationBlock damage={item}/>
      </div>
      <div className='flex flex-col w-full overflow-auto'>
        {!!eventDamage && <MeasurementBlock readonly={!prms.damages.measurement} event={event!} damage={editDamage!} eventDamage={eventDamage} onCreatedOrUpdated={onCreatedOrUpdated}/>}
        <PictureCarousel
          readonly={!prms.damages.update}
          damage={item} event={event} onChanged={onPicturesChanged}
          onCarouselRender={handlePictureCarouselRender}/>
        <EventDamageHistory event={event} damage={editDamage!} onCreatedOrUpdated={onCreatedOrUpdated}/>
        <DeleteDamageModal open={deleteDamageModalOpen} damage={item} changeDeleteDamageModalDisplay={changeDeleteDamageModalDisplay} onDamageDeleted={handleDamageDeleted}/>
      </div>
    </MultiplePanelsModal>
  );
}
