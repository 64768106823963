import DrawingViewer from '@app/components/Common/Block/InspectionDrawing/DrawingViewer';
import PanelFilters from '@app/components/Common/Panel/PanelFilters';
import {InspectionDrawing} from '@app/graphql/__types__/graphql';
import useIdwgStore from '@app/stores/idwg';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

type TRightSide = Readonly<{
  idwg: Partial<InspectionDrawing>;
}>;

export default function RightSide({idwg}: TRightSide) {
  const canvasContainerRef = React.createRef<HTMLDivElement>();
  const {t} = useTranslation();
  const [filters, setFilters] = useState<string[]>(['cml', 'damage']);
  const {changeCmlMarkupsDisplay, changeDamageMarkupsDisplay, cmlMarkupsShown, damageMarkupsShown} = useIdwgStore();

  const handleFilterChanged = (values: string[]) => {
    changeCmlMarkupsDisplay(values.includes('cml'));
    changeDamageMarkupsDisplay(values.includes('damage'));
  };

  useEffect(() => {
    const filters
      = Object.entries({
        cml: cmlMarkupsShown,
        damage: damageMarkupsShown,
      }).filter(([_, value]) => value).map(([key]) => key);
    setFilters(filters);
  }, [cmlMarkupsShown, damageMarkupsShown]);

  useEffect(() => {
    changeCmlMarkupsDisplay(true);
    changeDamageMarkupsDisplay(true);
  }, []);

  return (
    <div className='w-full h-full overflow-auto flex flex-col gap-1 p-3'>
      <div className='flex justify-end mb-1'>
        <PanelFilters<string>
          isMultiple
          items={[
            {label: t('label.displayCmls'), value: 'cml'},
            {label: t('label.displayDamages'), value: 'damage'},
          ]}
          value={filters}
          onChange={values => handleFilterChanged(values.map(v => v.value))}
        />
      </div>
      <div ref={canvasContainerRef} className='flex-1 w-full'>
        <DrawingViewer
          canvasContainerRef={canvasContainerRef}
          hasActionList={false}
          idwg={idwg}
        />
      </div>
    </div>
  );
}
