import React, {HTMLProps} from 'react';

export type TPageItem = Readonly<{
	label: string
	value?: string
}>;

export type TSimplePageSelector = Readonly<{
	items: TPageItem[]
	selectedValue?: string
	onSelectedValueChanged?: (value: string) => void
}> & HTMLProps<HTMLDivElement>;

export default function SimplePageSelector({items, selectedValue, onSelectedValueChanged, ...divProps}: TSimplePageSelector) {
  const baseClassName = 'cursor-pointer';
  const selectedItemClassName = `${baseClassName} text-primary`;
  const normalItemClassName = `${baseClassName} text-gray-500 hover:text-black`;

  return (
    <div {...divProps}>
      <div className='flex w-full justify-center items-center text-sm'>
        {
          items.map((item, i) => {
            const val = item.value ?? item.label;
            return (
              <React.Fragment key={`item-${val}`}>
                <div
                  className={selectedValue === val ? selectedItemClassName : normalItemClassName}
                  onClick={() => {
                    onSelectedValueChanged?.(val);
                  }}>
                  {item.label}
                </div>
                {i < items.length - 1 && <div className='mx-2 text-gray-300'>|</div>}
              </React.Fragment>
            );
          })
        }
      </div>
    </div>
  );
}
