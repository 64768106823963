import Tooltip, {ITooltipOptions} from 'devextreme-react/tooltip';
import React, {useEffect, useState} from 'react';
import {useMemo} from 'react';
import styled from 'styled-components';
import {twMerge} from 'tailwind-merge';

type TAppTooltip = Readonly<ITooltipOptions & {
  tooltipClassName?: string;
  wrapperClassName?: string;
  tooltipColor?: string;
  tooltipContainerClassName?: string;
}>;

type TContainerProps = Readonly<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  $tooltipColor?: string
}>;

const StyledContainer = styled.div<TContainerProps>`
  .app-tooltip-wrapper.dx-tooltip-wrapper.dx-popover-wrapper .dx-popover-arrow::after, .app-tooltip-wrapper.dx-tooltip-wrapper.dx-popover-wrapper .dx-popup-content, .app-tooltip-wrapper.dx-tooltip-wrapper.dx-popover-wrapper .dx-overlay-content{
    background: ${(props: TContainerProps) => props.$tooltipColor ?? 'var(--color-secondary)'};
  }
  .app-tooltip-wrapper.dx-tooltip-wrapper.dx-popover-wrapper .dx-popup-content {
    border-radius: .25rem;
  }
  .app-tooltip-wrapper.dx-tooltip-wrapper.dx-popover-wrapper .dx-overlay-content {
    border: none;
  }
`;

export default function AppTooltip({className, wrapperClassName, tooltipColor, tooltipClassName, tooltipContainerClassName, children, ...restProps}: TAppTooltip) {
  const containerRef = React.createRef<HTMLDivElement>();
  const [containerElem, setContainerElem] = useState<HTMLDivElement>();
  const tooltipWrapperAttr = useMemo(() => ({
    class: twMerge('app-tooltip-wrapper', wrapperClassName),
  }), []);

  useEffect(() => {
    setContainerElem(containerRef.current ?? undefined);
  }, []);

  return (
    <StyledContainer ref={containerRef} $tooltipColor={tooltipColor} className={twMerge('text-white text-xs font-medium', className)}>
      <Tooltip
        hideOnOutsideClick
        showEvent='mouseenter'
        hideEvent='mouseleave'
        container={containerElem}
        wrapperAttr={tooltipWrapperAttr}
        className={tooltipContainerClassName}
        {...restProps}
      >
        <div className={twMerge('p-0', tooltipClassName)}>{children}</div>
      </Tooltip>
    </StyledContainer>
  );
}
