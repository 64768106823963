import {gql} from '@apollo/client';
import {WORKORDER_REF_EVENT_TASKS_COMMON_FIELDS} from '../fragments';

export const WORKORDER_REF_EVENT_TASKS_GET_MANY = gql`
${WORKORDER_REF_EVENT_TASKS_COMMON_FIELDS}
query GetAllRefEventTasks($orderBy: [RefEventTaskOrderByWithRelationInput!], $where: RefEventTaskWhereInput) {
    refEventTasks(orderBy: $orderBy, where: $where) {
      ...RefEventTaskCommonFields
    }
}`;

export const WORKORDER_REF_EVENT_TASKS_GET_COUNTER = gql`
query GetRefEventTasksCounter {
  aggregateRefEventTask {
    _count {
      id
    }
  }
}
`;
