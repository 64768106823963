import {gql} from '@apollo/client';

export const WORKORDER_EVENT_FLOCS_COMMON_FIELDS = gql`
    fragment EventFlocCommonFields on EventFloc {
      id
      flocId
      sort
      inspStatus
      wrkoId
    }
`;
