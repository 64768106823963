import {gql} from '@apollo/client';

export const NOTIFICATION_REF_PRIORITY_COMMON_FIELDS = gql`
    fragment RefNotifPriorityCommonFields on RefNotifPriority {
        id
        description
        code
        executionDays
        consequenceProd
        offset
        windowLength
    }
`;
