import SearchBar from '@app/components/Common/SearchBar';
import React, {Fragment, ReactNode} from 'react';
import {twMerge} from 'tailwind-merge';

type TPanelHeader = Readonly<{
    filters?: React.ReactNode[];
    titleActions?: React.ReactNode;
    searchActions?: React.ReactNode;
    title?: ReactNode
    onSearch?: (searchInput: string) => void
    className?: string
    filtersClassName?: string
    searchContainerClassName?: string
}>

export default function PanelHeader({title, titleActions, searchActions, filters, onSearch, className, filtersClassName, searchContainerClassName}: TPanelHeader) {
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearch?.(e.target.value);
  };

  return (
    <div className={twMerge('flex flex-col items-end mb-3 mt-2 pr-2 gap-2', className)}>
      <div className={twMerge('flex items-center', filtersClassName)}>
        {!!filters?.length && <div className='flex items-center gap-1 w-full'>
          {filters.map(filter => <Fragment key={`filter-${filter}`}>{filter}</Fragment>)}
        </div>}
      </div>
      <div className={twMerge('flex flex-row items-center w-full justify-between', searchContainerClassName)}>
        <div className='flex gap-2 items-center'>
          <div className='text-secondary font-semibold text-sm font-montserrat'>{title}</div>
          {titleActions}
        </div>
        <div className='flex gap-1 items-center'>
          <SearchBar className='min-w-[50px] h-[24px]' onChange={handleSearch}/>
          {searchActions}
        </div>
      </div>
    </div>
  );
}
