import {gql} from '@apollo/client';

export const FLOC_CHARS_GET_COUNTER = gql`
query GetFlocCharsCounter {
  aggregateFlocChar {
    _count {
      id
    }
  }
}
`;

