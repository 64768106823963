import {OptimusClientConfig} from '@app/utils/clientConfig';

export class DataExportService {
  constructor(private getAccessToken: () => Promise<string | undefined>) {}
  async launchExport(type: string, key: string) {
    const uploadUrl = `${OptimusClientConfig.current.apiRestUrl}/export-datas/${type}/${key}`;
    const authToken = await this.getAccessToken();
    const r = await fetch(uploadUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    if (!r.ok || r.status !== 204) {
      throw new Error('Launch export failed with HTTP status: ' + r.status);
    }
  }
}
