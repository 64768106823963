import {QueryResult, useMutation, useQuery} from '@apollo/client';
import AppAutocomplete from '@app/components/Common/Form/Autocomplete';
import FormFieldsBlock from '@app/components/Common/Form/FormFieldsBlock';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import {
  FunctionalLocation,
  GetAllGridsAutocompleteQuery,
  GetAllRefFlocCatalogProfilesQuery,
  GetAllRefFlocCatalogsQuery,
  GetAllRefFlocClasTosQuery,
  GetAllRefFlocProcessesQuery,
  GetAllRefFlocSecesQuery,
  GetAllRefPlanStrategiesQuery,
  GetAllSectorsQuery,
  Grid,
  RefFlocCatalogProfile,
  RefFlocCatalogs,
  RefFlocClasTo,
  RefFlocProcess,
  RefFlocSece,
  RefPlanStrategy,
  Sector,
  UpdateFlocByIdMutation,
} from '@app/graphql/__types__/graphql';
import {
  FLOC_CATALOGS_GET_MANY,
  FLOC_REF_CATALOG_PROFILES_GET_MANY,
  FLOC_REF_CLAS_TOS_GET_MANY,
  FLOC_REF_PROCESSES_GET_MANY,
  FLOC_REF_SECES_GET_MANY,
  FLOCS_UPDATE_BY_ID,
  GRIDS_GET_ALL_AUTOCOMPLETE, SCHEDULING_REF_PLAN_STRATEGIES_GET_MANY, SECTORS_GET_ALL,
} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useLayoutStore} from '@app/stores/layout';
import {MAX_LENGTH_VALIDATORS, useFlocStore, ZOD_FLOC_DATAS} from '@app/stores/methodEngineering/floc';
import {TFieldsBlock, TMaybeCodeDescriptionDatas, TRenderAutocompleteProps} from '@app/types/app';
import {EFieldType, EFLOC_CATALOGS_CATEGORY} from '@app/utils/enums';
import {handleFormInputKeydown, renderCodeAndDescription, setObjValueByPath} from '@app/utils/functions';
import {Option, RadForm, RadTabs, RadTabsList, RadTabsTrigger} from '@holis/react-ui/rad';
import {zodResolver} from '@hookform/resolvers/zod';
import {t} from 'i18next';
import _ from 'lodash';
import React, {InputHTMLAttributes, ReactNode, RefObject, UIEvent, useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {LuClipboardList} from 'react-icons/lu';
import {z} from 'zod';
import CmlList from './CmlList';
import DamageList from './DamageList';
import DocumentList from './DocumentList';
import InspectionPointList from './InspectionPointList';

type TProps = Readonly<{
  floc: Partial<FunctionalLocation>;
}>;

const LEFT_PANEL_TABS = [
  'header',
  'checklist',
  'cml',
  'damage',
  'documentation',
];

type TPanelKey = typeof LEFT_PANEL_TABS[number];

export default function FlocInfo({floc}: TProps) {
  const {
    editFloc,
    setEditFloc,
    // activeFloc,
    updateData,
    setUpdateData,
    updateDataField,
    updateFloc,
    hasFieldError,
    updateFieldError,
    fetchFlocs,
  } = useFlocStore();
  const {startLoading, stopLoading} = useLayoutStore();
  // const navigate = useNavigate();

  const flocTechClassQueryResult = useQuery<GetAllRefFlocClasTosQuery>(FLOC_REF_CLAS_TOS_GET_MANY);
  const flocCatalogProfilesQueryResult = useQuery<GetAllRefFlocCatalogProfilesQuery>(FLOC_REF_CATALOG_PROFILES_GET_MANY);
  const flocSectorQueryResult = useQuery<GetAllSectorsQuery>(SECTORS_GET_ALL);
  const flocGridQueryResult = useQuery<GetAllGridsAutocompleteQuery>(GRIDS_GET_ALL_AUTOCOMPLETE);
  const flocProcessFunctionQueryResult = useQuery<GetAllRefFlocProcessesQuery>(FLOC_REF_PROCESSES_GET_MANY);
  const flocCatalogsQueryResult = useQuery<GetAllRefFlocCatalogsQuery>(FLOC_CATALOGS_GET_MANY);
  const refPlanStrategiesQueryResult = useQuery<GetAllRefPlanStrategiesQuery>(SCHEDULING_REF_PLAN_STRATEGIES_GET_MANY);
  const refFlocSeceQueryResult = useQuery<GetAllRefFlocSecesQuery>(FLOC_REF_SECES_GET_MANY);

  const [submitRequested, setSubmitRequested] = useState<boolean>(false);

  const [updateFlocByIdApi] = useMutation<UpdateFlocByIdMutation>(FLOCS_UPDATE_BY_ID);

  const zodFormObject = z.object(_.omit(ZOD_FLOC_DATAS(t), 'flocId', 'typeId'));
  const form = useForm<z.infer<typeof zodFormObject>>({
    resolver: zodResolver(zodFormObject),
    defaultValues: {
      floc: editFloc?.floc?.toString() ?? '',
      description: editFloc?.description?.toString() ?? '',
      techClassId: editFloc?.techClassId,
      sectorId: editFloc?.sectorId,
    },
    mode: 'onBlur',
  });
  const htmlForm = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    if (submitRequested) {
      htmlForm.current?.requestSubmit();

      setSubmitRequested(false);
    }
  }, [submitRequested]);

  const anchRef: Partial<{ [K in TPanelKey]: RefObject<HTMLDivElement> }> = {};

  for (const k of LEFT_PANEL_TABS) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    anchRef[k] = useRef(null);
  }

  const [currentAnchor, setCurrentAnchor] = useState(LEFT_PANEL_TABS[0]);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleSetAnchor = (anchor: TPanelKey) => {
    if (anchRef[anchor]?.current) {
      const elPos = anchRef[anchor]!.current!.offsetTop - scrollContainerRef.current!.offsetTop;
      scrollContainerRef.current?.scrollTo({
        top: elPos,
        behavior: 'smooth',
      });
    }

    setCurrentAnchor(anchor);
  };

  const handleInnerPanelScroll = (e: UIEvent<HTMLDivElement>) => {
    const scrollPos = e.currentTarget.scrollTop;
    let newCurrTarget: TPanelKey = LEFT_PANEL_TABS[0];
    for (let i = LEFT_PANEL_TABS.length - 1; i >= 0; i--) {
      const k = LEFT_PANEL_TABS[i];
      if (anchRef[k]?.current) {
        const elPos = anchRef[k]!.current!.offsetTop - e.currentTarget.offsetTop - 75;
        if (elPos <= scrollPos) {
          newCurrTarget = k;
          break;
        }
      }
    }

    setCurrentAnchor(newCurrTarget);
  };

  const renderAutocomplete = (props: TRenderAutocompleteProps): ReactNode => {
    const {
      fieldRow,
      setInputValue,
      renderMenuItemLabel,
      dbValue,
      field,
      foreignField,
      inputProps,
      isDisabled,
    } = props ?? {};

    return (
      <AppAutocomplete
        onSelect={(item: Record<string, unknown> | null) => {
          if (
            (updateData && Object.keys(updateData).includes(field!))
            || (!(updateData && Object.keys(updateData).includes(field!))
            && (item?.id ?? null) !== dbValue)
          ) {
            if (typeof renderMenuItemLabel!(item) === 'string') {
              setInputValue?.(renderMenuItemLabel!(item) as string);
            }

            const editedFloc = _.cloneDeep(editFloc);
            if (foreignField) {
              setObjValueByPath(editedFloc!, foreignField!, item);
            }

            if (field) {
              setObjValueByPath(editedFloc!, field!, item?.id ?? null);
            }

            setEditFloc(editedFloc);
            updateDataField(field!, item?.id ?? null);

            handleFieldBlur();
          }
        }}
        {...props}
        inputProps={{
          ...inputProps,
          disabled: isDisabled || inputProps?.disabled,
          onKeyDown(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
            handleFormInputKeydown(
              e,
              fieldRow!,
              val => setInputValue?.(val ?? ''),
              handleFieldChange,
            );
          },
        }}
      />
    );
  };

  const handleFieldChange = (field: string, value: unknown, update?: boolean) => {
    const editedFloc = {...editFloc};
    setObjValueByPath(editedFloc, field, value);
    setEditFloc(editedFloc);
    if (update) {
      updateDataField(field, value);
    }
  };

  const handleFieldBlur = () => {
    setSubmitRequested(true);
  };

  const updateFlocState = (newData: Partial<FunctionalLocation>) => {
    const newFloc = {
      ...editFloc,
      ...newData,
    } as Partial<FunctionalLocation>;

    updateFloc(newFloc);
    setUpdateData({});
    fetchFlocs?.();
  };

  const handleFlocUpdateError = (err?: Error) => {
    let errorMessage: string = t('message.error.default.title');
    if (typeof err?.message === 'string') {
      if (err.message.includes('Unique constraint failed on the fields: (`floc`)')) {
        errorMessage = t('message.error.unique.scheduling.floc.floc');
        updateFieldError('floc', true);
        form.setError('floc', {
          message: errorMessage,
        });
        return;
      }
    }

    AppNotifications.error(errorMessage);
  };

  const handleUpdateFloc = () => updateFlocByIdApi({variables: {id: floc!.id, data: updateData}}).then(newData => {
    const updatedFloc = newData?.data?.updateOneFunctionalLocation as Partial<FunctionalLocation>;
    AppNotifications.success(t('message.success.flocUpdated'));
    // const idwgNumberChanged = updatedFloc.floc && updatedFloc.floc !== activeFloc?.floc;
    // // If floc number changed, modify last url segment to set the new idwg number
    // if (idwgNumberChanged) {
    //   navigate(ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION_DETAIL.replace(/:number/, updatedFloc.floc!));
    //   return;
    // }

    updateFlocState(updatedFloc);
  }).catch(err => {
    handleFlocUpdateError(err);
  }).finally(() => {
    stopLoading();
  });

  const handleFormSubmitSucess = () => {
    // Submit changes only if there are pending value changes
    if (updateData && Object.keys(updateData).length) {
      startLoading();
      handleUpdateFloc();
    }
  };

  const fieldBlocks: TFieldsBlock[] = [
    {
      title: 'label.identification',
      fields: [
        {
          label: 'label.functionalLocation',
          field: 'floc',
          fieldType: EFieldType.text,
          className: 'w-full',
          initialValue: floc.floc,
          value: editFloc!.floc,
          hasError: hasFieldError('floc'),
          isRequired: true,
          inputProps: {
            maxLength: MAX_LENGTH_VALIDATORS.FLOC,
            required: true,
          } as InputHTMLAttributes<HTMLInputElement>,
        },
        {
          label: 'label.description',
          field: 'description',
          fieldType: EFieldType.text,
          className: 'w-full',
          initialValue: floc.description,
          value: editFloc!.description,
          hasError: hasFieldError('description'),
          isRequired: true,
          inputProps: {
            maxLength: MAX_LENGTH_VALIDATORS.DESCRIPTION,
            required: true,
          } as InputHTMLAttributes<HTMLInputElement>,
        },
        {
          label: 'label.referenceDocument',
          field: 'referenceDocument',
          fieldType: EFieldType.text,
          className: 'w-full',
          initialValue: floc.referenceDocument,
          value: editFloc!.referenceDocument,
          hasError: hasFieldError('referenceDocument'),
          inputProps: {
            maxLength: MAX_LENGTH_VALIDATORS.REFERENCE_DOCUMENT,
          } as InputHTMLAttributes<HTMLInputElement>,
        },
        // {
        //   label: 'label.package',
        //   field: 'package',
        //   fieldType: EFieldType.text,
        //   className: 'w-full',
        //   initialValue: floc.package,
        //   value: editFloc!.package,
        //   hasError: hasFieldError('package'),
        //   inputProps: {
        //     maxLength: MAX_LENGTH_VALIDATORS.PACKAGE,
        //   } as InputHTMLAttributes<HTMLInputElement>,
        // },
      ],
    },
    {
      title: 'label.classification',
      fields: [
        {
          label: 'label.technicalClass',
          field: 'techClassId',
          initialValue: renderCodeAndDescription({
            code: floc!.techClass?.techClass,
            description: floc!.techClass?.description,
          } as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({
            code: editFloc!.techClass?.techClass,
            description: editFloc!.techClass?.description,
          } as TMaybeCodeDescriptionDatas),
          foreignObject: editFloc!.techClass,
          isRequired: true,
          foreignField: 'techClass',
          className: 'flex w-full',
          dbValue: floc!.techClassId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: flocTechClassQueryResult,
          getItemsFromResult: (result: QueryResult) => (
            (result as QueryResult<GetAllRefFlocClasTosQuery>)?.data?.refFlocClasTos ?? []
          ),
          renderMenuItemLabel: field => renderCodeAndDescription({
            code: (field as RefFlocClasTo)?.techClass,
            description: (field as RefFlocClasTo)?.description,
          } as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
        {
          label: 'label.catalogProfile',
          field: 'catalogProfileId',
          initialValue: renderCodeAndDescription({
            code: floc!.catalogProfile?.catalog,
            description: floc!.catalogProfile?.description,
          } as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({
            code: editFloc!.catalogProfile?.catalog,
            description: editFloc!.catalogProfile?.description,
          } as TMaybeCodeDescriptionDatas),
          foreignObject: editFloc!.catalogProfile,
          foreignField: 'catalogProfile',
          className: 'flex w-full',
          dbValue: floc!.catalogProfileId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: flocCatalogProfilesQueryResult,
          getItemsFromResult: (result: QueryResult) => (
            (result as QueryResult<GetAllRefFlocCatalogProfilesQuery>)?.data?.refFlocCatalogProfiles ?? []
          ),
          renderMenuItemLabel: field => renderCodeAndDescription({
            code: (field as RefFlocCatalogProfile)?.catalog,
            description: (field as RefFlocCatalogProfile)?.description,
          } as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
        {
          label: 'label.classSece',
          field: 'classSece',
          fieldType: EFieldType.multivalue,
          className: 'w-full',
          initialValue: floc.classSece,
          value: editFloc!.classSece,
          hasError: hasFieldError('classSece'),
          items: ((refFlocSeceQueryResult.data?.refFlocSeces ?? []) as RefFlocSece[]).map(flocSece => ({
            value: flocSece.class,
            label: `[${flocSece.class}] - ${flocSece.description}`,
          })) as Option[],
        },
      ],
    },
    {
      title: 'label.location',
      fields: [
        {
          label: 'label.sector',
          field: 'sectorId',
          isRequired: true,
          initialValue: renderCodeAndDescription({
            code: floc!.sector?.sector,
            description: floc!.sector?.description,
          } as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({
            code: editFloc!.sector?.sector,
            description: editFloc!.sector?.description,
          } as TMaybeCodeDescriptionDatas),
          foreignObject: editFloc!.sector,
          foreignField: 'sector',
          className: 'flex w-full',
          dbValue: floc!.sectorId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: flocSectorQueryResult,
          getItemsFromResult: (result: QueryResult) => (
            (result as QueryResult<GetAllSectorsQuery>)?.data?.sectors ?? []
          ),
          renderMenuItemLabel: field => renderCodeAndDescription({
            code: (field as Sector)?.sector,
            description: (field as Sector)?.description,
          } as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
        {
          label: 'label.grid',
          field: 'gridId',
          initialValue: renderCodeAndDescription({
            code: floc!.grid?.grid,
            description: floc!.grid?.description,
          } as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({
            code: editFloc!.grid?.grid,
            description: editFloc!.grid?.description,
          } as TMaybeCodeDescriptionDatas),
          foreignObject: editFloc!.grid,
          foreignField: 'grid',
          className: 'flex w-full',
          dbValue: floc!.gridId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: flocGridQueryResult,
          getItemsFromResult: (result: QueryResult) => (
            (result as QueryResult<GetAllGridsAutocompleteQuery>)?.data?.grids ?? []
          ),
          renderMenuItemLabel: field => renderCodeAndDescription({
            code: (field as Grid)?.grid,
            description: (field as Grid)?.description,
          } as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
      ],
    },
    {
      title: 'label.integrity',
      fields: [
        {
          label: 'label.integritySystem',
          field: 'integritySystem',
          fieldType: EFieldType.multivalue,
          className: 'w-full',
          initialValue: floc.integritySystem,
          value: editFloc!.integritySystem,
          hasError: hasFieldError('integritySystem'),
          items: ((flocCatalogsQueryResult.data?.findManyRefFlocCatalogs ?? []) as RefFlocCatalogs[]).filter(flocSece => flocSece.category === EFLOC_CATALOGS_CATEGORY.INTEGRITY_SYSTEM).map(flocSece => ({
            value: flocSece.code,
            label: `[${flocSece.code}] - ${flocSece.description}`,
          })) as Option[],
        },
        {
          label: 'label.integrityReporting',
          field: 'integrityReporting',
          initialValue: floc.integrityStatuses?.length,
          value: editFloc!.integrityStatuses?.length,
          fieldType: EFieldType.boolean,
          handleFieldChange(field, value, updated) {
            // eslint-disable-next-line no-warning-comments
            // TODO: implement integrity reporting update
            console.group('onInputChange');
            console.log('field:', field);
            console.log('value:', value);
            console.log('updated:', updated);
            console.groupEnd();
          },
        },
      ],
    },
    {
      title: 'label.inspection',
      fields: [
        {
          label: 'label.strategy',
          field: 'strategyId',
          initialValue: renderCodeAndDescription({
            code: floc!.strategy?.strategy,
            description: floc!.strategy?.description,
          } as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({
            code: editFloc!.strategy?.strategy,
            description: editFloc!.strategy?.description,
          } as TMaybeCodeDescriptionDatas),
          foreignObject: editFloc!.strategy,
          foreignField: 'strategy',
          className: 'flex w-full',
          dbValue: floc!.strategyId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: refPlanStrategiesQueryResult,
          getItemsFromResult: (result: QueryResult) => (
            ((result as QueryResult<GetAllRefPlanStrategiesQuery>)?.data?.refPlanStrategies ?? [])
          ),
          renderMenuItemLabel: field => renderCodeAndDescription({
            code: (field as RefPlanStrategy)?.strategy,
            description: (field as RefPlanStrategy)?.description,
          } as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
        {
          label: 'label.classification',
          field: 'classification',
          fieldType: EFieldType.multivalue,
          className: 'w-full',
          initialValue: floc.classification,
          value: editFloc!.classification,
          hasError: hasFieldError('classification'),
          items: ((flocCatalogsQueryResult.data?.findManyRefFlocCatalogs ?? []) as RefFlocCatalogs[]).filter(flocSece => flocSece.category === EFLOC_CATALOGS_CATEGORY.CLASSIFICATION).map(flocCatalog => ({
            value: flocCatalog.code,
            label: `[${flocCatalog.code}] - ${flocCatalog.description}`,
          })) as Option[],
        },
        {
          label: 'label.legislation',
          field: 'legislation',
          fieldType: EFieldType.multivalue,
          className: 'w-full',
          initialValue: floc.legislation,
          value: editFloc!.legislation,
          hasError: hasFieldError('legislation'),
          items: ((flocCatalogsQueryResult.data?.findManyRefFlocCatalogs ?? []) as RefFlocCatalogs[]).filter(flocSece => flocSece.category === EFLOC_CATALOGS_CATEGORY.LEGISLATION).map(flocCatalog => ({
            value: flocCatalog.code,
            label: `[${flocCatalog.code}] - ${flocCatalog.description}`,
          })) as Option[],
        },
      ],
    },
    {
      title: 'label.corrosion',
      fields: [
        {
          label: 'label.processFunction',
          field: 'processFunctionId',
          initialValue: renderCodeAndDescription({
            code: floc!.processFunction?.processFunction,
            description: floc!.processFunction?.description,
          } as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({
            code: editFloc!.processFunction?.processFunction,
            description: editFloc!.processFunction?.description,
          } as TMaybeCodeDescriptionDatas),
          foreignObject: editFloc!.processFunction,
          foreignField: 'processFunction',
          className: 'flex w-full',
          dbValue: floc!.processFunctionId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: flocProcessFunctionQueryResult,
          getItemsFromResult: (result: QueryResult) => (
            (result as QueryResult<GetAllRefFlocProcessesQuery>)?.data?.refFlocProcesses ?? []
          ),
          renderMenuItemLabel: field => renderCodeAndDescription({
            code: (field as RefFlocProcess)?.processFunction,
            description: (field as RefFlocProcess)?.description,
          } as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
        {
          label: 'label.corrosionLoop',
          field: 'corrosionLoop',
          fieldType: EFieldType.multivalue,
          className: 'w-full',
          initialValue: floc.corrosionLoop,
          value: editFloc!.corrosionLoop,
          hasError: hasFieldError('corrosionLoop'),
          items: ((flocCatalogsQueryResult.data?.findManyRefFlocCatalogs ?? []) as RefFlocCatalogs[]).filter(flocSece => flocSece.category === EFLOC_CATALOGS_CATEGORY.CORROSION_LOOP).map(flocSece => ({
            value: flocSece.code,
            label: `[${flocSece.code}] - ${flocSece.description}`,
          })) as Option[],
        },
      ],
    },
    {
      title: 'label.responsabilities',
      fields: [
        {
          label: 'label.planningPlant',
          field: 'planningPlantId',
          initialValue: renderCodeAndDescription({
            code: floc!.planningPlant?.code,
            description: floc!.planningPlant?.description,
          } as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({
            code: editFloc!.planningPlant?.code,
            description: editFloc!.planningPlant?.description,
          } as TMaybeCodeDescriptionDatas),
          foreignObject: floc!.planningPlant,
          foreignField: 'planningPlant',
          className: 'flex w-full',
          dbValue: floc!.planningPlantId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: flocCatalogsQueryResult,
          getItemsFromResult: (result: QueryResult) => (
            ((result as QueryResult<GetAllRefFlocCatalogsQuery>)?.data?.findManyRefFlocCatalogs ?? [])
              .filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.PLAN_PLANT)
          ),
          renderMenuItemLabel: field => renderCodeAndDescription({
            code: (field as RefFlocCatalogs)?.code,
            description: (field as RefFlocCatalogs)?.description,
          } as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
        {
          label: 'label.plantWorkCenter',
          field: 'plantWorkCenterId',
          initialValue: renderCodeAndDescription({
            code: floc!.plantWorkCenter?.code,
            description: floc!.plantWorkCenter?.description,
          } as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({
            code: editFloc!.plantWorkCenter?.code,
            description: editFloc!.plantWorkCenter?.description,
          } as TMaybeCodeDescriptionDatas),
          foreignObject: floc!.plantWorkCenter,
          foreignField: 'plantWorkCenter',
          className: 'flex w-full',
          dbValue: floc!.plantWorkCenterId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: flocCatalogsQueryResult,
          getItemsFromResult: (result: QueryResult) => (
            ((result as QueryResult<GetAllRefFlocCatalogsQuery>)?.data?.findManyRefFlocCatalogs ?? [])
              .filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.PLANT_WORK_CENTER)
          ),
          renderMenuItemLabel: field => renderCodeAndDescription({
            code: (field as RefFlocCatalogs)?.code,
            description: (field as RefFlocCatalogs)?.description,
          } as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
        {
          label: 'label.plannerGroup',
          field: 'plannerGroupId',
          initialValue: renderCodeAndDescription({
            code: floc!.plannerGroup?.code,
            description: floc!.plannerGroup?.description,
          } as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({
            code: editFloc!.plannerGroup?.code,
            description: editFloc!.plannerGroup?.description,
          } as TMaybeCodeDescriptionDatas),
          foreignObject: editFloc!.plannerGroup,
          foreignField: 'plannerGroup',
          className: 'flex w-full',
          dbValue: floc!.plannerGroupId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: flocCatalogsQueryResult,
          getItemsFromResult: (result: QueryResult) => (
            ((result as QueryResult<GetAllRefFlocCatalogsQuery>)?.data?.findManyRefFlocCatalogs ?? [])
              .filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.PLAN_GROUP)
          ),
          renderMenuItemLabel: field => renderCodeAndDescription({
            code: (field as RefFlocCatalogs)?.code,
            description: (field as RefFlocCatalogs)?.description,
          } as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
        {
          label: 'label.mainWorkCenter',
          field: 'mainWorkCenterId',
          initialValue: renderCodeAndDescription({
            code: floc!.mainWorkCenter?.code,
            description: floc!.mainWorkCenter?.description,
          } as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({
            code: editFloc!.mainWorkCenter?.code,
            description: editFloc!.mainWorkCenter?.description,
          } as TMaybeCodeDescriptionDatas),
          foreignObject: editFloc!.mainWorkCenter,
          foreignField: 'mainWorkCenter',
          className: 'flex w-full',
          dbValue: floc!.mainWorkCenterId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: flocCatalogsQueryResult,
          getItemsFromResult: (result: QueryResult) => (
            ((result as QueryResult<GetAllRefFlocCatalogsQuery>)?.data?.findManyRefFlocCatalogs ?? [])
              .filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.MAIN_WORK_CENTER)
          ),
          renderMenuItemLabel: field => renderCodeAndDescription({
            code: (field as RefFlocCatalogs)?.code,
            description: (field as RefFlocCatalogs)?.description,
          } as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
      ],
    },
    {
      title: 'label.production',
      fields: [
        {
          label: 'label.consequenceProd',
          field: 'consequenceProdId',
          initialValue: renderCodeAndDescription({
            code: floc!.consequenceProd?.code,
            description: floc!.consequenceProd?.description,
          } as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({
            code: editFloc!.consequenceProd?.code,
            description: editFloc!.consequenceProd?.description,
          } as TMaybeCodeDescriptionDatas),
          foreignObject: editFloc!.consequenceProd,
          foreignField: 'consequenceProd',
          className: 'flex w-full',
          dbValue: floc!.consequenceProdId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: flocCatalogsQueryResult,
          getItemsFromResult: (result: QueryResult) => (
            ((result as QueryResult<GetAllRefFlocCatalogsQuery>)?.data?.findManyRefFlocCatalogs ?? [])
              .filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.CONSEQUENCE_PRODUCTION)
          ),
          renderMenuItemLabel: field => renderCodeAndDescription({
            code: (field as RefFlocCatalogs)?.code,
            description: (field as RefFlocCatalogs)?.description,
          } as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
        {
          label: 'label.shutdown',
          field: 'shutdownId',
          initialValue: renderCodeAndDescription({
            code: floc!.shutdown?.code,
            description: floc!.shutdown?.description,
          } as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({
            code: editFloc!.shutdown?.code,
            description: editFloc!.shutdown?.description,
          } as TMaybeCodeDescriptionDatas),
          foreignObject: editFloc!.shutdown,
          foreignField: 'shutdown',
          className: 'flex w-full',
          dbValue: floc!.shutdownId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: flocCatalogsQueryResult,
          getItemsFromResult: (result: QueryResult) => (
            ((result as QueryResult<GetAllRefFlocCatalogsQuery>)?.data?.findManyRefFlocCatalogs ?? [])
              .filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.SHUTDOWN)
          ),
          renderMenuItemLabel: field => renderCodeAndDescription({
            code: (field as RefFlocCatalogs)?.code,
            description: (field as RefFlocCatalogs)?.description,
          } as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
      ],
    },
  ];

  return (
    <div className='flex flex-col h-full w-full overflow-hidden'>
      <RadTabs className='pt-2 px-4' value={currentAnchor}>
        <RadTabsList>
          {LEFT_PANEL_TABS.map(tab => (
            <RadTabsTrigger key={tab} value={tab} onClick={() => handleSetAnchor(tab)}>
              {t(`label.${tab}`)}
            </RadTabsTrigger>
          ))}
        </RadTabsList>
      </RadTabs>

      <div ref={scrollContainerRef} className='flex-1 gap-4 mt-4 px-4 pb-6 flex flex-col overflow-y-auto' onScroll={handleInnerPanelScroll}>
        <div ref={anchRef.header}>
          <RadForm {...form}>
            <form
              ref={htmlForm}
              onSubmit={form.handleSubmit(handleFormSubmitSucess)}
            >
              <div className='flex flex-col'>
                <FormGroupHeader>
                  <div className='flex items-center gap-1'>
                    <LuClipboardList/>

                    {t('label.header')}
                  </div>
                </FormGroupHeader>

                <FormFieldsBlock
                  isFormContext
                  className='text-gray-700'
                  fieldsBlocks={fieldBlocks}
                  onFieldChange={handleFieldChange}
                  onFieldBlur={handleFieldBlur}
                />
              </div>
            </form>
          </RadForm>
        </div>

        <div ref={anchRef.checklist} className='flex flex-col gap-2'>
          <InspectionPointList floc={floc}/>
        </div>

        <div ref={anchRef.cml} className='flex flex-col gap-2'>
          <CmlList floc={floc}/>
        </div>

        <div ref={anchRef.damage} className='flex flex-col gap-2'>
          <DamageList floc={floc}/>
        </div>

        <div ref={anchRef.documentation} className='flex flex-col gap-2'>
          <DocumentList floc={floc}/>
        </div>
      </div>
    </div>
  );
}
