import {InspectionDrawing} from '@app/graphql/__types__/graphql';
import IdwgInfo from './IdwgInfo';

type TLeftSide = Readonly<{
    idwg: Partial<InspectionDrawing>;
}>;

export default function LeftSide({idwg}: TLeftSide) {
  return (
    <IdwgInfo idwg={idwg}/>
  );
}
