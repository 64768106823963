import {QueryResult, useLazyQuery, useMutation} from '@apollo/client';
import DetailsPageTitleBar from '@app/components/Layout/DetailsPageTitleBar';
import DeleteDocModal from '@app/components/Modal/MultiplePanels/Documentation/Document/components/DeleteDocModal';
import Footer from '@app/components/Modal/MultiplePanels/Documentation/Document/components/Footer';
import LeftSide from '@app/components/Modal/MultiplePanels/Documentation/Document/components/LeftSide';
import RightSide from '@app/components/Modal/MultiplePanels/Documentation/Document/components/RightSide';
import {Document, GetDocumentDetailByNumberQuery, UpdateDocumentByIdMutation} from '@app/graphql/__types__/graphql';
import {DOCUMENTATION_DOCUMENTS_GET_DETAIL_BY_NUMBER, DOCUMENTATION_DOCUMENTS_UPDATE_BY_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useDataStore} from '@app/stores/data';
import {TAdditionalActions} from '@app/types/app';
import {EAdditionalAction} from '@app/utils/enums';
import {addTokenToUrl, downloadFileFromUrl, getFileNameWithoutExtension} from '@app/utils/functions';
import {useHolisAuth} from '@holis/auth-client-react';
import {RadDropdownMenuItem, RadDropdownMenuSeparator} from '@holis/react-ui/rad';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LuDownload, LuExternalLink, LuTrash2, LuUpload} from 'react-icons/lu';
import {useParams} from 'react-router-dom';
import PageContent from '../../../../components/Layout/PageContent';
import {useDocumentStore} from '../../../../stores/document';
import {useLayoutStore} from '../../../../stores/layout';
import ItemsSummary, {TSummaryItem} from '@app/components/Common/ItemSummary';
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning';
import Error404 from '@app/pages/Errors/NotFound';
import SpinnerLoader from '@app/components/Loaders/SpinnerLoader';
import {AxiosError} from 'axios';
import {OptimusClientConfig} from '@app/utils/clientConfig';
import MultipleResizablePanels from '@app/components/Common/Panel/MultipleResizablePanels';

type routeParams = {
  number: string;
};

export default function DocumentationAssetDetailsPage() {
  const {number: documentNumber} = useParams<routeParams>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [confirmDocNameExistsModalDisplayed, changeConfirmDocNameExistsModalDisplay] = useState<boolean>(false);
  const {getAccessToken} = useHolisAuth();
  const {t} = useTranslation();

  const fileRef = React.createRef<HTMLInputElement>();

  const {startLoading, stopLoading} = useLayoutStore();
  const {editDoc, updateDataField, setActiveDoc, hasError, setUploadFile, deleteDocModalOpen, changeDeleteDocModalDisplay, uploadFile, updateData, updateFieldError, flocs, fileDoc, fetchFileDoc} = useDocumentStore();
  const [getDocumentDetailByNumberApi, {loading}] = useLazyQuery<GetDocumentDetailByNumberQuery>(DOCUMENTATION_DOCUMENTS_GET_DETAIL_BY_NUMBER);

  const [updateDocumentById] = useMutation<UpdateDocumentByIdMutation>(DOCUMENTATION_DOCUMENTS_UPDATE_BY_ID);
  const {uploadDownloadService} = useDataStore();

  const getAndSetDocumentDetail = () => getDocumentDetailByNumberApi({
    variables: {number: documentNumber},
    fetchPolicy: 'no-cache',
  }).then((queryResult: QueryResult<GetDocumentDetailByNumberQuery>) => {
    if (!queryResult.data?.document) {
      setNotFound(true);
      return;
    }

    const newDocument = {...(queryResult.data?.document)} as Partial<Document>;
    setActiveDoc(newDocument);
  });

  useEffect(() => {
    getAndSetDocumentDetail();
  }, []);

  useEffect(() => {
    if (loading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [loading]);

  useEffect(() => {
    handleUploadFile();
  }, [uploadFile]);

  const downloadFile = async () => {
    if (!editDoc || !editDoc.docName) {
      return;
    }

    startLoading();
    const url = await addTokenToUrl(`${OptimusClientConfig.current.fileBaseUrl}/${editDoc.docName}`, getAccessToken);

    downloadFileFromUrl(url, `${editDoc.docName!}${fileDoc?.extension ? `.${fileDoc.extension!}` : ''}`, {
      onEnd() {
        stopLoading();
      },
      onFail(error) {
        if (error instanceof AxiosError && error.response?.status === 404) {
          AppNotifications.error(t('message.error.fileNotFound'));
        } else {
          AppNotifications.error(t('message.error.default.title'));
        }
      },
    });
  };

  const updateDocument = (showSuccessMessage = true) => {
    if (updateData && Object.keys(updateData).length) {
      startLoading();
      updateDocumentById({variables: {id: editDoc!.id, data: updateData}}).then(newData => {
        const newDoc = {
          ...newData?.data?.updateOneDocument,
        } as Document;
        setActiveDoc(newDoc);
        if (showSuccessMessage) {
          AppNotifications.success(t('message.success.documentUpdated'));
        }
      }).catch((err: Error) => {
        let errorMessage: string = t('message.error.default.title');
        if (typeof err?.message === 'string') {
          if (err.message.includes('Unique constraint failed on the fields: (`document`)')) {
            if (AppNotifications.timeoutId) {
              clearTimeout(AppNotifications.timeoutId);
            }

            errorMessage = t('message.error.unique.documentation.document.reference');
            updateFieldError('document', true);
            return;
          }
        }

        AppNotifications.error(errorMessage);
      }).finally(stopLoading);
    }
  };

  const handleOverrideDoc = (confirmed: boolean) => {
    if (confirmed) {
      handleUploadFile(true);
    } else {
      updateDocument();
      fetchFileDoc?.();
    }
  };

  const handleUploadFile = (updateIfExists = false) => {
    if (uploadFile) {
      startLoading();
      uploadDownloadService!.uploadFile(uploadFile, {
        updateIfExists: updateIfExists ? 1 : 0,
      }).then(response => {
        if (response.status !== 200) {
          console.log(response);
          const responseJson = JSON.parse(response.data);
          throw new Error(typeof responseJson === 'object' && uploadFile.name && responseJson.errors?.files?.[uploadFile.name] ? responseJson.errors.files![uploadFile.name] : response.data.message ?? response.statusText);
        }

        AppNotifications.success(t('message.success.docFileUpdated'));
        updateDocument(false);
        if (updateIfExists) {
          fetchFileDoc?.();
        }
      }).catch((err: Error) => {
        let errorMessage = t('message.error.uploadDocumentationDoc');
        if (typeof err?.message === 'string') {
          errorMessage = err.message;
          if (err.message.includes(`File ${getFileNameWithoutExtension(uploadFile.name)} already exists`)) {
            if (AppNotifications.timeoutId) {
              clearTimeout(AppNotifications.timeoutId);
            }

            errorMessage = t('message.error.unique.documentation.document.docName');
            changeConfirmDocNameExistsModalDisplay(true);
            return;
          }
        }

        AppNotifications.error(errorMessage);
      }).finally(stopLoading);
    }
  };

  const submitChange = () => {
    updateDocument();
  };

  const onFileUploadClick = () => {
    fileRef.current?.click();
  };

  const onFileUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const uploadFile = e.target.files[0];
      setUploadFile(uploadFile);
      const docName = getFileNameWithoutExtension(uploadFile.name);
      updateDataField('docName', docName);
    }
  };

  const openExternalLink = async () => {
    if (!editDoc) {
      return;
    }

    let url = `${OptimusClientConfig.current.fileBaseUrl}/${editDoc.docName}`;
    url = await addTokenToUrl(url, getAccessToken);

    if (url) {
      window.open(url, '_blank');
    }
  };

  const itemsSummary: TSummaryItem[] = [
    {
      title: t('label.floc'),
      value: flocs?.length ?? editDoc?._count?.flocDocs ?? 0,
    },
  ];

  const additionalActions: TAdditionalActions = {
    [EAdditionalAction.OPEN_BLANK_PAGE]: (
      <RadDropdownMenuItem className='gap-2' onClick={openExternalLink}>
        <LuExternalLink/>

        {t('label.openBlankPage')}
      </RadDropdownMenuItem>
    ),
    [EAdditionalAction.DOWNLOAD]: (
      <RadDropdownMenuItem disabled={!editDoc?.docName} className='gap-2' onClick={downloadFile}>
        <LuDownload/>

        {t('label.downloadFile')}
      </RadDropdownMenuItem>
    ),
    [EAdditionalAction.UPLOAD]: (
      <RadDropdownMenuItem className='gap-2' onClick={onFileUploadClick}>
        <LuUpload/>

        {t('label.uploadFile')}
      </RadDropdownMenuItem>
    ),
    [EAdditionalAction.DELETE]: (
      <>
        <RadDropdownMenuSeparator/>

        <RadDropdownMenuItem className='gap-2 item-destructive' onClick={() => changeDeleteDocModalDisplay(true)}>
          <LuTrash2/>

          {t('label.deleteFile')}
        </RadDropdownMenuItem>
      </>
    ),
  };
  if (loading) {
    return <SpinnerLoader/>;
  }

  if (notFound) {
    return <Error404/>;
  }

  return (
    <PageContent
      header={
        editDoc ? (
          <>
            <DetailsPageTitleBar
              title={editDoc?.document ?? ''}
              subtitle={editDoc?.description ?? ''}
              actionButtons={{hasError, additionalActions, submitChange}}
            >
              <ItemsSummary items={itemsSummary}/>
            </DetailsPageTitleBar>

            <input
              ref={fileRef}
              accept='application/pdf,.pdf, image/*'
              className='hidden'
              type='file'
              onChange={onFileUploadChange}
            />
          </>
        ) : undefined
      }
      footer={editDoc ? <Footer doc={editDoc}/> : undefined}
    >
      {!!editDoc && (
        <MultipleResizablePanels
          panelsDefaultSize={[50, 50]}
          panelsMinSize={[27, 44]}
          autoSaveId='document-detail'
          resizerClassName='left-0 panelResizer'
        >
          <LeftSide doc={editDoc}/>
          <RightSide doc={editDoc}/>
        </MultipleResizablePanels>
        // <RadResizablePanelGroup className='flex' direction='horizontal' autoSaveId='resizable-panel-document-details'>
        //   <RadResizablePanel defaultSize={50} minSize={27}>
        //     <LeftSide doc={editDoc}/>
        //   </RadResizablePanel>

      //   <RadResizableHandle/>

      //   <RadResizablePanel defaultSize={50} minSize={44}>
      //     <RightSide doc={editDoc}/>
      //   </RadResizablePanel>
      // </RadResizablePanelGroup>
      )}

      {editDoc?.id && <DeleteDocModal open={deleteDocModalOpen} doc={editDoc!} onClose={() => changeDeleteDocModalDisplay(false)}/>}
      <WarningConfirmModal closeOnConfirm closeOnCancel cancelButtonLabel={t('label.no')} confirmButtonLabel={t('label.yes')} className='min-w-[auto] min-h-[150px] w-[600px]' open={confirmDocNameExistsModalDisplayed} description={t('message.questions.fileNameExistsWantToOverrideDoc')} onConfirm={() => handleOverrideDoc(true)} onClose={() => changeConfirmDocNameExistsModalDisplay(false)} onCancel={() => handleOverrideDoc(false)}/>
    </PageContent>
  );
}
