import {InspectionPoint} from '@app/graphql/__types__/graphql';
import {Badge} from '@holis/react-ui';
import {useTranslation} from 'react-i18next';
import {OBJ_NEW_ID} from '@app/utils/constants';
import React from 'react';
import IdText from '@app/components/Common/Text/IdText';
type TFooter = Readonly<{
    inspectionPoint: Partial<InspectionPoint>;
}>

export default function Footer({inspectionPoint}: TFooter) {
  const {t} = useTranslation();
  return (
    <div className='flex w-full h-full justify-between font-normal items-center px-2'>
      <div className='flex items-center gap-2 h-full'>
        {inspectionPoint.id !== OBJ_NEW_ID && (
          <>
            <Badge stylePreset={Badge.StylePresets.Outline} className='bg-indigo-100 text-indigo-700 border-indigo-700 font-normal font-montserrat py-1 uppercase rounded border'>{t(`label.flagStatus.${inspectionPoint.flagStatus}`)}</Badge>
            <IdText id={inspectionPoint.id}/>
          </>
        )}
      </div>
    </div>
  );
}
