import {useTranslation} from 'react-i18next';
import React from 'react';
import ActionButtons from '@app/components/Common/Form/ActionButtons';
import {Event, FunctionalLocation, InspectionDrawing, Notification, UpdateInspectionDrawingByIdMutation} from '@app/graphql/__types__/graphql';
import {INSPECTION_DRAWINGS_UPDATE_BY_ID} from '@app/graphql/requests';
import {useMutation} from '@apollo/client';
import AppNotifications from '@app/services/notification';
import {useDataStore} from '@app/stores/data';
import useIdwgStore, {IDWG_PREFIX} from '@app/stores/idwg';
import {useEventStore} from '@app/stores/event';
import {twMerge} from 'tailwind-merge';
import {TAdditionalActions, TDownloadProps} from '@app/types/app';
import {addTokenToUrl} from '@app/utils/functions';
import {useHolisAuth} from '@holis/auth-client-react';
import {EAdditionalAction, EImportService} from '@app/utils/enums';
import {Button} from '@holis/react-ui';
import {FaFileDownload, FaFileUpload, FaTrashAlt} from 'react-icons/fa';
import {useInspectionDrawingStore} from '@app/stores/methodEngineering/inspectionDrawing';

type TBottomActions = Readonly<TDownloadProps & {
  fileRef: React.RefObject<HTMLInputElement>;
  idwg: Partial<InspectionDrawing>;
  objectItem?: Partial<Event | Notification | FunctionalLocation>;
  className?: string;
}>

export default function BottomActions({fileRef, objectItem, idwg, className, downloadFileName, downloadUrl, isExternalSrc}: TBottomActions) {
  const {t} = useTranslation();
  const {fetchEventInspectionDrawings} = useEventStore();
  const {fetchInspectionDrawings} = useInspectionDrawingStore();
  const {setUpdateData, isSaved, hasError, changeDeleteInspectionDrawingModalDisplay, uploadFile, updateInspectionDrawing, updateFieldError, editIdwg, updateData, cancelEditData} = useIdwgStore(`${IDWG_PREFIX}${idwg.id}`);
  const {uploadDownloadService} = useDataStore();
  const {getAccessToken} = useHolisAuth();

  const [updateIdwgById] = useMutation<UpdateInspectionDrawingByIdMutation>(INSPECTION_DRAWINGS_UPDATE_BY_ID);
  const updateIdwg = () => {
    updateIdwgById({variables: {id: idwg!.id, data: updateData}}).then(newData => {
      const newIdwg = {
        ...editIdwg,
        ...newData?.data?.updateOneInspectionDrawing,
      } as InspectionDrawing;
      updateInspectionDrawing(newIdwg);
      setUpdateData({});
      AppNotifications.success(t('message.success.drawingUpdated'));
      if (objectItem) {
        if (objectItem.__typename === 'Event') {
          fetchEventInspectionDrawings?.();
        }
      } else {
        fetchInspectionDrawings?.();
      }
    }).catch((err: Error) => {
      let errorMessage: string = t('message.error.default.title');
      if (typeof err?.message === 'string') {
        if (err.message.includes('Unique constraint failed on the fields: (`idwg`)')) {
          errorMessage = t('message.error.unique.documentation.inspectionDrawing.idwg');
        }

        updateFieldError('idwg', true);
      }

      AppNotifications.error(errorMessage);
    });
  };

  const handleCancelEditDataClick = (_e?: React.MouseEvent) => {
    if (fileRef.current) {
      fileRef.current!.value = '';
    }

    cancelEditData();
  };

  const submitChange = () => {
    if (uploadFile) {
      uploadDownloadService!.uploadFile(uploadFile, undefined, EImportService.UPLOAD_DRAWING).then(response => {
        if (response.status !== 200) {
          const responseJson = JSON.parse(response.data);
          throw new Error(typeof responseJson === 'object' && uploadFile.name && responseJson.errors?.files?.[uploadFile.name] ? responseJson.errors.files![uploadFile.name] : response.data.message ?? response.statusText);
        }

        updateIdwg();
      }).catch((_error: Error) => {
        AppNotifications.error(_error.message ?? t('message.error.uploadDocumentationIdwg'));
      });
    } else {
      updateIdwg();
    }
  };

  const downloadFile = async (_e: React.MouseEvent) => {
    if (typeof downloadUrl === 'string') {
      let url = downloadUrl;
      if (!isExternalSrc && url) {
        url = await addTokenToUrl(url, getAccessToken);
      }

      if (url) {
        fetch(url!).then(res => res.arrayBuffer()).then(res => {
          const file = new Blob([res], {type: 'application/octet-stream'});
          const fileURL = URL.createObjectURL(file);
          const link: HTMLAnchorElement = document.createElement<'a'>('a');
          link.href = fileURL;
          link.download = downloadFileName ?? 'file.pdf';
          link.click();
          link.remove();
        });
      }
    }
  };

  const onFileUploadClick = (_e: React.MouseEvent) => {
    fileRef.current?.click();
  };

  const additionalActions: TAdditionalActions = {
    [EAdditionalAction.UPLOAD]: <Button className='gap-1 py-1.5 px-2 hover:bg-gray-100 text-gray-500 rounded-none pt-1 w-full' onClick={onFileUploadClick}><FaFileUpload/> {t('label.uploadFile')}</Button>,
    ...(downloadUrl ? {[EAdditionalAction.DOWNLOAD]: <Button className='gap-1 py-1.5 px-2 hover:bg-gray-100 text-gray-500 rounded-none pt-1 w-full' onClick={downloadFile}><FaFileDownload/> {t('label.downloadFile')}</Button>} : {}),
    [EAdditionalAction.DELETE]: <Button className='gap-1 py-1.5 px-2 hover:bg-gray-100 text-red-500 rounded-none pt-1 w-full' onClick={() => changeDeleteInspectionDrawingModalDisplay(true)}><FaTrashAlt/> {t('label.deleteDrawing')}</Button>,
  };

  return (
    <ActionButtons
      additionalActions={additionalActions}
      className={twMerge('mr-0', className)}
      isSaved={isSaved}
      hasError={hasError}
      dropdownClassName='action-dropdown'
      handleCancelEditDataClick={handleCancelEditDataClick}
      submitChange={submitChange}
    />
  );
}
