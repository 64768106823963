import React from 'react';

type THeader = Readonly<{
  title?: React.ReactNode;
  description?: React.ReactNode;
}>

export default function Header({title, description}: THeader) {
  return (
    <div className='flex w-full flex-col items-start text-base gap-2 font-semibold leading-4'>
      <div className='capitalize text-gray-700'>{title}</div>
      <div className='text-primary'>{description}</div>
    </div>
  );
}
