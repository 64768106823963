import SingleContentModal from '@app/components/Modal/SingleFormModal';
import {EventControlDataExtResponse} from '@app/graphql/__types__/graphql';
import {useTranslation} from 'react-i18next';
import {twMerge} from 'tailwind-merge';

export type TSummaryModalProps = Readonly<{
	isOpen?: boolean;
	onClose?: () => void;
	results: EventControlDataExtResponse[];
}>;
export default function SummaryModal(props: TSummaryModalProps) {
  const {t} = useTranslation();
  const total = props.results.length;
  const oks = props.results.filter(r => r.controlStatus === 'OK').length;
  const errors = props.results.filter(r => r.controlStatus === 'ERROR').length;
  return (
    <SingleContentModal
      isOpen={props.isOpen}
      title={t('label.wrkoImportControlSummary')}
      containerClassName='min-w-[600px]'
      onOpenChange={val => !val && props.onClose?.()}
    >
      <div className='max-w-[400px] ml-8 flex flex-col gap-4'>
        {resultRow(t('label.wrkoControlTotal'), total.toString())}
        {resultRow(t('label.wrkoControlOk'), oks.toString(), oks > 0 ? 'text-green-500' : 'text-red-500')}
        {resultRow(t('label.wrkoControlError'), errors.toString(), errors > 0 ? 'text-red-500' : 'text-green-500')}
      </div>
    </SingleContentModal>
  );
}

function resultRow(label: string, value: string, valueClass?: string) {
  return (
    <div className='flex justify-between text-sm'>
      <div>{label}:</div>
      <div className={twMerge(valueClass, 'font-bold')}>{value}</div>
    </div>
  );
}
