import {Damage} from '@app/graphql/__types__/graphql';
import {Badge} from '@holis/react-ui';
import {useTranslation} from 'react-i18next';
import {OBJ_NEW_ID} from '@app/utils/constants';
import React from 'react';
import IdText from '@app/components/Common/Text/IdText';
type TFooter = Readonly<{
    damage: Partial<Damage>;
}>

export default function Footer({damage}: TFooter) {
  const {t} = useTranslation();
  return (
    <div className='flex w-full h-full justify-between font-normal items-center px-2'>
      <div className='flex items-center gap-2 h-full'>
        {damage.id !== OBJ_NEW_ID && (
          <>
            <Badge stylePreset={Badge.StylePresets.Outline} className='font-normal font-montserrat bg-indigo-100 text-indigo-700 border-indigo-700 py-1 uppercase rounded border'>{t(`label.flagStatus.${damage.flagStatus}`)}</Badge>
            <IdText id={damage.id}/>
          </>
        )}
      </div>
    </div>
  );
}
