import {CSSProperties, HTMLAttributes} from 'react';
import {twMerge} from 'tailwind-merge';

type TSeparator = Readonly<HTMLAttributes<HTMLDivElement> & {
    className?: string
    color?: string
    width?: number|string
    height?: number|string
}>

export default function Separator({className, color, width, height, style, ...restProps}: TSeparator) {
  const styles: CSSProperties = {...style ?? {}};
  if (color) {
    styles.backgroundColor = color;
  }

  if (width) {
    styles.width = typeof width === 'number' ? `${width}px` : width;
  }

  if (height) {
    styles.height = typeof height === 'number' ? `${height}px` : height;
  }

  return <div className={twMerge('h-full w-px bg-gray-200', className)} style={styles} {...restProps}/>;
}
