import {EMarkupForm} from '@app/utils/enums';
import {IoTriangle, IoTriangleOutline} from 'react-icons/io5';
import {MdCircle, MdComment, MdDiamond, MdHexagon, MdOutlineCircle, MdOutlineDiamond, MdOutlineSquare, MdSquare} from 'react-icons/md';
import {FaUnsplash} from 'react-icons/fa';
import {twMerge} from 'tailwind-merge';
import {BiSolidCylinder} from 'react-icons/bi';
import {FaDiamondTurnRight} from 'react-icons/fa6';
import {LuTriangleRight} from 'react-icons/lu';
import {CSSProperties} from 'react';
// import Cylinder from "@app/assets/images/icons/Cylinder";
export type TFormIcon = Readonly<{
    form?: string,
    className?: string,
    size?: number,
    width?: number,
    height?: number
    style?: CSSProperties;
}>;

export const FIRST_OBJ_INVISIBLE_LIST: string[] = [EMarkupForm.CIRCLE_SOLID, EMarkupForm.DIAMOND_SOLID, EMarkupForm.DIAMOND_DOTTED, EMarkupForm.SQUARE_SOLID, EMarkupForm.CIRCLE_DOTTED, EMarkupForm.COMMENT, EMarkupForm.SQUARE_DOTTED, EMarkupForm.HEXAGONE_SOLID];

export default function FormIcon({form, style, className, size, width, height}: TFormIcon) {
  let Markup;
  switch (form) {
    case EMarkupForm.CIRCLE_SOLID:
      Markup = MdCircle;
      break;
    case EMarkupForm.CIRCLE_DOTTED:
      Markup = MdOutlineCircle;
      break;
    case EMarkupForm.DCIRCLE_SOLID:
      Markup = FaDiamondTurnRight;
      break;
    case EMarkupForm.TRIANGLERECT_SOLID:
      Markup = LuTriangleRight;
      break;
    case EMarkupForm.SQUARE_SOLID:
      Markup = MdSquare;
      break;
    case EMarkupForm.SQUARE_DOTTED:
      Markup = MdOutlineSquare;
      break;
    case EMarkupForm.TRIANGLE_SOLID:
      className = twMerge('mt-0', className);
      Markup = IoTriangle;
      break;
    case EMarkupForm.TRIANGLERECT_DOTTED:
      className = twMerge('mt-0', className);
      Markup = IoTriangleOutline;
      break;
    case EMarkupForm.CYLINDER_SOLID:
      Markup = BiSolidCylinder;
      break;
    case EMarkupForm.HEXAGONE_SOLID:
      Markup = MdHexagon;
      break;
    case EMarkupForm.DIAMOND_SOLID:
      Markup = MdDiamond;
      break;
    case EMarkupForm.DIAMOND_DOTTED:
      Markup = MdOutlineDiamond;
      break;
    case EMarkupForm.SPLASH_SOLID:
      Markup = FaUnsplash;
      break;
    case EMarkupForm.COMMENT:
      Markup = MdComment;
      break;
    default:
      Markup = MdCircle;
      break;
  }

  return <Markup style={style} width={width ?? size} height={height ?? size} size={size} className={className}/>;
}
