import {useState} from 'react';

export default function useVersion() {
  const [version, setVersion] = useState<string>();

  if (!version) {
    if (process.env.NODE_ENV === 'development') {
      return '0.dev';
    }

    // Fetch VERSION and extract from Version=.*
    fetch(`${import.meta.env.BASE_URL || '/'}VERSION`)
      .then(response => response.text())
      .then(text => {
        const version = text.match(/Version=(.*)/)?.[1];
        setVersion(version);
      });
  }

  return version;
}
