import DrawingObjectSelectionConfirmModal from '@app/components/Modal/Confirm/DrawingObjectSelection';
import {Damage, CreateNotificationDamagesMutation, DeleteNotificationDamagesMutation, NotificationDamage, InspectionDrawing, Cml, GetDamagesHavingEventByFlocIdsQuery, GetDamagesByFlocIdsAndEventQuery} from '@app/graphql/__types__/graphql';
import {NO_DRAWING_ID} from '@app/utils/constants';
import {useEffect, useState} from 'react';
import DamageCard from '../DamageBlock/DamageCard';
import {useTranslation} from 'react-i18next';
import {LuMapPin} from 'react-icons/lu';
import {useLazyQuery, useMutation} from '@apollo/client';
import {DAMAGES_GET_BY_FLOC_IDS_AND_EVENT, DAMAGES_GET_HAVING_EVENT_BY_FLOC_IDS, NOTIFICATION_DAMAGES_CREATE_MANY, NOTIFICATION_DAMAGES_DELETE_MANY} from '@app/graphql/requests';
import {TDbId, TObjId} from '@app/types/app';
import AppNotifications from '@app/services/notification';
import {useLayoutStore} from '@app/stores/layout';
import useNotificationStore from '@app/stores/notification';

export default function NotificationDamageSelectionModal() {
  const {t} = useTranslation();
  const {startLoading, stopLoading} = useLayoutStore();
  const [damagesLoading, setDamagesLoading] = useState<boolean>(false);
  const [drawings, setDrawings] = useState<Partial<InspectionDrawing>[]>();
  const {flocDamages, activeNotification, notificationDamages, notificationFlocDrawings, changeDamageSelectionModalDisplay, damageSelectionModalDisplayed, setNotificationFlocDamages, fetchNotificationDamages, eventDamages} = useNotificationStore();

  const [addNotificationDamagesApi] = useMutation<CreateNotificationDamagesMutation>(NOTIFICATION_DAMAGES_CREATE_MANY);
  const [deleteNotificationDamagesApi] = useMutation<DeleteNotificationDamagesMutation>(NOTIFICATION_DAMAGES_DELETE_MANY);

  const [getNotificationFlocDamagesApi] = useLazyQuery<GetDamagesHavingEventByFlocIdsQuery>(DAMAGES_GET_HAVING_EVENT_BY_FLOC_IDS, {
    fetchPolicy: 'no-cache',
  });
  const [getNotificationFlocDamagesByEventApi] = useLazyQuery<GetDamagesByFlocIdsAndEventQuery>(DAMAGES_GET_BY_FLOC_IDS_AND_EVENT, {
    fetchPolicy: 'no-cache',
  });

  const addItems = (ids: TDbId[]) => addNotificationDamagesApi({
    variables: {
      data: ids.map((dmgeId: TDbId) => ({
        notifId: activeNotification!.id!,
        dmgeId,
        qualReadingId: eventDamages?.find(item => item.dmgeId === dmgeId)?.qualReadingId ?? null,
      })),
    },
  });

  const deleteItems = (dmgIds: TDbId[]) => deleteNotificationDamagesApi({
    variables: {
      dmgIds,
      notifId: activeNotification!.id!,
    },
  });

  const handleValidateSelection = async (selectedItems: Partial<TObjId>[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[]) => {
    const promises = [];
    if (oldSelectedIds.length) {
      const dmgIds = (notificationDamages?.filter((item: Partial<NotificationDamage>) => item.dmgeId && oldSelectedIds.includes(item.dmgeId)).map((item: Partial<NotificationDamage>) => item.dmgeId) ?? []) as TDbId[];
      if (dmgIds.length) {
        promises.push(deleteItems(dmgIds));
      }
    }

    if (newSelectedIds.length) {
      promises.push(addItems(newSelectedIds));
    }

    if (promises.length) {
      startLoading();
      try {
        for (const asyncCall of promises) {
          await asyncCall;
        }

        fetchNotificationDamages?.();
        changeDamageSelectionModalDisplay(false);
        AppNotifications.success(t('message.success.notificationDamagesUpdated'));
      } catch (err) {
        AppNotifications.error(t('message.error.default.title'));
      }

      stopLoading();
    } else {
      changeDamageSelectionModalDisplay(false);
    }
  };

  const getNotificationFlocDamages = () => {
    setDamagesLoading(true);
    if (activeNotification?.wrkoId) {
      getNotificationFlocDamagesByEventApi({
        variables: {
          flocIds: [activeNotification!.flocId!],
          evtId: activeNotification.wrkoId,
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => {
        setNotificationFlocDamages((queryResult.data?.damages ?? []) as Partial<Damage>[]);
      }).finally(() => setDamagesLoading(false));
    } else {
      getNotificationFlocDamagesApi({
        variables: {
          flocIds: [activeNotification!.flocId!],
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => {
        setNotificationFlocDamages((queryResult.data?.damages ?? []) as Partial<Damage>[]);
      }).finally(() => setDamagesLoading(false));
    }
  };

  useEffect(() => {
    const idwgs = notificationFlocDrawings ?? [];
    setDrawings([
      {
        id: NO_DRAWING_ID,
      },
      ...idwgs,
    ]);
  }, [notificationFlocDrawings]);

  useEffect(() => {
    getNotificationFlocDamages();
  }, []);

  return (
    <DrawingObjectSelectionConfirmModal
      hasItems
      isMultiple
      isLoading={damagesLoading}
      title={t('label.manageDamages')}
      description={t('label.manageDamagesDescription')}
      headerTitle={<div className='flex items-center text-primary'><LuMapPin/> {t('label.damage')}</div>}
      drawings={drawings}
      open={damageSelectionModalDisplayed}
      items={flocDamages ?? []}
      drawingStoreIdSuffix='damage-selection-modal'
      selectedIds={notificationDamages?.map(item => item.dmgeId!) ?? []}
      renderItem={(item: Partial<Damage | Cml>, drawingStoreId: string) => <DamageCard display2dDisabled drawingStoreId={drawingStoreId} dmgObject={item as Partial<Damage>}/>}
      onValidate={handleValidateSelection}
      onClose={() => changeDamageSelectionModalDisplay(false)}/>
  );
}
