import {gql} from '@apollo/client';

export const NOTIFICATION_CATALOGS_COMMON_FIELDS = gql`
    fragment RefNotifCatalogCommonFields on RefNotifCatalog {
        id
        description
        code
        category
    }
`;
