import SingleContentModal from '@app/components/Modal/SingleFormModal';
import {Exportstatus} from '@app/types/export-datas-types';
import {useTranslation} from 'react-i18next';
import {LuCheckCircle, LuXCircle, LuCircle, LuArrowRight} from 'react-icons/lu';

export type TExportErrorsModal = Readonly<{
	opened?: boolean;
	onClose?: () => void;
  title?: string;
  exportStatus: Exportstatus;
}>;
export default function ExportStatusModal({opened, onClose, title, exportStatus}: TExportErrorsModal) {
  const {t} = useTranslation();

  const getStepStatusIcon = (status: string) => {
    switch (status) {
      case 'pending':
        return <LuCircle/>;
      case 'success':
        return <LuCheckCircle/>;
      case 'error':
        return <LuXCircle/>;
      default:
        return null;
    }
  };

  const getStepStatusClassName = (status: string) => {
    switch (status) {
      case 'pending':
        return 'text-black-50';
      case 'success':
        return 'text-green-600';
      case 'error':
        return 'text-red-500';
      default:
        return '';
    }
  };

  return (

    <SingleContentModal
      isOpen={opened}
      containerClassName='max-w-[600px] min-w-0'
      title={title ?? t('label.exportStatus')}
      onOpenChange={isOpen => {
        if (!isOpen) {
          onClose?.();
        }
      }}>
      <div className='text-base uppercase'>
        {t('label.exportStatus')}: <span className={getStepStatusClassName(exportStatus.status)}>{exportStatus.status}</span>
      </div>
      <div className='text-sm mt-4'>
        {exportStatus.steps?.map(step => (
          <div key={step.name} className={getStepStatusClassName(step.status?.status ?? 'pending') + ' mt-4 text-gray-500'}>
            <div className='flex gap-2 items-center'>{getStepStatusIcon(step.status?.status ?? 'pending')} <b>{step.name}</b>: {step.status?.status ?? 'pending'}</div>
            {step.status?.message && <div className='flex gap-2 items-center mt-1 ml-2'>
              <LuArrowRight/>
              <pre className='text-xs w-full overflow-x-auto max-h-[150px]'>{step.status.message}</pre>
            </div>}
          </div>
        ))}
      </div>
    </SingleContentModal>
  );
}
