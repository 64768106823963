import {CreatePlanFlocsMutation, Plan, PlanFloc, GetPlanFlocsByPlanIdQuery} from '@app/graphql/__types__/graphql';
import {useTranslation} from 'react-i18next';
import FlocItems from './FlocList';
import {useLayoutStore} from '@app/stores/layout';
import {FetchResult, useLazyQuery, useMutation} from '@apollo/client';
import {SCHEDULING_PLAN_FLOCS_CREATE_MANY, SCHEDULING_PLAN_FLOCS_GET_BY_PLAN_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useEffect} from 'react';
import DeletePlanFlocModal from './DeletePlanFlocModal';
import usePlanStore from '@app/stores/plan';

type TFlocBlock = Readonly<{
  plan: Partial<Plan>;
  filterString?: string;
}>;

export default function FlocBlock({plan, filterString} : TFlocBlock) {
  const {flocToDelete, setFlocToDelete, flocs, setFlocs} = usePlanStore();
  const {startLoading, stopLoading} = useLayoutStore();
  const [addPlanFlocsApi] = useMutation<CreatePlanFlocsMutation>(SCHEDULING_PLAN_FLOCS_CREATE_MANY);
  const [getPlanFlocsByPlanId, {refetch, called}] = useLazyQuery<GetPlanFlocsByPlanIdQuery>(SCHEDULING_PLAN_FLOCS_GET_BY_PLAN_ID, {
    variables: {
      planId: plan.id!,
    },
    fetchPolicy: 'no-cache',
  });
  const {t} = useTranslation();

  const refreshPlanFlocsList = () => {
    (called ? refetch : getPlanFlocsByPlanId)().then(queryResults => {
      const newFlocs = (queryResults.data?.planFlocs ?? []) as PlanFloc[];
      setFlocs(newFlocs);
    }).catch(_err => {
      AppNotifications.error(t('message.error.default.title'));
    });
  };

  const addFlocs = (items: Partial<PlanFloc>[], changeFlocAutocompleteDisplay: (displayed: boolean) => void) => {
    startLoading();
    addPlanFlocsApi({
      variables: {
        data: items.map((item: Partial<PlanFloc>) => ({
          planId: plan.id,
          flocId: item.flocId,
        })),
      },
    }).then((_res: FetchResult<CreatePlanFlocsMutation>) => {
      refreshPlanFlocsList();
      changeFlocAutocompleteDisplay(false);

      AppNotifications.success(t('message.success.addPlanFloc'));
      stopLoading();
    }).catch(_err => {
      stopLoading();
      AppNotifications.error(t('message.error.default.title'));
    });
  };

  useEffect(() => {
    refreshPlanFlocsList();
  }, [plan]);

  return (
    <div className='flex flex-col'>
      <div className='pl-0'>
        <FlocItems
          hasActionButtons
          refreshList={refreshPlanFlocsList}
          plan={plan}
          flocs={flocs}
          isDeleteButtonDisabled={!!flocToDelete}
          filterString={filterString}
          onClickAddFlocs={addFlocs}
          onDeleteClick={setFlocToDelete}
        />
      </div>
      <DeletePlanFlocModal open={!!flocToDelete} onDeleted={refreshPlanFlocsList}/>
    </div>
  );
}
