import {InMemoryCache, NormalizedCacheObject} from '@apollo/client/cache';
import {ErrorResponse, onError} from '@apollo/client/link/error';
import {
  ApolloClient,
  createHttpLink,
  from,
} from '@apollo/client/core';
import {setContext} from '@apollo/client/link/context';
import AppNotifications from '@app/services/notification';
import {t} from 'i18next';
import {OptimusClientConfig} from '@app/utils/clientConfig';

export class OptimusGraphQLClient {
  private _client: ApolloClient<NormalizedCacheObject>;

  constructor(private getAccessToken: () => Promise<string | undefined>) {
    if (!OptimusClientConfig.current.apiBaseUrl) {
      throw new Error('Environment variable API_BASE_URL is not set.');
    }

    const httpLink = createHttpLink({
      uri: OptimusClientConfig.current.apiBaseUrl,
    });
    const authLink = setContext(async (_, {headers}) => {
      const token = await this.getAccessToken();
      return ({
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      });
    });

    this._client = new ApolloClient({
      cache: new InMemoryCache(),
      link: from([
        onError(this.onGraphQLError),
        authLink.concat(httpLink),
      ]),
    });
  }

  private onGraphQLError({graphQLErrors, networkError}: ErrorResponse) {
    if (graphQLErrors) {
      AppNotifications.error(t('message.error.graphql'), {
        hideOnSameType: true,
        timeout: 500,
      });
      graphQLErrors.forEach(({message, locations, path}) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    } else if (networkError) {
      AppNotifications.error(t('message.error.graphql_network'), {
        hideOnSameType: true,
        timeout: 500,
      });
      console.error(`[GraphQL Network error]: ${networkError}`);
    }
  }

  public get client() {
    return this._client;
  }
}
