import {InspectionDrawing} from '@app/graphql/__types__/graphql';
import {NO_DRAWING_ID} from '@app/utils/constants';
import {RadAccordionItem, RadAccordion, RadAccordionTrigger, RadButton, RadAccordionContent} from '@holis/react-ui/rad';
import {ReactNode, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LuPanelLeftOpen} from 'react-icons/lu';
import styled from 'styled-components';
import {twMerge} from 'tailwind-merge';

/**
 * Needed to hide the expand/collapse icon when the accordion is open
 * and to hide the display drawing button when the accordion is closed
 */
const StyledAccordionItem = styled(RadAccordionItem)`
  button[data-state="open"] > svg {
    display: none;
  }
  button[data-state]:not([data-state="open"]) .display-drawing-button {
    display: none;
  }
  button[data-state] {
    text-decoration-line: none !important;
  }
`;

export type TInspectionDrawingAccordion = Readonly<{
	drawings?: Partial<InspectionDrawing>[];
	selectedDrawing?: Partial<InspectionDrawing>;
	getDrawingElementsCount?: (idwg: Partial<InspectionDrawing>) => number;
	renderDrawingElements?: (idwg: Partial<InspectionDrawing>) => ReactNode;
  onDisplayDrawingClick?: (idwg: Partial<InspectionDrawing>) => void;
  className?: string;
  drawingClassName?: string;
  triggerClassName?: string;
  contentClassName?: string;
  hideDisplayDrawingBtn?: boolean;
}>;

export default function InspectionDrawingAccordion({drawings, getDrawingElementsCount, renderDrawingElements, onDisplayDrawingClick, drawingClassName, triggerClassName, contentClassName, hideDisplayDrawingBtn, selectedDrawing, className}: TInspectionDrawingAccordion) {
  const {t} = useTranslation();
  const [openedIdwgs, setOpenedIdwgs] = useState<string[]>();
  const firstOpenedIdwgSet = useRef(false);

  useEffect(() => { // Open the first drawing by default
    if (
      firstOpenedIdwgSet.current
      || !drawings?.length
    ) {
      return;
    }

    // find first drawing for which getDrawingElementsCount is not 0
    const firstNonEmptyDrawing = drawings.find(idwg => getDrawingElementsCount?.(idwg) !== 0);

    if (firstNonEmptyDrawing) {
      setOpenedIdwgs([firstNonEmptyDrawing.id!.toString()]);
      firstOpenedIdwgSet.current = true;
    }
  }, [drawings]);

  return (
    <RadAccordion className={className} type='multiple' value={openedIdwgs} onValueChange={setOpenedIdwgs}>
      {drawings?.filter(idwg => getDrawingElementsCount?.(idwg) !== 0).map(idwg => (
        <StyledAccordionItem key={idwg.id} value={idwg.id!.toString()} className={twMerge('border-b-0', drawingClassName)}>
          <RadAccordionTrigger className={twMerge('border border-gray-200 rounded-md p-2 my-0.5', triggerClassName)}>
            <div className='flex w-full items-center'>
              <div className='italic text-xs flex-1 text-left'>
                {idwg.id === NO_DRAWING_ID ? t('label.noDrawing') : `${t('label.inspectionDrawing')}: ${idwg.idwg}`}
              </div>
              {idwg.id && idwg.id > 0 && !hideDisplayDrawingBtn && selectedDrawing?.id !== idwg.id && <RadButton
                variant='outline' className='display-drawing-button text-2xs no-underline px-2 h-6 mr-2'
                onClick={e => {
                  onDisplayDrawingClick?.(idwg);
                  e.preventDefault();
                }}>
                <LuPanelLeftOpen size={12} className='mr-2'/> {t('label.displayDrawing')}
              </RadButton>}
            </div>
          </RadAccordionTrigger>
          <RadAccordionContent className={twMerge('flex flex-col gap-1 pb-2 ml-1', contentClassName)}>
            {renderDrawingElements?.(idwg)}
          </RadAccordionContent>
        </StyledAccordionItem>
      ))}
    </RadAccordion>
  );
}
