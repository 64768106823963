import {gql} from '@apollo/client';
import {FILE_DOCS_COMMON_FIELDS} from '../fragments';

export const FILE_DOCS_GET_BY_NAME = gql`
${FILE_DOCS_COMMON_FIELDS}
query GetDocByName($docName: String!) {
    doc(where: {docName: $docName}){
        ...FileDocCommonFields
    }
}
`;

export const FILE_DOCS_GET_WITH_CONTENT_BY_NAME = gql`
${FILE_DOCS_COMMON_FIELDS}
query GetDocWithContentByName($docName: String!) {
    doc(where: {docName: $docName}){
        ...FileDocCommonFields
        content
    }
}
`;

export const FILE_DOCS_GET_COUNTER = gql`
query GetFileDocsCounter {
  aggregateDoc {
    _count {
      id
    }
  }
}
`;

export const FILE_DOCS_GET_DOC_IMG_COUNTER = gql`
query GetFileDocAndImgCounter {
  countAllDocAndImg
}
`;

export const FILE_DOCS_GET_PDF_SVG_COUNTER = gql`
query GetFileDocPdfAndSvgCounter {
  aggregateDoc(where: {extension: {in: ["svg", "pdf"]}}) {
    _count {
      id
    }
  }
}
`;

