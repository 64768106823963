import {useTranslation} from 'react-i18next';
import {RadButton, RadDialogClose} from '@holis/react-ui/rad';

import useUsersStore from '@app/stores/users';
import {MouseEvent} from 'react';

type TFooter = {
  readonly onSaveClicked?: () => void;
  readonly onDeleteClicked?: () => void;
}

export default function Footer({onSaveClicked, onDeleteClicked}: TFooter) {
  const {t} = useTranslation();
  const {editingRole} = useUsersStore();

  const handleSaveclicked = (e: MouseEvent) => {
    const frm = document.getElementById('mdl-role-edit-form') as HTMLFormElement;
    if (frm.reportValidity()) {
      e.preventDefault();
      onSaveClicked?.();
    }
  };

  return (
    <>
      <div className='flex-1 self-end text-muted-foreground ml-2'>
        # ID: {editingRole?.id}
      </div>
      <RadDialogClose asChild>
        <RadButton variant='outline'>
          {t('label.cancel')}
        </RadButton>
      </RadDialogClose>

      <RadButton variant='destructive' onClick={onDeleteClicked}>
        {t('label.delete')}
      </RadButton>

      <RadButton onClick={handleSaveclicked}>
        {t('label.save')}
      </RadButton>
    </>
  );
}
