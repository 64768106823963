import {useLazyQuery} from '@apollo/client';
import AppCheckbox from '@app/components/Common/Form/Checkbox';
import {Grid, TGridProps} from '@app/components/Common/Grid';
import {FunctionalLocation, GetAllFlocsViewWithDetailQuery} from '@app/graphql/__types__/graphql';
import {FLOCS_GET_VIEW_ALL_WITH_DETAIL_BY_SITES} from '@app/graphql/requests';
import {useDataStore} from '@app/stores/data';
import {useFlocStore} from '@app/stores/methodEngineering/floc';
import {OBJ_NEW_ID, ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION_DETAIL} from '@app/utils/constants';
import {RadButton} from '@holis/react-ui/rad';
import TNestedKeys from 'node_modules/@holis/react-ui/dist/src/components/List/TreeList/types/TNestedKeys';
import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {LuPlusCircle} from 'react-icons/lu';
import {MdOpenInNew} from 'react-icons/md';
import {useNavigate} from 'react-router-dom';
import CreateFlocModal from './Create';

export default function FunctionalLocationPage() {
  const navigate = useNavigate();
  const {selectedSites} = useDataStore();
  const {flocs, setFlocs, creatingFloc, setActiveFloc, setFetchFlocs} = useFlocStore();
  const {t} = useTranslation();
  const [getFlocListApi, {
    loading,
    error,
  }] = useLazyQuery<GetAllFlocsViewWithDetailQuery>(FLOCS_GET_VIEW_ALL_WITH_DETAIL_BY_SITES, {
    variables: {
      orderBy: [
        {
          id: 'desc',
        },
      ],
    },
    fetchPolicy: 'no-cache',
  });

  const fetchFlocs = () => {
    if (selectedSites?.length) {
      getFlocListApi({
        variables: {
          sites: selectedSites!,
        },
      })
        .then(queryResults => setFlocs((queryResults.data?.flocViews ?? []) as Array<Partial<FunctionalLocation>>));
    } else {
      setFlocs([]);
    }
  };

  const handleNewFloc = () => {
    setActiveFloc({
      id: OBJ_NEW_ID,
    }, true);
  };

  useEffect(() => {
    fetchFlocs();
    setFetchFlocs(fetchFlocs);
  }, [selectedSites]);

  const gridProps: TGridProps = useMemo(() => ({
    columns: [
      {
        field: 'action',
        title: t('label.action'),
        type: 'string',
        width: 60,
        cellRenderer: 'action',
        titleHidden: true,
        filter: false,
      },
      {
        field: 'id',
        title: 'Id',
        type: 'number',
      },
      {
        field: 'floc',
        title: t('label.floc'),
        type: 'string',
      },
      {
        field: 'description',
        title: t('label.description'),
        type: 'string',
      },
      {
        field: 'longDescription',
        title: t('label.longDescription'),
        type: 'string',
        cellRenderer: 'raw',
      },
      {
        field: 'referenceDocument',
        title: t('label.referenceDocument'),
        type: 'string',
      },
      {
        field: 'catalog_code',
        title: t('label.catalog'),
        type: 'string',
      },
      {
        field: 'parent_floc',
        title: t('label.parent'),
        type: 'string',
      },
      {
        field: 'sector_sector',
        title: t('label.sector'),
        type: 'string',
      },
      {
        field: 'grid_grid',
        title: t('label.grid'),
        type: 'string',
      },
      {
        field: 'techClass_techClass',
        title: t('label.techClass'),
        type: 'string',
      },
      {
        field: 'classSece',
        title: t('label.classSece'),
        type: 'string',
      },
      {
        field: 'integritySystem',
        title: t('label.integritySystem'),
        type: 'string',
      },
      {
        field: 'planningPlant_code',
        title: t('label.planningPlant'),
        type: 'string',
      },
      {
        field: 'plantWorkCenter_code',
        title: t('label.plantWorkCenter'),
        type: 'string',
      },
      {
        field: 'plannerGroup_code',
        title: t('label.plannerGroup'),
        type: 'string',
      },
      {
        field: 'mainWorkCenter_code',
        title: t('label.mainWorkCenter'),
        type: 'string',
      },
      {
        field: 'strategy_strategy',
        title: t('label.strategy'),
        type: 'string',
      },
      {
        field: 'classification',
        title: t('label.classification'),
        type: 'string',
      },
      {
        field: 'legislation',
        title: t('label.legislation'),
        type: 'string',
      },
      {
        field: 'docName',
        title: t('label.docName'),
        type: 'string',
      },
      {
        field: 'processFunction_processFunction',
        title: t('label.processFunction'),
        type: 'string',
      },
      {
        field: 'corrosionLoop',
        title: t('label.corrosionLoop'),
        type: 'string',
      },
      {
        field: 'consequenceProd_code',
        title: t('label.consequenceProd'),
        type: 'string',
      },
      {
        field: 'shutdown_code',
        title: t('label.shutdown'),
        type: 'string',
      },
      {
        field: 'externalRef',
        title: t('label.externalRef'),
        type: 'string',
      },
      {
        field: 'flagStatus',
        title: t('label.flagStatusLabel'),
        type: 'string',
      },
      {
        field: 'catalogProfile_catalog',
        title: t('label.catalogProfile'),
        type: 'string',
      },
    ],
    cellRenderers: {
      action: (_val: string, rowData: Partial<FunctionalLocation>) => (
        <RadButton
          size='icon'
          variant='outline'
          className='size-fit p-1'
          onClick={() => navigate(ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION_DETAIL.replace(':number', `${rowData.id}`))}
        >
          <MdOpenInNew size={18}/>
        </RadButton>
      ),
      document: (val: string) => <span className='font-bold'>{val}</span>,
      damage: (val: unknown) => <AppCheckbox disabled checked={val as boolean}/>,
      flagStatus: (val: string) => val ? t(`label.flagStatus.${val}`) : '',
      // eslint-disable-next-line react/no-danger
      raw: (val: unknown) => <div dangerouslySetInnerHTML={{__html: val as string}}/>,
    },
  }), [flocs]);

  // @ts-ignore
  const columnsAlwaysVisible: TNestedKeys<FunctionalLocation>[] = useMemo(() => ([
    'action',
    'description',
    'damage',
  ]), [flocs]);

  return (
    <div className='h-full'>
      {creatingFloc && (
        <CreateFlocModal
          isOpen={creatingFloc}
          onOpenChange={isOpen => {
            if (!isOpen) {
              setActiveFloc(undefined);
            }
          }}
        />
      )}

      <Grid
        gridName='methodEngineering-flocsGrid'
        queryData={{
          data: flocs ?? [],
          loading,
          error,
        }}
        gridProps={gridProps}
        title={<div className='text-lg font-semibold'>{t('label.menu.functionalLocations')}</div>}
        columnsAlwaysVisible={columnsAlwaysVisible}
      >
        <RadButton
          size='sm'
          className='flex gap-1 text-sm'
          onClick={handleNewFloc}
        >
          <LuPlusCircle/> {t('label.addFloc')}
        </RadButton>
      </Grid>
    </div>
  );
}
