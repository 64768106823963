import {RadCard, RadCardContent} from '@holis/react-ui/rad';
import {HTMLProps, ReactNode} from 'react';
import {twMerge} from 'tailwind-merge';

export type TItemCard = Readonly<{
	className?: string;
	children?: ReactNode;
}>;

export default function ItemCard(props: TItemCard) {
  return (
    <RadCard className={twMerge('rounded-md flex items-stretch min-h-[66px] shadow-none', props.className)}>
      <RadCardContent className='w-full flex px-2 py-1.5 gap-2 items-center'>
        {props.children}
      </RadCardContent>
    </RadCard>
  );
}

export function ItemCardContent({children, ...restProps}: HTMLProps<HTMLDivElement>) {
  return (
    <div {...restProps} className={twMerge('flex-1', restProps.className)}>
      {children}
    </div>
  );
}
