import {useLazyQuery} from '@apollo/client';
import ImgViewer from '@app/components/Common/Viewer/ImgViewer';
import PDFViewer from '@app/components/Common/Viewer/PDFViewer';
import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent';
import {Doc, Document, GetDocByNameQuery} from '@app/graphql/__types__/graphql';
import {FILE_DOCS_GET_BY_NAME} from '@app/graphql/requests';
import {useDocumentStore} from '@app/stores/document';
import {OptimusClientConfig} from '@app/utils/clientConfig';
import {createObjUrlFromUrl, getFileExtension} from '@app/utils/functions';
import {useHolisAuth} from '@holis/auth-client-react';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

type TRightSide = Readonly<{
  doc: Partial<Document>;
}>;

export default function RightSide(_props: TRightSide) {
  const {t} = useTranslation();
  const {uploadFile, fileDoc, setFileDoc, fetchFileDoc, setFetchFileDoc, activeDoc} = useDocumentStore();
  const [isPdfFile, setIsPdfFile] = useState<boolean>(false);
  const {docName} = activeDoc ?? {};
  const [docSrc, setDocSrc] = useState<string|File|null>();
  const [getFileDocByDocNameApi] = useLazyQuery<GetDocByNameQuery>(FILE_DOCS_GET_BY_NAME);
  const {getAccessToken} = useHolisAuth();

  const getFileDoc = () => {
    getFileDocByDocNameApi({
      variables: {
        docName,
      },
      fetchPolicy: 'no-cache',
    }).then(result => setFileDoc((result.data?.doc as Partial<Doc> | undefined) ?? null)).catch(() => setDocSrc(null));
  };

  useEffect(() => {
    if (fileDoc) {
      setIsPdfFile(fileDoc.extension?.toLowerCase() === 'pdf');
      createObjUrlFromUrl(`${OptimusClientConfig.current.fileBaseUrl}/${docName}?ts=${moment().toISOString()}`, {getAccessToken}).then(src => setDocSrc(src)).catch(() => setDocSrc(null));
    } else if (fileDoc === null) {
      setDocSrc(null);
    }
  }, [fileDoc]);

  useEffect(() => {
    if (uploadFile) {
      setIsPdfFile(getFileExtension(uploadFile.name) === 'pdf');
      setDocSrc(uploadFile);
    }
  }, [uploadFile]);

  useEffect(() => {
    if (docName) {
      setFetchFileDoc(getFileDoc);
    }
  }, [docName]);

  useEffect(() => {
    fetchFileDoc?.();
  }, [fetchFileDoc]);

  return (
    <SpinnerLoaderComponent isLoading={typeof docSrc === 'undefined'} contentClassName='p-3'>
      {typeof docSrc !== 'undefined' ? (docSrc === null ? <div className='text-center m-auto flex flex-col justify-center items-center h-full w-full text-red-700 text-sm'>{t('message.error.docLoad')}</div> : (isPdfFile ? <PDFViewer
        key={uploadFile ? 'input-file-' + uploadFile.name : docSrc as string}
        isExternalSrc
        file={docSrc}
      /> : <ImgViewer
        key={uploadFile ? 'input-file-' + uploadFile.name : docSrc as string}
        isExternalSrc
        src={docSrc}/>)) : undefined}
    </SpinnerLoaderComponent>
  );
}
