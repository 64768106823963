import {TDataRowTreeList} from '@holis/react-ui';
import {TBorderedTable} from '@app/components/Common/TreeList/BorderedTable';
import {ReactNode} from 'react';
import {QueryResult} from '@apollo/client';
import DataTable from '@app/components/Common/Datatable';
import SingleContentModal from '../SingleFormModal';
import _, {ListIterator, NotVoid} from 'lodash';
import {TDbId} from '@app/types/app';
import {twMerge} from 'tailwind-merge';
import Header from './components/Header';

export type TListModalWithoutListProps<TItem extends { id?: string | number } = { id?: string | number }> = Omit<TListModal<TItem>, 'listProps'>;

export type TListModal<TItem extends { id?: string | number } = { id?: string | number }> = Readonly<{
  title?: ReactNode
  description?: ReactNode
  onClose?: () => void
  open?: boolean;
  className?: string;
  listProps: TBorderedTable;
  hiddenIds?: TDbId[];
  items?: TItem[];
  hasItems?: boolean;
  sortFields?: ListIterator<TDataRowTreeList, NotVoid>[];
  sortOrders?: (boolean|'asc'|'desc')[];
  isLoading?: boolean;
  queryResult?: QueryResult;
  searchFilter?: (data: TDataRowTreeList[], searchText: string) => TDataRowTreeList[];
}>;

export default function ListModal<TItem extends { id?: string | number }>({title, description, listProps, queryResult, hiddenIds, searchFilter, sortFields, sortOrders, className, ...restProps}: TListModal<TItem>) {
  const {listProps: listPropsInit, ...restListPops} = listProps ?? {};

  return (

    <SingleContentModal
      isOpen={restProps.open}
      containerClassName='max-w-[1000px] h-[90%]'
      header={<Header title={title} description={description}/>}
      onOpenChange={isOpen => {
        if (!isOpen) {
          restProps.onClose?.();
        }
      }}
    >
      <div className={twMerge('h-full w-full overflow-hidden', className)}>
        <DataTable<TItem>
          hasSearchBar
          searchBarClassName='w-[400px]'
          searchFilter={searchFilter}
          hiddenIds={hiddenIds}
          listProps={{...listPropsInit, data: _.orderBy(listPropsInit.data, sortFields, sortOrders) as TDataRowTreeList[], isLoading: !!listPropsInit?.isLoading || !!queryResult?.loading, headerClass: 'font-bold text-xs'}}
          {...restListPops}
        />
      </div>
    </SingleContentModal>

  );
}
