import {useLazyQuery, useQuery} from '@apollo/client';
import AppCombobox from '@app/components/Common/Form/AppCombobox';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import InputLabelWrapper from '@app/components/Common/Form/InputLabelWrapper/InputLabelWrapper';
import {AppListItem} from '@app/components/Common/Form/list-item';
import {GetAllRefFlocCatalogProfilesQuery, GetAllRefFlocClasTosQuery, GetAllSectorsQuery, GetGridsBySectorIdQuery, Grid, RefFlocCatalogProfile, RefFlocClasTo, Sector} from '@app/graphql/__types__/graphql';
import {FLOC_REF_CATALOG_PROFILES_GET_MANY, FLOC_REF_CLAS_TOS_GET_MANY, GRIDS_GET_BY_SECTOR_ID, SECTORS_GET_ALL} from '@app/graphql/requests';
import {RadFormField, RadInput} from '@holis/react-ui/rad';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

export default function Form() {
  const {t} = useTranslation();
  const [sectors, setSectors] = useState<AppListItem<Sector>[]>([]);
  const [techClasses, setTechClasses] = useState<AppListItem<RefFlocClasTo>[]>([]);
  const [catalogProfiles, setCatalogProfiles] = useState<AppListItem<RefFlocCatalogProfile>[]>([]);
  const [grids, setGrids] = useState<AppListItem<Grid>[]>([]);
  const [sectorId, setSectorId] = useState<number>();

  const {loading: techClassesLoading} = useQuery<GetAllRefFlocClasTosQuery>(FLOC_REF_CLAS_TOS_GET_MANY, {
    onCompleted(data) {
      setTechClasses(data.refFlocClasTos.map((item: Partial<RefFlocClasTo>) => ({
        label: `[${item.techClass}] - ${item.description}`,
        value: item.id,
      })));
    },
  });

  const {loading: catalogProfilesLoading} = useQuery<GetAllRefFlocCatalogProfilesQuery>(FLOC_REF_CATALOG_PROFILES_GET_MANY, {
    onCompleted(data) {
      setCatalogProfiles(data.refFlocCatalogProfiles.map((item: Partial<RefFlocCatalogProfile>) => ({
        label: `[${item.catalog}] - ${item.description}`,
        value: item.id,
      })));
    },
  });

  const {loading: sectorsLoading} = useQuery<GetAllSectorsQuery>(SECTORS_GET_ALL, {
    onCompleted(data) {
      setSectors(data.sectors.map((item: Partial<Sector>) => ({
        label: `[${item.sector}] - ${item.description}`,
        value: item.id,
      })));
    },
  });

  const [getGridsBySectorApi, {loading: gridLoading}] = useLazyQuery<GetGridsBySectorIdQuery>(GRIDS_GET_BY_SECTOR_ID, {
    onCompleted(data) {
      setGrids(data.grids.map((item: Partial<Grid>) => ({
        label: `[${item.grid}] - ${item.description}`,
        value: item.id,
      })));
    },
  });

  useEffect(() => {
    if (sectorId) {
      getGridsBySectorApi({
        variables: {
          sectorId,
        },
      });
    }
  }, [sectorId]);

  return (
    <div className='flex flex-col gap-6 pl-0.5 pr-4 pb-0.5'>
      {/* Identification */}
      <div className='flex flex-col gap-4'>
        <FormGroupHeader>
          {t('label.identification')}
        </FormGroupHeader>

        <div className='flex flex-col gap-2'>
          {/* floc */}
          <RadFormField
            name='floc'
            render={({field, fieldState}) => (
              <InputLabelWrapper
                isRequired
                fieldState={fieldState}
                label={t('label.functionalLocation')}
                className='w-full'
              >
                <RadInput
                  placeholder='-'
                  {...field} className='uppercase'/>
              </InputLabelWrapper>
            )}
          />

          {/* Description */}
          <RadFormField
            name='description'
            render={({field, fieldState}) => (
              <InputLabelWrapper
                isRequired
                fieldState={fieldState}
                label={t('label.description')}
                className='w-full'
              >
                <RadInput
                  placeholder='-'
                  {...field}
                />
              </InputLabelWrapper>
            )}
          />
        </div>
        <div className='flex flex-col gap-4'>
          <FormGroupHeader>
            {t('label.location')}
          </FormGroupHeader>
          <div className='grid grid-cols-2 gap-2'>

            {/* Sector */}
            <RadFormField
              name='sectorId'
              render={({field, fieldState}) => (
                <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.sector')}>
                  <AppCombobox
                    required
                    items={sectors}
                    loading={sectorsLoading}
                    placeholder={t('label.selectSector')}
                    {...field}
                    onSelectedItemChanged={selectedItem => {
                      field.onChange(selectedItem?.value);
                      setSectorId(selectedItem?.value as number);
                    }}
                  />
                </InputLabelWrapper>
              )}
            />
            {/* Grid */}
            <RadFormField
              name='gridId'
              render={({field, fieldState}) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.grid')}>
                  <AppCombobox
                    items={grids}
                    disabled={!sectorId}
                    placeholder={t('label.selectGrid')}
                    loading={gridLoading}
                    {...field}
                    onSelectedItemChanged={selectedItem => field.onChange(selectedItem?.value)}
                  />
                </InputLabelWrapper>
              )}
            />
          </div>
        </div>
        <div className='flex flex-col gap-4'>
          <FormGroupHeader>
            {t('label.classification')}
          </FormGroupHeader>
          <div className='grid grid-cols-2 gap-2'>

            {/* Sector */}
            <RadFormField
              name='techClassId'
              render={({field, fieldState}) => (
                <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.technicalClass')}>
                  <AppCombobox
                    required
                    items={techClasses}
                    loading={techClassesLoading}
                    placeholder={t('label.selectTechnicalClass')}
                    {...field}
                    onSelectedItemChanged={selectedItem => field.onChange(selectedItem?.value)}
                  />
                </InputLabelWrapper>
              )}
            />
            {/* Grid */}
            <RadFormField
              name='catalogProfileId'
              render={({field, fieldState}) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.catalogProfile')}>
                  <AppCombobox
                    items={catalogProfiles}
                    placeholder={t('label.selectCatalogProfile')}
                    loading={catalogProfilesLoading}
                    {...field}
                    onSelectedItemChanged={selectedItem => field.onChange(selectedItem?.value)}
                  />
                </InputLabelWrapper>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
