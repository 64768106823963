import {useTranslation} from 'react-i18next';
import React, {ReactNode, useEffect, useState} from 'react';
import SingleContentModal from '../../SingleFormModal';
import {RadButton, RadCheckbox} from '@holis/react-ui/rad';
import {TDbId} from '@app/types/app';
import {twMerge} from 'tailwind-merge';
import {TSelectionConfirmModalWithoutListProps} from '../Selection';
import {Cml, Damage, InspectionDrawing} from '@app/graphql/__types__/graphql';
import InspectionDrawingAccordion from '@app/components/Common/Block/Event/EventModal/components/InspectionDrawingAccordion';
import PanelHeader from '@app/components/Common/Panel/PanelHeader';
import {searchArray} from '@app/utils/functions';
import {NO_DRAWING_ID} from '@app/utils/constants';
import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent';
import DrawingViewer from '@app/components/Common/Block/InspectionDrawing/DrawingViewer';
import {IDWG_PREFIX} from '@app/stores/idwg';
import _ from 'lodash';

type TItem = Partial<Cml | Damage>

export type TDrawingObjectSelectionConfirmModal = TSelectionConfirmModalWithoutListProps & Readonly<{
  hiddenIds?: TDbId[];
  selectedIds?: TDbId[];
  onValidate?: (selectedItems: TItem[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[]) => void;
  isMultiple?: boolean;
  items: TItem[];
  drawings?: Partial<InspectionDrawing>[];
  isRequired?: boolean;
  isLoading?: boolean;
  description?: string;
  searchFilter?: (data: TItem[], searchText: string) => TItem[];
  className?: string;
  drawingClassName?: string;
  drawingTriggerClassName?: string;
  drawingContentClassName?: string;
  renderItem: (item: TItem, drawingStoreId: string) => ReactNode;
  headerTitle?: ReactNode;
  drawingStoreIdSuffix?: string;
}>;

export default function DrawingObjectSelectionConfirmModal({title, description, hiddenIds, onValidate, isMultiple, renderItem, drawings, searchFilter, selectedIds, items, className, isRequired, headerTitle, drawingTriggerClassName, drawingContentClassName, drawingClassName, isLoading, drawingStoreIdSuffix, ...restProps}: TDrawingObjectSelectionConfirmModal) {
  const viewerContainerRef = React.createRef<HTMLDivElement>();
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedDrawing, setSelectedDrawing] = useState<Partial<InspectionDrawing>>();
  const [filteredItems, setFilteredItems] = useState<TItem[]>();
  const [selectedRows, setSelectedRows] = useState<TItem[]>([]);
  const [newSelectedIds, setNewSelectedIds] = useState<TDbId[]>([]);
  const [oldSelectedIds, setOldSelectedIds] = useState<TDbId[]>([]);
  const [itemsByDrawing, setItemsByDrawing] = useState<Record<number, TItem[]>>({});
  const {t} = useTranslation();
  const handleItemCheckboxChanged = (item: TItem, checked: boolean) => {
    if (isMultiple) {
      if (!selectedRows.includes(item) && checked) {
        setSelectedRows([
          ...selectedRows,
          item,
        ]);
      } else if (selectedRows.includes(item) && !checked) {
        setSelectedRows(selectedRows.filter(filteredItem => filteredItem.id !== item.id));
      }
    } else if (checked) {
      setSelectedRows([item]);
    } else {
      setSelectedRows([]);
    }
  };

  useEffect(() => {
    setItemsByDrawing(filteredItems?.reduce((acc, item) => {
      let {idwgId} = item;
      if (!idwgId) {
        idwgId = NO_DRAWING_ID;
      }

      if (!acc[idwgId]) {
        acc[idwgId] = [];
      }

      acc[idwgId].push(item);
      return acc;
    }, {} as Record<number, TItem[]>) ?? {});
  }, [filteredItems]);

  useEffect(() => {
    setSelectedRows(items?.filter((item: TItem) => item.id && selectedIds?.includes(item.id)) ?? []);
  }, [items]);

  useEffect(() => {
    const currentSelectedIds = selectedRows.filter((item: TItem) => !!item.id).map((item: TItem) => item.id) as (TDbId)[];
    setNewSelectedIds(currentSelectedIds.filter((id: TDbId) => !selectedIds?.includes(id)));
    setOldSelectedIds(selectedIds?.filter((id: TDbId) => !currentSelectedIds.includes(id)) ?? []);
  }, [selectedRows]);

  useEffect(() => {
    const sortedItems = _.orderBy(items, [
      item => item.id && selectedIds?.includes(item.id) ? 0 : 1,
      item => item.id,
    ]);
    setFilteredItems((searchFilter?.(sortedItems, searchInput) ?? searchArray(sortedItems, searchInput, undefined, ['__type'])).filter(item => !hiddenIds || !hiddenIds.includes(item.id)));
  }, [items, searchInput]);

  useEffect(() => {
    if (drawings?.length) {
      setSelectedDrawing(drawings.find(item => item.id !== NO_DRAWING_ID && itemsByDrawing[item.id!]?.length));
    }
  }, [drawings, itemsByDrawing]);

  return (

    <SingleContentModal
      isOpen={restProps.open}
      containerClassName='max-w-[90%] h-[90%]'
      contentClassName='overflow-hidden flex flex-col ml-4'
      title={title}
      description={description}
      footer={
        <div className='w-full flex items-center gap-2 justify-end'>
          {/* <div className='flex-1 text-gray-600'>
            {t('label.selected')}: {selectedRows.length} / {items.filter(item => !(item.id && hiddenIds?.includes(item.id))).length}
          </div> */}
          <RadButton variant='outline' onClick={() => restProps.onClose?.()}>{t('label.cancel')}</RadButton>
          <RadButton disabled={!!isRequired && !selectedRows.length} onClick={() => onValidate?.(selectedRows, newSelectedIds, oldSelectedIds)}>{t('label.import')}</RadButton>
        </div>
      }
      onOpenChange={isOpen => {
        if (!isOpen) {
          restProps.onClose?.();
        }
      }}
    >
      <div className='flex flex-row items-stretch gap-2 h-full'>
        <div className='flex flex-col min-w-[550px]'>
          <PanelHeader className='pr-0 mr-[17px]' title={headerTitle} onSearch={setSearchInput}/>
          <SpinnerLoaderComponent isLoading={isLoading} contentClassName={twMerge('h-full w-full flex-1 overflow-auto', className)}>
            <InspectionDrawingAccordion
              selectedDrawing={selectedDrawing}
              drawings={drawings}
              triggerClassName={twMerge('mr-1', drawingTriggerClassName)}
              contentClassName={drawingContentClassName}
              drawingClassName={twMerge('mt-1', drawingClassName)}
              renderDrawingElements={(drawing: Partial<InspectionDrawing>) => itemsByDrawing[drawing.id!]?.map((item: TItem) => (
                <div key={item.id} className='mt-2 relative flex flex-col justify-center'>
                  <RadCheckbox className='absolute' checked={selectedRows.includes(item)} onCheckedChange={checked => handleItemCheckboxChanged(item, !!checked)}/>
                  <div className='flex-1 ml-6 mr-1'>{renderItem(item, `${IDWG_PREFIX}-${drawing.id}-${drawingStoreIdSuffix ?? ''}`)}</div>
                </div>
              ))}
              getDrawingElementsCount={(drawing: Partial<InspectionDrawing>) => itemsByDrawing[drawing.id!]?.length ?? 0}
              onDisplayDrawingClick={setSelectedDrawing}
            />
          </SpinnerLoaderComponent>
        </div>
        <div ref={viewerContainerRef} className='flex-1'>
          {!!selectedDrawing && <DrawingViewer key={`${IDWG_PREFIX}-${selectedDrawing.id}-${drawingStoreIdSuffix ?? ''}`} canvasDisabled storeId={`${IDWG_PREFIX}-${selectedDrawing.id}-${drawingStoreIdSuffix ?? ''}`} size={viewerContainerRef.current?.clientWidth ?? 300} canvasContainerRef={viewerContainerRef} cmls={(items.length && items[0].__typename === 'Cml' ? itemsByDrawing[selectedDrawing.id!] : undefined) as Partial<Cml>[]} damages={(items.length && items[0].__typename === 'Damage' ? itemsByDrawing[selectedDrawing.id!] : undefined) as Partial<Damage>[]} hasActionList={false} idwg={selectedDrawing}/>}
        </div>
      </div>
    </SingleContentModal>

  );
}
