import {gql} from '@apollo/client/core';
import {CMLS_COMMON_FIELDS, WORKORDER_EVENT_CMLS_COMMON_FIELDS, WORKORDER_EVENTS_COMMON_FIELDS} from '../fragments';

export const WORKORDER_EVENT_CMLS_CREATE = gql`
${WORKORDER_EVENT_CMLS_COMMON_FIELDS}
mutation CreateEventCml($cmlId: Int!, $evtId: Int!) {
  createOneEventCml (data: {cmlId: $cmlId, wrkoId: $evtId}) {
    ...EventCmlCommonFields
    event {
      id
      event
    }
  }
}`;

export const WORKORDER_EVENT_CMLS_GET_BY_ID = gql`
${WORKORDER_EVENT_CMLS_COMMON_FIELDS}
${WORKORDER_EVENTS_COMMON_FIELDS}
query GetEventCmlById($id: Int!) {
  eventCml (where: {id: $id}) {
    ...EventCmlCommonFields
    event {
      ...EventCommonFields
    }
  }
}`;

export const WORKORDER_EVENT_CMLS_UPDATE_BY_ID = gql`
${WORKORDER_EVENT_CMLS_COMMON_FIELDS}
${CMLS_COMMON_FIELDS}
mutation UpdateEventCmlById($id: Int!, $data: EventCmlUncheckedUpdateInput!) {
  updateOneEventCml (where: {id: $id}, data: $data) {
    ...EventCmlCommonFields
    cml {
      ...CmlCommonFields
    }
    event {
      id
      event
    }
  }
}`;

export const WORKORDER_EVENT_CMLS_DELETE = gql`
mutation DeleteEventCml($cmlId: Int!, $evtId: Int!) {
  deleteOneEventCml (where: {wrkoId_cmlId: {cmlId: $cmlId, wrkoId: $evtId}}) {
    id
  }
}`;

export const WORKORDER_EVENT_CMLS_CREATE_MANY = gql`
mutation CreateEventCmls($data: [EventCmlCreateManyInput!]!) {
  createManyEventCml(data: $data) {
    count
  }
}`;

export const WORKORDER_EVENT_CMLS_DELETE_MANY = gql`
mutation DeleteEventCmls($ids: [Int!]) {
  deleteManyEventCml (where: {id: {in: $ids}}) {
    count
  }
}`;

export const WORKORDER_EVENT_CMLS_GET_BY_CML_ID = gql`
${WORKORDER_EVENT_CMLS_COMMON_FIELDS}
query GetEventCmlsByCmlId($cmlId: Int!, $orderBy: [EventCmlOrderByWithRelationInput!]) {
  eventCmls (where: {cmlId: {equals: $cmlId}}, orderBy: $orderBy) {
    ...EventCmlCommonFields
    event {
      id
      event
    }
  }
}`;

export const WORKORDER_EVENT_CMLS_GET_BY_EVT_ID = gql`
${WORKORDER_EVENT_CMLS_COMMON_FIELDS}
${CMLS_COMMON_FIELDS}
query GetEventCmlsByEvtId($evtId: Int!, $orderBy: [EventCmlOrderByWithRelationInput!]) {
  eventCmls (where: {wrkoId: {equals: $evtId}}, orderBy: $orderBy) {
    ...EventCmlCommonFields,
    cml{
      ...CmlCommonFields
      _count {
        pictures
      }
    }
  }
}`;

export const WORKORDER_EVENT_CMLS_GET_LATEST_BY_CML_ID = gql`
${WORKORDER_EVENT_CMLS_COMMON_FIELDS}
query GetLatestEventCmlByCmlId($cmlId: Int!) {
  findFirstEventCml (where: {cmlId: {equals: $cmlId}}, orderBy: [{reportingDate: {sort: desc}}, {id: desc}]) {
    ...EventCmlCommonFields
    event {
      id
      event
    }
  }
}`;

export const WORKORDER_EVENT_CMLS_GET_COUNTER = gql`
query GetWorkorderEventCmlsCounter {
  aggregateEventCml {
    _count {
      id
    }
  }
}
`;

export const WORKORDER_EVENT_CMLS_GET_BY_EVT_CML_ID = gql`
${WORKORDER_EVENT_CMLS_COMMON_FIELDS}
query GetEventCmlByEvtCmlId($evtId: Int!, $cmlId: Int!) {
  eventCml (where: {wrkoId_cmlId: {cmlId: $cmlId, wrkoId: $evtId}}) {
    ...EventCmlCommonFields
  }
}`;
