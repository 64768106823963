import {useLazyQuery} from '@apollo/client';
import SelectionConfirmModal, {TSelectionConfirmModalWithoutListProps} from '@app/components/Modal/Confirm/Selection';
import {GetAllGridsAutocompleteQuery, Grid} from '@app/graphql/__types__/graphql';
import {GRIDS_GET_ALL_AUTOCOMPLETE} from '@app/graphql/requests';
import {TDataRowTreeList} from '@holis/react-ui';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

export default function GridSelectionModal({items, hasItems, ...restProps}: TSelectionConfirmModalWithoutListProps<Partial<Grid>>) {
  const {t} = useTranslation();
  const [getGridsApi, {data, loading, error}] = useLazyQuery<GetAllGridsAutocompleteQuery>(GRIDS_GET_ALL_AUTOCOMPLETE);

  useEffect(() => {
    if (!hasItems) {
      getGridsApi();
    }
  }, []);

  return (
    <SelectionConfirmModal<Partial<Grid>>
      title={t('label.grid')}
      listProps={{
        listProps: {
          isLoading: loading,
          error: error ? t('message.error.default.title') : undefined,
          data: (items ?? data?.grids ?? []) as unknown as TDataRowTreeList[],
          columns: [
            {
              field: 'grid',
              title: t('label.grid'),
              type: 'string',
              width: 250,
            },
            {
              field: 'description',
              title: t('label.description'),
              type: 'string',
              width: 250,
            },
            {
              field: 'sector.site.site',
              title: t('label.site'),
              type: 'string',
              width: 60,
            },
          ],
        },
      }}
      {...restProps}
    />
  );
}
