import {gql} from '@apollo/client';
import {FILE_DRAWINGS_COMMON_FIELDS} from '../fragments';

export const FILE_DRAWINGS_GET_BY_NAME = gql`
${FILE_DRAWINGS_COMMON_FIELDS}
query GetDrawingByName($docName: String!) {
    drawing(where: {docName: $docName}){
        ...FileDrawingCommonFields
    }
}
`;

export const FILE_DRAWINGS_GET_COUNTER = gql`
query GetFileDrawingsCounter {
  aggregateDrawing {
    _count {
      id
    }
  }
}
`;

export const FILE_DRAWINGS_GET_PDF_SVG_COUNTER = gql`
query GetFileDrawingPdfAndSvgCounter {
  aggregateDrawing(where: {OR: [{contentPdf: {not: null}}, {contentSvg: {not: null}}, {path: {not: null}}, {pathSvg: {not: null}}]}) {
    _count {
      id
    }
  }
}
`;

