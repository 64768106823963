import BlockTitle from '@app/components/Common/Text/BlockTitle';
import {TDbTable} from '@app/types/app';
import {useTranslation} from 'react-i18next';
import DbTableCard from './DbTableCard';
import {TDbTablesGroup} from '..';

type TDbGroupedTables = Readonly<{
  item: TDbTablesGroup
}>;

export default function DbGroupedTables({item}: TDbGroupedTables) {
  const {t} = useTranslation();
  const {tables, name} = item;
  return (
    <div className='flex flex-col w-full'>
      <BlockTitle className='border-b mb-4 border-gray-400 font-poppins font-medium text-base text-gray-400'>{t(`label.dbSchemas.${name}.title`)}</BlockTitle>
      <div className='flex flex-row flex-wrap gap-2'>
        {tables?.map((table: TDbTable) => <DbTableCard key={`table-card-${table.name}`} item={{schema: name, ...table}}/>)}
      </div>
    </div>
  );
}
