import {Item, ItemTask, UpdateOneItemTaskByIdMutation} from '@app/graphql/__types__/graphql';
import TaskItem from './TaskItem';
import {useTranslation} from 'react-i18next';
import {EWORKORDER_REF_EVENT_TASK_CATEGORY} from '@app/utils/enums';
import SortableList, {SortableItem} from '@app/components/Common/List/SortableList';
import {useMutation, FetchResult} from '@apollo/client';
import {SCHEDULING_ITEM_TASKS_UPDATE_BY_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useLayoutStore} from '@app/stores/layout';
import useItemStore from '@app/stores/item';

type TTaskList = Readonly<{
    category?: EWORKORDER_REF_EVENT_TASK_CATEGORY,
    item: Partial<Item>;
    tasks?: Partial<ItemTask>[];
    onClickAddTasks?: (items: Partial<ItemTask>[], changeTaskSelectionModalDisplay: (displayed: boolean) => void) => void;
    isDeleteButtonDisabled?: boolean;
    hasActionButtons?: boolean;
    onDeleteClick?: (task: Partial<ItemTask>) => void;
}>

export default function TaskList({tasks, category, hasActionButtons, onDeleteClick}: TTaskList) {
  const {startLoading, stopLoading} = useLayoutStore();
  const {fetchItemTasks} = useItemStore();
  const [updateItemTaskApi] = useMutation<UpdateOneItemTaskByIdMutation>(SCHEDULING_ITEM_TASKS_UPDATE_BY_ID);
  const {t} = useTranslation();
  const handleDeleteItemClick = (task: Partial<ItemTask>) => {
    onDeleteClick?.(task);
  };

  const updateSortHandler = (item: Partial<ItemTask>, newSortVal: number) => {
    startLoading();
    updateItemTaskApi({
      variables: {
        id: item.id!,
        data: {
          sort: {
            set: newSortVal,
          },
        },
      },
    }).then((_result: FetchResult<UpdateOneItemTaskByIdMutation>) => {
      AppNotifications.success(t(`message.success.itemTask${category === EWORKORDER_REF_EVENT_TASK_CATEGORY.SAFETY ? 'Safety' : ''}Updated`));
      fetchItemTasks?.();
    }).catch((error: Error) => {
      console.log(error);
      AppNotifications.error(t('message.error.default.title'));
    }).finally(() => {
      stopLoading();
    });
  };

  const handleSortChange = (dragItem: SortableItem, dropItem: SortableItem, dropIndex: number) => {
    updateSortHandler(dragItem as Partial<ItemTask>, (dropItem as Partial<ItemTask>).sort ?? dropIndex);
  };

  return (
    <div className='flex flex-col gap-1'>
      <SortableList
        items={(tasks ?? []) as SortableItem[]}
        renderItem={(itemTask: SortableItem) => (
          <TaskItem
            key={`task-item-${itemTask.id!}`}
            isDeleteButtonDisabled
            hasDragBtn={(itemTask as ItemTask).task.category !== EWORKORDER_REF_EVENT_TASK_CATEGORY.SAFETY} category={category}
            hasActionButtons={hasActionButtons}
            itemTask={itemTask as ItemTask} onDeleteClick={handleDeleteItemClick}/>
        )}
        onSortChange={handleSortChange}
      />
    </div>
  );
}
