import {useMutation} from '@apollo/client';
import {IConfirmModal} from '@app/components/Modal/Confirm';
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning';
import {DeleteFlocByIdMutation, FunctionalLocation} from '@app/graphql/__types__/graphql';
import {FLOCS_DELETE_BY_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useLayoutStore} from '@app/stores/layout';
import {ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION} from '@app/utils/constants';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

type TDeleteFlocModal = Readonly<IConfirmModal & {
  floc: Partial<FunctionalLocation>;
}>;

export default function DeleteFlocModal({floc, ...restProps}: TDeleteFlocModal) {
  const {startLoading, stopLoading} = useLayoutStore();
  const [deleteFlocApi] = useMutation<DeleteFlocByIdMutation>(FLOCS_DELETE_BY_ID);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const onConfirm = () => {
    startLoading();
    deleteFlocApi({
      variables: {id: floc!.id},
    }).then(() => {
      navigate(ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION);
      AppNotifications.success(t('message.success.deleteFloc'));
    }).catch((_err: Error) => {
      AppNotifications.error(t('message.error.deleteFloc'));
    }).finally(() => {
      stopLoading();
    });
  };

  return (
    <WarningConfirmModal
      closeOnCancel
      description={t('message.questions.deleteFloc')}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      {...restProps}
    />
  );
}
