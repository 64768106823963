import {EWORKORDER_EVENT_STAGE} from '@app/utils/enums';
import React from 'react';
import WorkPackEventList from '../components/WorkPackEventList';
import {ROUTE_WORKPACK_EXEC} from '@app/utils/constants';

export default function WorkPackExecutionPage() {
  return (
    <WorkPackEventList stage={EWORKORDER_EVENT_STAGE.EXECUTION} title='label.execution' route={ROUTE_WORKPACK_EXEC}/>
  );
}
