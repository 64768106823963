import {gql} from '@apollo/client';

export const CML_REF_CODE_GROUP_VAL_CODES_GET_COUNTER = gql`
query GetRefCmlCodeGroupValCodesCounter {
  aggregateRefCmlCodeGroupValCode {
    _count {
      id
    }
  }
}
`;
