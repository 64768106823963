import {useEventStore} from '@app/stores/event';
import {useTranslation} from 'react-i18next';
import {twMerge} from 'tailwind-merge';

export default function ReportHeader() {
  const {activeEvent} = useEventStore();

  if (!activeEvent) {
    return null;
  }

  return (
    <div>
      <div className='grid grid-cols-3 gap-6 bg-gray-50 shadow-sm p-4'>
        <LabelValue label='reportNumber' value={activeEvent.reportNumber as string}/>
        <LabelValue label='reportDescription' value={activeEvent.reportDescription as string} className='col-span-2'/>
        <LabelValue label='inspectedBy' value={activeEvent.inspectorId as string}/>
        <LabelValue label='reviewedBy' value={activeEvent.reviewerId as string}/>
        <LabelValue label='approvedBy' value={activeEvent.approverId as string}/>
      </div>
    </div>
  );
}

function LabelValue({label, value, className}: Readonly<{ label: string; value: string | number | undefined, className?: string}>) {
  const {t} = useTranslation();
  return (
    <div className={twMerge('flex flex-row', className)}>
      <div className='font-semibold text-gray-400'>{t(`label.${label}`)}:</div>
      <div className='ml-4'>{value || '-'}</div>
    </div>
  );
}
