import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import {DEFAULT_LOCALE, LOCALE_EN, LOCALE_FR} from '@utils/constants';
import frTranslationsResources from './resources/fr/translations.json';
import enTranslationsResources from './resources/en/translations.json';
import moment from 'moment-timezone';
import 'moment/locale/fr';

moment.locale(i18next.language);
i18next.use(initReactI18next).init({
  preload: [LOCALE_EN, LOCALE_FR],
  fallbackLng: DEFAULT_LOCALE,
  resources: {
    [LOCALE_FR]: {
      translations: frTranslationsResources,
    },
    [LOCALE_EN]: {
      translations: enTranslationsResources,
    },
  },
  defaultNS: 'translations',
  ns: ['translations'],
  debug: import.meta.env.NODE_ENV === 'development',
});
