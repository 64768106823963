import {gql} from '@apollo/client';
import {INSPECTION_POINTS_COMMON_FIELDS} from '../fragments';

export const INSPECTION_POINTS_GET_COUNTER = gql`
query GetInspectionPointsCounter {
  aggregateInspectionPoint {
    _count {
      id
    }
  }
}
`;

export const INSPECTION_POINTS_CREATE = gql`
${INSPECTION_POINTS_COMMON_FIELDS}
mutation createInspectionPoint($data: InspectionPointUncheckedCreateInput!) {
  createOneInspectionPoint(data: $data) {
    ...InspectionPointCommonFields
  }
}`;

export const INSPECTION_POINTS_GET_BY_FLOC_IDS = gql`
${INSPECTION_POINTS_COMMON_FIELDS}
query GetInspectionPointsByFlocIds($flocIds: [Int!], $orderBy: [InspectionPointOrderByWithRelationInput!]) {
  inspectionPoints(where: {flocId: {in: $flocIds}}, orderBy: $orderBy){
      ...InspectionPointCommonFields
      _count {
        pictures
      }
    }
}`;

export const INSPECTION_POINTS_GET_BY_FLOC_ID = gql`
${INSPECTION_POINTS_COMMON_FIELDS}
query GetInspectionPointsByFlocId($flocId: Int!, $orderBy: [InspectionPointOrderByWithRelationInput!]) {
  inspectionPoints(where: {flocId: {equals: $flocId}}, orderBy: $orderBy){
      ...InspectionPointCommonFields
      _count {
        pictures
      }
    }
}`;

export const INSPECTION_POINTS_UPDATE_BY_ID = gql`
${INSPECTION_POINTS_COMMON_FIELDS}
mutation UpdateInspectionPointById($id: Int!, $data: InspectionPointUncheckedUpdateInput!) {
  updateOneInspectionPoint(data: $data, where: {id: $id}) {
    ...InspectionPointCommonFields
  }
}`;

export const INSPECTION_POINTS_DELETE_BY_ID = gql`
mutation DeleteInspectionPointById($id: Int!) {
  deleteOneInspectionPoint(where: {id: $id}) {
    id
  }
}`;
