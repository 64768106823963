import React, {PropsWithChildren, ReactNode} from 'react';
import {RadDropdownMenu, RadDropdownMenuContent, RadDropdownMenuTrigger} from '@holis/react-ui/rad';
import {BsThreeDots} from 'react-icons/bs';
import {twMerge} from 'tailwind-merge';

export type TActionButtons = Readonly<PropsWithChildren & {
  isSaved?: () => boolean;
  className?: string;
  buttonVariant?: string;
  buttonClassName?: string;
  dropdownClassName?: string;
  dropdownButtonClassName?: string;
  dropdownTransitionClassName?: string;
  hasError?: () => boolean;
  handleCancelEditDataClick?: (_e?: React.MouseEvent<HTMLButtonElement>) => void;
  additionalActions?: React.ReactNode[] | Record<string, React.ReactNode>;
  submitChange?: (_e?: React.MouseEvent<HTMLButtonElement>) => void;
  dropdownPositionFixed?: boolean;
  onMenuDisplayChange?: (menuShown: boolean, containerRef: React.RefObject<HTMLDivElement>) => void;
  menuAlign?: 'start' | 'end' | 'center';
  height?: number;
}>

export default function ActionButtons(props: TActionButtons) {
  const {additionalActions, buttonClassName} = props;
  // If actions is not a list of keyed elements, convert it to a list of keyed elements (where key is index)
  const actions = additionalActions
    ? (Array.isArray(additionalActions) ? additionalActions.reduce((acc: Record<string, ReactNode>, action, index) => {
      acc[index] = action;
      return acc;
    }, {}) : additionalActions)
    : {};

  return (
    <RadDropdownMenu>
      <RadDropdownMenuTrigger className={twMerge('outline-none', buttonClassName, props.height ? `h-${props.height}` : '')}>
        <BsThreeDots size={18}/>
      </RadDropdownMenuTrigger>
      <RadDropdownMenuContent align={props.menuAlign ?? 'start'}>
        {Object.keys(actions).map(key => (
          <React.Fragment key={key}>
            {actions[key]}
          </React.Fragment>
        ))}
      </RadDropdownMenuContent>
    </RadDropdownMenu>
  );
}
