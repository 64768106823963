import {useLazyQuery, useMutation} from '@apollo/client';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import {CreateIdwgFlocsMutation, DeleteIdwgFlocsByIdwgIdAndFlocIdsMutation, FunctionalLocation, GetFlocsByIdwgIdQuery, InspectionDrawing} from '@app/graphql/__types__/graphql';
import {DWG_IDWG_FLOCS_CREATE_MANY, DWG_IDWG_FLOCS_DELETE_MANY_BY_IDWG_ID_AND_FLOC_IDS, FLOCS_GET_BY_IDWG_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import useIdwgStore, {IDWG_PREFIX} from '@app/stores/idwg';
import {useLayoutStore} from '@app/stores/layout';
import {RadDropdownMenuItem} from '@holis/react-ui/rad';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LuBox, LuPlusSquare} from 'react-icons/lu';
import SearchBar from '@app/components/Common/SearchBar';
import FlocSelectionModal from '@app/components/Common/Block/Floc/FlocBlock/FlocSelectionModal';
import FlocCard from '@app/components/Common/Block/Floc/FlocBlock/FlocCard';
import {TDbId} from '@app/types/app';
import {searchArray} from '@app/utils/functions';
import DeleteIdwgFlocModal from '@app/components/Common/Block/InspectionDrawing/DrawingViewer/Floc/DeleteIdwgFlocModal';
import _ from 'lodash';

type TFlocBlock = Readonly<{
  idwg: Partial<InspectionDrawing>;
}>;

export default function FlocBlock({idwg} : TFlocBlock) {
  const [searchInput, setSearchInput] = useState<string>('');
  const [filteredFlocs, setFilteredFlocs] = useState<Partial<FunctionalLocation>[]>();
  const {setFlocs, flocs, flocToDelete, changeFlocSelectionModalDisplay, flocsSelectionDisplayed, pointFlocIds} = useIdwgStore(`${IDWG_PREFIX}${idwg.id}`);
  const [selectedIds, setSelectedIds] = useState<number[]>();
  const {startLoading, stopLoading} = useLayoutStore();
  const [addIdwgFlocsApi] = useMutation<CreateIdwgFlocsMutation>(DWG_IDWG_FLOCS_CREATE_MANY);
  const [deleteIdwgFlocsApi] = useMutation<DeleteIdwgFlocsByIdwgIdAndFlocIdsMutation>(DWG_IDWG_FLOCS_DELETE_MANY_BY_IDWG_ID_AND_FLOC_IDS);
  const [getIdwgFlocsById, {refetch, called}] = useLazyQuery<GetFlocsByIdwgIdQuery>(FLOCS_GET_BY_IDWG_ID, {
    variables: {
      idwgId: idwg.id!,
    },
    fetchPolicy: 'no-cache',
  });
  const {t} = useTranslation();

  const refreshIdwgFlocsList = () => {
    (called ? refetch : getIdwgFlocsById)().then(queryResults => {
      const newFlocs = (queryResults.data?.functionalLocations ?? []) as FunctionalLocation[];
      setFlocs(newFlocs);
    }).catch(_err => {
      AppNotifications.error(t('message.error.default.title'));
    });
  };

  const addItems = async (flocIds: TDbId[]) => addIdwgFlocsApi({
    variables: {
      data: flocIds.map((flocId: TDbId) => ({
        idwgId: idwg.id,
        flocId,
      })),
    },
  });

  const deleteItems = async (flocIds: TDbId[]) => deleteIdwgFlocsApi({
    variables: {
      flocIds,
      idwgId: idwg.id!,
    },
  });

  // Validate seletion modal (Add or delete floc )
  const onValidateSelection = async (selectedItems: Partial<FunctionalLocation>[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[]) => {
    const promises = [];
    if (oldSelectedIds.length) {
      const idwgFlocIds = (flocs?.filter((item: Partial<FunctionalLocation>) => oldSelectedIds.includes(item.id!)).map((item: Partial<FunctionalLocation>) => item.id) ?? []) as TDbId[];
      if (idwgFlocIds.length) {
        promises.push(deleteItems(idwgFlocIds));
      }
    }

    if (newSelectedIds.length) {
      promises.push(addItems(newSelectedIds));
    }

    if (promises.length) {
      startLoading();
      try {
        for (const asyncCall of promises) {
          await asyncCall;
        }

        changeFlocSelectionModalDisplay(false);
        refreshIdwgFlocsList?.();
        AppNotifications.success(t('message.success.idwgFlocsUpdated'));
      } catch (err) {
        AppNotifications.error(t('message.error.default.title'));
      }

      stopLoading();
    }
  };

  useEffect(() => {
    refreshIdwgFlocsList();
  }, [idwg]);

  useEffect(() => {
    setSelectedIds(flocs?.map(item => item.id!));
  }, [flocs]);

  useEffect(() => {
    setFilteredFlocs(searchArray(flocs ?? [], searchInput));
  }, [flocs, searchInput]);

  return (
    <div className='flex flex-col gap-2'>
      <FormGroupHeader
        menuItems={[
          (
            <RadDropdownMenuItem key='manage-cml' onClick={() => changeFlocSelectionModalDisplay(true)}>
              <LuPlusSquare className='mr-2'/>

              {t('label.manageFloc')}
            </RadDropdownMenuItem>
          ),
        ]}
        actions={
          <div className='flex items-center gap-2'>
            {/* <RadButton
              variant='outline'
              className='flex items-center gap-1 text-foreground'
              onClick={() => changeFlocSelectionModalDisplay(true)}
            >
              <LuPlusSquare/>

              {t('label.manageFloc')}
            </RadButton> */}

            <SearchBar
              className='w-[300px]'
              onChange={e => setSearchInput(e.target?.value)}
            />
          </div>
        }
      >
        <div className='flex items-center gap-1'>
          <LuBox/>

          {t('label.floc')}
        </div>
      </FormGroupHeader>

      <div className='flex flex-col gap-2 pl-0'>
        {filteredFlocs?.map(item => (
          <FlocCard
            key={`floc-${item.id!}`}
            hideCreateIanButton
            hasContextMenu
            floc={item as Partial<FunctionalLocation>}
            deleteText={t('label.removeFromDrawing')}
            isSchedulingTag={false}
            // onDeleteClick={() => setFlocToDelete(item as Partial<FunctionalLocation>)}
          />
        ))}
      </div>
      {flocsSelectionDisplayed && <FlocSelectionModal open disabledIds={_.intersection(pointFlocIds, selectedIds ?? [])} filterSites={typeof idwg.sector?.site.site === 'string' ? [idwg.sector?.site.site] : undefined} selectedIds={selectedIds} closeOnConfirm={false} onClose={() => changeFlocSelectionModalDisplay(false)} onValidate={onValidateSelection}/>}
      <DeleteIdwgFlocModal idwg={idwg} open={!!flocToDelete} onDeleted={() => refreshIdwgFlocsList()}/>
    </div>
  );
}
