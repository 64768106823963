import ListModal, {TListModalWithoutListProps} from '@app/components/Modal/List';
import {InspectionPoint} from '@app/graphql/__types__/graphql';
import {TDataRowTreeList} from '@holis/react-ui';
import {useTranslation} from 'react-i18next';

export default function InspectionPointListModal({items, title, ...restProps}: TListModalWithoutListProps<Partial<InspectionPoint>>) {
  const {t} = useTranslation();
  return (
    <ListModal<Partial<InspectionPoint>>
      title={title ?? t('label.inspectionChecklistPointList')}
      listProps={{
        listProps: {
          data: (items ?? []) as unknown as TDataRowTreeList[],
          columns: [
            {
              field: 'status',
              title: '-',
              type: 'string',
              width: 20,
              class: 'status-col',
            },
            {
              field: 'functionalLocation.floc',
              title: t('label.functionalLocation'),
              type: 'string',
              width: 250,
            },
            {
              field: 'position',
              title: t('label.position'),
              type: 'string',
              width: 250,
            },
            {
              field: 'description',
              title: t('label.description'),
              type: 'string',
              width: 250,
            },
            {
              field: 'class.class',
              title: t('label.class'),
              type: 'string',
              width: 60,
            },
            {
              field: 'codeGroup.codeGroup',
              title: t('label.codeGroup'),
              type: 'string',
              width: 60,
            },
          ],
        },
      }}
      {...restProps}
    />
  );
}
