export default class PermissionSchedulingPlans {
  public static resourceName = 'scheduling_plans';

  public display = false;
  public create = false;
  public import = false;
  public schedule = false;
  public reschedule = false;
  public cancel = false;
  public reactivate = false;
}
