import {useFlocStore} from '@app/stores/methodEngineering/floc';
import {useEffect} from 'react';
import PictureTab from '@app/components/Common/Block/Picture/Block/PictureTab';

type TProps = Readonly<{
  visible: boolean;
}>;

export default function PicturePage({visible}: TProps) {
  const {pictures, activeFloc, fetchPictures} = useFlocStore();

  useEffect(() => {
    fetchPictures?.();
  }, [fetchPictures]);

  return (
    <PictureTab
      isLoading={typeof pictures === 'undefined'}
      pictures={pictures}
      className={visible ? '' : 'hidden'}
      uploadParameters={{flocId: activeFloc!.id}}
      onPicturesChanged={fetchPictures}
    />
  );
}
