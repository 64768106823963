import ReportHeader from './components/ReportHeader';
import ReportSummary from './components/ReportSummary';
import IanList from './components/IanList/IanList';
import AttachmentList from './components/AttachmentList';
import {RightPanelPage} from '../..';
import IntegrityStatuses from './components/IntegrityStatuses';

export default function ReportPage({visible, readonly}: RightPanelPage) {
  return (
    <div className={`py3 gap-4 flex flex-col ${visible ? '' : 'hidden'}`}>

      <ReportHeader/>

      <ReportSummary readonly={readonly}/>

      <IntegrityStatuses readonly={readonly}/>

      <IanList readonly={readonly}/>

      <AttachmentList readonly={readonly}/>
    </div>
  );
}
