import {gql} from '@apollo/client';
import {SCHEDULING_ITEMS_COMMON_FIELDS, SCHEDULING_ITEM_TASKS_COMMON_FIELDS, WORKORDER_REF_EVENT_TASKS_COMMON_FIELDS} from '../fragments';

export const SCHEDULING_ITEMS_GET_BY_PLAN_ID = gql`
${SCHEDULING_ITEMS_COMMON_FIELDS}
${WORKORDER_REF_EVENT_TASKS_COMMON_FIELDS}
${SCHEDULING_ITEM_TASKS_COMMON_FIELDS}
query GetItemsByPlanId($planId: Int){
  items(where: {planId: {equals: $planId}}, orderBy: [{id: desc}]){
    ...ItemCommonFields,
    itemTasks {
      ...ItemTaskCommonFields
      task {
        ...RefEventTaskCommonFields
      }
    }
  }
}
`;

export const SCHEDULING_ITEMS_CREATE = gql`
${SCHEDULING_ITEMS_COMMON_FIELDS}
mutation createItem($data: ItemUncheckedCreateInput!) {
  createOneItem(data: $data) {
    ...ItemCommonFields
  }
}`;

export const SCHEDULING_ITEMS_UPDATE_BY_ID = gql`
${SCHEDULING_ITEMS_COMMON_FIELDS}
mutation UpdateItemById($id: Int!, $data: ItemUncheckedUpdateInput!) {
  updateOneItem(data: $data, where: {id: $id}) {
    ...ItemCommonFields
  }
}`;

export const SCHEDULING_ITEMS_DELETE_BY_ID = gql`
mutation DeleteItemById($id: Int!) {
  deleteOneItem(where: {id: $id}) {
    id
  }
}`;

export const SCHEDULING_ITEMS_GET_DETAIL_BY_ID = gql`
${SCHEDULING_ITEMS_COMMON_FIELDS}
query getItemDetailById($id: Int!) {
  item(where: {id: $id}) {
    ...ItemCommonFields,
  }
}`;

export const SCHEDULING_ITEMS_GET_COUNTER = gql`
query GetItemsCounter {
  aggregateItem {
    _count {
      id
    }
  }
}
`;
