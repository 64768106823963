import {gql} from '@apollo/client';
import {INTEGRITY_REF_CONDITIONS_COMMON_FIELDS} from './refIntegrityConditions';
import {INTEGRITY_REF_CONSEQUENCES_COMMON_FIELDS} from './refIntegrityConsequences';

export const INTEGRITY_REF_MATRICES_COMMON_FIELDS = gql`
    ${INTEGRITY_REF_CONDITIONS_COMMON_FIELDS}
    ${INTEGRITY_REF_CONSEQUENCES_COMMON_FIELDS}
    fragment RefIntegrityMatrixCommonFields on RefIntegrityMatrix {
        consequence
        refIntCons {
            ...RefIntegrityConsequenceCommonFields
        }
        condition
        refIntCond {
            ...RefIntegrityConditionCommonFields
        }
        description
        color
    }
`;
