import {gql} from '@apollo/client';
import {SCHEDULING_PLAN_VIEWS_COMMON_FIELDS, SCHEDULING_PLANS_COMMON_FIELDS, SCHEDULING_PLANS_COUNTER_FIELDS, WORKORDER_EVENTS_COMMON_FIELDS} from '../fragments';

export const SCHEDULING_PLANS_GET_ALL = gql`
${SCHEDULING_PLANS_COMMON_FIELDS}
query GetAllPlans($orderBy: [PlanOrderByWithRelationInput!]) {
  plans (orderBy: $orderBy) {
    ...PlanCommonFields
  }
}`;

export const SCHEDULING_PLAN_VIEWS_GET_ALL = gql`
${SCHEDULING_PLAN_VIEWS_COMMON_FIELDS}
query GetAllPlanViews($sites: [String!], $orderBy: [PlanViewOrderByWithRelationInput!]) {
  planViews (where:  {
     floc_sector_site_site:  {
        in: $sites
     }
  } orderBy: $orderBy) {
    ...PlanViewCommonFields
  }
}`;

export const SCHEDULING_PLANS_CREATE = gql`
${SCHEDULING_PLANS_COMMON_FIELDS}
mutation createPlan($data: PlanUncheckedCreateInput!) {
  createOnePlan(data: $data) {
    ...PlanCommonFields
  }
}`;

export const SCHEDULING_PLANS_UPDATE_BY_ID = gql`
${SCHEDULING_PLANS_COMMON_FIELDS}
mutation UpdatePlanById($id: Int!, $data: PlanUncheckedUpdateInput!) {
  updateOnePlan(data: $data, where: {id: $id}) {
    ...PlanCommonFields
  }
}`;

export const SCHEDULING_PLANS_DELETE_BY_ID = gql`
mutation DeletePlanById($id: Int!) {
  deleteOnePlan(where: {id: $id}) {
    id
  }
}`;

export const SCHEDULING_PLANS_GET_DETAIL_BY_ID = gql`
${SCHEDULING_PLANS_COMMON_FIELDS}
${SCHEDULING_PLANS_COUNTER_FIELDS}
query getPlanDetailById($id: Int!) {
  plan(where: {id: $id}) {
    ...PlanCommonFields
    ...PlanCounterFields
  }
}`;

export const SCHEDULING_PLANS_GET_DETAIL_BY_NUMBER = gql`
${SCHEDULING_PLANS_COMMON_FIELDS}
${SCHEDULING_PLANS_COUNTER_FIELDS}
query getPlanDetailByNumber($number: String!) {
  plan(where: {plan: $number}) {
    ...PlanCommonFields
    ...PlanCounterFields
  }
}`;

export const SCHEDULING_PLANS_SCHEDULE_PLAN_EVENTS = gql`
${WORKORDER_EVENTS_COMMON_FIELDS}
mutation scheduleInspectionPlanEvents($id: Int!, $isSimulation: Boolean!) {
  scheduleInspectionPlan(planId: $id, isSimulation: $isSimulation) {
    ...EventCommonFields
  }
}`;

export const SCHEDULING_PLANS_GET_COUNTER = gql`
query GetPlansCounter {
  aggregatePlan {
    _count {
      id
    }
  }
}
`;
