import React from 'react';
import {SchedulingBadge} from '..';
import EventListPage from '@app/components/Common/Block/Event/EventListPage';
import {ROUTE_SCHEDULING_INSPECTION_HISTORY} from '@app/utils/constants';

export default function InspectionHistory() {
  return (
    <EventListPage title='label.events' badge={<SchedulingBadge/>} route={ROUTE_SCHEDULING_INSPECTION_HISTORY}/>
  );
}
