import {TDataRowTreeList} from '@holis/react-ui';
import {TTreeList} from '@holis/react-ui';
import React, {useEffect, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import SearchBar from '../../SearchBar';
import {throttle} from 'lodash';
import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent';
import {useTranslation} from 'react-i18next';
import FormGroupHeader from '../../Form/FormGroupHeader';
import _ from 'lodash';
import styled from 'styled-components';
import NoResult from '../../Text/NoResult';

export type TBorderedTable = Readonly<{
  className?: string;
  headerClassName?: string;
  title?: React.ReactNode | ((data: TDataRowTreeList[]) => React.ReactNode);
  hasSearchBar?: boolean;
  searchFilter?: (data: TDataRowTreeList[], searchText: string) => TDataRowTreeList[];
  leftSearchBarComponent?: React.ReactNode;
  beforeTableComponent?: React.ReactNode;
  rightSearchBarComponent?: React.ReactNode;
  stopWindowResizeTrigger?: boolean;
  stickyHeaders?: boolean;
  searchInputClassName?: string;
  onRowClick?: (rowData: TDataRowTreeList) => void;
  listProps: {
    minimumColumnWidth?: number;
    filterTimeout?: number;
    isLoading?: boolean;
    error?: React.ReactNode;
    className?: string;
  } & Omit<TTreeList, 'minimumColumnWidth'|'filterTimeout'>
}>

const StyledTable = styled.table`
  tr.clickable:hover .inner-td-div {
    background-color: #f3f4f6;  
    cursor: pointer;
  }
`;

export default function BorderedTable({className, searchInputClassName, stickyHeaders, headerClassName, listProps, title, hasSearchBar, leftSearchBarComponent, rightSearchBarComponent, stopWindowResizeTrigger, searchFilter, onRowClick, beforeTableComponent}: TBorderedTable) {
  const {t} = useTranslation();
  const {isLoading, error, data, ...restListProps} = listProps ?? {};
  const [searchText, setSearchText] = useState<string>('');
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const triggerWindowResize = () => {
    if (stopWindowResizeTrigger) {
      return;
    }

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  };

  useEffect(() => {
    if (!isLoading && !error) {
      triggerWindowResize();
    }
  }, [data, isLoading, error]);

  const getTdClassName = (colIndex: number) => {
    let cn = 'border-y my-0.5 h-full flex flex-col justify-center align-start p-1 min-h-11 px-1.5';
    if (colIndex === 0) {
      cn += ' border-l rounded-l-md';
    }

    if (colIndex === listProps.columns.length - 1) {
      cn += ' border-r rounded-r-md';
    }

    return cn;
  };

  const getThStickyClassName = () => stickyHeaders ? 'sticky -top-1 bg-white z-10 py-1' : '';

  const filteredData = searchFilter ? searchFilter(data ?? [], searchText) : data;

  return (
    <div className={twMerge('flex flex-col w-full relative min-h-[150px]', className)}>
      {(title || hasSearchBar) && <div className={twMerge('flex justify-between items-center py-2', headerClassName)}>
        <FormGroupHeader>{typeof title === 'function' ? title(data) : title}</FormGroupHeader>
        <div className='flex justify-end items-center gap-2'>
          {leftSearchBarComponent}
          {hasSearchBar && <SearchBar className={twMerge('min-w-[280px]', searchInputClassName)} value={searchText} onChange={throttle(handleSearch, 500)}/>}
          {rightSearchBarComponent}
        </div>
      </div>}
      {beforeTableComponent}
      <SpinnerLoaderComponent className={twMerge('w-full h-full bg-white/20 flex-grow min-h-[50px]', listProps.className)} isLoading={isLoading} error={error === true ? t('message.error.default.title') : error}>
        {!error && !isLoading
          && <StyledTable className='w-full h-full border-collapse min-h-[80px]'>

            <thead>
              <tr>
                {restListProps.columns.map(column => (
                  <th key={`head-${column.field}`} className={twMerge('font-normal text-xs text-gray-600 pb-1 pl-1 text-start align-bottom', getThStickyClassName())} style={{width: column.width}}>
                    {column.title}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {filteredData?.length ? filteredData.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={`ìtem-row${index}`} className={onRowClick ? 'clickable' : ''} onClick={() => onRowClick?.(item)}>
                  {restListProps.columns.map((column, colIndex) => (
                    <td key={`col-${column.field}`} className='p-0' style={{width: column.width}}>
                      <div className={'inner-td-div ' + getTdClassName(colIndex)}>
                        {column.cellRenderer && listProps.cellRenderers ? listProps.cellRenderers[column.cellRenderer]?.(_.get(item, column.field), item) : _.get(item, column.field)}
                      </div>
                    </td>
                  ))}
                </tr>
              )) : <tr><td colSpan={restListProps.columns.length}><NoResult/></td></tr>}
            </tbody>

          </StyledTable>}
      </SpinnerLoaderComponent>
    </div>
  );
}
