import {Plan} from '@app/graphql/__types__/graphql';
import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

type State = {
  plans?: Partial<Plan>[];
  fetchPlans?: () => void;
};

const initialState: State = {
  plans: undefined,
};

export const MAX_LENGTH_VALIDATORS = {
  DESCRIPTION: 50,
  NOTES: 600,
  PLAN: 45,
};

type Actions = {
  setPlans: (plans?: Partial<Plan>[]) => void;
  setFetchPlans: (fetchPlans?: () => void) => void;
};

type SchedulingInspectionPlanState = State & Actions;

const useSchedulingInspectionPlanStore = create<SchedulingInspectionPlanState>()(
  immer(set => ({
    ...initialState,
    setPlans(plans) {
      set({plans});
    },
    resetData() {
      set({
        ...initialState,
      });
    },
    setFetchPlans(fetchPlans) {
      set({fetchPlans});
    },
  })),
);

export default useSchedulingInspectionPlanStore;
