import {IntegrityStatus, UpdateIntegrityStatusByIdMutation} from '@app/graphql/__types__/graphql';
import {FORMAT_DATE_EU, INTEGRITY_CONDITION_COLOR} from '@app/utils/constants';
import {RadContextMenu, RadContextMenuTrigger, RadContextMenuContent, RadContextMenuItem} from '@holis/react-ui/rad';
import moment from 'moment';
import {LuExternalLink, LuCalendarDays, LuLineChart} from 'react-icons/lu';
import ItemCard, {ItemCardContent} from '../../ItemCard';
import MeasurementValueContainer from '../../ItemCard/MeasurementValueContainer';
import LabelValueRow from '@app/components/Common/Form/LabelValueRow';
import {TextareaHTMLAttributes, useEffect, useState} from 'react';
import {useMutation} from '@apollo/client';
import {INTEGRITY_STATUSES_UPDATE_BY_ID} from '@app/graphql/requests';
import _ from 'lodash';
import AppNotifications from '@app/services/notification';
import {useTranslation} from 'react-i18next';
import {useLayoutStore} from '@app/stores/layout';

type TIntegrityStatusCard = Readonly<{
  integrityStatus: Partial<IntegrityStatus>;
  hasMenuContext?: boolean;
  className?: string;
}>;
export default function IntegrityStatusCard({integrityStatus, className, hasMenuContext}: TIntegrityStatusCard) {
  const floc = integrityStatus.functionalLocation!;
  const [ints, setInts] = useState<Partial<IntegrityStatus>>();
  const [notes, setNotes] = useState<string>();
  const {startLoading, stopLoading} = useLayoutStore();
  const {t} = useTranslation();
  const [updateIntegrityStatusApi] = useMutation<UpdateIntegrityStatusByIdMutation>(INTEGRITY_STATUSES_UPDATE_BY_ID);
  const handleNotesBlur = () => {
    if (!_.isEqual(notes ?? '', ints?.notes ?? '')) {
      startLoading();
      updateIntegrityStatusApi({
        variables: {
          id: integrityStatus.id,
          data: {
            notes: {
              set: notes,
            },
          },
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => {
        AppNotifications.success(t('message.success.intsUpdated'));
        setInts(queryResult.data?.updateOneIntegrityStatus as Partial<IntegrityStatus>);
      }).catch(() => {
        AppNotifications.error(t('message.error.default.title'));
      }).finally(stopLoading);
    }
  };

  useEffect(() => {
    setInts(integrityStatus);
  }, [integrityStatus.id]);

  useEffect(() => {
    setNotes(ints?.notes ?? '');
  }, [ints]);

  return (
    <RadContextMenu>
      <RadContextMenuTrigger disabled={!hasMenuContext}>
        <ItemCard className={className}>

          <ItemCardContent className='flex items-center gap-2'>

            <div className='flex-1 flex flex-col py-1 gap-1 self-stretch text-base'>
              <div className='flex gap-1 items-center'>
                <MeasurementValueContainer measurementValue={integrityStatus.integrityCondition?.condition} measurementColor={integrityStatus.integrityCondition?.color ?? INTEGRITY_CONDITION_COLOR as string}/>
                {/* {floc.techClass && <RadBadge className='bg-primary text-white'>{floc.techClass.techClass}</RadBadge>} */}
                <div className='flex flex-col gap-1'>
                  <div className='font-semibold'>{floc.floc}</div>
                  <div>{floc.description}</div>
                </div>
              </div>
              <LabelValueRow
                field='notes'
                className='my-2'
                inputComponent='textarea'
                value={notes}
                label={false}
                inputProps={{
                  rows: 3,
                  className: 'border-0 p-[10px] hover:border hover:p-[9px] focus:p-[9px] hover:bg-gray-50 focus:border',
                  onBlur: () => handleNotesBlur(),
                } as TextareaHTMLAttributes<HTMLTextAreaElement>}
                handleFieldChange={(field, value) => setNotes(String(value))}
              />
              <div className='flex flex2 text-gray-500'>
                <div className='flex items-center gap-1'><LuCalendarDays/><span className='mr-4'>{t('label.maxEndDate')}</span> <span className='font-bold text-sm'>{integrityStatus.maxEndDate ? moment.parseZone(integrityStatus.maxEndDate!).format(FORMAT_DATE_EU) : '-'}</span></div>
                <div className='flex items-center gap-1 ml-12'><LuLineChart/><span className='mr-4'>{t('label.corrRateExt')}</span> <span className='font-bold text-sm'>{integrityStatus.corrRateExt ?? '-'}</span></div>
                <div className='flex items-center gap-1 ml-12'><LuLineChart/><span className='mr-4'>{t('label.corrRateInt')}</span> <span className='font-bold text-sm'>{integrityStatus.corrRateInt ?? '-'}</span></div>
              </div>
            </div>
          </ItemCardContent>

        </ItemCard>
      </RadContextMenuTrigger>
      <RadContextMenuContent>
        <RadContextMenuItem>
          <LuExternalLink className='mr-2'/> {t('label.open')}
        </RadContextMenuItem>
      </RadContextMenuContent>
    </RadContextMenu>
  );
}
