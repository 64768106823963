import {useLazyQuery, useQuery} from '@apollo/client';
import BorderedTable from '@app/components/Common/TreeList/BorderedTable';
import {IConfirmModal} from '@app/components/Modal/Confirm';
import {GetAllNotificationStatusesQuery, GetNotificationWorkflowsByNotifIdQuery, Notification, NotificationWorkflow} from '@app/graphql/__types__/graphql';
import {APP_NOTIFICATION_STATUSES_GET_MANY, NOTIFICATION_WORKFLOWS_GET_BY_NOTIF_ID} from '@app/graphql/requests';
import {FORMAT_DATETIME} from '@app/utils/constants';
import {IColumn, TDataRowTreeList} from '@holis/react-ui';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {useEffect, useMemo} from 'react';
import useNotificationStore from '@app/stores/notification';
import SingleContentModal from '@app/components/Modal/SingleFormModal';

type TNotificationWorkflowTable = Readonly<IConfirmModal & {
  notification: Partial<Notification>;
}>;

export default function NotificationWorkflowTableModal({notification, ...restProps}: TNotificationWorkflowTable) {
  const {t} = useTranslation();
  const {changeWorkflowModalDisplay} = useNotificationStore();
  const [getNotifWorkflowsApi, {data, loading, error}] = useLazyQuery<GetNotificationWorkflowsByNotifIdQuery>(NOTIFICATION_WORKFLOWS_GET_BY_NOTIF_ID);

  const notificationStatusesResult = useQuery<GetAllNotificationStatusesQuery>(APP_NOTIFICATION_STATUSES_GET_MANY);

  const cellRenderers = useMemo(() => ({
    date(_val: string, rowData: unknown) {
      const dataRow = (rowData as Partial<NotificationWorkflow>);
      return <span>{dataRow.date ? moment(dataRow.date).format(FORMAT_DATETIME) : ''}</span>;
    },
    status(val: string) {
      return <>{val.replace(/\|/g, ' => ')}</>;
    },
  }), [notificationStatusesResult]);

  const columns: IColumn[] = [
    {
      field: 'date',
      title: t('label.date'),
      type: 'string',
      cellRenderer: 'date',
      width: 200,
      filter: false,
    },
    {
      field: 'userLogin',
      title: t('label.user'),
      type: 'string',
      width: 140,
      filter: false,
    },
    {
      field: 'status',
      title: t('label.status'),
      type: 'string',
      width: 125,
      filter: false,
      cellRenderer: 'status',
    },
    {
      field: 'description',
      title: t('label.description'),
      type: 'string',
      filter: false,
    },
  ];

  const handleCloseModal = () => {
    changeWorkflowModalDisplay(false);
  };

  useEffect(() => {
    getNotifWorkflowsApi({
      variables: {
        notifId: notification.id,
      },
      fetchPolicy: 'no-cache',
    });
  }, [notification]);

  return (
    <SingleContentModal
      containerClassName='max-w-[1400px] min-w-[200px] max-h-[90%] h-full'
      isOpen={restProps.open}
      title={`${t('label.workflowForNotification')}: ${notification.notif}`}
      onOpenChange={opened => {
        if (!opened) {
          handleCloseModal();
        }
      }}>
      <BorderedTable
        listProps={{
          columns,
          isLoading: loading,
          error: !!error,
          data: (data?.notificationWorkflows ?? []) as unknown as TDataRowTreeList[],
          cellRenderers,
        }}/>
    </SingleContentModal>
  );
}
