import {gql} from '@apollo/client';
import {FILE_PICTURES_COMMON_FIELDS, FILE_PICTURES_DETAIL_FIELDS} from '../fragments/pictures';

export const PICTURES_GET_MANY_BY = gql`
${FILE_PICTURES_DETAIL_FIELDS}
query GetPicturesBy($data: PictureWhereInput!, $orderBy: [PictureOrderByWithRelationInput!]) {
    pictures(where: $data, orderBy: $orderBy){
        ...FilePictureDetailFields
    }
}`;

export const PICTURES_GET_LATEST_BY = gql`
${FILE_PICTURES_DETAIL_FIELDS}
query GetLatestPictureBy($data: PictureWhereInput!) {
    findFirstPicture(where: $data, orderBy: {date: {sort: desc}}, skip:0, take: 1){
        ...FilePictureDetailFields
    }
}`;

export const PICTURES_GET_BY_CML_ID = gql`
${FILE_PICTURES_DETAIL_FIELDS}
query GetPicturesByCmlId($cmlId: Int!, $orderBy: [PictureOrderByWithRelationInput!]) {
    pictures(where: {cmlId: {equals: $cmlId}}, orderBy: $orderBy){
        ...FilePictureDetailFields
    }
}`;

export const PICTURES_DELETE_BY_ID = gql`
mutation DeletePictureById($id: Int!) {
  deleteOnePicture(where: {id: $id}) {
    id
  }
}`;

export const PICTURES_UPDATE_BY_ID = gql`
${FILE_PICTURES_DETAIL_FIELDS}
mutation UpdatePictureById($id: Int!, $data: PictureUncheckedUpdateInput!) {
  updateOnePicture(data: $data, where: {id: $id}) {
    ...FilePictureDetailFields
  }
}`;

export const PICTURES_GET_BY_EVT_OBJ_IDS = gql`
${FILE_PICTURES_DETAIL_FIELDS}
query GetEventPicturesByObjIds($evtId: Int!, $cmlIds: [Int!], $dmgIds: [Int!], $inspInds: [Int!],  $orderBy: [PictureOrderByWithRelationInput!]) {
    pictures(where: {OR: [{wrkoId: {equals: $evtId}}, {cmlId: {in: $cmlIds}}, {dmgeId: {in: $dmgIds}}, {inspId: {in: $inspInds}}]}, orderBy: $orderBy){
        ...FilePictureDetailFields
    }
}`;

export const PICTURES_GET_LATEST_BY_CML_ID = gql`
${FILE_PICTURES_COMMON_FIELDS}
query GetLatestPictureByCmlId($cmlId: Int!) {
    findFirstPicture(where: {cmlId: {equals: $cmlId}}, orderBy: {date: {sort: desc}}, skip:0, take: 1){
        ...FilePictureCommonFields
    }
}`;

export const PICTURES_GET_BY_DAMAGE_ID = gql`
${FILE_PICTURES_DETAIL_FIELDS}
query GetPicturesByDmgId($damageId: Int!, $orderBy: [PictureOrderByWithRelationInput!]) {
    pictures(where: {dmgeId: {equals: $damageId}}, orderBy: $orderBy){
        ...FilePictureDetailFields
    }
}`;

export const PICTURES_GET_BY_INSP_ID = gql`
${FILE_PICTURES_DETAIL_FIELDS}
query GetPicturesByInspId($inspId: Int!, $orderBy: [PictureOrderByWithRelationInput!]) {
    pictures(where: {inspId: {equals: $inspId}}, orderBy: $orderBy){
        ...FilePictureDetailFields
    }
}`;

export const PICTURES_GET_LATEST_BY_DAMAGE_ID = gql`
${FILE_PICTURES_COMMON_FIELDS}
query GetLatestPictureByDmgId($dmgId: Int!) {
    findFirstPicture(where: {dmgeId: {equals: $dmgId}}, orderBy: [{date: {sort: desc}}, {id: desc}], skip:0, take: 1){
        ...FilePictureCommonFields
    }
}`;

export const PICTURES_GET_BY_EVT_ID = gql`
${FILE_PICTURES_DETAIL_FIELDS}
query GetPicturesByEvtId($evtId: Int!, $orderBy: [PictureOrderByWithRelationInput!]) {
    pictures(where: {wrkoId: {equals: $evtId}}, orderBy: $orderBy){
        ...FilePictureDetailFields
    }
}`;

export const PICTURES_GET_BY_FLOC_ID = gql`
${FILE_PICTURES_DETAIL_FIELDS}
query GetPicturesByFlocId($flocId: Int!, $orderBy: [PictureOrderByWithRelationInput!]) {
    pictures(where: {flocId: {equals: $flocId}}, orderBy: $orderBy){
        ...FilePictureDetailFields
    }
}`;

export const PICTURES_GET_COUNTER = gql`
query GetPicturesCounter {
  aggregatePicture {
    _count {
      id
    }
  }
}
`;
