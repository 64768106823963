import {OBJ_NEW_ID} from '@app/utils/constants';
import {useTranslation} from 'react-i18next';
import {twMerge} from 'tailwind-merge';
import Separator from '../../Separator';

type TIdText = Readonly<{
  id?: string | null | number;
  className?: string;
  hasSeparator?: boolean;
}>;

export default function IdText({id, className, hasSeparator}: TIdText) {
  const {t} = useTranslation();
  return (
    <>
      <span className={twMerge('text-black font-semibold text-sm border-gray-300', className)}>{`${id && id !== OBJ_NEW_ID ? t('label.idText', {id}) : ''}`}</span>
      {hasSeparator && id && id !== OBJ_NEW_ID && <Separator height='70%'/>}
    </>
  );
}
