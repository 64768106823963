import {AuthUser} from '@holis/auth-client';
import {useTranslation} from 'react-i18next';
import {RadButton, RadDialogClose} from '@holis/react-ui/rad';

type TFooter = {
    readonly user: Partial<AuthUser>;
    readonly onSaveClicked?: () => void;
}

export default function Footer({user, onSaveClicked}: TFooter) {
  const {t} = useTranslation();

  return (
    <>
      <div className='flex-1 self-end text-muted-foreground ml-2'>
        # ID: {user?.id}
      </div>
      <RadDialogClose asChild>
        <RadButton variant='outline'>
          {t('label.cancel')}
        </RadButton>
      </RadDialogClose>

      <RadButton onClick={onSaveClicked}>
        {t('label.save')}
      </RadButton>
    </>
  );
}
