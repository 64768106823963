import Separator from '@app/components/Common/Separator';
import IdText from '@app/components/Common/Text/IdText';
import DetailsPageFooter from '@app/components/Layout/DetailsPagefooter';
import {EFlagStatus, FunctionalLocation} from '@app/graphql/__types__/graphql';
import {RadBadge} from '@holis/react-ui/rad';
import {useTranslation} from 'react-i18next';

type TProps = Readonly<{
  readonly floc: Partial<FunctionalLocation>;
}>;

export default function Footer({floc}: TProps) {
  const {t} = useTranslation();

  return (
    <DetailsPageFooter>
      <RadBadge variant='outline' className='py-1 uppercase font-normal text-blue-700 bg-blue-200 border-blue-700'>
        {t(`label.flagStatus.${floc.flagStatus ?? EFlagStatus.A}`)}
      </RadBadge>

      <Separator/>

      <IdText id={floc.id}/>
    </DetailsPageFooter>
  );
}
