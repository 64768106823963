import {gql} from '@apollo/client';

export const FLOC_CATALOGS_COMMON_FIELDS = gql`
    fragment RefFlocCatalogsCommonFields on RefFlocCatalogs {
        id
        description
        code
        category
        isActive
    }
`;
