import {gql} from '@apollo/client';

export const FILE_DOCS_COMMON_FIELDS = gql`
    fragment FileDocCommonFields on Doc {
        id
        extension
        docName
        contentType
        createdAt
        updatedAt
        fsAdapter
        path
    }
`;
