import {RadButton, RadDropdownMenu, RadDropdownMenuContent, RadDropdownMenuTrigger} from '@holis/react-ui/rad';
import React, {ReactNode} from 'react';
import {BsThreeDots} from 'react-icons/bs';

export type TActionThreeDotsMenu = Readonly<{
	children: ReactNode;
	menuAlign?: 'start' | 'end' | 'center';
  height?: number;
}>;

export default function ActionThreeDotsMenu({children, menuAlign, height}: TActionThreeDotsMenu) {
  return (
    <RadDropdownMenu>
      <RadDropdownMenuTrigger>
        <RadButton size='icon' variant='ghost' className={height ? `h-${height}` : ''}>
          <BsThreeDots size={18}/>
        </RadButton>
      </RadDropdownMenuTrigger>
      <RadDropdownMenuContent align={menuAlign}>
        {children}
      </RadDropdownMenuContent>
    </RadDropdownMenu>
  );
}
