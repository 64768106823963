import {useEventStore} from '@app/stores/event';
import {t} from 'i18next';
import {RightPanelPage} from '../..';
import {useMutation} from '@apollo/client';
import {UpdateEventByIdMutation} from '@app/graphql/__types__/graphql';
import {WORKORDER_EVENTS_UPDATE_BY_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import ReactQuill from 'react-quill';
import {useState} from 'react';

export default function LongDescriptionPage({visible, readonly}: RightPanelPage) {
  const {editEvent, updateEvent} = useEventStore();
  const [quillContent, setQuillContent] = useState<string>(editEvent?.longDescription ?? '');
  const [updateEventByIdApi] = useMutation<UpdateEventByIdMutation>(WORKORDER_EVENTS_UPDATE_BY_ID);

  const updateEventDescription = async (longDescription: string) => {
    if (longDescription.trim() === editEvent?.longDescription?.trim()) {
      return;
    }

    const updatedEvent = {...editEvent};
    updatedEvent.longDescription = longDescription;
    try {
      await updateEventByIdApi({
        variables: {
          id: updatedEvent.id,
          data: {
            longDescription: {set: updatedEvent.longDescription},
          },
        },
      });
      updateEvent(updatedEvent);
      AppNotifications.success(t('message.success.eventUpdated'));
    } catch (e) {
      AppNotifications.error(t('message.error.default.title'));
    }
  };

  return (
    <div className={(visible ? '' : 'hidden') + ' h-full'}>
      <div className='h-full'>
        <ReactQuill
          key={editEvent?.longDescription}
          readOnly={readonly}
          className={`mr-2 relative${readonly ? ' opacity-50' : ''}`} theme='snow' defaultValue={quillContent}
          style={{height: 'calc(100% - 3rem)'}}
          onChange={setQuillContent}
          onBlur={_ => {
            updateEventDescription(quillContent.trim());
          }}/>
      </div>
    </div>
  );
}
