import React, {useLayoutEffect} from 'react';

export default function useResizeObserver<T extends HTMLElement>(
  ref: React.RefObject<T>,
  callback: (entry: ResizeObserverEntry) => void,
) {
  useLayoutEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const observer = new ResizeObserver(entries => {
      callback(entries[0]);
    });

    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [callback, ref]);
}
