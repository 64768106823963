export type TClassPositionContainer = Readonly<{
	class?: string;
	position?: string;
}>;

export default function ClassPositionContainer(props : TClassPositionContainer) {
  return (
    <div className='self-stretch flex flex-col justify-center rounded-md bg-blue-50 p-2 min-w-14 text-center'>
      <div className='text-primary font-extrabold'>{props.class}</div>
      <div className='font-extrabold text-xs'>{props.position}</div>
    </div>
  );
}
