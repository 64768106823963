import {gql} from '@apollo/client';

export const FLOC_REF_CLASSES_GET_COUNTER = gql`
query GetRefFlocClassesCounter {
  aggregateRefFlocClass {
    _count {
      id
    }
  }
}
`;
