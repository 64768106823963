import {gql} from '@apollo/client';
import {FLOC_CATALOG_PROFILES_COMMON_FIELDS} from '../fragments';

export const FLOC_REF_CATALOG_PROFILES_GET_MANY = gql`
${FLOC_CATALOG_PROFILES_COMMON_FIELDS}
query GetAllRefFlocCatalogProfiles {
  refFlocCatalogProfiles {
    ...RefFlocCatalogProfileCommonFields
  }
}`;

export const FLOC_REF_CATALOG_PROFILES_GET_COUNTER = gql`
query GetRefFlocCatalogProfilesCounter {
  aggregateRefFlocCatalogProfile {
    _count {
      id
    }
  }
}
`;
