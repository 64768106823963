import {gql} from '@apollo/client';
import {SCHEDULING_REF_PLAN_TYPES_COMMON_FIELDS} from '../fragments';

export const SCHEDULING_REF_PLAN_TYPES_GET_MANY = gql`
${SCHEDULING_REF_PLAN_TYPES_COMMON_FIELDS}
query GetAllRefPlanTypes($orderBy: [RefPlanTypeOrderByWithRelationInput!], $where: RefPlanTypeWhereInput) {
    refPlanTypes(orderBy: $orderBy, where: $where) {
      ...RefPlanTypeCommonFields
    }
}`;

export const SCHEDULING_REF_PLAN_TYPES_GET_COUNTER = gql`
query GetRefPlanTypesCounter {
  aggregateRefPlanType {
    _count {
      id
    }
  }
}
`;
