import {gql} from '@apollo/client';

export const FILE_REPORTS_COMMON_FIELDS = gql`
    fragment FileReportCommonFields on Report {
        id
        extension
        docName
        contentType
        createdAt
        updatedAt
        fsAdapter
        path
        status
    }
`;
