import {gql} from '@apollo/client';
import {MEASPOINT_REF_CODE_GROUPS_COMMON_FIELDS} from '../fragments/refMeasPointCodeGroups';

export const MEASPOINT_REF_CODE_GROUPS_GET_MANY = gql`
${MEASPOINT_REF_CODE_GROUPS_COMMON_FIELDS}
query GetAllRefMeasPointCodeGroups($orderBy: [RefMeasPointCodeGroupOrderByWithRelationInput!], $where: RefMeasPointCodeGroupWhereInput) {
    refMeasPointCodeGroups(orderBy: $orderBy, where: $where) {
      ...RefMeasPointCodeGroupCommonFields
    }
}`;

export const MEASPOINT_REF_CODE_GROUPS_GET_COUNTER = gql`
query GetRefMeasPointCodeGroupsCounter {
  aggregateRefMeasPointCodeGroup {
    _count {
      id
    }
  }
}
`;
