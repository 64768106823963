export default class PermissionDamages {
  public static resourceName = 'damages';

  public display = false;
  public create = false;
  public import = false;
  public update = false;
  public measurement = false;
  public delete = false;
}
