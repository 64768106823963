import {RadDialogClose, RadButton} from '@holis/react-ui/rad';
import {useTranslation} from 'react-i18next';

type TFooter = {
	readonly onSaveClick: () => void;
};

export default function Footer({onSaveClick}: TFooter) {
  const {t} = useTranslation();
  return (
    <>
      <RadDialogClose asChild>
        <RadButton variant='outline'>
          {t('label.cancel')}
        </RadButton>
      </RadDialogClose>

      <RadButton onClick={onSaveClick}>
        {t('label.create')}
      </RadButton>
    </>
  );
}
