import Actions from '@app/components/Common/Block/Cml/CmlModal/components/Actions';
import GeneralInfo from '@app/components/Common/Block/Cml/CmlModal/components/GeneralInfo';
import PictureCarousel from '@app/components/Common/Block/Cml/CmlModal/components/pictures/PictureCarousel';
import MultiplePanelsModal, {TMultiplePanelsModal} from '@app/components/Modal/NewMultiplePanels';
import {Cml, Event, EventCml, Picture} from '@app/graphql/__types__/graphql';
import useCmlStore from '@app/stores/cml';
import {OBJ_NEW_ID} from '@app/utils/constants';
import {EApiOperator} from '@app/utils/enums';
import useUserPermissions from '@app/utils/hooks/useUserPermissions';
import {TCarouselApi} from '@holis/react-ui';
import {useState} from 'react';
import DeleteCmlModal from './components/DeleteCmlModal';
import EventCmlHistory from './components/events/EventCmlsHistory';
import Footer from './components/Footer';
import Header from './components/Header';
import MeasurementBlock from './components/measurement';
type TCmlModal = TMultiplePanelsModal<Partial<Cml>> & Readonly<{
    event?: Partial<Event>;
    eventCml?: Partial<EventCml>;
    onCreatedOrUpdated?: (cml?: Partial<Cml>, operator?: EApiOperator) => void;
    onPicturesChanged?: (images?: Partial<Picture>[], operator?: EApiOperator) => void;
  }>

export default function CmlModal({item, event, onOpenChange, onCreatedOrUpdated, onPicturesChanged, eventCml, ...restProps}: TCmlModal) {
  const isNew = !item.id || item.id === OBJ_NEW_ID;
  const prms = useUserPermissions();
  const {deleteCmlModalOpen, resetData, isSaved, changeDeleteCmlModalDisplay, editCml} = useCmlStore();

  const [pictureCarouselApi, setPictureCarouselApi] = useState<TCarouselApi>();

  const handlePictureCarouselRender = (api: TCarouselApi) => {
    setPictureCarouselApi(api);
  };

  const handleRightPanelResize = () => {
    pictureCarouselApi?.calculatePagination();
  };

  const handleCloseModal = () => {
    resetData();
    onOpenChange?.(false);
  };

  const handleCmlDeleted = () => {
    handleCloseModal();
    onCreatedOrUpdated?.(item);
  };

  return (
    <MultiplePanelsModal
      autoSaveId='modal-cml'
      header={<Header actions={isNew ? null : <Actions cml={item} onCreatedOrUpdated={onCreatedOrUpdated}/>} cml={item}/>}
      footer={<Footer cml={item}/>}
      panelsOnResize={[undefined, handleRightPanelResize]}
      panelsDefaultSize={[45, 55]}
      panelsMinSize={[30, 55]}
      item={item}
      isSaved={isSaved}
      onOpenChange={handleCloseModal}
      {...restProps}
    >
      <GeneralInfo cml={item} readonly={!prms.cmls.update} onCreatedOrUpdated={onCreatedOrUpdated}/>
      <div className='flex flex-col w-full overflow-auto'>
        {!!eventCml && <MeasurementBlock event={event!} readonly={!prms.cmls.measurement} cml={item} eventCml={eventCml} onCreatedOrUpdated={onCreatedOrUpdated}/>}
        <EventCmlHistory event={event} cml={editCml!} eventCml={eventCml} onCreatedOrUpdated={onCreatedOrUpdated}/>
        <PictureCarousel readonly={!prms.cmls.update} cml={item} event={event} onCarouselRender={handlePictureCarouselRender} onChanged={onPicturesChanged}/>
        <DeleteCmlModal open={deleteCmlModalOpen} cml={item} changeDeleteCmlModalDisplay={changeDeleteCmlModalDisplay} onCmlDeleted={handleCmlDeleted}/>
      </div>
    </MultiplePanelsModal>
  );
}
