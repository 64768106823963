import React from 'react';
import {Checkbox, TCheckbox} from '@holis/react-ui';
import styled from 'styled-components';
import {twMerge} from 'tailwind-merge';

type TAppCheckbox = TCheckbox;

const StyledCheckbox = styled(Checkbox)`
    &>div{
        padding: 0;
    }
`;

export default function AppCheckbox({className, ...restProps}: TAppCheckbox) {
  return (
    <StyledCheckbox {...restProps} className={twMerge('flex items-center text-gray-700', className)}/>
  );
}
