import {gql} from '@apollo/client';
import {INTEGRITY_REF_CONDITIONS_COMMON_FIELDS} from '../fragments';

export const INTEGRITY_REF_CONDITIONS_GET_MANY = gql`
${INTEGRITY_REF_CONDITIONS_COMMON_FIELDS}
query GetAllRefIntegrityConditions($orderBy: [RefIntegrityConditionOrderByWithRelationInput!], $where: RefIntegrityConditionWhereInput) {
    refIntegrityConditions(orderBy: $orderBy, where: $where) {
      ...RefIntegrityConditionCommonFields
    }
}`;

export const INTEGRITY_REF_CONDITIONS_GET_COUNTER = gql`
query GetRefIntegrityConditionsCounter {
  aggregateRefIntegrityCondition {
    _count {
      condition
    }
  }
}
`;
