import {gql} from '@apollo/client';

export const MEASPOINT_REF_CODE_GROUPS_COMMON_FIELDS = gql`
  fragment RefMeasPointCodeGroupCommonFields on RefMeasPointCodeGroup {
    id
    codeGroup
    description
    codeGroupValCodes {
      valCodeId
    }
}`;
