import {gql} from '@apollo/client';

export const MEASPOINT_REF_CHARS_COMMON_FIELDS = gql`
    fragment RefMeasPointCharCommonFields on RefMeasPointChar {
        id
        description
        char
        unit
        isActive
    }
`;
