import {IConfirmModal} from '@app/components/Modal/Confirm';
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning';
import {useAdminImportExportStore} from '@app/stores/admin/importExport';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import FileErrors, {TFileErrors} from './FileErrors';
import {TLineError} from '@app/types/app';

type TTableDataImportErrorsModal = IConfirmModal;

export default function TableDataImportErrorsModal(props: TTableDataImportErrorsModal) {
  const {t} = useTranslation();
  const {tableDataImportErrors} = useAdminImportExportStore();
  const [filesErrors, setFilesErrors] = useState<TFileErrors[]>();
  console.log(filesErrors);
  useEffect(() => {
    if (tableDataImportErrors) {
      setFilesErrors(Object.keys(tableDataImportErrors).map((fileName: string, idx: number) => {
        const linesErrors: unknown = Object.values(tableDataImportErrors)[idx];
        console.log(linesErrors);
        return {name: fileName, errors: Array.isArray(linesErrors) ? linesErrors.map((lineError: TLineError) => (`${lineError.line ? t('label.lineWithNo', {number: lineError.line}) + ': ' : ''}${lineError.message}`)) : [String(linesErrors)]};
      }));
    }
  }, [tableDataImportErrors]);
  return (
    <WarningConfirmModal
      title={t('label.tableDataImportError')}
      confirmButtonLabel={false}
      cancelButtonLabel={t('label.ok')}
      {...props}
    >
      <div className='flex flex-col gap-1 w-full flex-grow overflow-auto max-h-[80%]'>
        <div className='font-bold text-sm text-red-600'>{t('message.error.importService.seeErrorsDetail')}</div>
        <ul className='flex flex-col text-gray-600 w-full pl-8 pr-6 list-decimal list-outside'>
          {filesErrors?.map((fileErrors: TFileErrors) => (
            <li key={fileErrors.name}><FileErrors key={fileErrors.name} {...fileErrors}/></li>
          ))}
        </ul>
      </div>
    </WarningConfirmModal>
  );
}
