import {PropsWithChildren} from 'react';
import {twMerge} from 'tailwind-merge';

type TBlockTitle = Readonly<PropsWithChildren & {
  className?: string;
}>

export default function BlockTitle({children, className}: TBlockTitle) {
  return <div className={twMerge('text-sm w-full text-gray-700 mt-4 mb-1 font-bold flex items-center', className)}>{children}</div>;
}
