import {useLazyQuery, useMutation} from '@apollo/client';
import {Grid, TGridProps} from '@app/components/Common/Grid';
import {CreateEventWorkflowMutation, Event, EventView, GetEventViewsByStageQuery, UpdateEventByIdMutation, UserInfo} from '@app/graphql/__types__/graphql';
import {WORKORDER_EVENT_VIEWS_GET_ALL_BY_STAGE, WORKORDER_EVENT_WORKFLOWS_CREATE, WORKORDER_EVENTS_UPDATE_BY_ID} from '@app/graphql/requests';
import {useDataStore} from '@app/stores/data';
import {useEventStore} from '@app/stores/event';
import {FORMAT_DATE_EU} from '@app/utils/constants';
import {EWORKORDER_EVENT_STAGE, EWORKORDER_EVENT_STATUS} from '@app/utils/enums';
import {RadBadge, RadButton, RadCheckbox} from '@holis/react-ui/rad';
import moment from 'moment';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LuBan, LuUserCheck} from 'react-icons/lu';
import {MdOpenInNew} from 'react-icons/md';
import {useNavigate} from 'react-router-dom';
import UserAssignModal from '../UserAssignModal';
import {useLayoutStore} from '@app/stores/layout';
import AppNotifications from '@app/services/notification';

export type TEventListPage = Readonly<{
  stage?: string;
  title: string;
  badge: React.ReactNode;
  route: string;
}>;

export default function EventListPage({stage, title}: TEventListPage) {
  const navigate = useNavigate();
  const {selectedSites} = useDataStore();
  const {startLoading, stopLoading} = useLayoutStore();
  const [events, setEvents] = useState<Partial<EventView>[]>();
  const {t} = useTranslation();
  const [wrkoAssignInProgress, setWrkoAssignInProgress] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState<number[]>([]);
  const {setAssignModalOpened, assignModalOpened} = useEventStore();
  // const [getEventsApi, {loading, error, refetch, called}] = useLazyQuery<GetEventsByStageQuery>(WORKORDER_EVENTS_GET_ALL_BY_STAGE, {
  //   variables: {
  //     stage,
  //     orderBy: [
  //       {
  //         id: 'desc',
  //       },
  //     ],
  //   },
  //   fetchPolicy: 'no-cache',
  // });

  const [getEventsApi, {loading, error}] = useLazyQuery<GetEventViewsByStageQuery>(WORKORDER_EVENT_VIEWS_GET_ALL_BY_STAGE);
  const [updateEventByIdApi] = useMutation<UpdateEventByIdMutation>(WORKORDER_EVENTS_UPDATE_BY_ID);
  const [createEventWorkflowApi] = useMutation<CreateEventWorkflowMutation>(WORKORDER_EVENT_WORKFLOWS_CREATE);

  const getEvents = async () => {
    if (selectedSites?.length) {
      const queryResults = await getEventsApi({
        variables: {sites: selectedSites,
          orderBy: [
            {
              id: 'desc',
            },
          ], ...(stage ? {stage} : {})},
        fetchPolicy: 'no-cache',
      });
      setEvents((queryResults.data?.eventViews ?? []) as Partial<EventView>[]);
    } else {
      setEvents([]);
    }
  };

  useEffect(() => {
    getEvents();
  }, [selectedSites, stage]);

  const selectUnselectEvent = (id: number) => {
    if (selectedEvents.includes(id)) {
      setSelectedEvents(selectedEvents.filter(eid => eid !== id));
    } else {
      setSelectedEvents([...selectedEvents, id]);
    }
  };

  const canAssignEvent = (event: EventView) => event.status === EWORKORDER_EVENT_STATUS.EXEC_ASSIGN_WORKPACK_TECHNICIAN || event.status === EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION;

  const gridProps: TGridProps = useMemo(() => ({
    columns: [
      {
        field: 'action',
        title: t('label.action'),
        type: 'string',
        width: 77.5,
        cellRenderer: 'action',
        titleHidden: true,
        filter: false,
      },
      {
        field: 'status',
        title: t('label.status'),
        type: 'string',
        width: 117,
        cellRenderer: 'status',
        hidden: false,
        pinned: false,
      },
      {
        field: 'id',
        title: 'Id',
        type: 'number',
        width: 80,
        hidden: true,
      },
      {
        field: 'event',
        title: t('label.event'),
        type: 'string',
        width: 129,
        cellRenderer: 'textBold',
        hidden: false,
      },
      {
        field: 'description',
        title: t('label.description'),
        type: 'string',
        width: 289,
        hidden: false,
      },
      {
        field: 'schedulingTag_floc',
        title: t('label.schedulingTag'),
        type: 'string',
        width: 294,
        hidden: false,
      },
      {
        field: 'schedulingTag_description',
        title: t('label.schedulingTagDescription'),
        type: 'string',
        width: 445,
      },
      {
        field: 'schedulingTag_sector_site_site',
        title: t('label.site'),
        type: 'string',
        width: 77.5,
      },
      {
        field: 'schedulingTag_sector_sector',
        title: t('label.sector'),
        type: 'string',
        width: 77.5,
      },
      {
        field: 'schedulingTag_techClass_class_class',
        title: t('label.class'),
        type: 'string',
        width: 77.5,
      },
      {
        field: 'schedulingTag_techClass_techClass',
        title: t('label.technicalClass'),
        type: 'string',
        width: 120,
        hidden: false,
      },
      {
        field: 'schedulingTag_classSece',
        title: t('label.classSece'),
        type: 'string',
        width: 100,
        hidden: true,
      },
      {
        field: 'schedulingTag_grid_grid',
        title: t('label.grid'),
        type: 'string',
        width: 77.5,
        hidden: true,
      },
      {
        field: 'notes',
        title: t('label.notes'),
        type: 'string',
        width: 120,
        hidden: true,
      },
      {
        field: 'type_type',
        title: t('label.type'),
        type: 'string',
        width: 77.5,
        hidden: false,
      },
      {
        field: 'priority',
        title: t('label.priority'),
        type: 'string',
        width: 80,
      },
      {
        field: 'batchNumber',
        title: t('label.batchNumber'),
        type: 'string',
        width: 120,
      },
      {
        field: 'plannedDate',
        title: t('label.plannedDate'),
        type: 'string',
        cellRenderer: 'date',
        width: 134,
        hidden: false,
      },
      {
        field: 'inspectionDate',
        title: t('label.inspectionDate'),
        type: 'string',
        cellRenderer: 'date',
        width: 137,
      },
      {
        field: 'releasedDate',
        title: t('label.releasedDate'),
        type: 'string',
        cellRenderer: 'date',
        width: 80,
        hidden: true,
      },
      {
        field: 'stage',
        title: t('label.stage'),
        type: 'string',
        width: 80,
        cellRenderer: 'stage',
        hidden: false,
      },
      {
        field: 'inspectorId',
        title: t('label.inspectorId'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'reviewerId',
        title: t('label.reviewerId'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'approverId',
        title: t('label.approverId'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'reportNumber',
        title: t('label.reportNumber'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'reportDescription',
        title: t('label.reportDescription'),
        type: 'string',
        width: 80,
        hidden: true,
      },
      {
        field: 'reportingDate',
        title: t('label.reportingDate'),
        type: 'string',
        width: 80,
        cellRenderer: 'date',
        hidden: true,
      },
      {
        field: 'plan_plan',
        title: t('label.planNumber'),
        type: 'string',
        width: 77.5,
      },
      {
        field: 'mainWorkCenter_code',
        title: t('label.mainWorkCenter'),
        type: 'string',
        width: 77.5,
      },
      {
        field: 'plannerGroup_code',
        title: t('label.plannerGroup'),
        type: 'string',
        width: 77.5,
      },
      {
        field: 'externalId',
        title: t('label.externalId'),
        type: 'string',
        width: 77.5,
      },
    ],
    cellRenderers: {
      action(_val: string, rowData: unknown) {
        const viewDatas = rowData as EventView;
        const canAssign = wrkoAssignInProgress && canAssignEvent(viewDatas);
        return (
          <div className='!flex gap-4 items-center'>
            {wrkoAssignInProgress && <RadCheckbox
              disabled={!canAssign}
              className={canAssign ? '' : '!opacity-10'}
              checked={selectedEvents.includes(viewDatas.id)}
              onClick={() => {
                selectUnselectEvent(viewDatas.id);
              }}
            />}
            <RadButton
              size='icon'
              variant='outline'
              className='size-fit p-1'
              onClick={() => navigate(`./${(rowData as Event).event!}`, {relative: 'path'})}
            >
              <MdOpenInNew size={18}/>
            </RadButton>
          </div>
        );
      },
      status(val: string, rowData: unknown) {
        if (!val) {
          return '';
        }

        const viewDatas = rowData as EventView;
        const statusBg = viewDatas.status_displayColor ? (
          `bg-${viewDatas.status_displayColor}-200`
        ) : 'bg-gray-200';

        const statusFg = viewDatas.status_displayColor ? (
          `text-${viewDatas.status_displayColor}-700`
        ) : 'text-gray-700';

        return (
          <RadBadge variant='outline' className={`${statusBg} ${statusFg} py-1 font-normal`}>
            {viewDatas.status_description}
          </RadBadge>
        );
      },
      stage: (val: string) => val ? t(`label.eventStages.${val.toLowerCase()}`) : '',
      date: (val: string) => val ? moment(val).format(FORMAT_DATE_EU) : '',
      textBold: (val: string) => val ? <span className='font-bold'>{val}</span> : '',
    },
    canSelect: wrkoAssignInProgress ? 'single' : false,
    onRowsSelection(row) {
      const viewDatas = row as unknown as EventView;
      if (!canAssignEvent(viewDatas)) {
        return;
      }

      selectUnselectEvent(viewDatas.id);
    },
  }), [events, wrkoAssignInProgress, selectedEvents]);

  const columnsAlwaysVisible: string[] = useMemo(() => ([
    'action',
    'event',
    'description',
    'schedulingTag_floc',
    'type_type',
    'plannedDate',
    'status',
    'stage',
  ]), [events]);

  const handleAssignEvents = async (u: UserInfo) => {
    startLoading();
    for (const eventId of selectedEvents) {
      const previousEventStatus = events?.find(e => e.id === eventId)?.status;
      // Set event assigned to user
      await updateEventByIdApi({
        variables: {
          id: eventId,
          data: {
            inspectorId: {set: u.username},
            status: {
              set: EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION,
            },
          },
        },
      });
      // Create workflow entry
      await createEventWorkflowApi?.({
        variables: {
          data: {
            userLogin: u?.username,
            actionDate: new Date(),
            status: `${previousEventStatus}|${EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION}`,
            wrkoId: eventId,
            description: t('label.eventActions.descriptions.assign_workpack', {user: u.username}),
          },
        },
      });
    }

    AppNotifications.success(t('message.success.multiplEventAssigned', {count: selectedEvents.length}));
    getEvents();
    stopLoading();
    setSelectedEvents([]);
    setWrkoAssignInProgress(false);
    setAssignModalOpened(false);
  };

  return (
    <>
      <div className='h-full'>
        <Grid
          gridName={`events${t(title)}Grid`}
          queryData={{
            data: events ?? [],
            loading,
            error,
          }}
          gridProps={gridProps}
          title={<div className='text-lg font-semibold'>{t(title)}</div>}
          columnsAlwaysVisible={columnsAlwaysVisible}
        >
          {stage === EWORKORDER_EVENT_STAGE.EXECUTION && !wrkoAssignInProgress && (
            <RadButton
              size='sm'
              className='flex gap-1 text-sm'
              onClick={() => setWrkoAssignInProgress(true)}
            >
              <LuUserCheck/> {t('label.assignReassignWorkpacks')}
            </RadButton>
          )}
          {
            wrkoAssignInProgress && (
              <>
                <RadButton
                  size='sm'
                  className='flex gap-1 text-sm'
                  disabled={!selectedEvents.length}
                  onClick={() => setAssignModalOpened(true)}
                >
                  <LuUserCheck/> {t('label.assignCountWorkpacks', {count: selectedEvents.length})}
                </RadButton>
                <RadButton
                  size='sm'
                  variant='secondary'
                  className='flex gap-1 text-sm'
                  onClick={() => {
                    setSelectedEvents([]);
                    setWrkoAssignInProgress(false);
                  }}>
                  <LuBan/> {t('label.cancelWorkpacksAssignment')}
                </RadButton>
              </>
            )
          }
        </Grid>
      </div>
      <UserAssignModal opened={assignModalOpened} onClose={() => setAssignModalOpened(false)} onValidate={handleAssignEvents}/>
    </>
  );
}
