import {gql} from '@apollo/client';
import {SCHEDULING_REF_PLAN_STRATEGIES_COMMON_FIELDS} from '../fragments';

export const SCHEDULING_REF_PLAN_STRATEGIES_GET_MANY = gql`
${SCHEDULING_REF_PLAN_STRATEGIES_COMMON_FIELDS}
query GetAllRefPlanStrategies($orderBy: [RefPlanStrategyOrderByWithRelationInput!], $where: RefPlanStrategyWhereInput) {
    refPlanStrategies(orderBy: $orderBy, where: $where) {
      ...RefPlanStrategyCommonFields
    }
}`;

export const SCHEDULING_REF_PLAN_STRATEGIES_GET_COUNTER = gql`
query GetSchedulingRefPlanStrategiesCounter {
  aggregateRefPlanStrategy {
    _count {
      id
    }
  }
}
`;
