import {gql} from '@apollo/client';

export const WORKORDER_EVENT_WORKFLOWS_COMMON_FIELDS = gql`
  fragment EventWorkflowCommonFields on EventWorkflow {
    id
    wrkoId
    status
    actionDate
    userLogin
    isValid
    comment
    description
  }
`;
