import {Cml} from '@app/graphql/__types__/graphql';

export function isTHKPoint(cml: Partial<Cml>) : boolean {
  const calcThk = cml.codeGroup?.calcThk?.toUpperCase();
  return !!calcThk && ['THKI', 'THKE'].includes(calcThk);
}

export function calculateCmlCodeCond(cml: Partial<Cml>, quantReading?: number) : string {
  if (typeof quantReading === 'number') {
    const {alarm1, alarm2, alarm3} = cml;
    if (alarm1 && quantReading >= alarm1) {
      return '01';
    }

    if (alarm2 && quantReading >= alarm2) {
      return '02';
    }

    if (alarm3) {
      if (quantReading >= alarm3) {
        return '03';
      }

      return '04';
    }
  }

  return '00';
}
