import {EventTask, EventTaskUncheckedUpdateInput, UpdateOneEventTaskByIdMutation} from '@app/graphql/__types__/graphql';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {FetchResult, useMutation} from '@apollo/client';
import {WORKORDER_EVENT_TASKS_UPDATE_BY_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useTranslation} from 'react-i18next';
import {useEventStore} from '@app/stores/event';
import {useLayoutStore} from '@app/stores/layout';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {MdOutlineDragIndicator} from 'react-icons/md';
import {EWORKORDER_REF_EVENT_TASK_CATEGORY} from '@app/utils/enums';
import {RadBadge, RadContextMenu, RadContextMenuContent, RadContextMenuItem, RadContextMenuTrigger} from '@holis/react-ui/rad';
import BadgeInput from '@app/components/Common/Form/BadgeInput';
import {LuExternalLink, LuTimer, LuUsers} from 'react-icons/lu';
import ItemCard from '@app/components/Common/Block/ItemCard';

type TTaskItem = Readonly<{
  category?: EWORKORDER_REF_EVENT_TASK_CATEGORY,
  item: Partial<EventTask>;
  hasActionButtons?: boolean;
  onDeleteClick?: (task: Partial<EventTask>) => void;
  onOpenTaskClick?: (task: Partial<EventTask>) => void;
  isDeleteButtonDisabled?: boolean;
  hasDragBtn?: boolean;
  indicatorValue?: string;
}>

export default function TaskItem({item, hasDragBtn, onOpenTaskClick}: TTaskItem) {
  const {t} = useTranslation();
  const [editTask, setEditTask] = useState<Partial<EventTask>>();
  const {updateEventTask, newTaskData, setNewTaskData, fetchEventTasks} = useEventStore();
  const [updateEventTaskApi] = useMutation<UpdateOneEventTaskByIdMutation>(WORKORDER_EVENT_TASKS_UPDATE_BY_ID);
  const {startLoading, stopLoading} = useLayoutStore();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: item.id!});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const handleDurationChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number.parseFloat(e.target.value);
    setEditTask({
      ...editTask,
      duration: !isNaN(newValue) ? newValue : undefined,
    });
  };

  const handleNbWorkersChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number.parseInt(e.target.value, 10);
    setEditTask({
      ...editTask,
      nbWorkers: !isNaN(newValue) ? newValue : undefined,
    });
  };

  const updateEventTaskHandler = (data: EventTaskUncheckedUpdateInput) => {
    if (item.id) {
      startLoading();
      updateEventTaskApi({
        variables: {
          id: item.id,
          data,
        },
      }).then((fetchResult: FetchResult<UpdateOneEventTaskByIdMutation>) => {
        if (fetchResult.data?.updateOneEventTask) {
          updateEventTask(fetchResult.data.updateOneEventTask as Partial<EventTask>);
          AppNotifications.success(t(`message.success.eventTask${item.task?.category === EWORKORDER_REF_EVENT_TASK_CATEGORY.SAFETY ? 'Safety' : ''}Updated`));
        } else {
          throw new Error('Update failed');
        }
      }).catch((error: Error) => {
        console.log(error);
        AppNotifications.error(t('message.error.default.title'));
      }).finally(() => {
        fetchEventTasks?.();
        stopLoading();
      });
    }
  };

  const handleDurationBlur = () => {
    const newValue = Number.parseFloat(String(editTask?.duration));
    const duration = !Number.isNaN(newValue) ? newValue : item.duration;
    const oldDuration = item.duration ? Number.parseFloat(item.duration) : null;
    setEditTask({
      ...editTask,
      duration,
    });
    if (duration !== oldDuration) {
      if (item.id) {
        updateEventTaskHandler({
          duration: {
            set: String(duration),
          },
        });
      } else if (newTaskData?.task?.id && newTaskData?.task?.id === item.task?.id) {
        setNewTaskData({
          ...newTaskData,
          duration,
        });
      } else {
        setNewTaskData({
          ...item,
          duration,
        });
      }
    }
  };

  const handleNbWorkersBlur = () => {
    const newValue = Number.parseInt(String(editTask?.nbWorkers), 10);
    const nbWorkers = !Number.isNaN(newValue) ? newValue : item.nbWorkers;
    setEditTask({
      ...editTask,
      nbWorkers,
    });
    if (nbWorkers !== item.nbWorkers) {
      if (item.id) {
        updateEventTaskHandler({
          nbWorkers: {
            set: nbWorkers,
          },
        });
      } else if (newTaskData?.task?.id && newTaskData?.task?.id === item.task?.id) {
        setNewTaskData({
          ...newTaskData,
          nbWorkers,
        });
      } else {
        setNewTaskData({
          ...item,
          nbWorkers,
        });
      }
    }
  };

  useEffect(() => {
    setEditTask(item);
  }, []);
  return (
    <div ref={setNodeRef} style={style}>

      <RadContextMenu>
        <RadContextMenuTrigger disabled>

          <ItemCard className='min-h-[90px]'>

            {!!hasDragBtn
                  && <div>
                    <MdOutlineDragIndicator size={24} className='-ml-[6px] text-gray-300 hover:cursor-move focus:outline-none' {...attributes} {...listeners}/>
                  </div> }

            <div className='flex flex-col gap-1 flex-grow text-sm self-start'>
              <div className='flex gap-2 items-center'>
                <RadBadge className={item.task?.category === EWORKORDER_REF_EVENT_TASK_CATEGORY.SAFETY ? 'bg-yellow-200 text-black' : ''}>
                  {item?.task?.task}
                </RadBadge>

                <div className='line-clamp-1 font-semibold flex-1'>{item.task?.description}</div>

                {!(item.task?.category === EWORKORDER_REF_EVENT_TASK_CATEGORY.SAFETY && !item.task?.hasReporting) && (
                  <div className='flex flex-row items-start gap-1 mr-2'>
                    <div className='flex items-center justify-center gap-1 mr-2'>
                      <LuTimer size={18} className='text-gray-600 mr-1'/>
                      <BadgeInput
                        inputProps={{
                          type: 'number',
                          value: editTask?.duration ?? '',
                          onChange: handleDurationChange,
                          onBlur: handleDurationBlur,
                        }}
                        className='min-h-[24px] h-[24px]'
                      />
                    </div>
                    <div className='flex items-center justify-center gap-1'>
                      <LuUsers size={18} className='text-gray-600'/>
                      <BadgeInput
                        inputProps={{
                          type: 'number',
                          value: editTask?.nbWorkers ?? '',
                          onChange: handleNbWorkersChange,
                          onBlur: handleNbWorkersBlur,
                        }}
                        className='min-h-[24px] h-[24px]'
                      />
                    </div>
                  </div>
                )}

              </div>
              <div className='line-clamp-2'>
                {item.task?.longDescription}
              </div>

            </div>

            {/* { item.task?.category !== EWORKORDER_REF_EVENT_TASK_CATEGORY.SAFETY && (
              <MeasurementValueContainer measurementValue={indicatorValue}/>
            )} */}

          </ItemCard>
        </RadContextMenuTrigger>
        <RadContextMenuContent>
          <RadContextMenuItem onClick={() => onOpenTaskClick?.(item)}>
            <LuExternalLink className='mr-2'/> {t('label.openTask')}
          </RadContextMenuItem>
          {/* <RadContextMenuItem disabled={isDeleteButtonDisabled} className='item-destructive' onClick={() => onDeleteClick?.(item)}>
            <LuTrash2 className='mr-2'/> {t('label.removeFromEvent')}
          </RadContextMenuItem> */}
        </RadContextMenuContent>
      </RadContextMenu>

    </div>
  );
}
