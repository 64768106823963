// import EventMeasurementFilter from '@app/components/Common/Block/Filter/EventMeasurementFilter';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import BorderedTable from '@app/components/Common/TreeList/BorderedTable';
import SelectionConfirmModal from '@app/components/Modal/Confirm/Selection';
import {EventCml, EventDamage, EventInspectionPoint, RefCmlValCode, RefDamageValCode, RefMeasPointValCode} from '@app/graphql/__types__/graphql';
import {TLabelValue} from '@app/types/app';
import {EEventMeasurementFilter} from '@app/utils/enums';
import {IColumn, TCellRenderer, TDataRowTreeList} from '@holis/react-ui';
import {RadTabs, RadTabsContent, RadTabsList, RadTabsTrigger} from '@holis/react-ui/rad';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {LuHistory} from 'react-icons/lu';
import styled from 'styled-components';
import {twMerge} from 'tailwind-merge';
import EventMeasurementTableChart from './EventMeasurementTableChart';

type TProps<
  TItem extends Partial<EventCml | EventDamage | EventInspectionPoint>,
  TQualReadingData extends Partial<RefCmlValCode | RefDamageValCode | RefMeasPointValCode>,
> = Readonly<{
  onFilterChange?: (filter: TLabelValue<EEventMeasurementFilter>) => void;
  isLoading?: boolean;
  error?: React.ReactNode;
  cellRenderers: Record<string, TCellRenderer<TDataRowTreeList>>;
  eventCml?: Partial<EventCml>;
  items: Partial<TItem>[];
  columns: IColumn[];
  className?: string;
  qualReadingSelectionModalTitle?: string;
  qualReadingSelectionModalColumns?: IColumn[];
  updateHandler: (item: Partial<TItem>, field: string, val: number | null) => void;
  qualReadingSelectionModalItems?: Partial<TQualReadingData>[];
  selectedRow?: Partial<TItem>;
  onQualReadingSelectionModalClose?: () => void;
  beforeTableComponent?: React.ReactNode;
}>;

const StyledContainer = styled.div`
  & tr.tgrid-row:hover {
    & .reading-autocomplete{
      display: block;
    }
    & .reading-autocomplete ~ .reading-content{
      display: none
    }
  }
`;

export default function EventMeasurementTable<TItem extends Partial<EventCml | EventDamage | EventInspectionPoint>,
TQualReadingData extends Partial<RefCmlValCode | RefDamageValCode | RefMeasPointValCode>>(
  {...props}: TProps<TItem, TQualReadingData>,
) {
  const {t} = useTranslation();

  // const [filter, setFilter] = useState<EEventMeasurementFilter>(EEventMeasurementFilter.LIST);

  // const handleFilterChange = (filter: TLabelValue<EEventMeasurementFilter>) => {
  //   setFilter(filter.value);
  //   onFilterChange?.(filter);
  // };

  const handleQualReadingSelectionModalValidate = (items: Partial<TQualReadingData>[]) => {
    props.updateHandler?.(props.selectedRow!, 'qualReadingId', items.length ? items[0].id! : null);
  };

  return (
    <StyledContainer className={twMerge('flex text-gray-500 flex-col w-full', props.className)}>
      <RadTabs defaultValue='list'>
        <FormGroupHeader className='pt-4'>
          <div className='flex items-center font-bold px-2'>
            <LuHistory size={20} className='mr-2'/> {t('label.historyTrend')}
          </div>

          <RadTabsList className='grid grid-cols-2'>
            <RadTabsTrigger value='list'>
              List
            </RadTabsTrigger>

            <RadTabsTrigger value='chart'>
              Chart
            </RadTabsTrigger>
          </RadTabsList>
        </FormGroupHeader>

        <RadTabsContent value='list'>
          <BorderedTable
            // rightSearchBarComponent={<EventMeasurementFilter value={filter} onChange={handleFilterChange}/>}
            beforeTableComponent={props.beforeTableComponent}
            listProps={{
              isLoading: props.isLoading,
              error: !!props.error,
              cellRenderers: props.cellRenderers,
              data: props.items as TDataRowTreeList[],
              columns: props.columns,
            }}
          />

          {!!props.selectedRow && (
            <SelectionConfirmModal
              open
              isMultiple={false}
              title={props.qualReadingSelectionModalTitle}
              listProps={{
                listProps: {
                  data: (props.qualReadingSelectionModalItems ?? []) as TDataRowTreeList[],
                  columns: props.qualReadingSelectionModalColumns ?? [
                    {
                      field: 'valCode',
                      title: t('label.code'),
                      type: 'string',
                      width: 250,
                    },
                    {
                      field: 'description',
                      title: t('label.description'),
                      type: 'string',
                      width: 250,
                    },
                  ],
                },
              }}
              onClose={props.onQualReadingSelectionModalClose}
              onValidate={handleQualReadingSelectionModalValidate}
            />
          )}
        </RadTabsContent>

        <RadTabsContent value='chart'>
          <EventMeasurementTableChart
            eventCml={props.eventCml}
            items={props.items}
            beforeChartComponent={props.beforeTableComponent}
          />
        </RadTabsContent>
      </RadTabs>
    </StyledContainer>
  );
}
