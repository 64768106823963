import {GetInspectionDrawingsByFlocIdQuery, InspectionDrawing, Notification} from '@app/graphql/__types__/graphql';
import useNotificationStore from '@app/stores/notification';
import {EIanRightSideTab} from '@app/utils/enums';
import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useLazyQuery} from '@apollo/client';
import {INSPECTION_DRAWINGS_GET_BY_FLOC_ID} from '@app/graphql/requests';
import {useLayoutStore} from '@app/stores/layout';
import SimplePageSelector from '@app/components/Common/Tabs/SimplePageSelector';
import LongDescriptionBlock from './LongDescription';
import NotificationAttachments from './Attachments';
import DrawingViewers from '@app/components/Common/Block/InspectionDrawing/DrawingViewers';
type TRightSide = Readonly<{
  notification: Partial<Notification>;
  readonly?: boolean;
}>;

export default function RightSide({notification, readonly}: TRightSide) {
  const {t} = useTranslation();
  const {editNotification, setNotificationFlocDrawings, setFetchObjectDrawings, rightSideTabSelectedValue, changeRightSideTab, selectedDrawing, setSelectedDrawing, notificationDrawings, setDamageDrawingIds, notificationDamages, notificationFlocDrawings, setNotificationDrawings, damageDrawingIds} = useNotificationStore();
  const {startLoading, stopLoading} = useLayoutStore();
  const [getDrawingsByFlocIdApi] = useLazyQuery<GetInspectionDrawingsByFlocIdQuery>(INSPECTION_DRAWINGS_GET_BY_FLOC_ID, {
    fetchPolicy: 'no-cache',
  });
  const flocId = useMemo(() => editNotification?.flocId, [editNotification]);

  const getDrawings = () => {
    if (flocId) {
      startLoading();
      getDrawingsByFlocIdApi({
        variables: {
          flocId,
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => {
        setNotificationFlocDrawings((queryResult.data?.inspectionDrawings ?? []) as Partial<InspectionDrawing>[]);
      }).finally(() => {
        stopLoading();
      });
    }
  };

  useEffect(() => {
    if (flocId) {
      getDrawings();
      setFetchObjectDrawings?.(getDrawings);
    }
  }, [flocId]);

  useEffect(() => {
    setDamageDrawingIds(notificationDamages?.map(notifDamage => notifDamage.damage?.idwgId!));
  }, [notificationDamages]);

  useEffect(() => {
    setNotificationDrawings(notificationFlocDrawings?.filter(notifFlocDrawing => !!damageDrawingIds?.includes(notifFlocDrawing.id!)));
  }, [notificationFlocDrawings, damageDrawingIds]);

  return (
    <div className='h-full w-full flex flex-col'>

      <SimplePageSelector
        className='mt-2'
        items={[
          {label: t('label.damage'), value: EIanRightSideTab.DRAWINGS},
          {label: t('label.longDescription'), value: EIanRightSideTab.LONG_DESCRIPTION},
        ]}
        selectedValue={rightSideTabSelectedValue}
        onSelectedValueChanged={changeRightSideTab}
      />
      <div className='p-4 flex-1 flex flex-col overflow-auto'>
        <DrawingViewers visible={rightSideTabSelectedValue === EIanRightSideTab.DRAWINGS} objectItem={notification} drawings={notificationDrawings} setSelectedDrawing={setSelectedDrawing} selectedDrawing={selectedDrawing}/>
        <div className={`h-full w-full ${rightSideTabSelectedValue === EIanRightSideTab.LONG_DESCRIPTION ? 'flex flex-col gap-2' : 'hidden'}`}>
          <LongDescriptionBlock notification={notification} readonly={readonly}/>
          <NotificationAttachments notification={notification} readonly={readonly}/>
        </div>
      </div>
    </div>
  );
}
