import {useTranslation} from 'react-i18next';
import PanelFilters from '@app/components/Common/Panel/PanelFilters';
import {TLabelValue} from '@app/types/app';
import PrimaryButton from '@app/components/Common/Button/ElevatedButton/PrimaryButton';
import {EEventFilter} from '@app/utils/enums';

type TFilters = {
    readonly onChange?: (item: TLabelValue<EEventFilter>[]) => void
    readonly value?: (TLabelValue<EEventFilter>|EEventFilter)[]
}

export default function Filters({onChange, value}: TFilters) {
  const {t} = useTranslation();
  const filters: TLabelValue<EEventFilter>[] = [
    {
      label: t('label.processing'),
      value: EEventFilter.PROCESSING,
    },
    {
      label: t('label.completed'),
      value: EEventFilter.COMPLETED,
    },
    {
      label: t('label.cancelled'),
      value: EEventFilter.CANCELLED,
    },
  ];

  const renderItem = (item: TLabelValue<EEventFilter>) => {
    const strValues: EEventFilter[] = value?.map((val: TLabelValue<EEventFilter>|EEventFilter) => (val as TLabelValue<EEventFilter>).value ?? (val as EEventFilter)) ?? [];
    return <PrimaryButton className={`font-bold ${!strValues.includes(item.value) ? 'text-gray-400 bg-gray-100 border-none hover:bg-secondary hover:text-white hover:bg-opacity-80' : ''}`}>{item.label}</PrimaryButton>;
  };

  return (
    <PanelFilters<EEventFilter>
      isMultiple
      items={filters}
      value={value}
      className='flex-grow justify-end flex-row gap-2 mr-3'
      renderItem={renderItem}
      onChange={onChange}
    />
  );
}

