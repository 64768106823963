import {gql} from '@apollo/client';
import {INTEGRITY_REF_CONDITIONS_COMMON_FIELDS} from './refIntegrityConditions';

export const CML_REF_VAL_CODES_COMMON_FIELDS = gql`
  ${INTEGRITY_REF_CONDITIONS_COMMON_FIELDS}
  fragment RefCmlValCodeCommonFields on RefCmlValCode {
    id
    description
    valCode
    codeCond
    isActive
    integrityCondition {
      ...RefIntegrityConditionCommonFields
    }
}`;
