import {useLazyQuery} from '@apollo/client';
import AppCheckbox from '@app/components/Common/Form/Checkbox';
import {Grid, TGridProps} from '@app/components/Common/Grid';
import IdwgModal from '@app/components/Modal/Documentation/idwg';
import {GetAllInspectionDrawingViewsQuery, InspectionDrawing} from '@app/graphql/__types__/graphql';
import {INSPECTION_DRAWING_VIEWS_GET_ALL} from '@app/graphql/requests';
import {useDataStore} from '@app/stores/data';
import {useInspectionDrawingStore} from '@app/stores/methodEngineering/inspectionDrawing';
import {OBJ_NEW_ID, ROUTE_DOCUMENTATION_IDWG_DETAIL} from '@app/utils/constants';
import {RadButton} from '@holis/react-ui/rad';
import TNestedKeys from 'node_modules/@holis/react-ui/dist/src/components/List/TreeList/types/TNestedKeys';
import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {LuPlusCircle} from 'react-icons/lu';
import {MdOpenInNew} from 'react-icons/md';
import {useNavigate} from 'react-router-dom';
export default function IdwgPage() {
  const navigate = useNavigate();
  const {selectedSites} = useDataStore();
  const {inspectionDrawings, setInspectionDrawings, creatingIdwg, setActiveInspectionDrawing, setFetchInspectionDrawings} = useInspectionDrawingStore();
  const {t} = useTranslation();
  const [getIdwgListApi, {loading, error}] = useLazyQuery<GetAllInspectionDrawingViewsQuery>(INSPECTION_DRAWING_VIEWS_GET_ALL);

  const fetchInspectionDrawings = () => {
    if (selectedSites?.length) {
      getIdwgListApi({
        variables: {
          sites: selectedSites,
          orderBy: [
            {
              id: 'desc',
            },
          ],
        },
        fetchPolicy: 'no-cache',
      }).then(queryResults => setInspectionDrawings((queryResults.data?.inspectionDrawingViews ?? []) as InspectionDrawing[]));
    } else {
      setInspectionDrawings([]);
    }
  };

  useEffect(() => {
    fetchInspectionDrawings();
    setFetchInspectionDrawings(fetchInspectionDrawings);
  }, [selectedSites]);

  const handleNewIdwg = () => {
    setActiveInspectionDrawing({
      id: OBJ_NEW_ID,
    }, true);
  };

  const gridProps: TGridProps = useMemo(() => ({
    columns: [
      {
        field: 'action',
        title: t('label.action'),
        type: 'string',
        width: 75,
        cellRenderer: 'action',
        titleHidden: true,
        filter: false,
      },
      {
        field: 'id',
        title: 'Id',
        type: 'number',
        width: 106,
        hidden: true,
      },
      {
        field: 'sector_site_site',
        title: t('label.site'),
        type: 'string',
        width: 112,
      },
      {
        field: 'sector_sector',
        title: t('label.sector'),
        type: 'string',
        width: 105,
      },
      {
        field: 'idwg',
        title: t('label.reference'),
        type: 'string',
        width: 250,
        cellRenderer: 'document',
        sort: 'asc',
      },
      {
        field: 'description',
        title: t('label.description'),
        type: 'string',
        width: 484,
      },
      {
        field: 'type_type',
        title: t('label.type'),
        type: 'string',
        width: 135,
      },
      {
        field: 'revision',
        title: t('label.revision'),
        type: 'string',
        width: 115,
      },
      {
        field: 'flagStatus',
        title: t('label.status'),
        type: 'string',
        width: 170,
        cellRenderer: 'flagStatus',
        hidden: true,
      },
      {
        field: 'docName',
        title: t('label.docName'),
        type: 'string',
        width: 400,
      },
    ],
    cellRenderers: {
      action: (_val: string, rowData: Partial<InspectionDrawing>) => (
        <RadButton
          size='icon'
          variant='outline'
          className='size-fit p-1'
          onClick={() => navigate(ROUTE_DOCUMENTATION_IDWG_DETAIL.replace(':number', `${rowData.idwg}`))}
        >
          <MdOpenInNew size={18}/>
        </RadButton>
      ),
      document: (val: string) => <span className='font-bold'>{val}</span>,
      damage: (val: unknown) => <AppCheckbox disabled checked={val as boolean}/>,
      flagStatus: (val: string) => val ? t(`label.flagStatus.${val}`) : '',
    },
  }), [inspectionDrawings]);

  const columnsAlwaysVisible: TNestedKeys<InspectionDrawing>[] = useMemo(() => ([
    'action',
    'description',
  ]), [inspectionDrawings]);

  return (
    <div className='h-full'>
      {creatingIdwg && (
        <IdwgModal
          isOpen={creatingIdwg}
          onOpenChange={isOpen => {
            if (!isOpen) {
              setActiveInspectionDrawing(undefined);
            }
          }}
        />
      )}

      <Grid
        gridName='methodEngineering-inspectionDrawingsGrid'
        queryData={{
          data: inspectionDrawings ?? [],
          loading,
          error,
        }}
        gridProps={gridProps}
        title={<div className='text-lg font-semibold'>{t('label.inspectionDrawing')}</div>}
        columnsAlwaysVisible={columnsAlwaysVisible}
      >
        <RadButton
          size='sm'
          className='flex gap-1 text-sm'
          onClick={handleNewIdwg}
        >
          <LuPlusCircle/> {t('label.addDrawing')}
        </RadButton>
      </Grid>

      {/* {!!activeInspectionDrawing && <InspectionDrawingModal open item={activeInspectionDrawing} onClose={resetData}/>} */}
    </div>
  );
}
