import {gql} from '@apollo/client';
import {FLOC_REF_PROCESSES_COMMON_FIELDS} from '../fragments';

export const FLOC_REF_PROCESSES_GET_MANY = gql`
${FLOC_REF_PROCESSES_COMMON_FIELDS}
query GetAllRefFlocProcesses {
  refFlocProcesses {
    ...RefFlocProcessCommonFields
  }
}`;

export const FLOC_REF_PROCESSES_GET_COUNTER = gql`
query GetRefFlocProcessesCounter {
  aggregateRefFlocProcess {
    _count {
      id
    }
  }
}
`;
