import {gql} from '@apollo/client';
import {MEASPOINT_REF_VAL_CODES_COMMON_FIELDS} from '../fragments/refMeasPointValCodes';

export const MEASPOINT_REF_VAL_CODES_GET_MANY = gql`
${MEASPOINT_REF_VAL_CODES_COMMON_FIELDS}
query GetAllRefMeasPointValCodes($orderBy: [RefMeasPointValCodeOrderByWithRelationInput!], $where: RefMeasPointValCodeWhereInput) {
    refMeasPointValCodes(orderBy: $orderBy, where: $where) {
      ...RefMeasPointValCodeCommonFields
      codeGroupValCodes {
        id
        codeGroupId
      }
    }
}`;

export const MEASPOINT_REF_VAL_CODES_GET_COUNTER = gql`
query GetRefMeasPointValCodesCounter {
  aggregateRefMeasPointValCode {
    _count {
      id
    }
  }
}
`;
