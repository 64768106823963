import {gql} from '@apollo/client';
import {GRIDS_AUTOCOMPLETE_COMMON_FIELDS} from '../fragments/grids';

export const GRIDS_GET_ALL_AUTOCOMPLETE = gql`
  ${GRIDS_AUTOCOMPLETE_COMMON_FIELDS}
  query GetAllGridsAutocomplete {
    grids {
      ...GridAutocompleteCommonFields
    }
  }
`;

export const GRIDS_GET_BY_IDWG_ID = gql`
  ${GRIDS_AUTOCOMPLETE_COMMON_FIELDS}
  query GetGridsByIdwgId($idwgId: Int!) {
    grids(where: {idwgGrids: {some: {idwgId: {equals: $idwgId}}}}) {
      ...GridAutocompleteCommonFields
    }
  }
`;

export const GRIDS_GET_BY_SECTOR_ID = gql`
  ${GRIDS_AUTOCOMPLETE_COMMON_FIELDS}
  query GetGridsBySectorId($sectorId: Int!) {
    grids(where: {sectorId: {equals: $sectorId}}) {
      ...GridAutocompleteCommonFields
    }
  }
`;

export const GRIDS_GET_COUNTER = gql`
query GetGridsCounter {
  aggregateGrid {
    _count {
      id
    }
  }
}
`;
