import {useLazyQuery} from '@apollo/client';
import IntegrityStatusCard from '@app/components/Common/Block/IntegrityStatus/IntegrityStatusBlock/IntegrityStatusCard';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import {GetIntegrityStatusByFlocIdQuery, IntegrityStatus} from '@app/graphql/__types__/graphql';
import {INTEGRITY_STATUSES_GET_ONE_BY_FLOC_ID} from '@app/graphql/requests';
import {useFlocStore} from '@app/stores/methodEngineering/floc';
import {t} from 'i18next';
import {useEffect, useState} from 'react';
import {LuClipboardList} from 'react-icons/lu';

export default function IntegrityStatusBlock() {
  const {activeFloc} = useFlocStore();
  const [integrityStatus, setIntegrityStatus] = useState<Partial<IntegrityStatus | null>>();
  const [getFlocIntegrityStatusApi] = useLazyQuery<GetIntegrityStatusByFlocIdQuery>(INTEGRITY_STATUSES_GET_ONE_BY_FLOC_ID, {
    variables: {
      flocId: activeFloc!.id!,
    },
    fetchPolicy: 'no-cache',
  });

  const getFlocIntegrityStatus = () => {
    getFlocIntegrityStatusApi().then(queryResult => setIntegrityStatus(queryResult.data?.integrityStatus as Partial<IntegrityStatus> | null));
  };

  useEffect(() => {
    getFlocIntegrityStatus();
  }, []);

  return (
    <div className='flex flex-col gap-2 px-4 mb-6'>
      <FormGroupHeader className='mb-1'><LuClipboardList className='mr-2' size={20}/> {t('label.integrityStatus')}</FormGroupHeader>
      {integrityStatus ? <IntegrityStatusCard integrityStatus={integrityStatus}/> : <div className='flex justify-center'>{t('label.noResult')}</div>}
    </div>
  );
}
