import {gql} from '@apollo/client';

export const FLOC_REF_CLAS_TOS_COMMON_FIELDS = gql`
  fragment RefFlocClasToCommonFields on RefFlocClasTo {
    id
    classId
    techClass
    description
    reportingTemplate
    isActive
}`;
