import {gql} from '@apollo/client';
import {FLOC_CATALOGS_COMMON_FIELDS} from '../fragments';

export const FLOC_CATALOGS_GET_MANY = gql`
${FLOC_CATALOGS_COMMON_FIELDS}
query GetAllRefFlocCatalogs($orderBy: [RefFlocCatalogsOrderByWithRelationInput!], $where: RefFlocCatalogsWhereInput) {
    findManyRefFlocCatalogs(orderBy: $orderBy, where: $where) {
      ...RefFlocCatalogsCommonFields
    }
}`;

export const FLOC_CATALOGS_GET_COUNTER = gql`
query GetRefFlocCatalogsCounter {
  aggregateRefFlocCatalogs {
    _count {
      id
    }
  }
}
`;
