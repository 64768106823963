import {localeIncludes, reactElementToString} from '@app/utils/functions';
import {useDebounce} from '@app/utils/hooks/useDebounce';
import {RadCombobox, RadComboboxContent, RadComboboxGroup, RadComboboxItem, RadComboboxTrigger, RadVirtualItem, RadVirtualList} from '@holis/react-ui/rad';
import {Property} from 'csstype';
import {useRef, useState, useMemo, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {AppListItem} from '../list-item';
import styled from 'styled-components';
import _, {ListIterator, Many, NotVoid} from 'lodash';

type TAppCombobox<ItemType = unknown> = Readonly<{
  items: AppListItem<ItemType>[];
  value?: string | number | null;
  disabled?: boolean;
  required?: boolean;
  popOverWidth?: Property.Width<string | number>;
  placeholder?: string;
  className?: string;
  noDataLabel?: string;
  emptyValue?: string | number;
  loading?: boolean;
  loadingLabel?: string;
  variant?: 'holis';
  sortDisabled?: boolean;
  sortFields?: boolean;
  sortIteratees?: Many<ListIterator<AppListItem<ItemType>, NotVoid>>;
  sortOrders?: Many<boolean|'asc'|'desc'>;
  onSelectedItemChanged?: (item?: AppListItem<ItemType>) => void;
  onChange?: (value?: string | number | null) => void;
}>

const StyledRadComboboxContent = styled(RadComboboxContent)`
  [role=option] svg {
    min-width: 1rem;  
  }
`;

export default function AppCombobox<ItemType = unknown>({
  items,
  placeholder,
  onSelectedItemChanged,
  onChange,
  required,
  popOverWidth,
  noDataLabel,
  className,
  disabled,
  emptyValue = -1,
  loading,
  loadingLabel,
  value,
  variant,
  sortDisabled,
  sortIteratees,
  sortOrders,
}: TAppCombobox<ItemType>) {
  const {t} = useTranslation();

  const scrollerRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<AppListItem>();
  const [filter, setFilter] = useState<string>('');
  const [opened, setOpened] = useState<boolean>(false);
  const [sortedItems, setSortedItems] = useState<AppListItem[]>();

  const search = useDebounce(filter, 200);

  const filteredItems = useMemo(() => (sortDisabled ? items : _.orderBy(items, sortIteratees ?? ['label'], sortOrders)).filter(i => {
    const label = i.label || reactElementToString(i.render!);
    return localeIncludes(label, search);
  }).map(item => (
    <RadVirtualItem key={item.value} itemHeight={32}>
      <RadComboboxItem value={item.value as string} disabled={item.disabled}>
        <div className='line-clamp-1' title={item.label}>
          {item.render ?? item.label}
        </div>
      </RadComboboxItem>
    </RadVirtualItem>
  )), [search, sortedItems]);

  const onOpenChange = useCallback((open: boolean) => {
    setOpened(open);
    if (!open) {
      setFilter('');
    }
  }, [setFilter]);

  useEffect(() => {
    const selected = items.find(i => i.value === value);
    setSelected(selected);
  }, [value, items]);

  useEffect(() => {
    setSortedItems((sortDisabled ? items : _.orderBy(items, sortIteratees ?? ['label'], sortOrders)));
  }, [items]);

  const handleValueChange = (value: string) => {
    // eslint-disable-next-line eqeqeq
    const selectedItem = items.find(i => i.value == value);
    const newSelected = !required && value === emptyValue ? undefined : selectedItem;
    setSelected(newSelected);
    onSelectedItemChanged?.(newSelected);
    onChange?.(newSelected?.value);
    setOpened(false);
  };

  return (
    <RadCombobox value={selected?.value as string ?? emptyValue} open={opened} onOpenChange={onOpenChange} onFilterChange={setFilter} onValueChange={handleValueChange}>
      <RadComboboxTrigger key='trigger' variant={variant} className='text-start' disabled={disabled || loading}>
        <div className='overflow-hidden text-ellipsis'>
          {
            loading
              ? <span className='text-muted-foreground'>{loadingLabel ?? t('label.loading')}</span>
              : selected
                ? <span title={selected?.label}>{selected?.render ?? selected?.label}</span>
                : <span className='text-muted-foreground'>
                  {placeholder ?? t('label.select')}
                </span>
          }
        </div>
      </RadComboboxTrigger>
      <StyledRadComboboxContent
        key='content'
        ref={scrollerRef}
        loop
        shouldFilter={false}
        popoverWidth={popOverWidth}
        className={className}>
        <RadComboboxGroup>
          <RadVirtualList key='list-item' maxItemsDisplayed={10} scroll={false} scrollRef={scrollerRef}>
            {!required && <RadVirtualItem key={emptyValue} itemHeight={32}>
              <RadComboboxItem value={emptyValue as string} className='text-muted-foreground italic'>{t('label.empty')}</RadComboboxItem>
            </RadVirtualItem>}
            {filteredItems}
          </RadVirtualList>
          {items.length === 0 && <div key='no-data-elem' className='text-muted-foreground p-4'>{noDataLabel ?? t('label.noData')}</div>}
        </RadComboboxGroup>
      </StyledRadComboboxContent>
    </RadCombobox>
  );
}
