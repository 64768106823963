import {twMerge} from 'tailwind-merge';

export type TMeasurementValueContainer = Readonly<{
	measurementValue?: string;
	measurementColor?: string;
  className?: string;
}>;

export default function MeasurementValueContainer({measurementColor, className, measurementValue}: TMeasurementValueContainer) {
  return (
    <div className={twMerge('self-stretch rounded-md p-2 bg-gray-200 min-w-14 text-center flex flex-col justify-center', className)} style={{backgroundColor: measurementColor}}>
      <div className='font-medium whitespace-pre-line'>{measurementValue ?? '-'}</div>
    </div>
  );
}
