import {gql} from '@apollo/client';
import {FLOC_REF_PARTS_COMMON_FIELDS} from '../fragments';

export const FLOC_REF_PARTS_GET_MANY = gql`
${FLOC_REF_PARTS_COMMON_FIELDS}
query GetAllRefFlocParts($orderBy: [RefFlocPartOrderByWithRelationInput!], $where: RefFlocPartWhereInput) {
    refFlocParts(orderBy: $orderBy, where: $where) {
      ...RefFlocPartCommonFields
    }
}`;

export const FLOC_REF_PARTS_GET_COUNTER = gql`
query GetRefFlocPartsCounter {
  aggregateRefFlocPart {
    _count {
      id
    }
  }
}
`;
