import {DraggableAttributes} from '@dnd-kit/core';
import {SyntheticListenerMap} from '@dnd-kit/core/dist/hooks/utilities';
import {MdOutlineDragIndicator} from 'react-icons/md';

export type TDragHandle = Readonly<{
	className?: string;
	attributes?: DraggableAttributes;
	listeners?: SyntheticListenerMap;
}>;

export default function DragHandle(props: TDragHandle) {
  return (
    <div className={props.className}>
      <MdOutlineDragIndicator size={24} className='-ml-[6px] text-gray-300 hover:cursor-move focus:outline-none' {...props.attributes} {...props.listeners}/>
    </div>
  );
}
