import {useMutation} from '@apollo/client';
import {IConfirmModal} from '@app/components/Modal/Confirm';
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning';
import {DeleteOneIdwgGridMutation, InspectionDrawing} from '@app/graphql/__types__/graphql';
import {INSPECTION_DRAWINGS_DELETE_IDWG_GRID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import useIdwgStore, {IDWG_PREFIX} from '@app/stores/idwg';
import {useLayoutStore} from '@app/stores/layout';
import {useTranslation} from 'react-i18next';

type TDeleteIdwgGridModal = Readonly<IConfirmModal & {
  idwg: Partial<InspectionDrawing>;
  onDeleted?: () => void;
}>;

export default function DeleteIdwgGridModal({idwg, onDeleted, ...restProps}: TDeleteIdwgGridModal) {
  const {setGridToDelete, gridToDelete, deleteIdwgGrid} = useIdwgStore(`${IDWG_PREFIX}${idwg.id}`);
  const {startLoading, stopLoading} = useLayoutStore();
  const [deleteIdwgGridApi] = useMutation<DeleteOneIdwgGridMutation>(INSPECTION_DRAWINGS_DELETE_IDWG_GRID);
  const {t} = useTranslation();
  const onConfirm = () => {
    if (gridToDelete) {
      startLoading();
      deleteIdwgGridApi({
        variables: {
          idwgId: idwg!.id,
          gridId: gridToDelete!.id!,
        },
      }).then(() => {
        deleteIdwgGrid();
        onDeleted?.();
        AppNotifications.success(t('message.success.deleteIdwgGrid'));
      }).catch((_err: Error) => {
        AppNotifications.error(t('message.error.deleteIdwgGrid'));
      }).finally(() => {
        stopLoading();
      });
    }
  };

  return (
    <WarningConfirmModal
      description={t('message.questions.deleteIdwgGrid')}
      title={t('message.questions.areYouAbsolutlySure')}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      onClose={() => setGridToDelete(undefined)}
      {...restProps}
    />
  );
}
