import {Transition} from '@headlessui/react';
import {PropsWithChildren} from 'react';

type TEaseLinearTransition = Readonly<PropsWithChildren & {
  isShowing?: boolean;
  className?: string;
}>;

export default function EaseLinearTransition({children, isShowing, className}: TEaseLinearTransition) {
  return (
    <Transition
      enter='transition-opacity ease-linear duration-300'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='transition-opacity ease-linear duration-300'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
      show={isShowing}
      className={className}
    >
      {children}
    </Transition>
  );
}
