import React from 'react';
import {TLabelValue} from '@app/types/app';
import {RadToggleGroup, RadToggleGroupItem} from '@holis/react-ui/rad';

type TPanelFilters<TValue = string> = Readonly<{
    items: TLabelValue<TValue>[],
    value?: (TLabelValue<TValue>|TValue)[] | TLabelValue<TValue> | TValue,
    onChange?: (value: TLabelValue<TValue>[]) => void
    renderItem?: (item: TLabelValue<TValue>) => React.ReactNode
    itemClassName?: string;
    className?: string;
    isMultiple?: boolean;
}>;

export default function PanelFilters<TValue = string>({items, onChange, value, isMultiple}: TPanelFilters<TValue>) {
  const handleValueChanged = (val: string[] | string) => {
    if (onChange) {
      if (isMultiple) {
        onChange(items.filter(item => val.includes(item.value as string)));
      } else {
        onChange([items.find(item => item.value === val) as TLabelValue<TValue>]);
      }
    }
  };

  const val = Array.isArray(value) ? value.map((val: TLabelValue<TValue>|TValue) => (val as TLabelValue<TValue>).value ?? val ?? '') : [value ?? ''];

  const itemClass = (item: TLabelValue<TValue>) => {
    const selected = val.includes(item.value as string);
    const base = 'h-7 font-normal';
    return `${base} ${selected ? '!bg-blue-50 !text-blue-700' : 'text-gray-500'}`;
  };

  return (
    <div className='border rounded-md p-1'>
      {isMultiple
        ? (
          <RadToggleGroup type='multiple' value={val as string[]} onValueChange={handleValueChanged}>
            {items.map((item: TLabelValue<TValue>) => (
              <RadToggleGroupItem key={`item-${item.value}`} value={item.value as string} className={itemClass(item)}>
                {item.label}
              </RadToggleGroupItem>
            ))}
          </RadToggleGroup>
        )
        : (
          <RadToggleGroup type='single' value={val[0] as string} onValueChange={handleValueChanged}>
            {items.map((item: TLabelValue<TValue>) => (
              <RadToggleGroupItem key={`item-${item.value}`} value={item.value as string} className={itemClass(item)}>
                {item.label}
              </RadToggleGroupItem>
            ))}
          </RadToggleGroup>
        )}</div>
  );
}
