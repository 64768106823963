import {useMutation} from '@apollo/client';
import {IConfirmModal} from '@app/components/Modal/Confirm';
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning';
import {DeleteOneIdwgFlocMutation, InspectionDrawing} from '@app/graphql/__types__/graphql';
import {INSPECTION_DRAWINGS_DELETE_IDWG_FLOC} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import useIdwgStore, {IDWG_PREFIX} from '@app/stores/idwg';
import {useLayoutStore} from '@app/stores/layout';
import {useTranslation} from 'react-i18next';

type TDeleteIdwgFlocModal = IConfirmModal & Readonly<{
  idwg: Partial<InspectionDrawing>;
  onDeleted?: () => void;
}>;

export default function DeleteIdwgFlocModal({idwg, onDeleted, ...restProps}: TDeleteIdwgFlocModal) {
  const {setFlocToDelete, flocToDelete, deleteIdwgFloc} = useIdwgStore(`${IDWG_PREFIX}${idwg.id}`);
  const {startLoading, stopLoading} = useLayoutStore();
  const [deleteIdwgFlocApi] = useMutation<DeleteOneIdwgFlocMutation>(INSPECTION_DRAWINGS_DELETE_IDWG_FLOC);
  const {t} = useTranslation();
  const onConfirm = () => {
    if (flocToDelete) {
      startLoading();
      deleteIdwgFlocApi({
        variables: {
          idwgId: idwg!.id,
          flocId: flocToDelete!.id!,
        },
      }).then(() => {
        deleteIdwgFloc();
        onDeleted?.();
        AppNotifications.success(t('message.success.deleteIdwgFloc'));
      }).catch((_err: Error) => {
        AppNotifications.error(t('message.error.deleteIdwgFloc'));
      }).finally(() => {
        stopLoading();
      });
    }
  };

  return (
    <WarningConfirmModal
      description={t('message.questions.deleteIdwgFloc')}
      title={t('message.questions.areYouAbsolutlySure')}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      onClose={() => setFlocToDelete(undefined)}
      {...restProps}
    />
  );
}
