import {useQuery} from '@apollo/client';
import {
  EventCml,
  EventDamage,
  EventInspectionPoint,
  GetAllRefIntegrityConditionsQuery,
  RefIntegrityCondition,
} from '@app/graphql/__types__/graphql';
import {INTEGRITY_REF_CONDITIONS_GET_MANY} from '@app/graphql/requests';
import useCmlStore from '@app/stores/cml';
import {EINTEGRITY_REF_CONDITION_CODE} from '@app/utils/enums';
import {RadChartContainer, RadChartTooltip} from '@holis/react-ui/rad';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {CartesianGrid, Label, Line, LineChart, ReferenceArea, ReferenceLine, TooltipProps, XAxis, YAxis} from 'recharts';
import {NameType, ValueType} from 'recharts/types/component/DefaultTooltipContent';

type TChartDataPoint<TItem extends Partial<EventCml | EventDamage | EventInspectionPoint>> = {
  reportingDate: number;
  quantReading?: number;
  reader?: TItem['reader'];
};

type TChartLine<TItem extends Partial<EventCml | EventDamage | EventInspectionPoint>> = {
  name: string;
  data: TChartDataPoint<TItem>[];
};

type TProps<TItem extends Partial<EventCml | EventDamage | EventInspectionPoint>> = Readonly<{
  items: Partial<TItem>[];
  eventCml?: Partial<EventCml>;
  beforeChartComponent?: React.ReactNode;
}>;

export default function EventMeasurementTableChart<
  TItem extends Partial<EventCml | EventDamage | EventInspectionPoint>,
>(props: TProps<TItem>) {
  const {t} = useTranslation();
  const {editCml} = useCmlStore();

  const refIntegrityCondResult = useQuery<GetAllRefIntegrityConditionsQuery>(INTEGRITY_REF_CONDITIONS_GET_MANY);

  const integrityConditions = refIntegrityCondResult.data?.refIntegrityConditions;
  const nominalCondition: Partial<RefIntegrityCondition> | null | undefined = integrityConditions
    ?.find((item: Partial<RefIntegrityCondition>) => item.condition === EINTEGRITY_REF_CONDITION_CODE.NOMINAL);
  const alarm1Condition: Partial<RefIntegrityCondition> | null | undefined = integrityConditions
    ?.find((item: Partial<RefIntegrityCondition>) => item.condition === EINTEGRITY_REF_CONDITION_CODE.ALARM1);
  const alarm2Condition: Partial<RefIntegrityCondition> | null | undefined = integrityConditions
    ?.find((item: Partial<RefIntegrityCondition>) => item.condition === EINTEGRITY_REF_CONDITION_CODE.ALARM2);
  const alarm3Condition: Partial<RefIntegrityCondition> | null | undefined = integrityConditions
    ?.find((item: Partial<RefIntegrityCondition>) => item.condition === EINTEGRITY_REF_CONDITION_CODE.ALARM3);

  const {
    chartData,
    shouldRender,
  }: {
    chartData: TChartLine<TItem>[],
    shouldRender: boolean,
  } = useMemo(() => {
    const chartData: TChartLine<TItem>[] = [
      {
        name: 'line1',
        data: [],
      },
      {
        name: 'line2',
        data: [],
      },
    ];

    if (!props.items.length) {
      return {
        chartData,
        shouldRender: false,
      };
    }

    const sortedItems = [];

    props.items.forEach(item => {
      if (isItemEventCml(item) && !item.cancelled) {
        sortedItems.push(item);
      }
    });

    if (props.eventCml) {
      sortedItems.push(props.eventCml as TItem);
    }

    sortedItems.sort((a, b) => (a.reportingDate ?? 0) - (b.reportingDate ?? 0));

    const data = getPointsFromSortedItems(sortedItems);
    chartData[0].data = data[0];
    chartData[1].data = data[1];

    return {
      chartData,
      shouldRender: (chartData[0].data.length > 0 || chartData[1].data.length > 0),
    };
  }, [props.items]);

  const {
    yAxisTicks,
    defaultLowestTick,
    defaultHighestTick,
    maxEndDate,
  }: {
    yAxisTicks: number[],
    defaultLowestTick: number,
    defaultHighestTick: number,
    maxEndDate?: number,
  } = useMemo(() => {
    const yAxisTicks: number[] = [];
    const allYAxisTicks: number[] = [];
    let maxEndDate;

    if (editCml) {
      allYAxisTicks.push(
        editCml.nominal,
        editCml.alarm1,
        editCml.alarm2,
        editCml.alarm3,
      );

      maxEndDate = new Date(editCml.maxEndDate ?? 0).getTime();
    }

    const defaultLowestTick = (editCml?.alarm3 && parseInt(editCml.alarm3, 10) > 2) ? parseInt(editCml.alarm3, 10) - 2 : 0;
    const defaultHighestTick = parseInt(editCml?.nominal ?? '0', 10) + 2;

    let displayDefaultLowestTick = true;
    let displayDefaultHighestTick = true;

    const allDataPoints: TChartDataPoint<TItem>[] = [...chartData[0].data, ...chartData[1].data];

    allDataPoints.forEach(dataPoint => {
      if (dataPoint?.quantReading === undefined) {
        return;
      }

      if (dataPoint.quantReading <= defaultLowestTick) {
        displayDefaultLowestTick = false;
      }

      if (dataPoint.quantReading >= defaultHighestTick) {
        displayDefaultHighestTick = false;
      }

      allYAxisTicks.push(dataPoint.quantReading);
      allYAxisTicks.push(dataPoint.quantReading);
    });

    if (displayDefaultLowestTick) {
      allYAxisTicks.push(defaultLowestTick);
    }

    if (displayDefaultHighestTick) {
      allYAxisTicks.push(defaultHighestTick);
    }

    const seen: Record<number, boolean> = {};
    allYAxisTicks.forEach(tick => {
      if (seen[tick]) {
        return;
      }

      seen[tick] = true;
      yAxisTicks.push(tick);
    });

    yAxisTicks.sort((a, b) => a - b);

    return {
      yAxisTicks,
      defaultLowestTick,
      defaultHighestTick,
      maxEndDate,
    };
  }, [chartData, editCml]);

  const xAxisTicks: number[] = useMemo(() => {
    if (!chartData[0].data.length && !chartData[1].data.length) {
      return [];
    }

    const allDataPoints: TChartDataPoint<TItem>[] = [...chartData[0].data, ...chartData[1].data];

    const seen: Record<string, boolean> = {};
    const filteredDataPoints = allDataPoints.filter(dataPoint => {
      if (Object.prototype.hasOwnProperty.call(seen, dataPoint.reportingDate)) {
        return false;
      }

      seen[dataPoint.reportingDate] = true;
      return true;
    });

    filteredDataPoints.sort((a, b) => a.reportingDate - b.reportingDate);

    const minData: number = filteredDataPoints[0].reportingDate;
    const maxData: number = filteredDataPoints[filteredDataPoints.length - 1].reportingDate;
    const minYear: number = new Date(minData).getFullYear();
    const lastXAxisValue: number = (maxEndDate && maxData < maxEndDate) ? maxEndDate : maxData;

    const beforeMinData: number = new Date(minYear, new Date(minData).getMonth() - 3).getTime();
    const afterLastXAxisValue: number = new Date(
      new Date(lastXAxisValue).getFullYear(),
      new Date(lastXAxisValue).getMonth() + 3,
    ).getTime();

    const yearInterval: number = new Date(maxData).getFullYear() - minYear;
    const result: number[] = [beforeMinData, minData];

    for (let i = 1; i < yearInterval; i++) {
      result.push(new Date(minYear + i, 0).getTime());
    }

    result.push(lastXAxisValue);
    result.push(afterLastXAxisValue);
    return result;
  }, [chartData]);

  function getPointsFromSortedItems(items: Partial<TItem>[]): TChartDataPoint<TItem>[][] {
    const result: TChartDataPoint<TItem>[][] = [[], []];

    const firstDataPoint = items[0];
    const lastDataPoint = items[items.length - 1];

    if (isItemEventCml(firstDataPoint) && isItemValidDataPoint(firstDataPoint)) {
      result[0].push({
        reportingDate: new Date(firstDataPoint.reportingDate).getTime(),
        quantReading: firstDataPoint.quantReading as number,
        reader: firstDataPoint?.reader,
      });
    }

    if (items.length > 2) {
      const penultimateDataPoint = items[items.length - 2];

      if (isItemEventCml(penultimateDataPoint) && isItemValidDataPoint(penultimateDataPoint)) {
        result[1].push({
          reportingDate: new Date(penultimateDataPoint.reportingDate).getTime(),
          quantReading: penultimateDataPoint.quantReading as number,
          reader: penultimateDataPoint?.reader,
        });
      }
    }

    if (
      items.length > 1
      && isItemEventCml(lastDataPoint)
      && isItemValidDataPoint(lastDataPoint)
    ) {
      result[0].push({
        reportingDate: new Date(lastDataPoint.reportingDate).getTime(),
        quantReading: lastDataPoint.quantReading as number,
        reader: lastDataPoint?.reader,
      });

      result[1].push({
        reportingDate: new Date(lastDataPoint.reportingDate).getTime(),
        quantReading: lastDataPoint.quantReading as number,
        reader: lastDataPoint?.reader,
      });
    }

    return result;
  }

  function isItemEventCml(item: unknown): item is EventCml {
    if (item && typeof (item as EventCml).cmlId === 'number') {
      return true;
    }

    return false;
  }

  function isItemValidDataPoint(item: EventCml): boolean {
    if (
      item.reportingDate
      && item.quantReading !== undefined
      && item.quantReading !== null
    ) {
      return true;
    }

    return false;
  }

  function CustomTooltip<
      TValue extends ValueType,
      TName extends NameType,
    >({active, payload, label}: TooltipProps<TValue, TName>) {
    if (active && payload && payload.length) {
      const labelAsDateString = new Date(label).toLocaleDateString();

      return (
        <div className='flex items-center gap-6 p-2 rounded drop-shadow-xl bg-background'>
          <div className='grid grid-cols-[auto,auto] gap-x-2'>
            <b>{t('label.date')}</b>
            <span>{labelAsDateString}</span>

            <b>{t('label.meas')}</b>
            <span>{payload[0].value} mm</span>

            {payload[0].payload.reader && (
              <>
                <b>{t('label.reader')}</b>
                <span>{payload[0].payload.reader}</span>
              </>
            )}
          </div>
        </div>
      );
    }

    return null;
  }

  const xAxisTickFormatter = (value: number) => new Date(value).toLocaleDateString();

  return (
    <div className='flex flex-col'>
      {props.beforeChartComponent}

      {!shouldRender ? (
        <div className='flex justify-center items-center w-full h-[200px]'>
          {t('label.chartUnavailable')}
        </div>
      ) : (<RadChartContainer className='min-h-[200px]' config={{}}>
        <LineChart
          accessibilityLayer
          margin={{
            top: 15,
            left: 15,
            right: 55,
          }}
        >
          <CartesianGrid
            strokeDasharray='3 3'
            strokeWidth={2}
            className='!stroke-border'
          />

          <YAxis
            dataKey='quantReading'
            type='number'
            domain={[defaultLowestTick, defaultHighestTick]}
            ticks={yAxisTicks}
            unit=' mm'
            tickMargin={8}
          />

          <XAxis
            dataKey='reportingDate'
            type='number'
            domain={['dataMin', 'dataMax']}
            ticks={xAxisTicks}
            tickFormatter={xAxisTickFormatter}
            tickMargin={8}
          />

          <ReferenceArea
            y1={editCml?.alarm1}
            fill={nominalCondition?.color ?? undefined}
            opacity={0.5}
          >
            <Label position='right'>
              {t('label.nominal')}
            </Label>
          </ReferenceArea>

          <ReferenceArea
            y1={editCml?.alarm2}
            y2={editCml?.alarm1}
            fill={alarm1Condition?.color ?? undefined}
            opacity={0.5}
          >
            <Label position='right'>
              {t('label.alarm1')}
            </Label>
          </ReferenceArea>

          <ReferenceArea
            y1={editCml?.alarm3}
            y2={editCml?.alarm2}
            fill={alarm2Condition?.color ?? undefined}
            opacity={0.5}
          >
            <Label position='right'>
              {t('label.alarm2')}
            </Label>
          </ReferenceArea>

          <ReferenceArea
            y2={editCml?.alarm3}
            fill={alarm3Condition?.color ?? undefined}
            opacity={0.5}
          >
            <Label position='right'>
              {t('label.alarm3')}
            </Label>
          </ReferenceArea>

          {maxEndDate && (
            <ReferenceLine
              x={maxEndDate}
              strokeDasharray='2 2'
              style={{stroke: 'hsl(var(--foreground))'}}
              ifOverflow='extendDomain'
            >
              <Label position='top'>
                {new Date(maxEndDate).toLocaleDateString()}
              </Label>
            </ReferenceLine>
          )}

          <RadChartTooltip
            cursor={false}
            content={<CustomTooltip/>}
          />

          <Line
            dataKey='quantReading'
            data={chartData[0].data}
            type='linear'
            strokeWidth={2}
            activeDot={{r: 6}}
          />

          <Line
            dataKey='quantReading'
            data={chartData[1].data}
            type='linear'
            strokeWidth={2}
            activeDot={{r: 6}}
          />
        </LineChart>
      </RadChartContainer>
      )}
    </div>
  );
}
