import {gql} from '@apollo/client';

export const DAMAGE_REF_CODE_GROUP_FLOCS_GET_COUNTER = gql`
query GetRefDamageCodeGroupFlocsCounter {
  aggregateRefDamageCodeGroupFloc {
    _count {
      id
    }
  }
}
`;
