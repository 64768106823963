import {gql} from '@apollo/client';
import {FLOC_REF_PARTS_COMMON_FIELDS} from './refFlocParts';
import {GRIDS_AUTOCOMPLETE_COMMON_FIELDS} from './grids';
import {DAMAGE_REF_CODE_GROUPS_COMMON_FIELDS} from './refDamageCodeGroups';
import {FLOCS_AUTOCOMPLETE_COMMON_FIELDS} from './flocs';
import {FLOC_CATALOGS_COMMON_FIELDS} from './refFlocCatalogs';
import {NOTIFICATIONS_COMMON_FIELDS} from './notifications';
import {WORKORDER_REF_EVENT_TECHNIQUES_COMMON_FIELDS} from './refEventTechniques';

export const DAMAGES_COMMON_FIELDS = gql`
    ${FLOC_REF_PARTS_COMMON_FIELDS}
    ${GRIDS_AUTOCOMPLETE_COMMON_FIELDS}
    ${DAMAGE_REF_CODE_GROUPS_COMMON_FIELDS}
    ${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
    ${FLOC_CATALOGS_COMMON_FIELDS}
    ${NOTIFICATIONS_COMMON_FIELDS}
    ${WORKORDER_REF_EVENT_TECHNIQUES_COMMON_FIELDS}
    fragment DamageCommonFields on Damage {
        id
        position
        longDescription
        markerCoordinates2d
        markerCoordinates3d
        description
        flagStatus
        display2d
        display3d
        coordinates3d
        coordinates2d
        idwgId
        partId
        techniqueId
        technique {
            ...RefEventTechniqueCommonFields
        }
        part {
            ...RefFlocPartCommonFields
        }
        gridId
        grid {
            ...GridAutocompleteCommonFields
        }
        codeGroupId
        codeGroup {
            ...RefDamageCodeGroupCommonFields
        }
        flocId
        functionalLocation {
            ...FlocAutocompleteCommonFields
        }
        accessibilityId
        accessibility {
            ...RefFlocCatalogsCommonFields
        }
        maxEndDate
        notificationDamages {
            notification {
                ...NotificationCommonFields
            }
            notifId
        }
        annotation
    }
`;

export const DAMAGE_VIEWS_COMMON_FIELDS = gql`
    fragment DamageViewCommonFields on DamageView {
        id
        position
        longDescription
        markerCoordinates2d
        markerCoordinates3d
        description
        flagStatus
        display2d
        display3d
        coordinates3d
        coordinates2d
        idwgId
        idwg_idwg
        idwg_description
        partId
        techniqueId
        technique_technique
        technique_description
        part_part
        part_description
        gridId
        grid_grid
        grid_description
        codeGroupId
        codeGroup_codeGroup
        codeGroup_description
        codeGroup_class_class
        codeGroup_class_description
        flocId
        floc_floc
        floc_description
        floc_techClass_techClass
        floc_techClass_description
        floc_techClass_class_class
        floc_techClass_class_description
        floc_classSece
        floc_sector_sector
        floc_sector_site_site
        floc_sector_site_description
        floc_sector_site_longDescription
        accessibilityId
        accessibility_code
        accessibility_description
        maxEndDate
        annotation
        notif_notif
        notif_description
        notif_externalId
        notif_externalStatus
    }
`;
