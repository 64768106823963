import {AddIdwgGridsMutation, Grid, IdwgGrid, InspectionDrawing} from '@app/graphql/__types__/graphql';
import GridItem from './GridItem';
import TextButton from '@app/components/Common/Button/TextButton';
import {FaPlus} from 'react-icons/fa';
import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';
import {FetchResult, useMutation} from '@apollo/client';
import {INSPECTION_DRAWINGS_ADD_IDWG_GRIDS} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useLayoutStore} from '@app/stores/layout';
import _ from 'lodash';
import useIdwgStore, {IDWG_PREFIX} from '@app/stores/idwg';
import GridSelectionModal from '../../../Grid/GridBlock/GridSelectionModal';

type TGridList = Readonly<{
    grids?: Partial<Grid>[];
    idwg: Partial<InspectionDrawing>
}>

export default function GridList({idwg, grids}: TGridList) {
  const {setIdwgGrids, idwgGrids} = useIdwgStore(`${IDWG_PREFIX}${idwg.id!}`);
  const [hiddenIds, setHiddenIds] = useState<number[]>();
  const {startLoading, stopLoading} = useLayoutStore();
  const [gridSelectionModalDisplayed, changeGridSelectionModalDisplay] = useState<boolean>(false);
  // const [gridAutocompleteShown, changeGridAutocompleteDisplay] = useState<boolean>(false);
  // const [addIdwgGrid] = useMutation<AddIdwgGridMutation>(INSPECTION_DRAWINGS_ADD_IDWG_GRID);
  const [addIdwgGrids] = useMutation<AddIdwgGridsMutation>(INSPECTION_DRAWINGS_ADD_IDWG_GRIDS);
  const {t} = useTranslation();
  const addGrids = (items: Partial<Grid>[]) => {
    startLoading();
    addIdwgGrids({
      variables: {
        data: items.map((item: Partial<Grid>) => ({
          gridId: item.id!,
          idwgId: idwg.id!,
        })),
      },
    }).then((_res: FetchResult<AddIdwgGridsMutation>) => {
      const newIdwgGrids: Array<Partial<IdwgGrid>> = _.cloneDeep(idwgGrids ?? []);
      newIdwgGrids.push(...(items.map((item: Partial<Grid>) => ({
        gridId: item.id!,
        idwgId: idwg!.id!,
        grid: {
          ...item,
        },
      })) as Partial<IdwgGrid>[]));
      setIdwgGrids(newIdwgGrids);
      changeGridSelectionModalDisplay(false);
      AppNotifications.success(t('message.success.addIdwgGrid'));
      stopLoading();
    }).catch(_err => {
      stopLoading();
      AppNotifications.error(t('message.error.default.title'));
    });
  };
  // const addGrid = (item: Partial<Grid>) => {
  //   startLoading();
  //   addIdwgGrid({
  //     variables: {
  //       idwgId: idwg!.id!,
  //       gridId: item.id!,
  //     },
  //   }).then((_res: FetchResult<AddIdwgGridMutation>) => {
  //     const newIdwgGrids: Array<Partial<IdwgGrid>> = _.cloneDeep(idwgGrids ?? []);
  //     newIdwgGrids.push({
  //       gridId: item.id!,
  //       idwgId: idwg!.id!,
  //       grid: {
  //         ...item,
  //       },
  //     } as Partial<IdwgGrid>);
  //     setIdwgGrids(newIdwgGrids);
  //     changeGridAutocompleteDisplay(false);
  //     AppNotifications.success(t('message.success.addIdwgGrid'));
  //     stopLoading();
  //   }).catch(_err => {
  //     stopLoading();
  //     AppNotifications.error(t('message.error.default.title'));
  //   });
  // };

  // const toggleShowHideNewGrid = () => {
  //   changeGridAutocompleteDisplay(!gridAutocompleteShown);
  // };

  useEffect(() => {
    setHiddenIds(grids?.map((item: Partial<Grid>) => item.id!));
  }, [grids]);

  return (
    <div className='flex flex-col gap-2'>
      {grids?.map((grid: Partial<Grid>) => <GridItem key={`grid-item-${grid.id!}`} hasActionButtons idwg={idwg} grid={grid}/>)}
      {/* <div className={`w-full ${gridAutocompleteShown ? 'block' : 'hidden'}`}>
        <GridAutocomplete key={`grid-autocomplete-${hiddenIds?.join('-') ?? ''}`} idwg={idwg} hiddenIds={new Set<number>(hiddenIds)} className='bg-gray-100' onSelect={addGrid}/>
      </div> */}
      {gridSelectionModalDisplayed && <GridSelectionModal open hiddenIds={hiddenIds} closeOnConfirm={false} onValidate={addGrids} onClose={() => changeGridSelectionModalDisplay(false)}/>}
      <TextButton className='-ml-4' onClick={() => changeGridSelectionModalDisplay(true)}><FaPlus/> {t('label.addGRID')}</TextButton>
    </div>
  );
}
