import {gql} from '@apollo/client';
import {NOTIFICATION_REF_CONSEQUENCE_COMMON_FIELDS} from '../fragments';

export const NOTIFICATION_REF_CONSEQUENCES_GET_MANY = gql`
${NOTIFICATION_REF_CONSEQUENCE_COMMON_FIELDS}
query GetAllRefNotifConsequences($orderBy: [RefNotifConsequenceOrderByWithRelationInput!], $where: RefNotifConsequenceWhereInput) {
    refNotifConsequences (orderBy: $orderBy, where: $where) {
      ...RefNotifConsequenceCommonFields
    }
}`;

export const NOTIFICATION_REF_CONSEQUENCES_GET_COUNTER = gql`
query GetRefNotifConsequencesCounter {
  aggregateRefNotifConsequence {
    _count {
      _all
    }
  }
}
`;
