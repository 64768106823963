import EventInspectionPointsHistory from './EventInspectionPointsHistory';
import {Event, InspectionPoint} from '@app/graphql/__types__/graphql';

type TEventInspectionPointBlock = Readonly<{
  inspectionPoint: Partial<InspectionPoint>;
  event?: Partial<Event>
}>

export default function EventInspectionPointBlock({inspectionPoint, event}: TEventInspectionPointBlock) {
  return (
    <div className='flex flex-col w-full px-2'>
      <EventInspectionPointsHistory event={event} inspectionPoint={inspectionPoint}/>
    </div>
  );
}
