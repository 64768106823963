import Axios from 'axios';
import {setupCache} from 'axios-cache-interceptor';

const instance = Axios.create({
  // headers: {
  //   'Content-Type': 'application/json',
  //   Accept: 'application/json',
  // },
  withCredentials: false,
});
const axios = setupCache(instance);
export default axios;
