import {Cml, Damage, InspectionDrawing} from '@app/graphql/__types__/graphql';
import {TFormState, TObjectDrawingViewerState} from '@app/types/app';
import {Draft} from 'immer';
import {EDamageFilter} from '../enums';

export const generateCommonFormStore = <T>(set: (nextStateOrUpdater: TFormState<T> | Partial<TFormState<T>> | ((state: Draft<TFormState<T>>) => void), shouldReplace?: boolean | undefined) => void, _get: () => TFormState<T>) => ({
  changeAutoSave(autoSave: boolean) {
    set({autoSave});
  },
  setIsModal(isModal: boolean) {
    set({isModal});
  },
  setItemToDelete(itemToDelete?: T) {
    set({itemToDelete});
  },
});

export const generateCommonObjectDrawingStore = (set: (nextStateOrUpdater: TObjectDrawingViewerState | Partial<TObjectDrawingViewerState> | ((state: Draft<TObjectDrawingViewerState>) => void), shouldReplace?: boolean | undefined) => void, _get: () => TObjectDrawingViewerState) => ({
  setFetchObjectDrawings(fetchObjectDrawings?: () => void) {
    set({fetchObjectDrawings});
  },
  setSelectedDrawing(selectedDrawing?: Partial<InspectionDrawing>) {
    set({selectedDrawing});
  },
  setRightSideTabSelectedValue(rightSideTabSelectedValue: string) {
    set({rightSideTabSelectedValue});
  },
  setDamageSearchInput(damageSearchInput: string) {
    set({damageSearchInput});
  },
  setDamageActiverFilter(damageActiveFilter: EDamageFilter[]) {
    set({damageActiveFilter});
  },
  setRightSideWidth(rightSideWidth?: number) {
    set({rightSideWidth});
  },
  changeLastDamageUpdated(lastDamageUpdated?: Partial<Damage>) {
    set({lastDamageUpdated, lastDamageUpdatedAt: new Date()});
  },
  changeLastCmlUpdated(lastCmlUpdated?: Partial<Cml>) {
    set({lastCmlUpdated, lastCmlUpdatedAt: new Date()});
  },
  changeLastDamagePicUpdated(lastDamagePicUpdated?: Partial<Damage>) {
    set({lastDamagePicUpdated, lastDamagePicUpdatedAt: new Date()});
  },
  changeDamageSelectionModalDisplay(damageSelectionModalDisplayed: boolean) {
    set({damageSelectionModalDisplayed});
  },
});

