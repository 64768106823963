import React from 'react';
import {EWORKORDER_EVENT_STAGE} from '@app/utils/enums';
import {ROUTE_WORKPACK_PREP} from '@app/utils/constants';
import WorkPackEventList from '../components/WorkPackEventList';

export default function WorkPackPreparationPage() {
  return (
    <WorkPackEventList stage={EWORKORDER_EVENT_STAGE.PREPARATION} title='label.preparation' route={ROUTE_WORKPACK_PREP}/>
  );
}
