import {gql} from '@apollo/client';
import {APP_EVENT_STATUSES_COMMON_FIELDS} from '../fragments';

export const APP_EVENT_STATUSES_GET_MANY = gql`
${APP_EVENT_STATUSES_COMMON_FIELDS}
query GetAllEventStatuses($orderBy: [StatusWorkOrderOrderByWithRelationInput!], $where: StatusWorkOrderWhereInput) {
    statusWorkOrders(orderBy: $orderBy, where: $where) {
      ...EventStatusCommonFields
    }
}`;
