import {Cml} from '@app/graphql/__types__/graphql';
import {OBJ_NEW_ID} from '@app/utils/constants';
import {Badge} from '@holis/react-ui';
import {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';

type THeader = Readonly<{
    cml: Partial<Cml>
    actions?: ReactNode
}>

export default function Header({cml, actions}: THeader) {
  const {t} = useTranslation();

  return (
    <div className='flex h-full w-full items-center gap-2 uppercase'>
      <Badge stylePreset={Badge.StylePresets.Outline} className='bg-indigo-100 text-indigo-700 border-indigo-700 flex flex-col justify-center font-normal text-sm uppercase'>{t('label.cml')}</Badge>
      {cml.id !== OBJ_NEW_ID ? (
        <>
          <div className='font-semibold text-base'>{cml.position}</div>
          <div className='line-clamp-1 text-sm'>{cml.description}</div>
        </>
      ) : (
        <div className='font-medium'>{t('label.createCml')}</div>
      )}
      {actions}
    </div>
  );
}
