import SelectionConfirmModal, {TSelectionConfirmModalWithoutListProps} from '@app/components/Modal/Confirm/Selection';
import {Damage} from '@app/graphql/__types__/graphql';
import {TDataRowTreeList} from '@holis/react-ui';
import {useTranslation} from 'react-i18next';
import DamageCard from './DamageCard';
import styled from 'styled-components';
import React from 'react';

const StyledSelectionConfirmModal = styled(SelectionConfirmModal<Partial<Damage>>)`
  & td{
    height: fit-content;
  }
  & td>div{
    height: 100%;
    border: none;
  } 
`;

export default function DamageSelectionModal({items, ...restProps}: TSelectionConfirmModalWithoutListProps<Partial<Damage>>) {
  const {t} = useTranslation();
  return (
    <StyledSelectionConfirmModal
      title={t('label.damage')}
      items={items}
      listProps={{
        listProps: {
          data: (items ?? []) as unknown as TDataRowTreeList[],
          cellRenderers: {
            damage(_value: unknown, rowData: unknown) {
              return <DamageCard key={(rowData as TDataRowTreeList).id} display2dDisabled dmgObject={rowData as Partial<Damage>}/>;
            },
          },
          columns: [
            {
              field: 'damage',
              title: t('label.damage'),
              type: 'string',
              width: 250,
              cellRenderer: 'damage',
              class: 'border-0',
            },
          ],
        },
      }}
      {...restProps}
    />
  );
}
