type TFirstLetterBadge = {
    readonly iconColor: string
    readonly title: string
}

export default function FirstLetterBadge({iconColor, title}: TFirstLetterBadge) {
  return (
    <div className='flex'>
      <div className='flex justify-end w-10'>
        <span className={`flex items-center justify-center w-5 h-5 mr-2 rounded text-white uppercase ${iconColor}`}>
          {title.charAt(0)}
        </span>
      </div>
      <span className='text-sm font-semibold'>
        {title}
      </span>
    </div>
  );
}
