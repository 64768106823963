import {Event, EventInspectionDrawing} from '@app/graphql/__types__/graphql';
import SortableList, {SortableItem} from '@app/components/Common/List/SortableList';
import React, {useEffect} from 'react';
import DrawingViewer from '@app/components/Common/Block/InspectionDrawing/DrawingViewer';
import {useEventStore} from '@app/stores/event';
import {useTranslation} from 'react-i18next';

type TDrawingViewers = Readonly<{
  event: Partial<Event>
  hideActionList?: boolean
  showIdwgDetailButton?: boolean
}>

export default function DrawingViewers({event, hideActionList, showIdwgDetailButton}: TDrawingViewers) {
  const {eventInspectionDrawings, selectedDrawing, inspectionDrawingSortChangeCallback, setSelectedDrawing} = useEventStore();
  const {t} = useTranslation();
  useEffect(() => {
    if (eventInspectionDrawings?.length === 1) {
      setSelectedDrawing(eventInspectionDrawings[0].inspectionDrawing);
    } else {
      setSelectedDrawing();
    }
  }, [eventInspectionDrawings]);

  return (
    <div className={`${selectedDrawing || !eventInspectionDrawings?.length ? 'h-full w-full' : 'grid grid-cols-2 gap-4 overflow-auto'}`}>
      {Array.isArray(eventInspectionDrawings) ? <SortableList
        items={eventInspectionDrawings as SortableItem[]}
        renderItem={(item: SortableItem) => (
          <DrawingViewer
            key={`drawing-viewer-${(item as Partial<EventInspectionDrawing>).id!}`}
            hasActionList={!hideActionList && (eventInspectionDrawings?.length ?? 0) > 1} sortableId={item.id} objectItem={event}
            idwg={(item as Partial<EventInspectionDrawing>).inspectionDrawing!} showIdwgDetailButton={showIdwgDetailButton}
          />
        )}
        onSortChange={inspectionDrawingSortChangeCallback}
      /> : t('label.loading')}
    </div>
  );
}
