import ElevatedButton from '@app/components/Common/Button/ElevatedButton';
import Separator from '@app/components/Common/Separator';
import {Grid, InspectionDrawing} from '@app/graphql/__types__/graphql';
import {FaMinus} from 'react-icons/fa';
import {Button} from '@holis/react-ui';
import styled from 'styled-components';
import useIdwgStore, {IDWG_PREFIX} from '@app/stores/idwg';

type TGridItem = Readonly<{
    grid: Partial<Grid>;
    hasActionButtons?: boolean;
    idwg: Partial<InspectionDrawing>
}>

const StyledContainer = styled.div`
  &:hover .action-buttons{
    opacity: 1;
  }
`;

export default function GridItem({idwg, grid, hasActionButtons}: TGridItem) {
  const {setGridToDelete, gridToDelete} = useIdwgStore(`${IDWG_PREFIX}${idwg.id}`);

  const handleDeleteFlocClick = () => {
    setGridToDelete(grid);
  };

  return (
    <StyledContainer className='flex items-center w-full relative text-xs'>
      {!!hasActionButtons && <div className='transition action-buttons absolute opacity-0 -left-8 px-2'><ElevatedButton className='bg-gray-200 p-1' shapePreset={Button.ShapePresets.Square} disabled={!!gridToDelete} onClick={handleDeleteFlocClick}><FaMinus/></ElevatedButton></div>}
      <div className='flex items-center w-full border rounded border-gray-300 border-w-[0.25px] text-gray-700 py-0.5 px-1'>
        <div className='grid grid-flow-col grid-cols-5 flex-grow'>
          <div className='col-span-2 font-semibold'>{grid.grid}</div>
          <div className='col-span-3 font-light'>{grid.description}</div>
        </div>
        <div className='col-span1 flex items-center gap-2'>
          <Separator className='h-8'/>
          <div className='border bg-gray-200 py-1 px-2 rounded font-normal text-center flex-grow'>
            {grid.sector?.site?.site}
          </div>
        </div>
      </div>
    </StyledContainer>
  );
}
