import {gql} from '@apollo/client';

export const USER_INFOS_COMMON_FIELDS = gql`
    fragment UserInfosCommonFields on UserInfo {
        userId
        username
        firstName
        lastName
        email
    }
`;
