import {gql} from '@apollo/client';

export const MEASPOINT_REF_CODE_GROUP_VAL_CODES_GET_COUNTER = gql`
query GetRefMeasPointCodeGroupValCodesCounter {
  aggregateRefMeasPointCodeGroupValCode {
    _count {
      id
    }
  }
}
`;
